import * as React from 'react';
import { FC } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export type ThemeFontsConfig = {
  family: string;
  color: string;
  weight: string;
  size: string;
  lineHeight: string;
  letterSpacing: string;
  textTransform: string;
  style: string;
  textDecoration: string;
  margin: string;
};
export const Fonts = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body1',
  'body2',
  'subtitle1',
  'subtitle2',
  'fontButton',
  'smallFontButton',
  'caption1',
  'caption2',
  'link',
  'input',
] as const;
export type ThemeFontsAvailable = typeof Fonts[number];
export type ThemeFonts = {
  [font in ThemeFontsAvailable]: ThemeFontsConfig;
};
export type ThemeColors = {
  danger: string;
  success: string;
  lightSuccess: string;
  warning: string;
  lightWarning: string;
  lightDanger: string;
  light: string;
  dark: string;
  grey10: string;
  grey20: string;
  grey30: string;
  grey40: string;
  grey50: string;
  grey60: string;
  grey70: string;
  grey80: string;
  grey90: string;
  grey95: string;
  primary: string;
  invertPrimary: string;
  secondary: string;
  invertSecondary: string;
};
export type ThemeType = {
  colors: ThemeColors;
  input: {
    borderRadius: string;
    backgroundColor: string;
    color: string;
    borderColor: string;
    placeholderColor: string;
  };
  box: {
    borderRadius: string;
  };
  card: {
    padding: string;
  };
  button: {
    small: {
      borderRadius: string;
      padding: string;
    };
    default: {
      borderRadius: string;
      padding: string;
    };
  };
  fonts: ThemeFonts;
  icons: 'back' | 'front';
};
export type ThemeProviderProps = {
  theme: ThemeType;
  children?: React.ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ theme, children }) => {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};
