import { FC } from 'react';
import { getTypo } from '@linkeo.com/ui-lib-react';
import { Address } from '../../interface/company.types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

export type AddressBoxValue = {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  address: Address;
  telephone?: string;
  email?: string;
};

interface DocumentAddressBoxProps {
  value: AddressBoxValue;
  withoutPlaceholder?: boolean;
}

const Wrapper = styled.div`
  ${getTypo('subtitle1')}
`;

export const DocumentAddressBox: FC<DocumentAddressBoxProps> = props => {
  const { value, withoutPlaceholder } = props;
  const intl = useIntl();
  const blank: boolean | string = withoutPlaceholder ? ' ' : false;
  return <Wrapper>
    {(value.firstName || value.lastName) &&
      <>{value.firstName} {value.lastName}<br /></>}
    {value.companyName && <>{value.companyName}<br /></>}
    {value.address.country || blank || intl.formatMessage({ id: 'country', defaultMessage: 'Pays' })}<br />
    {value.address.addressLines.length ?
      value.address.addressLines.map(line => <div key={line}>{line || blank || intl.formatMessage({
        id: 'address',
        defaultMessage: 'Adresse',
      })}</div>) :
      <>{blank || intl.formatMessage({ id: 'address', defaultMessage: 'Adresse' })}<br /></>}
    {value.address.postalCode || blank || intl.formatMessage({
      id: 'postalCode',
      defaultMessage: 'Code postal',
    })} {value.address.city || blank || intl.formatMessage({ id: 'city', defaultMessage: 'Ville' })}<br />
    {value.telephone || blank || intl.formatMessage({
      id: 'companyDocFormPhoneLabel',
      defaultMessage: 'Numéro de téléphone',
    })}<br />
    {value.email || blank || intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' })}<br />
  </Wrapper>;
};