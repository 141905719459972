import React, { FC, useEffect, useState } from 'react';
import { PageWrapper } from '../../../components';
import { Card, CardSubtitle, CardTitle, Container } from '@linkeo.com/ui-lib-react';
import { Category } from '../../../interface/category.types';
import { useApi } from '../../../providers/api-provider';
import { CategoryList } from '../../../components/category/category-list';
import { useIntl } from 'react-intl';
import { ServerError } from '../../../components/commons/server-error';

export const ActivitiesPage: FC = () => {
  const intl = useIntl();
  const API = useApi();
  const [getCategories, setCategories] = useState<Category[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true)
    API.getCategories()
      .then((result) => setCategories(result))
      .catch(e => {
        console.error(e);
        setError(true)
      }).finally(() => setLoading(false));
  }, [API]);

  return <PageWrapper isLoading={loading}>
    {!error ? <Container size={'lg'} style={{ paddingBottom: '250px' }}>
      <Card>
        <CardTitle>
          {intl.formatMessage({ id: 'activityTitle', defaultMessage: 'Ajoutez ici les activités que vous proposez.' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'activitySubtitle',
            defaultMessage: 'Paramétrez les informations de valeurs et si nécessaire les informations complémentaires qui permettront à vos clients d’obtenir une estimation en ligne.',
          })}
        </CardSubtitle>
        {!!getCategories && <CategoryList
          categories={getCategories}
          onChange={setCategories} />}
      </Card>
    </Container> : <ServerError />}
  </PageWrapper>;
};
