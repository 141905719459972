import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BaseButton,
  Card,
  Flex,
  InOut,
  Input,
  InputNumber,
  RadioGroup,
  RadioOption,
  RemoveItemInArray,
  Select,
  SelectOption,
  Svg,
  Switch,
  Textarea, useToaster,
} from '@linkeo.com/ui-lib-react';
import { Product, ProductType, ReferentialProduct } from '../../interface/product.types';
import { UpdateItemInArray, UpdateItemInObject } from '../../utils/deep-object.utils';
import { SmallCol, SmallRow } from '../ui/small-columns';
import { useApi } from '../../providers/api-provider';
import { Devise, getSymbolCurrency, RoundFloat } from '../../utils/number.utils';
import { COL_SIZES_CONFIG } from '../document/col-sizes-config';
import { Debounce } from '../../utils/algo.utils';
import { FormFooter } from '../ui/form-footer';
import { useDocParam } from '../../providers/document-param.provider';
import { useTheme } from 'styled-components';
import { EditReferentialDialog } from '../referential/edit-referential.dialog';
import {
  IsSameProductAsReferential,
  ProductToProductReferential,
  UpdateProductFromReferential,
} from '../../utils/product.utils';
import { EditReferentialType } from '../../interface/api.types';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { TaxesForm } from '../document-param/taxes-form';

interface ProductFormProps {
  currency: Devise;
  onCancel: () => void;
  onChange: (val: Product) => void;
  onDelete: () => void;
  onPositionMove: (direction: 'up' | 'down') => void;
  product: Product;
}

export const ProductForm: FC<ProductFormProps> = props => {
  const { product, onChange, onCancel, onPositionMove, onDelete, currency } = props;
  const [getFormControl, setFormControl] = useState<Product>(product);
  const [getSelectedReferentialProductCode, setSelectedReferentialProductCode] = useState<string | undefined>();
  const [getShowDialog, setShowDialog] = useState<boolean>(false);
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getCollision, setCollision] = useState<boolean>(false);
  const intl = useIntl();
  const [getMessageErrors, setMessageErrors] = useState<string>('');
  const API = useApi();
  const theme = useTheme();
  const toast = useToaster();
  const { taxes, locale } = useDocParam();

  useEffect(() => {
    setFormControl({
      ...product,
      taxes: product.taxes.length > 0 ? product.taxes : taxes[product.productType],
    });
  }, [product, taxes]);

  const productServiceOptions: RadioOption<ProductType>[] = useMemo(() => [{
    data: 'product',
    name: intl.formatMessage({ id: 'productFormProductLabel', defaultMessage: 'Article' }),
    columns: [4],
  }, {
    data: 'service',
    name: intl.formatMessage({ id: 'productFormServiceLabel', defaultMessage: 'Service' }),
    columns: [4],
  }], [intl]);

  const canCallTotalProduct = (product: Product): boolean => {
    if (!product.price) {
      return false;
    }
    if (!product.quantity) {
      return false;
    }
    let canCall = false;
    if (product.price !== getFormControl.price) {
      canCall = true;
    }
    if (product.quantity !== getFormControl.quantity) {
      canCall = true;
    }
    if (product.discount !== getFormControl.discount) {
      canCall = true;
    }
    if (product.taxes !== getFormControl.taxes) {
      canCall = true;
    }
    return canCall;
  };

  const debounceUpdate = useRef<number | undefined>();

  const updateProduct = (product: Product) => {
    setFormControl(product);
    if (!canCallTotalProduct(product)) {
      return;
    }
    debounceUpdate.current = Debounce(() => {
      API.simulateProduct(product).then(result => {
        setFormControl({
          ...product,
          total: result.total,
        });
      }).catch(() => {
        setFormControl({
          ...product,
          total: undefined,
        });
      });
    }, 500, debounceUpdate.current || 0);

  };
  const [getProductsChoice, setProductsChoice] = useState<SelectOption<ReferentialProduct>[]>([]);

  const searchProduct = useCallback((params: { productCode?: string, name?: string }) => {
    API.getProducts(params).then(result => setProductsChoice(result.products.map(p => {
      return {
        value: p,
        label: params.productCode ? p.code : `${p.name} - ${intl.formatNumber(p.price, {
          style: 'currency',
          currency,
        })} ${intl.formatMessage({ id: 'HT', defaultMessage: 'HT' })} - ${p.code}`,
        caption: params.productCode ? p.name : `${p.code} -  ${intl.formatNumber(p.price, {
          style: 'currency',
          currency,
        })} ${intl.formatMessage({ id: 'HT', defaultMessage: 'HT' })}`,
      };
    })));
  }, [API, currency, intl]);

  useEffect(() => {
    searchProduct({});
    return () => setProductsChoice([])
  }, [searchProduct]);

  const productTypeChange = (selected: RadioOption<ProductType>) => {
    if (!selected.data) {
      return;
    }
    updateProduct(
      {
        ...getFormControl,
        productType: selected.data,
        taxes: taxes[selected.data],
      });
  };

  const submitForm = (ev: React.FormEvent) => {
    ev.preventDefault();
    setMessageErrors('');
    setLoading(true);
    API.getProduct(getFormControl.productCode).then((refProduct) => {
      if (IsSameProductAsReferential(getFormControl, refProduct)) {
        emitChange('keep');
      } else {
        setSelectedReferentialProductCode(refProduct.code);
        setShowDialog(true);
      }
    }).catch(() => {
      setSelectedReferentialProductCode(undefined);
      setShowDialog(true);
    }).finally(() => setLoading(false));
  };

  const emitChange = (type: EditReferentialType, newCodeProduct?: string) => {
    if (!getFormControl.total) {
      return;
    }
    if (type === 'keep') {
      onChange(getFormControl);
    }
    setLoading(true);
    if (type === 'create') {
      API.createProduct(ProductToProductReferential({
        ...getFormControl,
        productCode: newCodeProduct ? newCodeProduct : getFormControl.productCode,
      }, locale)).then((result) => {
        onChange(UpdateProductFromReferential(getFormControl, result));
        setCollision(false);
      }).catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          setCollision(true);
        }
        setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
        console.error(error);
      }).finally(() => {
        setLoading(false);
        setShowDialog(false);
      });
    }
    if (type === 'update') {
      API.updateProduct(ProductToProductReferential(getFormControl, locale)).then((result) => {
        onChange(UpdateProductFromReferential(getFormControl, result));
      }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }),
      )).finally(() => {
        setLoading(false);
        setShowDialog(false);
      });
    }
  };

  return <><InOut
    show={true} autoScroll={{ behavior: 'smooth', block: 'center' }} startTriggering><Card
    style={{ margin: '0 -70px', padding: '16px 8px' }}>
    <Flex justifyContent={['space-between']}>
      <Flex style={{ width: '62px' }} direction={['column']} justifyContent={['space-between']}>
        <div>
          <BaseButton onClick={() => onPositionMove('up')}>
            <Svg icon={'up'} fill={theme.colors.primary} width={24} height={24} />
          </BaseButton>
        </div>
        <div>
          <BaseButton onClick={() => onPositionMove('down')}>
            <Svg icon={'down'} fill={theme.colors.primary} width={24} height={24} />
          </BaseButton>
        </div>
      </Flex>
      <form style={{ flex: '1' }} onSubmit={submitForm}>
        <SmallRow>
          <SmallCol columns={COL_SIZES_CONFIG[0]}>
            <Select small
                    label={intl.formatMessage({ id: 'ProductFormNamingLabel', defaultMessage: 'Intitulé' })}
                    required
                    onSelect={(selected) => updateProduct(UpdateProductFromReferential(getFormControl, selected.value))}
                    typing={getFormControl.name}
                    onTyping={(typing) => setFormControl({ ...getFormControl, name: typing })}
                    onDebounceTyping={(typing) => searchProduct({ name: typing })}
                    options={getProductsChoice} />
            <RadioGroup<ProductType>
              value={productServiceOptions.find(f => f.data === getFormControl.productType)}
              onChange={productTypeChange}
              options={productServiceOptions}
              groupName={'productType'} />
          </SmallCol>
          <SmallCol columns={COL_SIZES_CONFIG[1]}>
            <InputNumber
              small
              required
              label={intl.formatMessage({ id: 'unitprice', defaultMessage: 'Prix U. HT' })}
              step='any'
              min={0}
              value={getFormControl.price}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'price', v || 0))} />
          </SmallCol>
          <SmallCol columns={COL_SIZES_CONFIG[2]}>
            <InputNumber
              required
              small
              label={intl.formatMessage({ id: 'quantity', defaultMessage: 'Qté' })}
              min={0.01}
              step='any'
              value={getFormControl.quantity}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'quantity', v || 0))} />
            <Input
              small
              label={intl.formatMessage({ id: 'ProductFormUnitLabel', defaultMessage: 'Unité' })}
              value={getFormControl.unit}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'unit', v))} />
          </SmallCol>
          <SmallCol columns={COL_SIZES_CONFIG[3]}>
            <InputNumber
              small
              label={`${intl.formatMessage({
                id: 'discountTypeLabel',
                defaultMessage: 'Remise',
              })} ${getFormControl.discount?.type === 'percentage' ? '%' : getSymbolCurrency(currency)} `}
              min={0}
              step='any'
              max={getFormControl.discount?.type === 'percentage' ? 100 : undefined}
              value={getFormControl.discount?.value}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'discount',
                {
                  value: v || 0,
                  type: getFormControl.discount?.type === 'percentage' ? 'percentage' : 'amount',
                }))} />
            <Switch
              small label={intl.formatMessage({ id: 'discountTypeSwitchLabel', defaultMessage: 'Type de remise' })}
              value={getFormControl.discount?.type === 'percentage'}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'discount',
                {
                  value: getFormControl.discount?.value || 0,
                  type: v ? 'percentage' : 'amount',
                }))}
              offLabel={getSymbolCurrency(currency)}
              onLabel={'%'} />
          </SmallCol>
          <SmallCol columns={COL_SIZES_CONFIG[4]} style={{ textAlign: 'right' }}>
            <InputNumber
              readOnly
              small
              style={{ textAlign: 'right' }}
              label={intl.formatMessage({ id: 'TotalHT', defaultMessage: 'Total HT' })}
              value={getFormControl.total?.totalWithoutTax ? RoundFloat(getFormControl.total.totalWithoutTax, 100) : undefined}
              onChange={() => {
              }} />
          </SmallCol>
        </SmallRow>
        <SmallRow justifyContent={'space-between'}>
          <SmallCol columns={COL_SIZES_CONFIG[0]}>
            <Select small
                    label={intl.formatMessage({ id: 'ProductFormRefLabel', defaultMessage: 'Référence' })}
                    required
                    onSelect={(selected) => updateProduct(UpdateProductFromReferential(getFormControl, selected.value))}
                    typing={getFormControl.productCode}
                    onTyping={(typing) => setFormControl({ ...getFormControl, productCode: typing })}
                    onDebounceTyping={(typing) => searchProduct({ productCode: typing })}
                    options={getProductsChoice} />
            <Textarea
              small
              style={{ padding: '8px' }}
              label={intl.formatMessage({ id: 'ProductFormDescLabel', defaultMessage: 'Description' })}
              value={getFormControl.description}
              onChange={v => updateProduct(UpdateItemInObject(getFormControl, 'description', v))}
            />
          </SmallCol>

          <TaxesForm
            small
            label={intl.formatMessage({ id: 'paramTarifTaxeTitle', defaultMessage: 'Taxes' })}
            taxes={getFormControl.taxes}
            type={getFormControl.productType}
            changeTaxe={(_, i, v) => updateProduct(UpdateItemInObject(getFormControl, 'taxes', UpdateItemInArray(
              getFormControl.taxes, i, { ...getFormControl.taxes[i], ...typeof v === 'number' ? { rate: v } : { name: v } })))}
            onTaxeAdd={() => setFormControl({
              ...getFormControl,
              taxes: [...getFormControl.taxes, { name: '', rate: 0 }],
            })}
            onTaxeRemove={(_, index) => updateProduct({
              ...getFormControl,
              taxes: RemoveItemInArray(getFormControl.taxes, index),
            })} />

        </SmallRow>
        <FormFooter errorMessage={getMessageErrors} loading={getLoading} disabled={!getFormControl.total} small
                    onCancel={onCancel} />
      </form>
      <div style={{ width: '62px', alignSelf: 'center', textAlign: 'right' }}>
        <BaseButton onClick={() => onDelete()}>
          <Svg icon={'delete'} fill={theme.colors.primary} width={24} height={24} />
        </BaseButton>
      </div>
    </Flex>
  </Card></InOut>
    <EditReferentialDialog
      askNewId
      concern={'product'}
      isLoading={getLoading}
      currentId={getSelectedReferentialProductCode}
      isCollision={getCollision}
      onClose={() => setShowDialog(false)}
      onSubmit={emitChange} show={getShowDialog} />
  </>;
};