import { Locale } from '../providers/intl.provider';

export type ScopeQuestionType = 'QUESTION_REFERENTIAL' | 'QUESTION_CUSTOM' | 'CATEGORY';

export interface ScopeQuestion {
  active: boolean;
  description: string;
  id: string;
  mandatory: boolean;
  name: string;
  type: ScopeQuestionType;
}

export type ScopeType = 'TARGETING' | 'ACTIVITY' | 'CONTACT' | 'ACTIVITY_QUESTIONS';
export type Scopes = {
  [key in ScopeType]?: ScopeQuestion[];
}

export type EstimateParamDisplayType = 'BETWEEN' | 'FROM' | 'MANUAL' | 'EXACT';
export const VOLUME = 'volume';
export const SURFACE = 'surface';
export const VALUE = 'value';
export const PRICE = 'price';
export type EstimateParamType = typeof VALUE | typeof PRICE;

export interface EstimateParamUnit {
  type: EstimateParamType;
  value?: string | null;
  valueCurrency?: string | null;
}

export const AIDES_ET_PRIMES = 'AIDES_ET_PRIMES';
export type FormType = typeof AIDES_ET_PRIMES;

export interface FormParams {
  unit: EstimateParamUnit;
  display: EstimateParamDisplayType;
  details: string | null;
  formType: FormType | null
}

export type FormScope = {
  id: string,
  locale: Locale,
  name: string;
  params: FormParams
}