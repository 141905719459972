import * as React from 'react';
import { FC, HTMLProps } from 'react';
import { ImageValue } from '../../common';
import { ReadFileToDataUrl } from '../../helpers';

interface BaseInputImageProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'value'> {
  /**
   * this props can be set only by onLocalChange or by empty string for deleting
   */
  local: string;
  /**
   * in Megabyte default 2mb
   */
  maxSize?: number;
  onChange: (value: ImageValue) => void;
  onError: (reason: any) => void;
  /**
   * use this value to set local
   * @param local
   */
  onLocalChange: (local: string) => void;
}

export const BaseInputImage: FC<BaseInputImageProps> = props => {
  const { maxSize, onChange, local, onLocalChange, onError, ...rest } = props;
  const emitChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    onLocalChange(target.value);
    const files: FileList = target.files || new FileList();
    if (!files || !files.length) {
      return;
    }
    const file = files[0];
    if (file) {
      ReadFileToDataUrl(file, maxSize)
        .then(result => {
          onChange({
            stringFormat: result,
            binary: file,
          });
        })
        .catch(error => {
          onError(error);
        });
    }
  };
  return (
    <input
      accept=".gif,.jpg,.jpeg,.png"
      {...(rest as any)}
      type={'file'}
      onChange={emitChange}
      value={local}
    />
  );
};
