import { Button, Col, Row, SpanCaption1 } from '@linkeo.com/ui-lib-react';
import React, { FC } from 'react';
import { SmallCol, SmallRow } from './small-columns';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

interface FormFooterProps {
  disabled?: boolean;
  errorMessage?: string;
  loading?: boolean;
  onCancel: () => void;
  small?: boolean;
}

const ErrorCaption = styled(SpanCaption1)`
  color: ${({ theme }) => theme.colors.danger};
`;
export const FormFooter: FC<FormFooterProps> = props => {
  const { onCancel, small, disabled, loading, errorMessage } = props;
  const intl = useIntl();
  return <>
    {
      errorMessage ? <div><ErrorCaption>{errorMessage}</ErrorCaption></div> : null
    }
    {small ? <SmallRow justifyContent={['flex-end']} style={{ marginTop: '10px', marginBottom: '10px' }}>
      <SmallCol>
        <Button
          small disabled={loading} backgroundColor={'#FA3B38'} fontColor={'white'}
          onClick={onCancel}>{intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}</Button>
      </SmallCol>
      <SmallCol>
        <Button
          disabled={disabled}
          loading={loading}
          type={'submit'} small backgroundColor={'#35D759'} fontColor={'white'}> {intl.formatMessage({id: 'submit', defaultMessage: 'Valider'})}</Button>
      </SmallCol>
    </SmallRow> : <Row justifyContent={['center']}>
      <Col>
        <Button colorType={'outline'} disabled={loading} onClick={onCancel}>
          {intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}
        </Button>
      </Col>
      <Col>
        <Button type={'submit'} colorType={'primary'} loading={loading} disabled={disabled}>
          {intl.formatMessage({id: 'save', defaultMessage: 'Enregistrer'})}
        </Button>
      </Col>
    </Row>}</>;
};