import styled, { css } from 'styled-components';
import {
  FlexProperties,
  ResponsiveAlignItems,
  ResponsiveDirection,
  ResponsiveJustifyContent,
  ResponsiveWrap,
} from '../flex';
import React, { createContext, FC, HTMLProps, useContext } from 'react';
import { MakeCssMediaQuery, DEVICES, PickChoiceByDevice } from '../../common';
import { mergeStyled } from '../../helpers';

export interface RowContextProps {
  gapColumn?: number | number[];
}

export interface RowProps extends FlexProperties, HTMLProps<HTMLDivElement>, RowContextProps {}

const RowCss = (val: number): string => {
  return `
  width: calc(100% + ${val * 2}px);
  margin-left: calc(-${val}px);
  margin-right: calc(-${val}px);
  `;
};
const ResponsiveRowGap = css<RowContextProps>`
  ${({ gapColumn }) =>
    typeof gapColumn === 'number'
      ? RowCss(gapColumn)
      : gapColumn
      ? DEVICES.slice(0, gapColumn.length).map(device =>
          MakeCssMediaQuery(RowCss(PickChoiceByDevice(device, gapColumn)), device)
        )
      : RowCss(16)};
`;
const RowStyle = mergeStyled(
  styled.div<RowProps>`
    display: flex;
    position: relative;
  `,
  [
    ResponsiveRowGap,
    ResponsiveWrap,
    ResponsiveAlignItems,
    ResponsiveJustifyContent,
    ResponsiveDirection,
  ]
);
const RowContext = createContext<RowContextProps>({});
export const useRow = () => useContext(RowContext);
export const Row: FC<RowProps> = ({ gapColumn, children, ...props }) => {
  return (
    <RowContext.Provider value={{ gapColumn }}>
      <RowStyle gapColumn={gapColumn} {...(props as any)}>
        {children}
      </RowStyle>
    </RowContext.Provider>
  );
};
