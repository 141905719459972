import styled, { ThemeContext } from 'styled-components';
import * as React from 'react';
import { useContext } from 'react';
import { SpanSubtitle1, ThemeType } from '../../common';
import { Flex } from '../flex';
import { BaseButton } from '../base-button';
import { Svg } from '../svg';

export interface TabBarProps {
  withSteps?: boolean;
  activeIndex: number;
  tabTitles: string[];
  onChange: (event: number) => void;
}

const TabNavBarContainer = styled.nav<{ withSteps?: boolean }>`
  border-color: #e6e6e6;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  padding: 0 20px;
  display: flex;
  overflow: auto;
  justify-content: ${({ withSteps }): string => (withSteps ? 'center' : 'left')};
`;
const TabNavBarItem = styled(BaseButton)<{
  active: boolean;
  withSteps?: boolean;
  previous: boolean;
}>`
  padding: 13px 20px 9px;
  border-radius: 0;
  border-width: 2px;
  border-style: none none solid none;
  background: transparent;
  transition: all ease-in-out 200ms;
  border-color: ${({ active, withSteps, theme }) =>
    withSteps ? 'transparent' : active ? theme.colors.primary : 'transparent'};
  color: ${({ active, previous, withSteps, theme }) =>
    active || (previous && withSteps) ? theme.colors.primary : theme.colors.grey40};

  &:not(:disabled):hover {
    color: ${({ active, previous, withSteps, theme }) =>
      active || (previous && withSteps) ? theme.colors.primary : theme.colors.grey20};
  }
`;
export const TabBar: React.FC<TabBarProps> = props => {
  const { tabTitles, withSteps, activeIndex, onChange } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <TabNavBarContainer withSteps={withSteps}>
      {tabTitles.map((title, index) => (
        <Flex key={index} alignItems={['center']}>
          {withSteps && index !== 0 && (
            <Svg width={24} height={24} icon={'right'} fill={theme.colors.grey80} />
          )}
          <TabNavBarItem
            withSteps={withSteps}
            onClick={() => onChange(index)}
            previous={index < activeIndex}
            active={index === activeIndex}
            disabled={withSteps && index > activeIndex}
          >
            <SpanSubtitle1>{title}</SpanSubtitle1>
          </TabNavBarItem>
        </Flex>
      ))}
    </TabNavBarContainer>
  );
};
