import * as React from 'react';
import { InOut } from '../in-out';
import { Keyframes } from '../../common';
import styled from 'styled-components';

export interface LoadingContentProps {
  isLoading: boolean;
  children?: React.ReactNode;
}

const Container = styled.div`
  position: relative;
`;
const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
`;
const Bar = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const LoadingContent: React.FC<LoadingContentProps> = props => {
  const { children, isLoading } = props;
  return (
    <Container>
      <InOut show={!isLoading} startTriggering>
        {children}
      </InOut>
      <ProgressBarContainer>
        <InOut
          startTriggering
          show={isLoading}
          startStyle={{ opacity: 1 }}
          enter={{ keyframes: Keyframes.growInToRight, options: { duration: 5000 } }}
        >
          <Bar />
        </InOut>
      </ProgressBarContainer>
    </Container>
  );
};
