import * as React from 'react';
import { FC, useContext } from 'react';
import { getTypo, ThemeType } from '../../common';
import styled, { ThemeContext } from 'styled-components';
import { Flex } from '../flex';
import { Svg } from '../svg';
import { IconName } from '../../icons';
import { lighten } from 'polished';

const Wrapper = styled.div<{ active?: boolean; color: string }>`
  ${getTypo('subtitle1')};
  color: ${({ active, color, theme }): string => (active ? color : theme.colors.grey40)};
  cursor: pointer;

  &:hover {
    color: ${({ active, color, theme }) => (active ? color : lighten(0.1, theme.colors.grey40))};
  }
`;

interface NavLinkProps {
  active: boolean;
  icon: IconName;
  label: string;
}

export const NavLink: FC<NavLinkProps> = props => {
  const { label, active, icon } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Wrapper title={label} active={active} color={theme.colors.primary}>
      <Flex alignItems={['center']}>
        <Svg
          icon={icon}
          height={24}
          width={24}
          fill={active ? theme.colors.primary : theme.colors.grey40}
          style={{ marginRight: '10px' }}
        />
        <span>{label}</span>
      </Flex>
    </Wrapper>
  );
};
