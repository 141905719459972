import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { IsDisabledDate } from '../../helpers';
import { Dropdown } from '../dropdown';
import { Calendar } from '../calendar';
import { InputDate, InputDateProps } from '../input-date';

export interface DatePickerProps extends InputDateProps {}

export const DatePicker: FC<DatePickerProps> = props => {
  const { value, onChange, right, disabledDays, locale, offsetWeekDay, readOnly, ...rest } = props;
  const refDatePicker = useRef<HTMLDivElement>(null);
  const [getOpen, setOpen] = useState<boolean>(false);

  const navigateCalendar = (keyCode: number, ctrl: boolean) => {
    let incrementDate = 0;
    let incrementMonth = 0;
    let incrementYear = 0;
    switch (keyCode) {
      case 37: // left
        if (ctrl) {
          incrementMonth = -1;
        } else {
          incrementDate = -1;
        }
        break;
      case 39: // right
        if (ctrl) {
          incrementMonth = +1;
        } else {
          incrementDate = +1;
        }
        break;
      case 40: // down
        if (ctrl) {
          incrementYear = +1;
        } else {
          incrementDate = +7;
        }
        break;
      case 38: // up
        if (ctrl) {
          incrementYear = -1;
        } else {
          incrementDate = -7;
        }
        break;
      default:
    }
    const previousDate = value || new Date();
    const date = new Date(
      previousDate.getFullYear() + incrementYear,
      previousDate.getMonth() + incrementMonth,
      previousDate.getDate() + incrementDate
    );
    if (IsDisabledDate(date, disabledDays)) {
      return;
    }
    onChange(date);
  };
  const keyDown = (event: React.KeyboardEvent) => {
    if (readOnly) {
      return;
    }
    switch (event.keyCode || event.key) {
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowRight':
      case 40: // down
      case 38: // up
      case 37: // left
      case 39: // right
        if (getOpen) {
          event.stopPropagation();
          event.preventDefault();
          navigateCalendar(event.keyCode, event.ctrlKey);
        } else {
          setOpen(true);
        }
        break;
      case 27: // escape
      case ' ': // escape
        if (getOpen) {
          setOpen(false);
        }
        break;
      case 13: // enter
      case 'Enter': // enter
      case 9: // tab
      case 'Tab': // tab
        setOpen(false);
        break;
      default:
    }
  };
  return (
    <div style={{ position: 'relative', width: '100%' }} ref={refDatePicker}>
      <InputDate
        {...(rest as any)}
        {...{ readOnly, right }}
        onKeyDown={keyDown}
        value={value}
        onChange={onChange}
        locale={locale}
        onFocus={() => !readOnly && setOpen(true)}
        icon={readOnly ? undefined : 'down'}
        afterChild={
          <Dropdown
            wrapperRef={refDatePicker}
            onClose={() => setOpen(false)}
            right={right}
            show={getOpen}
            offset={-16}
          >
            <Calendar
              onChange={onChange}
              disabledDays={disabledDays}
              offsetWeekDay={offsetWeekDay}
              value={value}
              locale={locale}
            />
          </Dropdown>
        }
      />
    </div>
  );
};
