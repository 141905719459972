import { css } from 'styled-components';

const box = css`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.box.borderRadius};
`;
const shadow1 = css`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;
const shadow2 = css`
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
`;
const card = css`
  ${box};
  ${shadow1};
`;
const bubble = css`
  ${box};
`;
const input = css`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 7px 8px 8px 7px;
`;
const smallInput = css`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 2px 5px 2px 5px;
`;
const button = css`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.button.default.borderRadius};
  padding: ${({ theme }) => theme.button.default.padding};
`;
const smallButton = css`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.button.small.borderRadius};
  padding: ${({ theme }) => theme.button.small.padding};
`;
const circle = css`
  ${box};
  border-radius: 50%;
`;
const line = css`
  ${box};
  height: 1px;
`;
export const Shapes = {
  card,
  bubble,
  input,
  button,
  smallButton,
  circle,
  line,
  shadow1,
  shadow2,
  smallInput,
};
