import React, { FC } from 'react';
import { Address } from '../../interface/company.types';
import { Input } from '@linkeo.com/ui-lib-react';
import { AddressLinesFields } from '../company/address-lines-fields';
import { AddressErrors } from '../../utils/quote-document-validator';
import { useIntl } from 'react-intl';

interface AddressSmallFields {
  errors: AddressErrors;
  onChange: (v: Address) => void;
  value: Address;
}

export const AddressSmallFields: FC<AddressSmallFields> = props => {
  const { value, onChange, errors } = props;
  const intl = useIntl();
  return <>
    <Input hasError={!!errors.country} small label={intl.formatMessage({id: 'country', defaultMessage: 'Pays'})} required value={value.country}
           onChange={v => onChange({ ...value, country: v })} />
    <AddressLinesFields addressLineErrors={errors.addressLines} small addressLines={value.addressLines.length ? value.addressLines : ['']}
                        onChange={addressLines => onChange({ ...value, addressLines })} />
    <Input hasError={!!errors.postalCode} small label={intl.formatMessage({id: 'postalCode', defaultMessage: 'Code postal'})} required value={value.postalCode}
           onChange={v => onChange({ ...value, postalCode: v })} />
    <Input hasError={!!errors.city} small label={intl.formatMessage({id: 'city', defaultMessage: 'Ville'})} required value={value.city}
           onChange={v => onChange({ ...value, city: v })} />
  </>;
};