import * as React from 'react';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { BaseInput, BaseInputProps } from '../base-input';
import styled from 'styled-components';
import { TimeSelect } from '../time-select';
import { Dropdown } from '../dropdown';

type OmitBaseInput = Omit<BaseInputProps, 'value' | 'onChange' | 'name' | 'label'>;

export interface TimePickerProps extends LabelWrapperProps, OmitBaseInput {
  onChange: (event: string) => void;
  value: string;
}

const InputContainer = styled.div`
  width: 140px;
`;
export const TimePicker: React.FC<TimePickerProps> = props => {
  const { label, caption, disabled, hasError, required, right, onChange, value, ...rest } = props;
  const refTimePicker = React.useRef<HTMLDivElement>();
  const [open, setOpen] = React.useState<boolean>(false);
  const emitChange = (val: string) => {
    onChange(val);
  };
  const changeInput = (event: React.FormEvent) => {
    const element = event.target as HTMLInputElement;
    let val = element.value;
    const regexAddSeparation = /^\d{3}$/;
    if (val.match(regexAddSeparation)) {
      val = val.substr(0, 2) + ':' + val[2];
    }
    const regexTime = /^(\d{0,2}):?(\d{0,2})$/;
    const match = val.match(regexTime);
    if (!match) {
      return;
    }
    const exec = regexTime.exec(val);
    if (!exec) {
      return;
    }
    const hours = parseInt(exec[1], 10);
    const minutes = parseInt(exec[2], 10);
    if (hours > 23 || minutes > 59) {
      return;
    }
    emitChange(val);
  };
  const autoClose = (): void => {
    setOpen(false);
  };
  const openDropDown = (): void => {
    setOpen(true);
  };
  return (
    <div style={{ position: 'relative', width: '100%' }} ref={refTimePicker as any}>
      <LabelWrapper {...{ label, caption, disabled, hasError, required, right }}>
        <InputContainer>
          <BaseInput
            {...rest}
            required={required}
            disabled={disabled}
            hasError={hasError}
            autoComplete="off"
            value={value}
            onFocus={openDropDown}
            onChange={changeInput}
            type="text"
          />
        </InputContainer>
        <Dropdown show={open} onClose={autoClose} wrapperRef={refTimePicker as any} offset={-8}>
          <TimeSelect onChange={emitChange} value={value} />
        </Dropdown>
      </LabelWrapper>
    </div>
  );
};
