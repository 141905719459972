import { FC } from 'react';
import { Button, Dialog, SpanSubtitle1 } from '@linkeo.com/ui-lib-react';
import { DocumentType } from '../../interface/document.type';
import { FormattedMessage, useIntl } from 'react-intl';

interface FinalisationDocumentDialogProps {
  documentType: DocumentType;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  show: boolean;
}

export const FinalisationDocumentDialog: FC<FinalisationDocumentDialogProps> = props => {
  const { onConfirm, onClose, show, isLoading, documentType } = props;
  const intl = useIntl();
  const emitClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };
  const footer = <>
    <Button colorType={'secondary'} onClick={emitClose} disabled={isLoading}>
      <FormattedMessage id={'cancel'} defaultMessage={'Annuler'} />
    </Button>
    <Button colorType={'secondary'} loading={isLoading}
            onClick={onConfirm}>
      <FormattedMessage id={'finalisationDialogConfirmButton'} defaultMessage={'Finaliser {value}'}
                        values={{
                          value: documentType === 'quote' ? intl.formatMessage({
                            id: 'finalisationDialogConfirmButtonQuote',
                            defaultMessage: 'ce devis',
                          }) : intl.formatMessage({
                            id: 'finalisationDialogConfirmButtonInvoice',
                            defaultMessage: 'cette facture',
                          }),
                        }} />
    </Button>
  </>;
  return <Dialog
    title={intl.formatMessage({ id: 'finalisationDialogConfirmButton', defaultMessage: 'Finaliser {value}' }, {
      value: documentType === 'quote' ? intl.formatMessage({
        id: 'finalisationDialogConfirmButtonQuote',
        defaultMessage: 'ce devis',
      }) : intl.formatMessage({
        id: 'finalisationDialogConfirmButtonInvoice',
        defaultMessage: 'cette facture',
      }),
    })} onClose={emitClose}
    show={show} size={'md'} footer={footer}>
    <div style={{ textAlign: 'center' }}>
      <SpanSubtitle1>
        <FormattedMessage id={'finalisationDialogCaption1'}
                          defaultMessage={'Vous êtes sur le point de finaliser votre {value}'} values={{
          value: documentType === 'quote' ? intl.formatMessage({
            id: 'finalisationDialogCaptionQuote',
            defaultMessage: 'devis',
          }) : intl.formatMessage({
            id: 'finalisationDialogCaptionInvoice',
            defaultMessage: 'facture',
          }),
        }} />.<br />
        <br />
        <FormattedMessage id={'finalisationDialogCaption2'}
                          defaultMessage={'Lorsque votre {value} aura été finalisé, vous ne pourrez plus le modifier.'}
                          values={{
                            value: documentType === 'quote' ? intl.formatMessage({
                              id: 'finalisationDialogCaptionQuote',
                              defaultMessage: 'devis',
                            }) : intl.formatMessage({
                              id: 'finalisationDialogCaptionInvoice',
                              defaultMessage: 'facture',
                            }),
                          }}
        />
        <br />
        <FormattedMessage id={'finalisationDialogCaption3'}
                          defaultMessage={'Voulez-vous continuer ?'} /></SpanSubtitle1>
    </div>

  </Dialog>;
};