import { FC } from 'react';
import { Tag } from '@linkeo.com/ui-lib-react';
import { DocumentTag } from '../../interface/document.type';
import { useIntl } from 'react-intl';

interface DocumentStatusTagProps {
  tag: DocumentTag;
}

export const DocumentStatusTag: FC<DocumentStatusTagProps> = props => {
  const { tag } = props;
  const intl = useIntl();

  switch (tag) {
    default:
      return <></>
    case 'quote':
      return <Tag color={'#239f39'} fontColor={'white'}>{intl.formatMessage({id: 'quote', defaultMessage: 'Devis'})}</Tag>;
    case 'invoice':
      return <Tag color={'#469fdc'} fontColor={'white'}>{intl.formatMessage({id: 'invoice', defaultMessage: 'Facture'})}</Tag>;
    case 'new':
      return <Tag color={'#FF9800'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagNewRequest', defaultMessage: 'Nouvelle demande'})}</Tag>;
    case 'accepted':
      return <Tag color={'#288c7f'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagApproved', defaultMessage: 'Accepté'})}</Tag>;
    case 'draft':
      return <Tag color={'#FF7133'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagDraft', defaultMessage: 'Brouillon'})}</Tag>;
    case 'expired':
      return <Tag color={'#999999'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagExpired', defaultMessage: 'Expiré'})}</Tag>;
    case 'finalized':
      return <Tag color={'#5f0096'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagFinalized', defaultMessage: 'Finalisé'})}</Tag>;
    case 'sent':
      return <Tag color={'#9F6060'} fontColor={'white'}>{intl.formatMessage({id: 'documentsTagSent', defaultMessage: 'Envoyé'})}</Tag>;
  }
};