import styled, { css } from 'styled-components';
import { ResponsiveCol } from '../flex';
import React, { FC, HTMLProps } from 'react';
import { RowContextProps, useRow } from '../row';
import { DEVICES, MakeCssMediaQuery, PickChoiceByDevice } from '../../common';
import { mergeStyled } from '../../helpers';

export interface ColProps extends HTMLProps<HTMLDivElement> {
  columns?: number | number[];
}

const CollCss = (val: number) => {
  return `padding: 0 ${val}px;`;
};
const ResponsiveColGap = css<RowContextProps>`
  ${({ gapColumn }) =>
    typeof gapColumn === 'number'
      ? CollCss(gapColumn)
      : gapColumn
      ? DEVICES.slice(0, gapColumn.length).map(device =>
          MakeCssMediaQuery(CollCss(PickChoiceByDevice(device, gapColumn)), device)
        )
      : CollCss(16)};
`;
const ColStyle = mergeStyled(
  styled.div<RowContextProps & ColProps>`
    box-sizing: border-box;
    position: relative;
  `,
  [ResponsiveCol, ResponsiveColGap]
);

export const Col: FC<ColProps> = ({ children, ...props }) => {
  const { gapColumn } = useRow();
  return (
    <ColStyle gapColumn={gapColumn} {...(props as any)}>
      {children}
    </ColStyle>
  );
};
