import * as React from 'react';
import { ReactNode } from 'react';

export const QueryChildrenByType = (
  children: ReactNode | ReactNode[],
  type: React.FC
): ReactNode => {
  const arrayChildren = React.Children.toArray(children);
  return arrayChildren.find((element): boolean => {
    if (React.isValidElement(element)) {
      return element.type === type;
    }
    return false;
  });
};
export const ExcludeChildrenByType = (
  children: ReactNode | ReactNode[],
  types: React.FC[]
): ReactNode[] => {
  const arrayChildren = React.Children.toArray(children);
  return arrayChildren.filter((element): boolean => {
    if (React.isValidElement(element)) {
      return !types.some(type => element.type === type);
    }
    return true;
  });
};
