import * as React from 'react';
import { BaseInput, FilterSelect, SelectOption, Row, Col, Svg, Dropdown, FilterMenu } from '@linkeo.com/ui-lib-react';
import styled, { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { ChangeEvent, useRef, useState } from 'react';
import { Debounce } from '../../utils/algo.utils';
import { PartialQuoteDocument } from '../../interface/document.type';

interface DocumentsFilterProps<T> {
  onSelect: (filter: SelectOption<T>) => void;
  options: SelectOption<T>[];
  value: SelectOption<T>;
  onSearch: (value: string) => void;
  searchOptions: SelectOption<PartialQuoteDocument>[];
  onSearchClose: () => void;
  onSearchSelect: (value: SelectOption<PartialQuoteDocument>) => void;
}

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey95};
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-top: 1px solid ${({ theme }) => theme.colors.grey80};
  position: relative;
  z-index: 10;
`;

export const DocumentsFilter = <T, >({
                                       onSelect,
                                       value,
                                       options,
                                       onSearch,
                                       searchOptions,
                                       onSearchClose,
                                       onSearchSelect,
                                     }: DocumentsFilterProps<T>) => {
  const intl = useIntl();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceRef = useRef<number>();

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debounceRef.current = Debounce(() => {
      onSearch(e.target.value);
    }, 500, debounceRef.current || 0);
  };

  const onClose = () => {
    onSearchClose();
    setSearchValue('');
  };
  return <Wrapper>
    <Row justifyContent={['space-between']}>
      <Col>
        <FilterSelect
          value={value}
          onChange={onSelect}
          options={options} />
      </Col>
      <Col>
        <Row alignItems={['center']} style={{ height: '100%' }}>
          <Col style={{ height: '100%' }}>
            <div ref={wrapperRef} style={{ height: '100%' }}>
              <BaseInput style={{ height: '100%', backgroundColor: theme.colors.grey95, borderRight: 'none' }}
                         ref={inputRef}
                         placeholder={intl.formatMessage({ id: 'searchInputPlaceholder', defaultMessage: 'Recherche' })}
                         onChange={onSearchChange}
                         value={searchValue} />
              <Dropdown show={searchOptions.length > 0}
                        onClose={onClose}
                        wrapperRef={wrapperRef}>
                <FilterMenu<PartialQuoteDocument> options={searchOptions} onClick={onSearchSelect} /></Dropdown>
            </div>
          </Col>
          <Col style={{ cursor: 'pointer' }} onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}>
            <Svg icon={'magnify'} height={24} width={24} fill={theme.colors.grey60} />
            <Svg icon={'down'} height={20} width={20} fill={theme.colors.grey60} />
          </Col>
        </Row>
      </Col>
    </Row>
  </Wrapper>;
};
