import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, Input } from '@linkeo.com/ui-lib-react';
import { Category } from '../../interface/category.types';
import { useIntl } from 'react-intl';

interface CategoryModifyDialogProps {
  category?: Category;
  onChange: (value: Category) => void;
  onClose: () => void;
}

export const CategoryModifyDialog: FC<CategoryModifyDialogProps> = props => {
  const { category, onClose, onChange } = props;
  const intl = useIntl();
  const [getName, setName] = useState<string>(category?.name || '');
  const emitChange = () => {
    if (!category) {
      return;
    }
    onChange({
      ...category,
      name: getName,
    });
  };
  useEffect(() => {
    if (!category) {
      return;
    }
    setName(category.name);
  }, [category]);
  const footer = <>
    <Button colorType={'secondary'} onClick={onClose}>{intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}</Button>
    <Button colorType={'secondary'} onClick={emitChange}>{intl.formatMessage({id: 'save', defaultMessage: 'Enregistrer'})}</Button>
  </>;
  return <Dialog
    title={intl.formatMessage({id: 'categoryRename', defaultMessage: 'Modifier le nom de la catégorie'})}
    onClose={onClose}
    show={!!category}
    footer={footer}
    size={'md'}>
    <Input
      required
      label={intl.formatMessage({id: 'categoryRenameLabel', defaultMessage: 'Indiquer un nouveau nom'})}
      value={getName}
      onChange={setName}
    />

  </Dialog>;
};