import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const offset = 130;
const dash = keyframes`
  0% {
    stroke-dashoffset: ${offset}; }
  50% {
    stroke-dashoffset: ${offset / 4};
    transform: rotate(135deg); 
    }
  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg); } 
`;
const rotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); }
     
`;

const SpinnerGroup = styled.g`
  animation: ${rotateKeyframe} 1.4s linear infinite;
  -webkit-backface-visibility: hidden;
  transform: rotate(0deg);
  transform-origin: center;
`;

const Circle = styled.circle`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
  -webkit-backface-visibility: hidden;
`;

export const Spinner = (
  <SpinnerGroup>
    <Circle fill="none" strokeWidth="6" cx="24" cy="24" r="18" />
  </SpinnerGroup>
);
