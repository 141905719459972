import React, {FC} from 'react';
import {QuestionChoices} from '../../interface/question.types';
import {Box} from '@linkeo.com/ui-lib-react';
import {WithChoice} from './with-choice.form';
import {Choice} from '../../interface/choice.types';
import { useIntl } from 'react-intl';

interface RadioFormProps {
  onSubmit: (value: QuestionChoices) => void;
  question: QuestionChoices;
  onClose: () => void;
  onDeleteChoice: (choice: Choice) => void;
  onAddChoice: (preSaveQuestion: QuestionChoices) => void;
  onEditChoice: (choice: Choice) => void;
  loading: boolean;
}

export const RadioForm: FC<RadioFormProps> = props => {
  const {onClose, question, onSubmit, onDeleteChoice, onAddChoice, onEditChoice, loading} = props;
  const intl = useIntl();
  return <Box
      title={intl.formatMessage({id: 'questionFormRadioTitle', defaultMessage: 'Question choix unique'})}
      subtitle={intl.formatMessage({id: 'questionFormRadioSubtitle', defaultMessage: 'Les prospects à qui vous poserez cette question pourront sélectionner un seul des choix de réponse que vous leur proposerez.{br}Le choix effectué pourra avoir un impact sur le calcul de l’estimation finale.'}, {br: <br />}) as string}
      onClose={onClose}>
    <WithChoice
        onDeleteChoice={onDeleteChoice}
        onSubmit={onSubmit}
        question={question}
        onClose={onClose}
        onAddChoice={onAddChoice}
        onEditChoice={onEditChoice}
        loading={loading}
    />
  </Box>;
};
