import React, { FC, FormEvent, useState } from 'react';
import { Activity } from '../../interface/activity.types';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { QuestionDialog } from '../question/question.dialog';
import { Question, QuestionType } from '../../interface/question.types';
import { useApi } from '../../providers/api-provider';
import { EditImage } from '../../interface/api.types';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useConfirm } from '../../providers/confirm-provider';
import { useIntl } from 'react-intl';
import { routeActivities } from '../../routes';
import {
  BaseButton,
  Box,
  Button,
  CardSubtitle,
  CardTitle,
  Col,
  DraggableList,
  DraggableListItem,
  ImageValue, InOut,
  Input,
  InputImage,
  InputNumber, LabelWrapper,
  Row,
  SpanLink, Switch, useToaster,
} from '@linkeo.com/ui-lib-react';
import { TextEditor } from '../commons/text-editor';

interface ActivityFormProps {
  activity: Activity;
  categoryId: string;
}

export const ActivityForm: FC<ActivityFormProps> = props => {
  const { categoryId, activity } = props;
  const history = useHistory();
  const intl = useIntl();
  const API = useApi();
  const toast = useToaster();
  const { openConfirm } = useConfirm();
  const theme = useTheme();
  const [getImageError, setImageError] = useState<boolean>(false);
  const [getActivity, setActivity] = useState<Activity>(activity);
  const [getShowDialogAddQuestion, setShowDialogAddQuestion] = useState<boolean>(false);
  const [getEditImage, setEditImage] = useState<EditImage>({
    isNew: false,
    image: { stringFormat: activity.picture?.absoluteUrl },
  });

  const [getInvalids, setInvalids] = useState<string[]>([]);
  const isInvalidForm = (): boolean => {
    const invalids = [];
    if (!getActivity.name) {
      invalids.push('name');
    }
    if (getActivity.minPrice && getActivity.maxPrice) {
      if (getActivity.minPrice > getActivity.maxPrice) {
        invalids.push('maxPrice', 'minPrice');
      }
    }
    setInvalids(invalids);
    return !!invalids.length;
  };

  const onSubmitForm = (event: FormEvent) => {
    event.preventDefault();
    if (isInvalidForm()) {
      return;
    }
    sendActivityChange().then(() => {
      onClose();
    }).catch(e => {
      console.error(e);
      toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
    });
  };

  const onClose = () => {
    history.push(routeActivities);
  };

  const gotoQuestion = (activityId: string, questionType: QuestionType | string) => {
    history.push(`/estimate/category/${categoryId}/activity/${activityId}/question/${questionType}`);
  };

  const sendActivityChange = async (): Promise<Activity> => {
    let picture;
    let tempActivity: Activity = {
      ...getActivity,
      maxPrice: getActivity.maxPrice || 0,
      minPrice: getActivity.minPrice || 0,
    };
    if (getEditImage.isNew && getEditImage.image.binary) {
      picture = await API.postFile(getEditImage.image.binary, '/picture');
    }
    if (getEditImage.isNew) {
      tempActivity = {
        ...getActivity,
        picture,
      };
    }
    if (activity.id.startsWith('NEW')) {
      return API.postActivity(categoryId, tempActivity);
    }
    return API.putActivity(categoryId, tempActivity);
  };

  const changeImage = (image: ImageValue) => {
    setImageError(false);
    setEditImage({
      isNew: true,
      image,
    });
  };

  const requestEditDraggedQuestion = (dragged: DraggableListItem<Question>) => {
    gotoQuestion(getActivity.id, dragged.data.id);
  };

  const requestAddQuestion = () => {
    if (isInvalidForm()) {
      return;
    }
    setShowDialogAddQuestion(true);
  };

  const addQuestion = (type: QuestionType) => {
    setShowDialogAddQuestion(false);
    sendActivityChange().then((result) => {
      gotoQuestion(result.id, type);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const deleteQuestion = async (question: Question): Promise<Question[]> => {
    return new Promise(resolve => {
      openConfirm({
        title: '',
        content: intl.formatMessage({
            id: 'confirmDelete',
            defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}',
          },
          { name: question.label }),
      }).then(() => {
        API.deleteQuestion(categoryId, getActivity.id, question.id)
          .then(result => resolve(result))
          .catch(() => toast(intl.formatMessage({
            id: 'errorServerMessage',
            defaultMessage: 'Une erreur est survenue',
          })));
      }).catch(() => {
        resolve(getActivity.questions);
      });
    });
  };

  const emitDelete = (deleted: DraggableListItem<Question>) => {
    deleteQuestion(deleted.data).then(questions => {
      setActivity(UpdateItemInObject(getActivity, 'questions', questions));
    });
  };

  const updateDraggedQuestion = (draggedList: DraggableListItem<Question>[]) => {
    setActivity({ ...getActivity, questions: draggedList.map(d => d.data) });
  };

  return <Box onClose={onClose}
              title={activity.id.startsWith('NEW') ? intl.formatMessage({
                id: 'addAnActivity',
                defaultMessage: 'Ajouter une activité',
              }) : activity.name}>
    <form onSubmit={onSubmitForm}>
      <div style={{ padding: '20px 40px 40px' }}>
        <Row>
          <Col columns={[12, 6]}>
            <Input
              required
              label={intl.formatMessage({ id: 'actFormNameLabel', defaultMessage: 'Intitulé de l’activité' })}
              hasError={getInvalids.includes('name')}
              value={getActivity.name}
              onChange={ev => setActivity(UpdateItemInObject(getActivity, 'name', ev))}
            />
          </Col>
          <Col columns={[12, 6]}>
            <InputImage
              label={intl.formatMessage({ id: 'actFormImageLabel', defaultMessage: 'Image d’illustration' })}
              value={getEditImage.image}
              addImageLabel={intl.formatMessage({ id: 'addImage', defaultMessage: 'Ajouter une image' })}
              replaceImageLabel={intl.formatMessage({ id: 'replaceImage', defaultMessage: 'Remplacer l’image' })}
              removeImageLabel={intl.formatMessage({ id: 'removeImage', defaultMessage: 'Supprimer l’image' })}
              onError={() => setImageError(true)}
              caption={getImageError ? intl.formatMessage({
                id: 'fieldLogoSizeError',
                defaultMessage: 'Accepte les images jpg,png de 2mb max',
              }) : ''}
              hasError={getImageError}
              onChange={changeImage}
            />
          </Col>
        </Row>
        <Row>
          <Col columns={[12]}>
            <Input
              label={intl.formatMessage({ id: 'actFormDescLabel', defaultMessage: 'Description' })}
              caption={intl.formatMessage({
                id: 'actFormDescCaption',
                defaultMessage: 'Ajoutez une description pour préciser votre activité',
              })}
              value={getActivity.description || ''}
              onChange={ev => setActivity(UpdateItemInObject(getActivity, 'description', ev))}
            />
          </Col>
        </Row>
        <CardTitle>
          {intl.formatMessage({ id: 'actFormTarifTitle', defaultMessage: 'Valeur de base de l’activité' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'actFormTarifSubtitle',
            defaultMessage: 'Précisez la valeur de base de votre activité en communiquant des chiffres moyens (évitez les extrèmes).',
          })}
        </CardSubtitle>
        <Row>
          <Col columns={[12, 6]}>
            <InputNumber
              label={intl.formatMessage({ id: 'actFormPriceMinLabel', defaultMessage: 'Valeur minimum de l’activité' })}
              hasError={getInvalids.includes('minPrice')}
              min={0}
              step='any'
              value={getActivity.minPrice}
              onChange={ev => setActivity(UpdateItemInObject(getActivity, 'minPrice', ev))}
            />
          </Col>
          <Col columns={[12, 6]}>
            <InputNumber
              label={intl.formatMessage({ id: 'actFormPriceMaxLabel', defaultMessage: 'Valeur maximum de l’activité' })}
              hasError={getInvalids.includes('maxPrice')}
              min={0}
              step='any'
              value={getActivity.maxPrice}
              onChange={ev => setActivity(UpdateItemInObject(getActivity, 'maxPrice', ev))}
            />
          </Col>
        </Row>
        <Row style={{ padding: '24px 0' }}>
          <Col columns={[12]}>
            <Switch value={!!getActivity.eligibility}
                    onChange={(eligibility) => setActivity({ ...getActivity, eligibility })}
                    onLabel={intl.formatMessage({
                      id: 'actFormEligibilityLabel',
                      defaultMessage: 'Configurer les réponses d’éligibilité',
                    })}
                    caption={intl.formatMessage({
                      id: 'actFormEligibilityCaption',
                      defaultMessage: 'Activez cette option pour indiquer si le client est éligible ou non et rédiger des messages personnalisés en conséquence.',
                    })} />
            <InOut show={!!getActivity.eligibility}>
              <LabelWrapper label={intl.formatMessage({
                id: 'actFormEligibilityTextLabel',
                defaultMessage: 'Message pour client {text}',
              }, { text: <strong>{intl.formatMessage({ id: 'eligibility', defaultMessage: 'éligible' })}</strong> })}>
                <TextEditor locale={intl.locale} value={getActivity.eligibilityCustomMessage || ''}
                            onChange={(eligibilityCustomMessage) => setActivity({
                              ...getActivity,
                              eligibilityCustomMessage,
                            })} />
              </LabelWrapper>
              <LabelWrapper label={intl.formatMessage({
                id: 'actFormEligibilityTextLabel',
                defaultMessage: 'Message pour client {text}',
              }, { text: <strong>{intl.formatMessage({ id: 'ineligibility', defaultMessage: 'non éligible' })}</strong> })}>
                <TextEditor locale={intl.locale} value={getActivity.ineligibilityCustomMessage || ''}
                            onChange={(ineligibilityCustomMessage) => setActivity({
                              ...getActivity,
                              ineligibilityCustomMessage,
                            })} />
              </LabelWrapper>
            </InOut>
          </Col>
        </Row>
        <CardTitle>
          {intl.formatMessage({ id: 'actFormQuestionTitle', defaultMessage: 'Questions complémentaires' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'actFormQuestionSubtitle',
            defaultMessage: 'Certains paramètres peuvent faire varier l\'estimation de votre activité. Sollicitez ici des informations complémentaires à vos prospects pour obtenir des estimations plus précises et leur fournir un résultat plus juste.',
          })}
        </CardSubtitle>
        <DraggableList<Question>
          onChange={updateDraggedQuestion}
          onDelete={emitDelete}
          items={getActivity.questions.map(q => ({
            data: q,
            id: q.id,
            title: q.label,
            htmlSubtitle: q.description,
            canRemove: true,
            displayModify: true,
          } as DraggableListItem<Question>))}
          onEdit={requestEditDraggedQuestion} />
        <div style={{ margin: '30px 0' }}>
          <BaseButton style={{ color: theme.colors.primary }}
                      onClick={requestAddQuestion}>
            +&nbsp;
            <SpanLink
              style={{ color: theme.colors.primary }}>{intl.formatMessage({
              id: 'addAQuestion',
              defaultMessage: 'Ajouter une question',
            })}</SpanLink>
          </BaseButton>
        </div>
        <Row justifyContent={['center']}>
          <Col>
            <Button colorType={'outline'} onClick={onClose}>
              {intl.formatMessage({ id: 'cancel', defaultMessage: 'Annuler' })}
            </Button>
          </Col>
          <Col>
            <Button colorType={'primary'} type={'submit'}>
              {intl.formatMessage({ id: 'save', defaultMessage: 'Enregistrer' })}
            </Button>
          </Col>
        </Row>
      </div>
    </form>
    <QuestionDialog
      show={getShowDialogAddQuestion}
      onClose={() => setShowDialogAddQuestion(false)}
      onValid={addQuestion} />
  </Box>;
};
