import {FC} from 'react';
import {InteractiveCol} from '../ui/interactive-col';
import {Col, Row, SpanCaption1, SpanSubtitle1, SpanSubtitle2} from '@linkeo.com/ui-lib-react';
import {ItemState} from '../../interface/product.types';
import {ProductForm} from './product.form';
import {SmallCol, SmallRow} from '../ui/small-columns';
import {Devise, RoundFloat} from '../../utils/number.utils';
import {COL_SIZES_CONFIG} from '../document/col-sizes-config';
import {Locale} from '../../providers/intl.provider';
import {useIntl} from 'react-intl';

interface ProductLineProps {
  currency: Devise;
  locale: Locale;
  itemState: ItemState;
  onCancel: () => void;
  onDelete: () => void;
  onItemChange: (v: ItemState) => void;
  onPositionMove: (direction: 'up' | 'down') => void;
  onStateChange: (v: ItemState) => void;
  readOnly: boolean;
}

export const ProductLine: FC<ProductLineProps> = props => {
  const {
    itemState,
    onCancel,
    onDelete,
    onPositionMove,
    onItemChange,
    onStateChange,
    readOnly,
    currency,
  } = props;
  const intl = useIntl();
  
  return <Row>
    {itemState.item.itemType === 'product' && itemState.editMode ? <Col columns={[12]}>
      <ProductForm
        product={itemState.item}
        onChange={v => onItemChange({ ...itemState, item: v, editMode: false })}
        onCancel={onCancel}
        onDelete={onDelete}
        currency={currency}
        onPositionMove={onPositionMove} />
    </Col> : itemState.item.itemType === 'product' && <InteractiveCol
      tabIndex={itemState.readOnly || readOnly ? -1 : 0}
      columns={[12]}
      onClick={() => !itemState.readOnly && !readOnly && onStateChange({ ...itemState, editMode: true })}
      locked={itemState.readOnly || readOnly}>
      <SmallRow>
        <SmallCol columns={COL_SIZES_CONFIG[0]}>
          <SpanSubtitle2>{itemState.item.name}</SpanSubtitle2><br />
          <SpanSubtitle1 style={{ color: '#999999' }}>{itemState.item.productCode}</SpanSubtitle1><br />
          <SpanCaption1 style={{ color: '#999999' }}>{itemState.item.description}</SpanCaption1>
        </SmallCol>
        <SmallCol columns={COL_SIZES_CONFIG[1]}>
          <SpanSubtitle1 style={{ color: '#999999' }}>{intl.formatNumber(itemState.item.price, {
            style: 'currency',
            currency,
          })}</SpanSubtitle1>
        </SmallCol>
        <SmallCol columns={COL_SIZES_CONFIG[2]}>
          <SpanSubtitle1
            style={{ color: '#999999' }}>{RoundFloat(itemState.item.quantity, 100)} {itemState.item.unit ? <>
            <br />{itemState.item.unit}</> : null}</SpanSubtitle1>
        </SmallCol>
        <SmallCol columns={COL_SIZES_CONFIG[3]}>
          <SpanSubtitle1
            style={{ color: '#999999' }}>{RoundFloat(itemState.item.total?.discountPercentage)} %</SpanSubtitle1><br />
          <SpanSubtitle1
            style={{ color: '#999999' }}>{intl.formatNumber(itemState.item.total?.discountAmount||0, {
            style: 'currency',
            currency,
          })}</SpanSubtitle1>
        </SmallCol>
        <SmallCol columns={COL_SIZES_CONFIG[4]} style={{ textAlign: 'right' }}>
          <SpanSubtitle1
            style={{ color: '#999999' }}>{intl.formatNumber(itemState.item.total?.totalWithoutTax||0, {
            style: 'currency',
            currency,
          })}</SpanSubtitle1>
        </SmallCol>
      </SmallRow>
    </InteractiveCol>}
  </Row>;
};