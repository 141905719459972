import { FC, FormEvent, useEffect, useState } from 'react';
import { DocumentParam } from '../../interface/document-param.types';
import {
  Card,
  CardTitle,
  Col,
  Input,
  InputNumber,
  Row,
  SpanBody1,
  SpanSubtitle1,
} from '@linkeo.com/ui-lib-react';
import { FormFooter } from '../ui/form-footer';
import { useHistory } from 'react-router-dom';
import { RemoveItemInArray, UpdateItemInArray, UpdateItemInObject } from '../../utils/deep-object.utils';
import { DeviseSelect } from './devise-select';
import styled from 'styled-components';
import { ProductType } from '../../interface/product.types';
import { IntSelect } from '../commons/int-select';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeHome } from '../../routes';
import { TaxesForm } from './taxes-form';

interface QuoteParamFormProps {
  onChange: (quoteParam: DocumentParam) => void;
  value: DocumentParam;
}

const Part = styled.div<{ borderless?: boolean }>`
    padding: 30px 40px;
    border-bottom: ${({ borderless }) => borderless ? 'transparent' : '#ccc'} 1px solid;
`;
export const DocumentParamForm: FC<QuoteParamFormProps> = props => {
  const { value, onChange } = props;
  const intl = useIntl();
  const [getFormControl, setFormControl] = useState<DocumentParam>(value);
  const history = useHistory();

  useEffect(() => {
    setFormControl(value);
  }, [value]);

  const changeTaxe = (type: ProductType, indexTaxes: number, update: number | string) => {
    setFormControl({
      ...getFormControl,
      taxes: {
        ...getFormControl.taxes,
        [type]: UpdateItemInArray(getFormControl.taxes[type], indexTaxes, {
          ...getFormControl.taxes[type][indexTaxes],
          ...typeof update === 'number' ? { rate: update } : { name: update },
        }),
      },
    });
  };

  const onTaxeRemove = (type: ProductType, indexTaxes: number) => {
    setFormControl({...getFormControl, taxes: {
      ...getFormControl.taxes, [type] : RemoveItemInArray(getFormControl.taxes[type], indexTaxes)
      }})
  }

  const onTaxeAdd = (type: ProductType) => {
    setFormControl({...getFormControl, taxes: {
      ...getFormControl.taxes, [type] : [...getFormControl.taxes[type], {name: '', rate: null}]
      }})
  }

  const emitChange = (event: FormEvent) => {
    event.preventDefault();
    onChange(getFormControl);
  };

  return <Card style={{ padding: 0 }}>
    <form onSubmit={emitChange}>
      <Part>
        <CardTitle>{intl.formatMessage({ id: 'currency', defaultMessage: 'Devise' })}</CardTitle>
        <Row>
          <Col columns={[12, 12, 6]}>
            <DeviseSelect
              value={getFormControl.currency}
              onChange={(v) => setFormControl(UpdateItemInObject(getFormControl, 'currency', v))} />
          </Col>
          <Col columns={[12, 12, 6]}>
            <IntSelect
              label={intl.formatMessage({ id: 'docParamFormLocaleLabel', defaultMessage: 'Locale du document' })}
              value={getFormControl.locale}
              onChange={(v) => setFormControl(UpdateItemInObject(getFormControl, 'locale', v))} />
          </Col>
        </Row>
      </Part>

      <Part>
        <CardTitle>
          <FormattedMessage id={'docParamTaxTitle'} defaultMessage={'Valeurs par défaut des taxes'} />
        </CardTitle>
        <SpanSubtitle1>
          <FormattedMessage id={'docParamTaxCaption'}
                            defaultMessage={'Renseignez ici les valeurs de taxes qui concernent la majorité des articles et services que vous devisez et facturez.'} />
        </SpanSubtitle1>
        <Row>
          <TaxesForm taxes={getFormControl.taxes['product']}
                     changeTaxe={changeTaxe}
                     onTaxeAdd={onTaxeAdd}
                     onTaxeRemove={onTaxeRemove}
                     type={'product'}
                     label={intl.formatMessage({
                       id: 'docParamTaxInputLabel',
                       defaultMessage: 'Valeur de la taxe par défaut pour {value} (en %)',
                     }, {
                       value: <b>{intl.formatMessage({
                         id: 'items',
                         defaultMessage: 'les articles',
                       })}</b>,
                     }) as string} />
          <TaxesForm taxes={getFormControl.taxes['service']}
                     changeTaxe={changeTaxe}
                     onTaxeAdd={onTaxeAdd}
                     onTaxeRemove={onTaxeRemove}
                     type={'service'}
                     label={intl.formatMessage({
                       id: 'docParamTaxInputLabel',
                       defaultMessage: 'Valeur de la taxe par défaut pour {value} (en %)',
                     }, {
                       value: <b>{intl.formatMessage({
                         id: 'services',
                         defaultMessage: 'les services',
                       })}</b>,
                     }) as string} />
        </Row>
      </Part>

      <Part>
        <CardTitle>
          <FormattedMessage id={'docParamDocTitle'} defaultMessage={'Valeurs par défaut des documents'} />
        </CardTitle>
        <Row>
          <Col columns={[12, 12, 6]}>
            <InputNumber
              label={intl.formatMessage({
                id: 'docParamDocInputLabel1',
                defaultMessage: 'Durée de validité des devis (en jours)',
              })}
              required
              onChange={v => setFormControl({ ...getFormControl, quoteLifetime: v || 0 })}
              min={1} value={getFormControl.quoteLifetime} step={1} />
          </Col>
          <Col columns={[12, 12, 6]}>
            <InputNumber label={intl.formatMessage({
              id: 'docParamDocInputLabel2',
              defaultMessage: 'Acompte à l’acceptation des devis (en % du total)',
            })}
                         required
                         onChange={v => setFormControl({ ...getFormControl, depositPercentage: v || 0 })}
                         min={0} value={getFormControl.depositPercentage} step='any' />

          </Col>
          <Col columns={[12, 12, 6]}>
            <InputNumber label={intl.formatMessage({
              id: 'docParamDocInputLabel3',
              defaultMessage: 'Délai de paiement des factures (en jours)',
            })}
                         required
                         onChange={v => setFormControl({ ...getFormControl, invoicePaymentDeadline: v || 0 })}
                         min={1} value={getFormControl.invoicePaymentDeadline} step={1}
            />

          </Col>
          <Col columns={[12, 12, 6]}>
            <Input label={intl.formatMessage({
              id: 'docParamDocInputLabel4',
              defaultMessage: 'Mode de paiement des factures',
            })}
                   value={getFormControl.invoicePaymentMethod}
                   onChange={v => setFormControl({ ...getFormControl, invoicePaymentMethod: v })}
            />
          </Col>
        </Row>
      </Part>

      <Part>
        <CardTitle>
          <FormattedMessage id={'docParamNumberingTitle'} defaultMessage={'Numérotation automatique des devis'} />
        </CardTitle>
        <Row>
          <Col columns={[12, 12, 6]}>
            <Input label={intl.formatMessage({
              id: 'docParamNumberingInput1',
              defaultMessage: 'Racine pour la numérotation des devis',
            })}
                   required
                   value={getFormControl.prefixes.quote}
                   onChange={v => setFormControl({
                     ...getFormControl,
                     prefixes: { ...getFormControl.prefixes, quote: v },
                   })}

            />
          </Col>
        </Row>
        <p><SpanBody1>{intl.formatMessage({
          id: 'resultPreview',
          defaultMessage: 'Aperçu du résultat : ',
        })}{getFormControl.prefixes.quote}-2021-02-0001</SpanBody1></p>
        <p>
          <SpanSubtitle1>
            <FormattedMessage id={'docParamNumberingCaption'}
                              defaultMessage={'En changeant de système de numérotation, les factures déjà éditées avec votre ancien système conserveront leur numérotation originelle.'} />
          </SpanSubtitle1>
        </p>
      </Part>

      <Part borderless>
        <CardTitle>
          <FormattedMessage id={'docParamInvoiceNumberingTitle'}
                            defaultMessage={'Numérotation automatique des factures'} />
        </CardTitle>
        <Row>
          <Col columns={[12, 12, 6]}>
            <Input label={intl.formatMessage({
              id: 'docParamInvoiceNumberingInput',
              defaultMessage: 'Racine pour la numérotation des factures',
            })}
                   required
                   value={getFormControl.prefixes.invoice}
                   onChange={v => setFormControl({
                     ...getFormControl,
                     prefixes: { ...getFormControl.prefixes, invoice: v },
                   })}
            />
          </Col>
        </Row>
        <p><SpanBody1>{intl.formatMessage({
          id: 'resultPreview',
          defaultMessage: 'Aperçu du résultat : ',
        })}{getFormControl.prefixes.invoice}-2021-02-0001</SpanBody1></p>
        <p>
          <SpanSubtitle1>
            <FormattedMessage id={'docParamInvoiceNumberingCaption'}
                              defaultMessage={'En changeant de système de numérotation, les factures déjà éditées avec votre ancien système conserveront leur numérotation originelle.'} />

          </SpanSubtitle1>
        </p>
      </Part>

      <Part borderless>
        <FormFooter onCancel={() => history.push(routeHome)} />
      </Part>

    </form>
  </Card>;

};