import { FC } from 'react';
import { SpanCaption1 } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { SmallCol, SmallRow } from '../ui/small-columns';
import { COL_SIZES_CONFIG } from './col-sizes-config';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  margin-bottom: 20px;
  span {
    color: #999
  }
`;

export const DocumentTableHead: FC = () => {

  return <Wrapper><SmallRow>
    <SmallCol columns={COL_SIZES_CONFIG[0]}>
      <SpanCaption1><FormattedMessage id={'designation'} defaultMessage={'Désignation'} /></SpanCaption1>
    </SmallCol>
    <SmallCol columns={COL_SIZES_CONFIG[1]}>
      <SpanCaption1><FormattedMessage id={'unitprice'} defaultMessage={'Prix U. HT'}/></SpanCaption1>
    </SmallCol>
    <SmallCol columns={COL_SIZES_CONFIG[2]}>
      <SpanCaption1><FormattedMessage id={'quantity'} defaultMessage={'Qté'}/></SpanCaption1>
    </SmallCol>
    <SmallCol columns={COL_SIZES_CONFIG[3]}>
      <SpanCaption1><FormattedMessage id={'discount'} defaultMessage={'Remise HT'}/></SpanCaption1>
    </SmallCol>
    <SmallCol columns={COL_SIZES_CONFIG[4]} style={{ textAlign: 'right' }}>
      <SpanCaption1><FormattedMessage id={'TotalHT'} defaultMessage={'Total HT'}/></SpanCaption1>
    </SmallCol>
  </SmallRow></Wrapper>;
};