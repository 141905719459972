import React, { FC } from 'react';
import { Select, SelectOption } from '@linkeo.com/ui-lib-react';
import { Locale } from '../../providers/intl.provider';
import { LocaleToName } from '../../utils/locale.utils';

interface IntSelectProps {
  label: string;
  onChange: (v: Locale) => void;
  value?: Locale;
}

export const IntSelect: FC<IntSelectProps> = props => {
  const { value, onChange, label } = props;
  const localOptions: SelectOption<Locale>[] = [{
    value: 'fr-FR',
    label: LocaleToName('fr-FR'),
  }, {
    value: 'en-US',
    label: LocaleToName('en-US'),
  }, {
    value: 'en-CA',
    label: LocaleToName('en-CA'),
  }, {
    value: 'fr-CA',
    label: LocaleToName('fr-CA'),
  }, {
    value: 'es-US',
    label: LocaleToName('es-US'),
  }, {
    value: 'en-AU',
    label: LocaleToName('en-AU'),
  }];

  const getLocalSelect = localOptions.find(f => f.value === value);
  const emitChange = (v: SelectOption<Locale>) => {
    onChange(v.value);
  };
  return <Select<Locale>
    label={label}
    options={localOptions}
    selected={getLocalSelect}
    onSelect={emitChange} />;
};