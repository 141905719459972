export const ReadFileToDataUrl = async (
  file: File,
  maxSize: number = 2
): Promise<string | ArrayBuffer | null> => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const size = file.size / (1024 * 1024);
    if (size > maxSize) {
      return reject('fileSize');
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      { once: true }
    );
    reader.addEventListener('abort', reject, { once: true });
    reader.addEventListener('error', reject, { once: true });
    reader.readAsDataURL(file);
  });
};
