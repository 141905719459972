import styled, { ThemeContext } from 'styled-components';
import * as React from 'react';
import { BaseButton } from '../base-button';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ColorHSV, Shapes, ThemeType } from '../../common';
import {
  ColorSelectGradientToCss,
  CoordinateToHsv,
  HslToCss,
  HsvToCoordinate,
  HsvToHsl,
  setInRange,
} from '../../helpers';
import { Svg } from '../svg';

export interface ColorSelectProps {
  /**
   * default 200px
   */
  height?: number;
  onChange: (event: ColorHSV) => void;
  /**
   * hsv is hue and x y coordinate range 0 - 1
   */
  value: ColorHSV;
  /**
   * default 200px
   */
  width?: number;
  onClose?: () => void;
}

const ColorSelectContainer = styled.div`
  ${Shapes.card};
  display: inline-block;
  padding: 20px;
  background: ${({ theme }) => theme.colors.grey95};
  border: 1px solid ${({ theme }) => theme.colors.grey60};
  position: relative;
  user-select: none;
`;
const ColorSelectGrid = styled.div`
  position: relative;
  margin-bottom: 18px;
`;
const ColorSelectGradientContainer = styled.div`
  width: 100%;
  height: 100%;
  background-blend-mode: luminosity;
  mix-blend-mode: normal;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #ffffff 0%, #000000 100%);
    background-blend-mode: luminosity;
    mix-blend-mode: multiply;
    border-radius: 4px;
  }
`;
const ColorSelectPointer = styled(BaseButton)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  left: -10px;
  border-radius: 50%;
  box-sizing: content-box;
  border: 2px solid white;
  transition: border-color 200ms ease-in-out;
`;
const ColorSelectHuePicker = styled.input`
  appearance: none;
  position: relative;
  height: 10px;
  width: 100%;
  background: linear-gradient(
      90deg,
      #ff0000 0%,
      #faff00 15.63%,
      #14ff00 31.25%,
      #00ffd1 45.83%,
      #0066ff 58.33%,
      #9e00ff 71.87%,
      #d0007b 85.42%,
      #ff0000 100%
    ),
    #c4c4c4;
  border-radius: 5px;
  border: none;
  padding: 0;
  margin: 0;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;
    background-color: var(--hue);
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;
    background-color: var(--hue);
  }
`;

interface ColorSelectData {
  cursorX: number;
  cursorY: number;
}

export const ColorSelect: FC<ColorSelectProps> = props => {
  const { value, onChange, width, height, onClose } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  const getWidth = width || 200;
  const getHeight = height || 200;
  const [getHue, setHue] = useState(0);
  const [getDragged, setDragged] = useState<boolean>(false);
  const refColorSelectGradient = useRef<HTMLDivElement>(null);
  const refColorSelectPointer = useRef<HTMLButtonElement>(null);
  const refColorSelectHuePicker = useRef<HTMLInputElement>(null);

  const data = useRef<ColorSelectData>({
    cursorX: 0,
    cursorY: 0,
  });
  const updatePointerPosition = useCallback(
    (x: number, y: number) => {
      const pointer = refColorSelectPointer.current;
      if (!pointer) {
        return;
      }
      pointer.style.transform = `translate(${x}px, ${y}px)`;
    },
    [refColorSelectPointer]
  );
  const updatePointerColor = useCallback(
    (hue: number, saturation: number, lightness: number) => {
      const pointer = refColorSelectPointer.current;
      if (!pointer) {
        return;
      }
      pointer.style.backgroundColor = HslToCss(hue, saturation, lightness);
    },
    [refColorSelectPointer]
  );
  const updateGradiant = useCallback(
    (hue: number) => {
      const grad = refColorSelectGradient.current as HTMLDivElement;
      if (!grad) {
        return;
      }
      grad.style.background = ColorSelectGradientToCss(hue);
      const picker = refColorSelectHuePicker.current;
      if (!picker) {
        return;
      }
      picker.style.setProperty('--hue', HslToCss(hue, 100, 50));
    },
    [refColorSelectGradient, refColorSelectHuePicker]
  );

  const changeHue = (event: ChangeEvent<HTMLInputElement>) => {
    const hue = parseInt((event.target as HTMLInputElement).value, 10);
    onChange(CoordinateToHsv(hue, data.current.cursorX, data.current.cursorY, getWidth, getHeight));
  };

  const mousePosInContainer = (x: number, y: number): { x: number; y: number } => {
    const container = refColorSelectGradient.current;
    if (!container) {
      return { x: 0, y: 0 };
    }
    const rect = container.getBoundingClientRect();
    return {
      x: setInRange(x - rect.x, 0, getWidth),
      y: setInRange(y - rect.y, 0, getHeight),
    };
  };

  const dragStart = (event: React.MouseEvent) => {
    setDragged(true);
    const relativePos = mousePosInContainer(event.clientX, event.clientY);
    updatePointerPosition(relativePos.x, relativePos.y);
    data.current.cursorX = relativePos.x;
    data.current.cursorY = relativePos.y;
  };
  const dragEnd = () => {
    if (!getDragged) {
      return;
    }
    setDragged(false);
    onChange(
      CoordinateToHsv(getHue, data.current.cursorX, data.current.cursorY, getWidth, getHeight)
    );
  };
  const mouseMove = (event: React.MouseEvent) => {
    if (!getDragged) {
      return;
    }
    const relativePos = mousePosInContainer(event.clientX, event.clientY);
    updatePointerPosition(relativePos.x, relativePos.y);
    data.current.cursorX = relativePos.x;
    data.current.cursorY = relativePos.y;
    const coo = CoordinateToHsv(
      getHue,
      data.current.cursorX,
      data.current.cursorY,
      getWidth,
      getHeight
    );
    const hsl = HsvToHsl(coo[0], coo[1], coo[2]);
    updatePointerColor(getHue, hsl[1] * 100, hsl[2] * 100);
  };
  useEffect(() => {
    const coo = HsvToCoordinate(value, getWidth, getHeight);
    data.current.cursorX = coo.cursorX;
    data.current.cursorY = coo.cursorY;
    const hsl = HsvToHsl(value[0], value[1], value[2]);
    const hue = hsl[0] * 360;
    setHue(hue);
    updatePointerColor(hue, hsl[1] * 100, hsl[2] * 100);
    updatePointerPosition(coo.cursorX, coo.cursorY);
    updateGradiant(coo.hue360);
  }, [value, updatePointerPosition, updateGradiant, updatePointerColor, getWidth, getHeight]);

  useEffect(() => {
    updateGradiant(getHue);
  }, [getHue, updateGradiant]);
  return (
    <ColorSelectContainer onMouseMove={mouseMove} onMouseLeave={dragEnd}>
      {onClose && (
        <Svg
          icon={'cross'}
          height={24}
          width={24}
          fill={theme.colors.dark}
          style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
          onClick={onClose}
        />
      )}
      <ColorSelectGrid
        style={{ width: getWidth, height: getHeight }}
        onMouseDown={dragStart}
        onMouseUp={dragEnd}
      >
        <ColorSelectGradientContainer ref={refColorSelectGradient} />
        <ColorSelectPointer ref={refColorSelectPointer} onKeyDown={() => {}} />
      </ColorSelectGrid>
      <ColorSelectHuePicker
        ref={refColorSelectHuePicker}
        type="range"
        value={getHue}
        min={0}
        max={360}
        onChange={changeHue}
      />
    </ColorSelectContainer>
  );
};
