import styled, { css } from 'styled-components';
import { getTypo, Shapes } from '../../common';
import { mergeStyled } from '../../helpers';

export interface BaseInputProps {
  /** put error style */
  hasError?: boolean;
  iconPosition?: 'left' | 'right';
  /** text right **/
  right?: boolean;
  small?: boolean;
}

const withIcon = css<{ iconPosition?: 'left' | 'right'; small?: boolean }>`
  ${({ iconPosition, small }) =>
    iconPosition === 'right'
      ? `padding-right:${small ? '24' : '30'}px`
      : iconPosition === 'left'
      ? `padding-left:${small ? '24' : '30'}px`
      : ''};
`;
const shapeInput = css<{ small?: boolean }>`
  ${({ small }) => (small ? Shapes.smallInput : Shapes.input)};
`;
const rightInput = css<{ right?: boolean }>`
  ${({ right }): string => (right ? 'text-align: right' : '')};
`;
const errorInput = css<{ hasError?: boolean }>`
  border: 1px solid
    ${({ hasError, theme }): string => (hasError ? theme.colors.danger : theme.colors.grey90)};

  &:focus:not(:disabled):not(:read-only) {
    border-color: ${({ hasError, theme }): string =>
      hasError ? theme.colors.lightDanger : theme.colors.grey60};
  }

  &:hover:not(:disabled):not(:read-only) {
    border-color: ${({ hasError, theme }): string =>
      hasError ? theme.colors.lightDanger : theme.colors.grey70};
  }
`;
const themeInput = css`
  background: ${({ theme }) => theme.input.backgroundColor};
  color: ${({ theme }) => theme.input.color};
  border: ${({ theme }) => theme.input.borderColor} 1px solid;
  outline: none;
  width: 100%;
  margin: 0;
  transition: background ease-in-out 200ms, border-color ease-in-out 200ms, color ease-in-out 200ms,
    box-shadow ease-in-out 200ms;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey60};
  }

  &:focus:not(:disabled):not(:read-only) {
    background-color: ${({ theme }) => theme.colors.grey95};
    ${Shapes.shadow1};
  }

  &:hover:not(:disabled):not(:read-only) {
    background-color: ${({ theme }) => theme.colors.grey95};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.grey95};
    background-color: ${({ theme }) => theme.colors.grey95};
    cursor: not-allowed;

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholderColor};
    }
  }

  &:read-only:not(:disabled) {
    background: transparent;
    margin-left: -1px;
    padding-left: 0;
    padding-right: 0;
    border-color: transparent;
  }
`;

export const BaseInput = mergeStyled(styled.input<BaseInputProps>``, [
  getTypo('input'),
  shapeInput,
  withIcon,
  rightInput,
  themeInput,
  errorInput,
]);
