import * as React from 'react';
import { useContext } from 'react';
import { Svg } from '../svg';
import { ThemeType } from '../../common';
import { ThemeContext } from 'styled-components';

export interface StarProps {
  color?: string;
  half?: boolean;
  onClick?: () => void;
}

export const Star: React.FC<StarProps> = props => {
  const { half, color, onClick } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Svg
      style={{ cursor: onClick ? 'pointer' : 'initial' }}
      onClick={() => onClick && onClick()}
      width={24}
      height={24}
      icon={'star'}
      fill={color ? color : theme.colors.grey60}
    >
      {half && (
        <>
          <defs>
            <pattern id="Pattern" x="0" y="0" width="48" height="48">
              <rect x="21" y="0" width="48" height="48" fill={theme.colors.grey60} />
            </pattern>
          </defs>
          <path
            fill="url(#Pattern)"
            d="M24,37.79,13,43.85a1.18,1.18,0,0,1-1.74-1.23L13.36,29.7l-9-9.2a1.18,1.18,0,0,1,.67-2l12.41-1.89L22.93,4.93c.59-1.24,1.55-1.24,2.14,0l5.51,11.68L43,18.5a1.18,1.18,0,0,1,.67,2l-9,9.2,2.12,12.92A1.18,1.18,0,0,1,35,43.85Z"
          />
        </>
      )}
    </Svg>
  );
};
