import { Flex, getTypo, H1, Svg } from "@linkeo.com/ui-lib-react"
import { FC } from "react"
import { FormattedMessage } from "react-intl";
import styled, { useTheme } from "styled-components"

const Text = styled.p`
  ${getTypo('h6')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 30px 0;
`;

export const ServerError: FC = () => {
    const theme = useTheme();

    return <Flex justifyContent={['center']} alignItems={['center']} style={{ height: '100%', paddingTop: '100px' }}>
    <div style={{ textAlign: 'center' }}>
      <Svg icon={'cross-circle'} width={36} height={36} fill={theme.colors.grey60} />
      <H1>500</H1>
      <Text>Internal server error</Text>
      <Text>
        <FormattedMessage id={'errorBody'}
                          defaultMessage={'Une erreur s’est produite alors que {br} le serveur tentait de traiter la {br} demande.'}
                          values={{ br: <br /> }} />
      </Text>
    </div>
  </Flex>
}