import React, { FC, useEffect, useState } from 'react';
import { PageWrapper } from '../../components';
import { useApi } from '../../providers/api-provider';
import { EstimateParam } from '../../interface/estimate-param.types';
import { Container, PageSubtitle, PageTitle, useToaster } from '@linkeo.com/ui-lib-react';
import { EstimationParamsForm } from '../../components/estimation-params/estimation-params.form';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeHome } from '../../routes';
import { ServerError } from '../../components/commons/server-error';

export const EstimateParamPage: FC = () => {
  const [getParam, setParam] = useState<EstimateParam>();
  const API = useApi();
  const intl = useIntl();
  const toast = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    API.getEstimateParam()
      .then(result => setParam(result))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [API]);

  const updateParam = (param: EstimateParam) => {
    setLoading(true);
    API.putEstimateParam(param).then(result => {
      setParam(result);
      history.push(routeHome);
    }).catch(e => {
      console.error(e);
      toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'}))
    }).finally(() => setLoading(false));
  };

  return <PageWrapper isLoading={isLoading}>
    {!error ? <Container size={'lg'}>
      <PageTitle>
        {intl.formatMessage({ id: 'paramPageTitle', defaultMessage: 'Paramètres de l’estimation' })}
      </PageTitle>
      <PageSubtitle>
        <small>{intl.formatMessage({
          id: 'paramPageSubtitle',
          defaultMessage: 'Après avoir répondu aux questions du formulaire, une estimation sera calculée sur la base des tarifs de vos activités et communiquée à vos prospects. Réglez ici la manière dont vous souhaitez communiquer cette estimation.',
        })}</small>
      </PageSubtitle>
      {getParam && <EstimationParamsForm isLoading={loading} estimationParam={getParam} onChange={updateParam} />}
    </Container> : <ServerError />}
  </PageWrapper>;
};
