import React, { createContext, FC } from 'react';
import { createIntl, createIntlCache, IntlShape, RawIntlProvider } from 'react-intl';
import frFR from '../lang/fr-FR.json';
import enAU from '../lang/en-AU.json';
import enCA from '../lang/en-CA.json';
import enUS from '../lang/en-US.json';
import frCA from '../lang/fr-CA.json';
import esUS from '../lang/es-US.json';

export const getMessage = (locale: Locale) => {
  switch (locale) {
    default:
    case 'fr-FR':
      return frFR;
    case 'en-AU':
      return enAU;
    case 'en-CA':
      return enCA;
    case 'en-US':
      return enUS;
    case 'fr-CA':
      return frCA;
    case 'es-US':
      return esUS;
  }
};

export const isLocale = (value: any): value is Locale => {
  return Locales.includes(value);
}

const cache = createIntlCache();
export const Locales = ['fr-FR', 'fr-CA', 'en-US','es-US', 'en-AU', 'en-CA'] as const;
export type Locale = typeof Locales[number];

interface IntlProviderProps {
  locale: Locale,
  messages: Record<string, string>
}

interface IntlContext extends IntlProviderProps {
  intl: IntlShape;
}

const renderContent = (intl: IntlShape, children: React.ReactNode) => (
  <RawIntlProvider value={intl}>{children}</RawIntlProvider>
);

const LocalContext = createContext<IntlContext>(
  {
    messages: {},
    locale: 'fr-FR',
    intl: createIntl({
      locale: 'fr-FR',
      messages: {},
    }, cache),
  },
);

export const IntlProvider: FC<IntlProviderProps> = props => {
  const { children, locale, messages } = props;
  const intl = createIntl({
    locale,
    messages,
  }, cache);
  
  const content = renderContent(intl, children);
  return <LocalContext.Provider value={{ messages, locale, intl }}>{content}</LocalContext.Provider>;
};
