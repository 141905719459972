import { FC } from 'react';
import { Button, useToaster } from '@linkeo.com/ui-lib-react';
import { useApi } from '../../providers/api-provider';
import { useIntl } from 'react-intl';

interface DownloadDocumentButtonProps {
  documentId: string;
  fileTitle: string;
}

export const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = props => {
  const { documentId, fileTitle } = props;
  const API = useApi();
  const intl = useIntl();
  const toast = useToaster();

  const downloadPdf = () => {
    API.getPDF(documentId).then(result => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}_${documentId}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(e => {
      console.error(e);
      toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'}))
    });
  };

  return <Button colorType={'secondary'}
                 onClick={downloadPdf}>{intl.formatMessage({id: 'downloadInPdf', defaultMessage: 'Télécharger en pdf'})}</Button>;
};