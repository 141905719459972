import {FC, FormEvent, useEffect, useState} from 'react';
import {InteractiveDiv} from '../ui/interactive-col';
import {Flex, InputNumber, SpanSubtitle1, Switch} from '@linkeo.com/ui-lib-react';
import {Devise, getSymbolCurrency} from '../../utils/number.utils';
import {NumberMod} from '../../interface/product.types';
import {SmallCol, SmallRow} from '../ui/small-columns';
import {FormFooter} from '../ui/form-footer';
import {Locale} from '../../providers/intl.provider';
import {useIntl} from 'react-intl';

interface TotalSubLineFormProps {
    currency: Devise;
    locale: Locale;
    label: string;
    mod: NumberMod;
    onModChange: (v: NumberMod) => void;
    readOnly: boolean;
    readOnlyLabel: string;
    switchLabel: string;
    totalAmount: number;
}

export const TotalSubLineForm: FC<TotalSubLineFormProps> = props => {
    const {readOnly, onModChange, mod, totalAmount, currency, readOnlyLabel, switchLabel, label} = props;
    const intl = useIntl();
    const [getEditMode, setEditMode] = useState<boolean>(false);
    const [getFormControl, setFormControl] = useState<NumberMod>(mod);
    useEffect(() => {
        setFormControl(mod);
    }, [mod, getEditMode]);
    const emitChange = (event: FormEvent) => {
        event.preventDefault();
        onModChange(getFormControl);
        setEditMode(false);
    };
    return getEditMode ? <form onSubmit={emitChange}>
            <SmallRow justifyContent={['space-between']}>
                <SmallCol columns={[6]}>
                    <InputNumber
                        label={`${label} ${getFormControl.type === 'percentage' ? '%' : getSymbolCurrency(currency)}`}
                        small onChange={v => setFormControl({...getFormControl, value: v || 0})}
                        value={getFormControl.value}/>
                </SmallCol>
                <SmallCol>
                    <Switch label={switchLabel}
                            small
                            right
                            onChange={v => setFormControl({...getFormControl, type: v ? 'percentage' : 'amount'})}
                            value={getFormControl.type === 'percentage'}
                            onLabel={'%'}
                            offLabel={getSymbolCurrency(currency)}
                    />
                </SmallCol>
            </SmallRow>
            <FormFooter onCancel={() => setEditMode(false)} small/>
        </form> :
        readOnly && totalAmount === 0 ? null :
            <InteractiveDiv tabIndex={readOnly ? -1 : 0} locked={readOnly}
                            onClick={() => !readOnly && setEditMode(true)}>
                <Flex justifyContent={['space-between']}>
                    <SpanSubtitle1>{readOnlyLabel}</SpanSubtitle1>
                    <SpanSubtitle1>{intl.formatNumber(totalAmount, {style: 'currency', currency})}</SpanSubtitle1>
                </Flex></InteractiveDiv>;
};