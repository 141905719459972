import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { DisabledDays, getTypo, Shapes, ThemeType } from '../../common';
import { BaseButton } from '../base-button';
import {
  AddDay,
  DaysName,
  FirstDayOfMonth,
  FirstMonDayOfWeek,
  IsDisabledDate,
  IsSameDay,
  MonthsNames,
  RangeSign,
} from '../../helpers';
import { Svg } from '../svg';
import { InOut } from '../in-out';
import { lighten } from 'polished';

type CalendarView = { month: number; year: number; scale: number };

interface CalendarWithView {
  onViewChange: (view: CalendarView) => void;
  view: CalendarView;
}

const CalendarContainer = styled.article`
  ${Shapes.card};
  background: ${({ theme }) => theme.colors.light};
  position: relative;
  width: 320px;
  min-height: 320px;
  padding: 10px;
  user-select: none;
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
`;

const DayCell = styled(BaseButton)<DayCellProps>`
  ${getTypo('body1')};
  width: 38px;
  height: 38px;
  margin: 2.2px;
  text-align: center;
  padding: 6.5px 0;
  border-radius: ${({ theme }) => theme.button.small.borderRadius};
  color: ${({ isFade, theme }) => (isFade ? theme.colors.grey70 : '')};
  border-color: ${({ isToday, theme }) => (isToday ? theme.colors.primary : '')};
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.primary : '')};
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.light : '')};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${({ theme, isSelected }) =>
      isSelected ? '' : lighten(0.2, theme.colors.primary)};
  }
`;

interface MonthYearCellProps {
  height: number;
  isToday?: boolean;
  width: number;
}

const MonthYearCell = styled(BaseButton)<MonthYearCellProps>`
  ${getTypo('body1')};
  text-align: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: 2px;
  border-radius: 4px;
  border-color: ${({ isToday, theme }) => (isToday ? theme.colors.primary : 'transparent')};

  &:disabled {
    color: ${({ theme }) => theme.colors.grey60};
  }
`;

const NavButton = styled(BaseButton)`
  ${getTypo('h6')};
  padding: 8.5px 11px;
  border-radius: 4px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.grey60};

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.grey80};
  }

  &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.grey90};
  }

  svg {
    fill: ${({ theme }) => theme.colors.dark};
  }

  &:disabled {
    svg {
      fill: ${({ theme }) => theme.colors.grey80};
    }
  }
`;
const NavDay = styled.div<NavDayProps>`
  ${getTypo('caption1')};
  display: inline-block;
  text-transform: capitalize;
  text-align: center;
  padding: 5px 0;
  width: 36.5px;
  margin: 0 3px;
  box-sizing: border-box;
  opacity: ${({ hide }): string => (hide ? '0' : '1')};
`;

interface CellProps {
  isToday?: boolean;
}

interface DayCellProps extends CellProps {
  isFade?: boolean;
  isSelected?: boolean;
}

interface NavDayProps {
  hide?: boolean;
}

interface CalendarHeaderProps {
  onChangeScale: () => void;
  onNext: () => void;
  onPrevious: () => void;
  text: string;
}

const CalendarHeader: FC<CalendarHeaderProps> = props => {
  const { onPrevious, onNext, text, onChangeScale } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <header>
      <NavContainer>
        <NavButton onClick={onPrevious}>
          <Svg
            style={{ position: 'relative', top: '3px' }}
            width={24}
            height={24}
            icon={theme.icons === 'back' ? 'left' : 'arrow-ios-back-fill'}
          />
        </NavButton>
        <NavButton onClick={onChangeScale}>{text}</NavButton>
        <NavButton onClick={onNext}>
          <Svg
            style={{ position: 'relative', top: '3px' }}
            width={24}
            height={24}
            icon={theme.icons === 'back' ? 'right' : 'arrow-ios-forward-fill'}
          />
        </NavButton>
      </NavContainer>
    </header>
  );
};

interface CalendarMonthsProps extends CalendarWithView {
  locale?: string;
  max?: Date;
  min?: Date;
}

const CalendarMonths: FC<CalendarMonthsProps> = props => {
  const { onViewChange, view, locale, min, max } = props;
  const today = new Date();
  const isDisabled = (month: number): boolean => {
    const signMin = RangeSign(new Date(view.year, month + 1, -1, 23, 59, 59), min, null);
    const signMax = RangeSign(new Date(view.year, month, 0, 0, 0, 0), null, max);
    return signMin === -1 || signMax === 1;
  };
  return (
    <>
      <CalendarHeader
        onPrevious={() => onViewChange({ ...view, year: view.year - 1 })}
        onNext={() => onViewChange({ ...view, year: view.year + 1 })}
        onChangeScale={() => onViewChange({ ...view, scale: 3 })}
        text={view.year + ''}
      />
      {MonthsNames(locale).map((m, i) => (
        <MonthYearCell
          onClick={() => onViewChange({ month: i, year: view.year, scale: 1 })}
          key={m + view.year}
          width={94}
          height={71}
          disabled={isDisabled(i)}
          isToday={IsSameDay(new Date(view.year, i, today.getDate()), today)}
        >
          {m}
        </MonthYearCell>
      ))}
    </>
  );
};
const CalendarYears: FC<CalendarMonthsProps> = props => {
  const { onViewChange, view, max, min } = props;
  const today = new Date();
  const years = new Array(12).fill(0).map((_, i) => view.year - 5 + i);
  const isDisabled = (year: number): boolean => {
    return RangeSign(year, min?.getFullYear(), max?.getFullYear()) !== 0;
  };
  return (
    <>
      <CalendarHeader
        onPrevious={() => onViewChange({ ...view, year: view.year - 10 })}
        onNext={() => onViewChange({ ...view, year: view.year + 10 })}
        onChangeScale={() => onViewChange({ ...view, scale: 3 })}
        text={view.year - 5 + '-' + (view.year + 6)}
      />
      {years.map(y => (
        <MonthYearCell
          onClick={() => onViewChange({ month: view.month, year: y, scale: 2 })}
          disabled={isDisabled(y)}
          width={71}
          height={71}
          key={y}
          isToday={IsSameDay(new Date(y, today.getMonth(), today.getDate()), today)}
        >
          {y}
        </MonthYearCell>
      ))}
    </>
  );
};

interface CalendarDaysProps extends CalendarWithView {
  disabledDays?: DisabledDays;
  firstDayOfWeekOffset?: number;
  locale?: string;
  onChange: (date: Date) => void;
  value?: Date | null;
}

const CalendarDays: FC<CalendarDaysProps> = props => {
  const { firstDayOfWeekOffset, disabledDays, value, onChange, onViewChange, view, locale } = props;

  const [getDays, setDays] = useState<Date[]>([]);
  useEffect(() => {
    const firstDayOfMonth = FirstDayOfMonth(view.year, view.month);
    const firstDayOfWeek = FirstMonDayOfWeek(firstDayOfMonth, firstDayOfWeekOffset);
    const testLastDay = AddDay(firstDayOfWeek, 35);
    const daysCellCount = testLastDay.getMonth() === view.month ? 42 : 35;
    const days: Date[] = new Array(daysCellCount)
      .fill(undefined)
      .map((_, i) => AddDay(firstDayOfWeek, i));
    setDays(days);
  }, [view, firstDayOfWeekOffset]);
  const previousMonth = () => {
    const month = view.month - 1;
    const year = view.year;
    onViewChange({
      year: month < 0 ? year - 1 : year,
      month: month < 0 ? 11 : month,
      scale: 1,
    });
  };
  const nextMonth = () => {
    const month = view.month + 1;
    const year = view.year;
    onViewChange({
      year: month > 11 ? year + 1 : year,
      month: month > 11 ? 0 : month,
      scale: 1,
    });
  };
  return (
    <>
      <CalendarHeader
        onPrevious={previousMonth}
        onNext={nextMonth}
        onChangeScale={() => onViewChange({ ...view, scale: 2 })}
        text={`${MonthsNames(locale)[view.month]} ${view.year}`}
      />
      {DaysName(typeof firstDayOfWeekOffset === 'undefined' ? 1 : firstDayOfWeekOffset, locale).map(
        name => (
          <NavDay key={name}>{name.substring(0, 3)}</NavDay>
        )
      )}
      {getDays.map(d => (
        <DayCell
          disabled={IsDisabledDate(d, disabledDays)}
          isFade={d.getMonth() !== view.month || IsDisabledDate(d, disabledDays)}
          key={view.month + d.toISOString()}
          isToday={IsSameDay(d, new Date())}
          isSelected={IsSameDay(d, value || new Date())}
          onClick={() => onChange(d)}
        >
          {d.getDate()}
        </DayCell>
      ))}
    </>
  );
};

export interface CalendarProps {
  disabledDays?: DisabledDays;
  /** change locale like fr-FR default browser config */
  locale?: string;
  /** change first day of week default is 1 (monday) */
  offsetWeekDay?: number;
  /** return selected date when click on day cell */
  onChange: (event: Date) => void;
  /** Init date */
  value?: Date | null;
}

export const Calendar: FC<CalendarProps> = props => {
  const { onChange, value, locale, offsetWeekDay, disabledDays } = props;

  const [getView, setView] = useState<CalendarView>({
    month: 0,
    year: 0,
    scale: 1,
  });
  useEffect(() => {
    const date: Date = value || new Date();
    setView({
      month: date.getMonth(),
      year: date.getFullYear(),
      scale: 1,
    });
  }, [value]);
  return (
    <CalendarContainer>
      <InOut
        show={getView.scale === 1}
        keepContent
        style={{ display: getView.scale === 1 ? 'block' : 'none' }}
      >
        <CalendarDays
          disabledDays={disabledDays}
          value={value}
          locale={locale}
          firstDayOfWeekOffset={offsetWeekDay}
          onChange={onChange}
          view={getView}
          onViewChange={setView}
        />
      </InOut>
      <InOut
        show={getView.scale === 2}
        keepContent
        style={{ display: getView.scale === 2 ? 'block' : 'none' }}
      >
        <CalendarMonths
          locale={locale}
          view={getView}
          min={disabledDays?.min}
          max={disabledDays?.max}
          onViewChange={setView}
        />
      </InOut>
      <InOut
        show={getView.scale === 3}
        keepContent
        style={{ display: getView.scale === 3 ? 'block' : 'none' }}
      >
        <CalendarYears
          view={getView}
          min={disabledDays?.min}
          max={disabledDays?.max}
          onViewChange={setView}
        />
      </InOut>
    </CalendarContainer>
  );
};
