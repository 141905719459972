import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  CardSubtitle,
  CardTitle,
  Col,
  Input, LabelWrapper,
  Radio,
  RadioGroup,
  RadioOption,
  Row,
} from '@linkeo.com/ui-lib-react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AIDES_ET_PRIMES,
  EstimateParamDisplayType,
  EstimateParamType,
  FormParams,
  PRICE,
  SURFACE,
  VALUE,
  VOLUME,
} from '../../interface/scope.types';
import { Debounce } from '../../utils/algo.utils';
import { TextEditor } from '../commons/text-editor';

interface EstimationValueParamsFormProps {
  params: FormParams;
  onParamsChange: (params: FormParams) => void;
  name?: string | null;
}

export const EstimationValueParamsForm: FC<EstimationValueParamsFormProps> = ({ params, onParamsChange, name }) => {
  const intl = useIntl();
  const [getDetails, setDetails] = useState<string>(params?.details ?? '');
  const [isCustomValue, setIsCustomValue] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | null>(params?.unit.value ?? null);
  const [inputValueCurrency, setInputValueCurrency] = useState<string | null>(params?.unit.valueCurrency ?? null);
  const debounceRef = useRef<number>();
  const debounceCustomValueRef = useRef<number>();

  const optionsValue: RadioOption<EstimateParamType>[] = [{
    data: PRICE,
    name: intl.formatMessage({ id: 'estimationParamsFormOptionPrice', defaultMessage: 'Estimation Prix' }),

  }, {
    data: VALUE,
    name: intl.formatMessage({ id: 'estimationParamsFormOptionValue', defaultMessage: 'Estimation en Valeurs' }),

  }];
  const optionsTarif: RadioOption<EstimateParamDisplayType>[] = useMemo(() => {
    return [{
      data: 'BETWEEN',
      caption: params.unit.type === PRICE
        ? intl.formatMessage({ id: 'paramTarifBetweenCaption', defaultMessage: 'Exemple : Entre 100 et 200 Euros' })
        : intl.formatMessage({
          id: 'paramTarifBetweenCaptionValue', defaultMessage: 'Exemple : Entre 100 et 200 {value}',
        }, { value: params.unit.valueCurrency }),
      name: params.unit.type === PRICE
        ? params.formType === AIDES_ET_PRIMES
          ? intl.formatMessage({ id: 'paramTarifBetweenAssistanceLabel', defaultMessage: 'Une fourchette d\'aide' })
          : intl.formatMessage({ id: 'paramTarifBetweenLabel', defaultMessage: 'Une fourchette de prix' })
        : intl.formatMessage({ id: 'paramTarifBetweenLabelValue', defaultMessage: 'Une fourchette' }),
    }, {
      data: 'FROM',
      caption: params.unit.type === PRICE
        ? intl.formatMessage({ id: 'paramTarifFromCaption', defaultMessage: 'Exemple : À partir de 100 Euros' })
        : intl.formatMessage({
          id: 'paramTarifFromCaptionValue',
          defaultMessage: 'Exemple : À partir de 100 {value}',
        }, { value: params.unit.valueCurrency }),
      name: params.unit.type === PRICE
        ? params.formType === AIDES_ET_PRIMES
          ? intl.formatMessage({ id: 'paramTarifFromAssistanceLabel', defaultMessage: 'Une aide d’entrée' })
          : intl.formatMessage({ id: 'paramTarifFromLabel', defaultMessage: 'Un prix d’entrée' })
        : intl.formatMessage({ id: 'paramTarifFromLabelValue', defaultMessage: 'Une valeur d’entrée' }),
    }, {
      data: 'MANUAL',
      caption: params.formType === AIDES_ET_PRIMES
        ? intl.formatMessage({
          id: 'paramTarifManualAssistanceCaption',
          defaultMessage: 'Vous préférez indiquez uniquement l’éligibilité',
        })
        : intl.formatMessage({ id: 'paramTarifManualCaption', defaultMessage: 'Vous préférez chiffrer par vous même' }),
      name: params.unit.type === PRICE
        ? params.formType === AIDES_ET_PRIMES
          ? intl.formatMessage({ id: 'paramTarifManualAssistanceLabel', defaultMessage: 'Pas d’estimation d\'aide' })
          : intl.formatMessage({ id: 'paramTarifManualLabel', defaultMessage: 'Pas d’estimation de prix' })
        : intl.formatMessage({ id: 'paramTarifManualLabelValue', defaultMessage: 'Pas d’estimation' }),
    }, {
      data: 'EXACT',
      caption: params.unit.type === PRICE
        ? intl.formatMessage({ id: 'paramTarifExactCaption', defaultMessage: 'Exemple : 100 Euros' })
        : intl.formatMessage({
          id: 'paramTarifExactCaptionValue',
          defaultMessage: 'Exemple : 100 {value}',
        }, { value: params.unit.valueCurrency }),
      name: params.unit.type === PRICE
        ? params.formType === AIDES_ET_PRIMES
          ? intl.formatMessage({ id: 'paramTarifExactAssistanceLabel', defaultMessage: 'Somme exacte' })
          : intl.formatMessage({ id: 'paramTarifExactLabel', defaultMessage: 'Prix exact' })
        : intl.formatMessage({ id: 'paramTarifExactLabelValue', defaultMessage: 'Valeur exacte' }),
    }];
  }, [params.unit.type, params.unit.valueCurrency, params.formType, intl]);

  useEffect(() => {
    setDetails(params?.details ?? '');
    setInputValue(params?.unit.value && params.unit.value !== SURFACE && params.unit.value !== VOLUME ? params.unit.value : null);
    setInputValueCurrency(params?.unit.valueCurrency && params.unit.value !== SURFACE && params.unit.value !== VOLUME ? params.unit.valueCurrency : null);
  }, [params]);

  useEffect(() => {
    if (params.unit.type === PRICE) {
      setIsCustomValue(false);
    } else {
      if (params.unit.value === VOLUME || params.unit.value === SURFACE) {
        setIsCustomValue(false);
      } else {
        setIsCustomValue(true);
      }
    }
  }, [params.unit]);

  const onCustomValueChange = (v: { value: string | null, valueCurrency: string | null }) => {
    setInputValue(v.value);
    setInputValueCurrency(v.valueCurrency);
    debounceCustomValueRef.current = Debounce(() => {
      if (v.value && v.valueCurrency) {
        onParamsChange({ ...params, unit: { ...params.unit, value: v.value, valueCurrency: v.valueCurrency } });
      }
    }, 1000, debounceCustomValueRef.current || 0);
  };

  const onDetailsChange = (details: string) => {
    setDetails(details);
    debounceRef.current = Debounce(() => {
      onParamsChange({ ...params, details });
    }, 1000, debounceRef.current || 0);
  };

  return <div style={{ margin: '30px 0' }}>
    <Card>
      <CardTitle>
        {params.formType === AIDES_ET_PRIMES
          ? intl.formatMessage({ id: 'paramCardEstimationAssitanceTitle', defaultMessage: 'Formulaire d\'aide' })
          : intl.formatMessage({ id: 'paramCardEstimationTitle', defaultMessage: 'Formulaire' })}
        {name && <span style={{ fontWeight: 400 }}>&nbsp;- {name}</span>}
      </CardTitle>
      {params.formType !== AIDES_ET_PRIMES && <CardSubtitle>
        {intl.formatMessage({
          id: 'paramCardEstimationSubtitle',
          defaultMessage: 'Voulez-vous estimer un prix ou une valeur ?',
        })}
      </CardSubtitle>}
      {params.formType !== AIDES_ET_PRIMES && <RadioGroup<EstimateParamType>
        groupName={'paramsValue'}
        onChange={(v) => {
          onParamsChange({
            ...params,
            unit: {
              ...params.unit,
              type: v.data,
              value: v.data === VALUE ? VOLUME : null,
              valueCurrency: v.data === VALUE ? 'm3' : null,
            },
          });
        }}
        value={optionsValue.find(f => f.data === params.unit.type)}
        options={optionsValue} />}
      {params.unit.type === VALUE && <Row wraps={'wrap'}>
        <Col columns={[12, 6, 4, 3]}>
          <Radio
            name={'valueType'}
            onChange={() => onParamsChange({ ...params, unit: { ...params.unit, value: VOLUME, valueCurrency: 'm3' } })}
            value={params.unit.value === VOLUME && !isCustomValue}
            caption={intl.formatMessage({
              id: 'estimationParamsFormOptionTypeValueVolumeCaption',
              defaultMessage: 'Exemple : Votre déménagement équivaut à 120 m3',
            })}>
            <FormattedMessage id={'estimationParamsFormOptionTypeValueVolumeName'}
                              defaultMessage={'En volume (m3)'} />
          </Radio>
        </Col>
        <Col columns={[12, 6, 4, 3]}>
          <Radio
            name={'valueType'}
            onChange={() => onParamsChange({
              ...params,
              unit: { ...params.unit, value: SURFACE, valueCurrency: 'm2' },
            })}
            value={params.unit.value === SURFACE && !isCustomValue}
            caption={intl.formatMessage({
              id: 'estimationParamsFormOptionValueSurfaceCaption',
              defaultMessage: 'Exemple : Votre surface à peindre équivaut à 44m2',
            })}>
            <FormattedMessage id={'estimationParamsFormOptionValueSurfaceName'} defaultMessage={'En surface (m2)'} />
          </Radio>
        </Col>
        <Col columns={[12, 10, 8, 6]}>
          <Radio
            name={'valueType'}
            onChange={() => {
              setIsCustomValue(true);
              if (!!inputValueCurrency && !!inputValue) {
                onParamsChange({
                  ...params,
                  unit: { ...params.unit, value: inputValue, valueCurrency: inputValueCurrency },
                });
              }
            }}
            value={isCustomValue}
            caption={intl.formatMessage({
              id: 'estimationParamsFormOptionValueOtherCaption',
              defaultMessage: 'Exemple : Définissez une valeur',
            })}>
            <Row style={{ verticalAlign: 'center' }}>
              <Col columns={[4]}>
                <FormattedMessage id={'estimationParamsFormOptionValueOtherName'} defaultMessage={'Autre valeurs'} />
              </Col>
              <Col columns={[4]} style={{ margin: '-22px 0 0' }}>
                <Input type={'text'}
                       disabled={!isCustomValue}
                       value={inputValue || ''}
                       hasError={isCustomValue && !inputValue}
                       placeholder={intl.formatMessage({
                         id: 'paramValueCustomInputNamePlaceholder',
                         defaultMessage: 'nom',
                       })}
                       onChange={(value) => onCustomValueChange({ value, valueCurrency: inputValueCurrency })} />
              </Col>
              <Col columns={[4]} style={{ margin: '-22px 0 0' }}>
                <Input type={'text'}
                       disabled={!isCustomValue}
                       value={inputValueCurrency || ''}
                       hasError={isCustomValue && !inputValueCurrency}
                       placeholder={intl.formatMessage({
                         id: 'paramValueCustomInputCurrencyPlaceholder',
                         defaultMessage: 'unité',
                       })}
                       onChange={(valueCurrency) => onCustomValueChange({ value: inputValue, valueCurrency })} />
              </Col>
            </Row>
          </Radio>
        </Col>
      </Row>}
      {params.unit.type === VALUE && <CardSubtitle style={{ margin: '25px 0' }}>
        <FormattedMessage id={'estimationParamsFormDisplayLabel'}
                          defaultMessage={'Apportez des précisions à l’estimation'} />
      </CardSubtitle>}
      <RadioGroup<EstimateParamDisplayType>
        required
        options={optionsTarif}
        groupName={'display'}
        onChange={(v) => v.data && onParamsChange({ ...params, display: v.data })}
        value={optionsTarif.find(f => f.data === params.display)} />
      <LabelWrapper
        label={intl.formatMessage({
          id: 'paramTarifDetailLabel',
          defaultMessage: 'Apportez des précisions à l’estimation',
        })}
        caption={intl.formatMessage({
          id: 'paramTarifDetailCaption',
          defaultMessage: 'Vous pouvez ajouter ici un texte libre pour apporter des précisions à l’estimation qui sera communiquée à vos prospects.',
        })}>
        <TextEditor
          locale={intl.locale}
          value={getDetails}
          onChange={onDetailsChange} />
      </LabelWrapper>
    </Card>
  </div>;
};