import { FC, useEffect, useRef, useState } from 'react';
import { DocumentStatus, QuoteDocument } from '../../interface/document.type';
import { Button, Col, Container, Flex, PageSubtitle, Row, Svg, useToaster } from '@linkeo.com/ui-lib-react';
import { PageWrapper } from '../../components';
import { useApi } from '../../providers/api-provider';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentView } from '../../components/document/document-view';
import { DocumentParamProvider } from '../../providers/document-param.provider';
import { getInvalidQuoteDocument, QuoteDocumentErrors } from '../../utils/quote-document-validator';
import { FinalisationDocumentDialog } from '../../components/document/finalisation-document-dialog';
import { EstimationCardRecap } from '../../components/estimation/estimation-card-recap';
import { useRouting } from '../../providers/routing.provider';
import { DocumentStatusToPageRoute } from '../../utils/document.utils';
import { DuplicateDocumentButton } from '../../components/document/duplicate-document-button';
import { OpenAttachedQuoteButton } from '../../components/document/open-attached-quote-button';
import { useIntl } from 'react-intl';
import { routeDocuments } from '../../routes';
import { Error404 } from '../../components/commons/404-error';
import { ServerError } from '../../components/commons/server-error';

export const DocumentDraftPage: FC = () => {
  const [getDocument, setDocument] = useState<QuoteDocument>();
  const [getErrors, setErrors] = useState<QuoteDocumentErrors>({});
  const [getLocked, setLocked] = useState<boolean>(false);
  const [getShowConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [pageError, setPageError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { documentId } = useParams<{ documentId: string }>();
  const API = useApi();
  const history = useHistory();
  const intl = useIntl();
  const toast = useToaster();
  const { beforeRouteChange, removeChangeEvent } = useRouting();
  const changeRef = useRef<number>(0);

  useEffect(() => {
    setLoading(true)
    API.retrieveDocument(documentId)
      .then(result => setDocument(result))
      .catch(() => setPageError(true))
      .finally(() => setLoading(false))
  }, [API, documentId]);

  useEffect(() => {
    if (!getDocument) {
      return;
    }
    if (getDocument.status === 'draft') {
      return;
    }
    history.replace(`/${DocumentStatusToPageRoute(getDocument.status, getDocument.type)}/document/${getDocument.id}`);
  }, [getDocument, history]);

  useEffect(() => {
    if (!getDocument) {
      return;
    }
    beforeRouteChange((event) => {
      if (getDocument.status === 'draft' && changeRef.current > 1) {
        const confirmation = confirm(
          intl.formatMessage({id: 'confirmNoSave', defaultMessage: 'vos changements n’ont pas été enregistré voulez vous continuez'}),
        );
        if (!confirmation) {
          event.preventDefault();
          setLocked(false);
        }
      }
    });
    changeRef.current++;
    setErrors(getInvalidQuoteDocument(getDocument, getDocument.parameters.locale));
    return () => {
      removeChangeEvent();
    };
  }, [getDocument, changeRef, beforeRouteChange, removeChangeEvent, intl]);

  const emitDelete = () => {
    if (!getDocument || getLocked) {
      return;
    }
    setLocked(true);
    API.deleteDocument(getDocument.id)
      .then(() => {
      history.push(routeDocuments);
    }).catch(e => {
      console.error(e);
      setPageError(true)
    })
      .finally(() => {
      setLocked(false);
    });
  };

  const emitSave = (status: DocumentStatus) => {
    if (!getDocument || getLocked) {
      return;
    }
    setLocked(true);
    API.updateDocument({ ...getDocument, status }).then(result => {
      setDocument(result);
      changeRef.current = 1;
    }).catch(() => toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'})
    )).finally(() => {
      setShowConfirmDialog(false)
      setLocked(false);
    });
  };

  const updateDraft = (doc: QuoteDocument) => {
    const { recipient, issueDate, expirationDate, ...rest } = doc;
    API.simulateDocument({ ...rest }).then(
      result => setDocument({ ...doc, ...result, recipient, issueDate, expirationDate, title: doc.title, id: doc.id }));
  };

  return <PageWrapper isLoading={loading}>
    <DocumentParamProvider>
      {getDocument && !pageError ?
        <Container size={'lg'}>
          {getDocument.estimationId ? <EstimationCardRecap estimationId={getDocument.estimationId} /> : null}
          {
            getDocument.acceptance === 'accepted' && <Flex alignItems={['center']}>
              <Svg style={{ marginBottom: '14px', marginRight: '10px' }} icon={'check-circle'} fill={'#35D759'}
                   width={24} height={24} />
              <PageSubtitle>
                {
                  intl.formatMessage({id: 'acceptedQuoteAt', defaultMessage: 'Devis accepté le {date}'},
                    { date: intl.formatDate(getDocument.acceptanceDate || new Date(), {dateStyle: 'short'}) })
                }
              </PageSubtitle>
            </Flex>}
          <DocumentView
            errors={getErrors}
            onDocumentChange={updateDraft} quoteDocument={getDocument} readOnly={false} />
          <Row style={{ marginTop: '20px' }}>
            {
              getDocument.quoteId ? <Col>
                <OpenAttachedQuoteButton quoteId={getDocument.quoteId} />
              </Col> : null
            }
            <Col>
              <DuplicateDocumentButton quoteDocument={getDocument} />
            </Col>
            <Col>
              <Button loading={getLocked} colorType={'secondary'}
                      onClick={emitDelete}>{intl.formatMessage({id: 'delete', defaultMessage: 'Supprimer'})}</Button>
            </Col>
            <Col>
              <Button loading={getLocked} colorType={'secondary'} onClick={() => emitSave('draft')}>
                {intl.formatMessage({id: 'saveDraft', defaultMessage: 'Enregistrer le brouillon'})}</Button>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button disabled={!!Object.keys(getErrors).length} colorType={'primary'}
                      onClick={() => setShowConfirmDialog(true)}>
                {getDocument.type === 'quote' ? intl.formatMessage({id: 'finalizeQuote', defaultMessage: 'Finaliser le devis'}) : intl.formatMessage({id: 'finalizeInvoice', defaultMessage: 'Finaliser la facture'})}
              </Button>
            </Col>
          </Row>
          <FinalisationDocumentDialog
            isLoading={getLocked}
            onClose={() => setShowConfirmDialog(false)} onConfirm={() => emitSave('finalized')}
            show={getShowConfirmDialog} documentType={getDocument.type} />
        </Container> : getDocument && pageError ? <ServerError /> : <Error404 /> }
    </DocumentParamProvider>
  </PageWrapper>
    ;
};