import React, { FC } from 'react';
import { Activity } from '../../interface/activity.types';
import {
  BaseButton,
  Col,
  DraggableList,
  DraggableListItem,
  Row,
  SpanLink,
  SquareButton,
} from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

interface ActivityListProps {
  activities: Activity[];
  categoryName: string;
  onChange: (items: Activity[]) => void;
  onCopy: (item: Activity) => void;
  onDelete: (item: Activity) => void;
  onEdit: (item: Activity) => void;
  onNew: () => void;
  onToggle: (item: Activity) => void;
  readOnly?: boolean | null;
}

const Footer = styled.footer`
  padding: 24px;
`;


export const ActivityList: FC<ActivityListProps> = ({
                                                      activities,
                                                      onChange,
                                                      onEdit,
                                                      categoryName,
                                                      onNew,
                                                      onDelete,
                                                      onToggle,
                                                      onCopy,
                                                      readOnly,
                                                    }) => {
  const intl = useIntl();
  const updateDraggedActivity = (draggedList: DraggableListItem<Activity>[]) => {
    onChange(draggedList.map(d => d.data));
  };
  const activeActivity = (draggedActivity: DraggableListItem<Activity>) => {
    onToggle(draggedActivity.data);
  };
  return <>
    <DraggableList<Activity>
      isCollapsed
      onChange={updateDraggedActivity}
      items={activities.map(act => ({
        data: act,
        id: act.id,
        title: act.name,
        subtitle: act.minPrice === act.maxPrice ?
          intl.formatMessage({ id: 'catTarifBaseFrom', defaultMessage: 'Valeur de base à partir de {min}' }, {
            min: act.minPrice || 0,
          }) :
          intl.formatMessage({ id: 'catTarifBase', defaultMessage: 'Valeur de base entre {min} et {max}' }, {
            min: act.minPrice || 0,
            max: act.maxPrice || 0,
          }),
        canRemove: act.type === 'CUSTOMER',
        canToggle: true,
        displayModify: !readOnly,
        custom: !readOnly ? <Row>
          <Col>
            <SquareButton icon={'copy'} onClick={() => onCopy(act)} />
          </Col>
          <Col>
            <SquareButton icon={'edit'} onClick={() => onEdit(act)} />
          </Col>
          {act.type === 'CUSTOMER' ? <Col>
            <SquareButton icon={'delete'} onClick={() => onDelete(act)} />
          </Col> : null}
        </Row> : null,
        toggleState: act.active,
      } as DraggableListItem<Activity>))}
      onToggle={activeActivity}
    />
    {!readOnly && <Footer>
      <BaseButton onClick={onNew}>
        +
        <SpanLink>
          {intl.formatMessage({ id: 'addActivityOf', defaultMessage: 'Ajouter une activité de' })} {categoryName}
        </SpanLink>
      </BaseButton>
    </Footer>}
  </>;
};
