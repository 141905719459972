import styled from 'styled-components';
import { MakeCssMediaQueryStrict, ScreenDevices } from '../../common';

interface HideProps {
  devices: ScreenDevices[];
}

export const Hide = styled.div<HideProps>`
  ${({ devices }) =>
    devices.map(d =>
      MakeCssMediaQueryStrict(
        `
  display:none;
  visibility:hidden;
  `,
        d
      )
    )}
`;
