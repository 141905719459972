import { QuoteDocument, Recipient } from '../interface/document.type';
import { Address, BankInformations, Company, LegalInformations } from '../interface/company.types';
import { AddDay, RangeSign } from '@linkeo.com/ui-lib-react';
import { isValidIban, isValidRib, isValidSiret } from './algo.utils';
import { Locale } from '../providers/intl.provider';

export type AddressLineErrors = number[];
export type AddressErrors = { [key in keyof Address]?: boolean | AddressLineErrors };
export const getInvalidAddress = (address: Address): AddressErrors => {
  const errors: AddressErrors = {};
  if (!address.city) {
    errors['city'] = true;
  }
  if (!address.country) {
    errors['country'] = true;
  }
  if (!address.postalCode) {
    errors['postalCode'] = true;
  }
  if (!address.addressLines || !address.addressLines.length) {
    errors['addressLines'] = true;
  } else {
    address.addressLines.forEach((line, index) => {
      if (!line) {
        if (typeof errors['addressLines'] !== 'object') {
          errors['addressLines'] = [index];
        } else {
          errors['addressLines'].push(index);
        }
      }
    });
  }
  return errors;
};
export const hasBankInformations = (bankInformations: BankInformations): boolean => {
  return !!(bankInformations.iban || bankInformations.bic || bankInformations.bankName || bankInformations.rib);
};
export type BankInformationsErrors = { [key in keyof BankInformations]?: boolean };
export const getInvalidBankInformations = (bankInformations: BankInformations, locale: Locale): BankInformationsErrors => {
  const errors: BankInformationsErrors = {};
  if (locale !== 'fr-FR') {
    return errors;
  }
  if (hasBankInformations(bankInformations)) {
    if (bankInformations.iban && !isValidIban(bankInformations.iban)) {
      errors['iban'] = true;
    }
    if (bankInformations.rib && !isValidRib(bankInformations.rib)) {
      errors['rib'] = true;
    }
    if (bankInformations.bic && !bankInformations.bic.match(/^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/i)) {
      errors['bic'] = true;
    }
    if (!bankInformations.iban) {
      errors['iban'] = true;
    }
    if (!bankInformations.bic) {
      errors['bic'] = true;
    }
    if (!bankInformations.bankName) {
      errors['bankName'] = true;
    }
    if (!bankInformations.rib) {
      errors['rib'] = true;
    }
  }
  return errors;
};
export type LegalInformationErrors = { [key in keyof LegalInformations]?: boolean };
export const getInvalidLegalInformation = (legalInformation: LegalInformations, locale: Locale): LegalInformationErrors => {
  const errors: LegalInformationErrors = {};
  if (locale !== 'fr-FR') {
    return errors;
  }
  if (!legalInformation.legalStatus) {
    errors['legalStatus'] = true;
  }
  if (!legalInformation.awaitingRegistration && (!legalInformation.siret || !isValidSiret(legalInformation.siret))) {
    errors['siret'] = true;
  }
  if (!legalInformation.businessName) {
    errors['businessName'] = true;
  }
  if (!legalInformation.apeCode || !legalInformation.apeCode.match(/^\d{4}[a-z]$/i)) {
    errors['apeCode'] = true;
  }
  if (!legalInformation.capitalShare) {
    errors['capitalShare'] = true;
  }
  if (legalInformation.isSubjectToVat && !legalInformation.vatNumber) {
    errors['vatNumber'] = true;
  }
  return errors;
};
export type SenderErrors = { [key in keyof Company]?: AddressErrors | LegalInformationErrors | BankInformationsErrors | boolean };
export const getInvalidSender = (sender: Company, locale: Locale, infoCheck?: boolean): SenderErrors => {

  const errors: SenderErrors = {};
  if (!sender.name) {
    errors['name'] = true;
  }
  if (!sender.telephone) {
    errors['telephone'] = true;
  }
  if (!sender.email) {
    errors['email'] = true;
  }
  const invalidAddress = getInvalidAddress(sender.address);
  if (Object.keys(invalidAddress).length) {
    errors['address'] = invalidAddress;
  }
  if (infoCheck) {
    return errors
  }
  const invalidLegalInfo = getInvalidLegalInformation(sender.legalInformations, locale);
  if (Object.keys(invalidLegalInfo).length) {
    errors['legalInformations'] = invalidLegalInfo;
  }
  const invalidBankInfo = getInvalidBankInformations(sender.bankInformations, locale);
  if (Object.keys(invalidBankInfo).length) {
    errors['bankInformations'] = invalidBankInfo;
  }
  return errors;
};
export type RecipientErrors = { [key in keyof Recipient]?: AddressErrors | boolean };
export const getInvalidRecipient = (recipient: Recipient): RecipientErrors => {
  const errors: RecipientErrors = {};
  if (!recipient.companyName) {
    if (!recipient.lastName) {
      errors['lastName'] = true;
    }
    if (!recipient.firstName) {
      errors['firstName'] = true;
    }
  }
  if (!recipient.lastName && !recipient.firstName) {
    if (!recipient.companyName) {
      errors['companyName'] = true;
    }
  }
  if (!recipient.email) {
    errors['email'] = true;
  }
  if (!recipient.telephone) {
    errors['telephone'] = true;
  }
  const invalidAddress = getInvalidAddress(recipient.address);
  if (Object.keys(invalidAddress).length) {
    errors['address'] = invalidAddress;
  }
  return errors;
};
export type QuoteDocumentErrors = { [key in keyof QuoteDocument]?: RecipientErrors | SenderErrors | boolean }
export const getInvalidQuoteDocument = (quoteDocument: QuoteDocument, locale: Locale): QuoteDocumentErrors => {
  const errors: QuoteDocumentErrors = {};
  if (!quoteDocument.type) {
    errors['type'] = true;
  }
  if (quoteDocument.expirationDate) {
    if (RangeSign(new Date(quoteDocument.expirationDate), new Date(quoteDocument.issueDate), null, true) < 0) {
      errors['expirationDate'] = true;
    }
  } else {
    errors['expirationDate'] = true;
  }
  if (quoteDocument.issueDate) {
    if (RangeSign(new Date(quoteDocument.issueDate), AddDay(new Date(), -1), null) < 0) {
      errors['issueDate'] = true;
    }
  } else {
    errors['issueDate'] = true;
  }
  if (!quoteDocument.items.length) {
    errors['items'] = true;
  }
  const invalidRecipient = getInvalidRecipient(quoteDocument.recipient);
  if (Object.keys(invalidRecipient).length) {
    errors['recipient'] = invalidRecipient;
  }
  const invalidSender = getInvalidSender(quoteDocument.sender, locale);
  if (Object.keys(invalidSender).length) {
    errors['sender'] = invalidSender;
  }
  return errors;
};