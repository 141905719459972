import * as React from 'react';
import { CSSProperties, HTMLProps, ReactNode, useContext } from 'react';
import { BaseInput, BaseInputProps } from '../base-input';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { IconName } from '../../icons';
import { Svg } from '../svg';
import { ThemeType } from '../../common';
import { ThemeContext } from 'styled-components';
import { BaseButton } from '../base-button';

type BaseInputOmitChange = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'value' | 'label' | 'children'
>;

export interface InputProps extends LabelWrapperProps, BaseInputOmitChange, BaseInputProps {
  /**
   * insert react node in LabelWrapper after input
   */
  afterChild?: ReactNode;
  /**
   * insert react node in LabelWrapper before input
   */
  beforeChild?: ReactNode;
  icon?: IconName;
  onChange?: (event: string) => void;
  onIconClick?: () => void;
  value?: string;
  children?: React.ReactNode;
}

export const Input: React.FC<InputProps> = props => {
  const {
    required,
    disabled,
    label,
    caption,
    hasError,
    small,
    value,
    onChange,
    right,
    beforeChild,
    afterChild,
    icon,
    iconPosition,
    onIconClick,
    ...rest
  } = props;
  const emitChange = (event: React.FormEvent): void => {
    if (onChange) {
      const target = event.target as HTMLInputElement;
      onChange(target.value);
    }
  };
  const theme = useContext<ThemeType>(ThemeContext);
  const styleIconWrapper: CSSProperties = {
    position: 'absolute',
    inset: `${small ? '4px' : '7px'} ${iconPosition === 'left' ? 'auto' : '2px'} 0 ${
      iconPosition === 'left' ? '2px' : 'auto'
    }`,
  };
  return (
    <LabelWrapper {...{ required, disabled, caption, label, hasError, small, right }}>
      {beforeChild}
      <div style={{ position: 'relative' }}>
        <BaseInput
          {...(rest as any)}
          {...{ required, disabled, hasError, small, right }}
          iconPosition={iconPosition ? iconPosition : icon ? 'right' : undefined}
          value={typeof value === 'undefined' ? '' : value}
          onChange={emitChange}
        />
        {icon && onIconClick ? (
          <BaseButton onClick={onIconClick} style={{ ...styleIconWrapper, marginTop: '-2px' }}>
            <Svg
              icon={icon}
              width={small ? 24 : 28}
              height={small ? 24 : 28}
              fill={theme.input.placeholderColor}
            />
          </BaseButton>
        ) : (
          <div style={{ ...styleIconWrapper, pointerEvents: 'none' }}>
            <Svg
              icon={icon}
              width={small ? 24 : 28}
              height={small ? 24 : 28}
              fill={theme.input.placeholderColor}
            />
          </div>
        )}
      </div>
      {afterChild}
    </LabelWrapper>
  );
};
