import React, {FC} from 'react';
import {Question, QuestionChoices, QuestionNumber, QuestionText} from '../../interface/question.types';
import {TextForm} from './text.form';
import {TextAreaForm} from './textarea.form';
import {NumberForm} from './number.form';
import {CheckboxForm} from './checkbox.form';
import {RadioForm} from './radio.form';
import {Choice} from '../../interface/choice.types';

interface QuestionFormProps {
    question: Question;
    onSubmit: (question: Question) => void;
    onDeleteChoice: (choice: Choice) => void;
    onAddChoice: (preSaveQuestion: Question) => void;
    onEditChoice: (choice: Choice) => void;
    onClose: () => void;
    loading: boolean
}

export const QuestionForm: FC<QuestionFormProps> = props => {
    const {question, onSubmit, onDeleteChoice, onClose, onEditChoice, onAddChoice, loading} = props;
    switch (question.type) {
        default:
        case 'SHORT_TEXT':
            return <TextForm
                onClose={onClose}
                question={question as QuestionText}
                onSubmit={onSubmit}/>;
        case 'LONG_TEXT':
            return <TextAreaForm
                onClose={onClose}
                question={question as QuestionText}
                onSubmit={onSubmit}/>;
        case 'QUANTITY':
            return <NumberForm
                onClose={onClose}
                question={question as QuestionNumber}
                onSubmit={onSubmit}/>;
        case 'MULTIPLE_CHOICE':
        case 'SINGLE_CHOICE':
            return <>{question.type === 'MULTIPLE_CHOICE' ?
                <CheckboxForm
                    onDeleteChoice={onDeleteChoice}
                    onClose={onClose}
                    question={question as QuestionChoices}
                    onSubmit={onSubmit}
                    onEditChoice={onEditChoice}
                    onAddChoice={onAddChoice}
                    loading={loading}
                /> :
                <RadioForm
                    onDeleteChoice={onDeleteChoice}
                    onClose={onClose}
                    question={question as QuestionChoices}
                    onSubmit={onSubmit}
                    onEditChoice={onEditChoice}
                    onAddChoice={onAddChoice}
                    loading={loading}
                />}
            </>;
    }
};
