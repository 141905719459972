import styled from 'styled-components';
import { Col, Row } from '@linkeo.com/ui-lib-react';

export const SmallRow = styled(Row)`
  margin-left: calc(-8px);
  margin-right: calc(-8px);
  width: calc(100% + 16px);
`
export const SmallCol = styled(Col)`
  padding: 0 8px;
`