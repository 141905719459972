import * as React from 'react';
import { Radio } from '../radio';
import { BaseInputProps } from '../base-input';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { Row } from '../row';
import { Col } from '../col';

export interface RadioOption<T> {
  /**
   * columns default : [12,6,6,3]
   */
  columns?: number[];
  caption?: string;
  disabled?: boolean;
  data: T;
  label?: string;
  name: string;
  uid?: string;
}

export interface RadioGroupProps<T>
  extends Omit<BaseInputProps, 'value' | 'onChange' | 'label'>,
    LabelWrapperProps {
  /**
   * le nom du group affin de les différentier des autres radioGroup
   */
  groupName: string;
  onChange: (value: RadioOption<T>) => void;
  options: RadioOption<T>[];
  value?: RadioOption<T>;
}

export const RadioGroup = <T extends any>(props: RadioGroupProps<T>) => {
  const {
    value,
    onChange,
    label,
    caption,
    disabled,
    hasError,
    required,
    right,
    options,
    groupName,
    small,
  } = props;
  return (
    <LabelWrapper {...{ label, caption, disabled, hasError, required, right, small }}>
      <Row wraps={['wrap']}>
        {options.map(o => (
          <Col key={o.uid || o.name} columns={o.columns || [12, 6, 4, 3]}>
            <Radio
              label={o.label}
              caption={o.caption}
              required={required}
              disabled={o.disabled}
              onChange={() => onChange(o)}
              value={o.uid && value?.uid ? o.uid === value.uid : o.name === value?.name}
              name={groupName}
            >
              {o.name}
            </Radio>
          </Col>
        ))}
      </Row>
    </LabelWrapper>
  );
};
