import { Dashboard, PageWrapper } from '../../components';
import React, { FC } from 'react';
import { Container } from '@linkeo.com/ui-lib-react';

interface HomePageProps {
}

export const HomePage: FC<HomePageProps> = () => {
  
  return (
    <PageWrapper isLoading={false}>
      <Container size={1050}>
        <Dashboard quoteCount={10} />
      </Container>
    </PageWrapper>
  );
};
