import React, { createContext, FC, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';

interface RoutingChangeEvent {
    destinationPath: string;
    isUnload: boolean;

    preventDefault(): void;
}

type RoutingChangeCb = (event: RoutingChangeEvent) => void;

interface RoutingChangeRef {
  blockUnregister: UnregisterCallback;
  reloadRegister: (event: BeforeUnloadEvent) => void;
}

interface RoutingContextProps {
    /**
     * Subscribing to routing change event , can prevent event.preventDefault to block redirection
     * dont forget to unsubscribing with removeChangeEvent
     * send reloadAction:true if reloading/close effect
     * @param cb
     * @return number index ref to unsub with removeChangeEvent
     */
    beforeRouteChange: (cb: RoutingChangeCb) => void;
    removeChangeEvent: () => void;
}


const RoutingContext = createContext<RoutingContextProps>({
    beforeRouteChange: () => {
        console.warn('useRouting.addChangeEvent is called before implementing routing provider');
        return -1;
    },
    removeChangeEvent: () => {
        console.warn('useRouting.removeChangeEvent is called before implementing routing provider');
    },
});
export const useRouting = () => useContext(RoutingContext);
export const RoutingProvider: FC = props => {
    const { children } = props;
    const history = useHistory();
    const ref = useRef<RoutingChangeRef | undefined>();
    const beforeRouteChange = (cb: RoutingChangeCb): void => {
        const onReload = (event: BeforeUnloadEvent) => {
            let prevent = false;
            cb({
                destinationPath: '',
                preventDefault: () => prevent = true,
                isUnload: true,
            });
            if (prevent) {
                event.preventDefault();
                event.returnValue = true;
                return confirm;
            }
        };
        window.addEventListener('beforeunload', onReload);
        ref.current = {
            reloadRegister: onReload,
            blockUnregister: history.block((location) => {
                let prevent = false;
                cb({
                    destinationPath: location.pathname,
                    preventDefault: () => prevent = true,
                    isUnload: false,
                });
                if (prevent) {
                    return false;
                }
            }),
        };
    };
    const removeChangeEvent = () => {
        const current = ref.current;
        if (!current) {
            return;
        }
        current.blockUnregister();
        window.removeEventListener('beforeunload', current.reloadRegister);
        ref.current = undefined;
    };
    return <RoutingContext.Provider
        value={{ beforeRouteChange, removeChangeEvent }}>
        {children}
    </RoutingContext.Provider>;
}
