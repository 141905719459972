import styled from 'styled-components';
import { Col } from '@linkeo.com/ui-lib-react';

export const InteractiveCol = styled(Col)<{ locked: boolean,hasError?: boolean }>`
  padding-top: 16px;
  padding-bottom: 16px;
  transition: 200ms background-color ease-in-out;
  border: 1px solid transparent;
  ${({ locked }) => locked ? '' : 'cursor: pointer'};
  ${({hasError,theme,locked})=>hasError && !locked ? `
    border-color: ${theme.colors.lightDanger};
  ` : ''}
  &:hover, &:focus {
    ${({ locked }) => locked ? '' : 'background-color: #ECDFDF'};
  }
`;
export const InteractiveDiv = styled.div<{ locked: boolean }>`
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  margin-right: -10px;
  transition: 200ms background-color ease-in-out;
  ${({ locked }) => locked ? '' : 'cursor: pointer'};
  &:hover, &:focus {
    ${({ locked }) => locked ? '' : 'background-color: #ECDFDF'};
  }
`;