import { PageWrapper } from '../../components';
import { Container, PageTitle, useToaster } from '@linkeo.com/ui-lib-react';
import { useEffect, useState } from 'react';
import { DocumentParam } from '../../interface/document-param.types';
import { useApi } from '../../providers/api-provider';
import { DocumentParamForm } from '../../components/document-param/document-param.form';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeHome } from '../../routes';
import { ServerError } from '../../components/commons/server-error';

export const DocumentParamPage = () => {
  const [getParams, setParams] = useState<DocumentParam>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const API = useApi();
  const history = useHistory();
  const intl = useIntl();
  const toast = useToaster();

  useEffect(() => {
    setLoading(true);
    API.getDocumentParam().then(result => {
      setParams(result);
    }).catch(e => {
      console.error(e);
      setError(true);
    }).finally(() => setLoading(false));
  }, [API]);

  const updateParam = (params: DocumentParam) => {
    API.putDocumentParam(params).then(result => {
      setParams(result);
      history.push(routeHome);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  return <PageWrapper isLoading={loading}>
    {!error ? <Container size={'lg'}>
      <div style={{ marginBottom: '20px' }}>
        <PageTitle>
          {intl.formatMessage({ id: 'documentParamTitle', defaultMessage: 'Paramètres du document' })}
        </PageTitle>
      </div>
      {getParams && <DocumentParamForm value={getParams} onChange={updateParam} />}
    </Container> : <ServerError />}
  </PageWrapper>;
};