import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '../../../components';
import { Container, useToaster } from '@linkeo.com/ui-lib-react';
import { BASE_QUESTION, useApi } from '../../../providers/api-provider';
import { Question, QuestionChoices, QuestionType } from '../../../interface/question.types';
import { QuestionForm } from '../../../components/question/question.form';
import { Choice, EditChoice } from '../../../interface/choice.types';
import { FindAndUpdateItemInArray, UpdateItemInObject } from '../../../utils/deep-object.utils';
import { ChoiceDialog } from '../../../components/choice/choice.dialog';
import { getQuestionByType } from '../../../utils/quetion.utils';
import { useIntl } from 'react-intl';
import { ServerError } from '../../../components/commons/server-error';

const baseChoice: Choice = {
  id: 'NEW',
  description: '',
  label: '',
  addChoiceQuantity: false,
  setPrice: false,
  choiceQuantityLabel: '',
  choiceQuantityUnit: '',
};
export const QuestionPage: FC = () => {
  const {
    categoryId,
    activityId,
    questionType,
  } = useParams<{ categoryId: string, activityId: string, questionType: QuestionType | string }>();
  const history = useHistory();
  const intl = useIntl();
  const toast = useToaster();
  const [getQuestion, setQuestion] = useState<Question>(BASE_QUESTION);
  const [getEditChoice, setEditChoice] = useState<Choice>();
  const [getGlobalLoading, setGlobalLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [choiceLoading, setChoiceLoading] = useState<boolean>(false);
  const API = useApi();

  useEffect(() => {
    const newQuestion = getQuestionByType(questionType);
    if (newQuestion) {
      setQuestion(newQuestion);
      setGlobalLoading(false);
      return;
    }
    API.getQuestion(categoryId, activityId, questionType).then(result => {
      setQuestion(result);
    }).catch(() => setError(true)).finally(() => {
      setGlobalLoading(false);
    });
  }, [questionType, API, activityId, categoryId]);

  const submitQuestion = (question: Question) => {
    updateQuestion(question).then(() => {
      closeForm();
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const updateQuestion = async (question: Question) => {
    if (question.id.startsWith('NEW')) {
      return API.postQuestion(categoryId, activityId, question);
    }
    return API.putQuestion(categoryId, activityId, question);
  };

  const deleteChoice = (value: Choice) => {
    API.deleteChoice(
      categoryId,
      activityId,
      getQuestion.id,
      value.id)
      .then((res) => setQuestion(UpdateItemInObject(getQuestion as QuestionChoices, 'choices', res)))
      .catch(e => {
        console.error(e);
        toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
      });
  };

  const closeForm = () => {
    history.push(`/estimate/category/${categoryId}/activity/${activityId}`);
  };
  const addChoice = (preSaveQuestion: Question) => {
    updateQuestion(preSaveQuestion).then(result => {
      setQuestion(result);
      setEditChoice(baseChoice);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const editChoice = (choice: Choice) => {
    setEditChoice(choice);
  };

  const submitChoice = async (editChoice: EditChoice) => {
    setChoiceLoading(true)
    let tempChoice = editChoice.choice;
    const editImage = editChoice.editImage;
    let picture;
    if (editImage.isNew && editImage.image.binary) {
      picture = await API.postFile(editImage.image.binary, '/picture');
    }
    if (editImage.isNew) {
      tempChoice = {
        ...tempChoice,
        picture,
      };
    }
    if (tempChoice.id.startsWith('NEW')) {
      await API.postChoice(categoryId, activityId, getQuestion.id, tempChoice).then((result) => {
        setQuestion(UpdateItemInObject(getQuestion as QuestionChoices, 'choices', [
          ...(getQuestion as QuestionChoices).choices,
          result,
        ]));
      }).catch(() => toast(intl.formatMessage({
        id: 'errorServerMessage',
        defaultMessage: 'Une erreur est survenue',
      })));
    } else {
      await API.putChoice(categoryId, activityId, getQuestion.id, tempChoice).then((result) => {
        setQuestion(UpdateItemInObject(getQuestion as QuestionChoices, 'choices',
          FindAndUpdateItemInArray((getQuestion as QuestionChoices).choices, 'id', result),
        ));
      }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
    }
    setChoiceLoading(false);
    setEditChoice(undefined)
  };

  return <PageWrapper isLoading={getGlobalLoading}>
    {!error ? <Container size={'lg'}>
      <QuestionForm
        question={getQuestion}
        onSubmit={submitQuestion}
        onClose={closeForm}
        onDeleteChoice={deleteChoice}
        onAddChoice={addChoice}
        onEditChoice={editChoice}
        loading={!!getEditChoice}
      />
      <ChoiceDialog
        choice={getEditChoice}
        loading={choiceLoading}
        onSubmit={submitChoice}
        onClose={() => setEditChoice(undefined)} />
    </Container> : <ServerError />}
  </PageWrapper>;
};
