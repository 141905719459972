import React, { FC } from 'react';
import { PageWrapper } from '../components';
import { Error404 } from '../components/commons/404-error';

export const Page404: FC = () => {
  return (
    <PageWrapper isLoading={false}>
      <Error404 />
    </PageWrapper>
  );
};

