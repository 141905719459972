import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { DocumentParam } from '../interface/document-param.types';
import { useApi } from './api-provider';
import { ServerError } from '../components/commons/server-error';

const DocParamContext = createContext<DocumentParam>({
  currency: 'EUR',
  depositPercentage: 0,
  invoicePaymentDeadline: 0,
  locale: 'fr-FR',
  quoteLifetime: 0,
  prefixes: {
    invoice: '',
    quote: '',
  },
  taxes: { service: [], product: [] },
  invoicePaymentMethod: '',
});
export const useDocParam = () => useContext(DocParamContext);
export const DocumentParamProvider: FC = props => {
  const { children } = props;
  const [getData, setData] = useState<DocumentParam>();
  const [error, setError] = useState<boolean>(false);
  const API = useApi();

  useEffect(() => {
    API.getDocumentParam()
      .then(result => setData(result))
      .catch(() => setError(true));
  }, [API]);

  return getData ? <DocParamContext.Provider
    value={getData}>
    {children}
  </DocParamContext.Provider> : error ? <ServerError /> : <></>;
};