import * as React from 'react';
import { FC, useContext } from 'react';
import {
  AnimatedIcon,
  AnimatedIcons,
  IconsBackName,
  IconsBackPaths,
  IconsFrontName,
  IconsFrontPaths,
} from '../../icons';
import { ThemeContext } from 'styled-components';
import { ThemeType } from '../../common';

export interface IconSvgProps extends React.SVGProps<SVGSVGElement> {
  animatedIcon?: AnimatedIcon;
  icon?: IconsBackName | IconsFrontName;
}

export const Svg: FC<IconSvgProps> = props => {
  const { icon, animatedIcon, children, ...rest } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <svg width={48} height={48} {...rest} viewBox={'0 0 48 48'} xmlns="http://www.w3.org/2000/svg">
      {icon
        ? theme.icons === 'front'
          ? IconsFrontPaths[icon as IconsFrontName]
          : IconsBackPaths[icon as IconsBackName]
        : null}
      {animatedIcon ? AnimatedIcons[animatedIcon] : null}
      {children}
    </svg>
  );
};
