import { FC, FormEvent, useState } from 'react';
import { Button, DatePicker, Dialog, SpanBody1, SpanSubtitle1 } from '@linkeo.com/ui-lib-react';
import { FormattedMessage, useIntl } from 'react-intl';

interface AcceptionQuoteDialogProps {
  documentId: string;
  onClose: () => void;
  onSubmit: (date: Date, accepted: boolean) => void;
  minDate: Date;
  maxDate: Date;
  show: boolean;
  isLoading: boolean;
}

export const AcceptationQuoteDialog: FC<AcceptionQuoteDialogProps> = props => {
  const { onClose, documentId, show, onSubmit, maxDate, minDate, isLoading } = props;
  const intl = useIntl();
  const [getDate, setDate] = useState<Date|null>(new Date());
  const emitClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };
  const emitSubmit = (event: FormEvent) => {
    if (isLoading || !getDate) {
      return;
    }
    event.preventDefault();
    onSubmit(getDate, true);
  };
  const footer = <>
    <Button colorType={'secondary'} loading={isLoading} onClick={emitClose}>
      <FormattedMessage id={'cancel'} defaultMessage={'Annuler'} />
    </Button>
    <Button colorType={'secondary'} type={'submit'} form={'dialogQuoteAnswer'} loading={isLoading}>
      <FormattedMessage id={'submit'} defaultMessage={'Valider'} />
    </Button>
  </>;
  return <Dialog
    title={intl.formatMessage({ id: 'AcceptationQuoteDialogTitle', defaultMessage: 'Devis accepté/refusé' })}
    onClose={emitClose} show={show} size={'md'} footer={footer}>

    <form id={'dialogQuoteAnswer'} onSubmit={emitSubmit}>
      <p>
        <SpanBody1>
          {intl.formatMessage({ id: 'quote', defaultMessage: 'Devis' })} {documentId}
        </SpanBody1>
      </p>
      <DatePicker label={intl.formatMessage({
        id: 'AcceptationQuoteDialogDateLabel',
        defaultMessage: 'Date d’acceptation du devis',
      })} onChange={setDate}
                  value={getDate} disabledDays={{ min: minDate, max: maxDate }} />
      <p>
        <SpanSubtitle1>
          <FormattedMessage id={'AcceptationQuoteDialogCaption'}
                            defaultMessage={'Attention, une fois que vous aurez validé, vous ne pourrez plus modifier le statut de votre devis.'} />
        </SpanSubtitle1>
      </p>
    </form>

  </Dialog>;
};