import { FC } from 'react';
import { Button } from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

interface OpenAttachedQuoteButtonProps {
  quoteId: string;
}

export const OpenAttachedQuoteButton: FC<OpenAttachedQuoteButtonProps> = props => {
  const {quoteId} = props;
  const theme = useTheme();
  const {locale} = useIntl();
  const openAttachedQuote = ()=>{
    const win = window.open(`/${locale}/quote/document/${quoteId}`, "_blank");
    if(!win){
      return
    }
    win.focus()
  }
  return <Button
    colorType={"secondary"}
    onClick={openAttachedQuote}
    borderColor={theme.colors.grey40}
    backgroundColor={theme.colors.grey40}>
    <FormattedMessage id={'quoteButtonLabel'} defaultMessage={'Voir le devis'}/>
  </Button>
}