import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { LabelWrapperProps } from '../label-wrapper';
import styled, { css } from 'styled-components';
import { CssHexaColorToHsv, HsvToCssHexaColor, mergeStyled } from '../../helpers';
import { Dropdown } from '../dropdown';
import { ColorSelect } from '../color-select';
import { Row } from '../row';
import { Col } from '../col';
import { BaseInputProps } from '../base-input';
import { ColorHSV } from '../../common';
import { Input } from '../input';

type OmitBaseInput = Omit<BaseInputProps, 'value' | 'onChange' | 'name' | 'label'>;

export interface ColorPickerProps extends LabelWrapperProps, OmitBaseInput {
  onChange: (val: string) => void;
  /**
   * css color
   */
  value: string;
}

const PreviewError = css<{ hasError?: boolean }>`
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.colors.danger : theme.colors.grey60)};
`;
const PreviewDisabled = css<{ disabled?: boolean }>`
  opacity: ${({ disabled }): string => (disabled ? '0.5' : '1')};
`;
const PreviewHasColor = css<{ hasColor: boolean }>`
  &:after {
    opacity: ${({ hasColor }) => (hasColor ? 0 : 1)};
  }
`;
const PreviewStyle = css`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey95};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 200%;
    top: -50%;

    left: 50%;
    transform: rotate(45deg);
    transform-origin: center;
    background-color: ${({ theme }) => theme.colors.danger};
  }
`;
const PreviewColor = mergeStyled(
  styled.div<{
    hasColor: boolean;
    hasError?: boolean;
    disabled?: boolean;
  }>``,
  [PreviewError, PreviewDisabled, PreviewHasColor, PreviewStyle]
);
export const ColorPicker: FC<ColorPickerProps> = props => {
  const {
    hasError,
    label,
    caption,
    disabled,
    required,
    value,
    onChange,
    right,
    small,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [hasColor, setHasColor] = useState(false);
  const [getHSV, setHSV] = useState<ColorHSV>([0, 0, 0]);
  const refPreviewColor = useRef<HTMLDivElement>();
  const refColorPicker = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    if (typeof value === 'undefined') {
      return;
    }
    setHasColor(true);
    setInputValue(value ? value.toUpperCase() : '');
    updatePreview(value);
    setHSV(CssHexaColorToHsv(value));
  }, [value]);
  const updatePreview = (cssColor: string): void => {
    const preview = refPreviewColor.current;
    if (preview) {
      setHasColor(!!cssColor);
      preview.style.backgroundColor = cssColor;
    }
  };
  const updateHex = (hsv: ColorHSV) => {
    const cssHexaColor = HsvToCssHexaColor(hsv[0], hsv[1], hsv[2]);
    onChange(cssHexaColor);
    updatePreview(cssHexaColor);
    setInputValue(cssHexaColor);
  };
  const debounceTimeout = useRef<number | undefined>();
  const changeValue = (hsv: ColorHSV): void => {
    setHSV(hsv);
    window.clearTimeout(debounceTimeout.current);
    debounceTimeout.current = window.setTimeout(() => {
      updateHex(hsv);
      debounceTimeout.current = undefined;
    }, 300);
  };
  const changeInput = (targetValue: string): void => {
    const regColor = /^#([0-9a-f]{1,6})$/gim;
    if (targetValue.length >= 8) {
      return;
    }
    setInputValue(targetValue ? targetValue.toUpperCase() : '');
    const result = regColor.exec(targetValue);
    if (!result) {
      return onChange(targetValue ? targetValue.toUpperCase() : '');
    }
    if (targetValue.length <= 6) {
      return;
    }
    onChange(targetValue);
    updatePreview(targetValue);
    const hsv = CssHexaColorToHsv(targetValue);
    setHSV(hsv);
  };

  return (
    <div ref={refColorPicker}>
      <Row style={{ flexDirection: right ? 'row-reverse' : 'row' }}>
        <Col style={{ paddingTop: label ? '43px' : '16px' }}>
          <PreviewColor
            onClick={() => setOpen(true)}
            {...{ disabled, hasError, hasColor }}
            ref={refPreviewColor as any}
          />
        </Col>
        <Col style={{ flex: '1' }}>
          <Input
            {...{ hasError, label, caption, disabled, required, right, small, ...rest }}
            onFocus={() => setOpen(true)}
            value={inputValue}
            onChange={changeInput}
            afterChild={
              <Dropdown
                show={open}
                onClose={() => setOpen(false)}
                wrapperRef={refColorPicker as any}
                offset={16}
                right={right}
              >
                <ColorSelect
                  value={getHSV}
                  onChange={changeValue}
                  onClose={!disabled ? () => setOpen(false) : undefined}
                />
              </Dropdown>
            }
          />
        </Col>
      </Row>
    </div>
  );
};
