import { FC, useEffect, useMemo, useState } from 'react';
import { Select, SelectOption } from '@linkeo.com/ui-lib-react';
import { Devise } from '../../utils/number.utils';
import { useIntl } from 'react-intl';

interface DeviseSelectProps {
  onChange: (selected: Devise) => void;
  value: Devise;
}

export const DeviseSelect: FC<DeviseSelectProps> = props => {
  const { onChange, value } = props;
  const intl = useIntl();
  const [getSelectedDevise, setSelectedDevise] = useState<SelectOption<Devise>>();
  const deviseOption: SelectOption<Devise>[] = useMemo(() => [
    {
      value: 'EUR',
      label: intl.formatMessage({id: 'EUR', defaultMessage: 'Euro €'}),
    },
    {
      value: 'USD',
      label: intl.formatMessage({id: 'USD', defaultMessage: 'Dollars $'}),
    },
    {
      value: 'CAD',
      label: intl.formatMessage({id: 'CAD', defaultMessage: 'Dollars Canadien $'}),
    },
    {
      value: 'AUD',
      label: intl.formatMessage({id: 'AUD', defaultMessage: 'Dollars Australien $'}),
    },
  ], [intl]);
  useEffect(() => {
    const selected = deviseOption.find(f => f.value === value);
    if (!selected) {
      return;
    }
    setSelectedDevise(selected);
  }, [value, deviseOption]);
  const onSelectDeviseChange = (select: SelectOption<Devise>) => {
    onChange(select.value);
  };
  return <Select<Devise>
    label={intl.formatMessage({id: 'deviseSelectLabel', defaultMessage: 'Votre devise'})}
    required
    selected={getSelectedDevise}
    onSelect={onSelectDeviseChange} options={deviseOption} />;
};