import * as React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { getTypo, SelectOption } from '../../common';

const Menu = styled.ul`
  ${getTypo('subtitle1')};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.grey90};
  padding: 0;
  margin: 0;
  list-style: none;
  border-radius: 0 0 4px 4px;
`;
const Item = styled.li<{ active?: boolean }>`
  padding: 10px 15px 10px 30px;
  color: ${({ active, theme }) => (active ? theme.colors.grey20 : theme.colors.grey40)};
  background-color: ${({ active, theme }) => (active ? theme.colors.grey80 : theme.colors.grey90)};
  cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.grey80 : theme.colors.grey80};
  }
`;

interface FilterMenuProps<T> {
  onClick: (v: SelectOption<T>) => void;
  options: SelectOption<T>[];
  selected?: SelectOption<T>;
}

export function FilterMenu<T>({
  options,
  selected,
  onClick,
}: PropsWithChildren<FilterMenuProps<T>>) {
  return (
    <Menu>
      {options.map(option => (
        <Item
          key={option.uid || option.label}
          title={option.label}
          active={
            selected &&
            (option.uid && selected.uid
              ? option.uid === selected.uid
              : option.label === selected.label)
          }
          onClick={() => onClick(option)}
        >
          {option.label}
        </Item>
      ))}
    </Menu>
  );
}
