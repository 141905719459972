import styled, {
  FlattenInterpolation,
  StyledComponent,
  ThemedStyledProps,
} from 'styled-components';

export const mergeStyled = <
  T extends StyledComponent<any, any>,
  U extends FlattenInterpolation<ThemedStyledProps<any, any>>
>(
  base: T,
  cssExtends: U[]
): T => {
  // @ts-ignore
  return cssExtends.reduce((prev, current) => {
    return styled(prev)`
      ${current}
    `;
  }, base);
};
