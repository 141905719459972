import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HomePage } from './pages/home/home.page';
import { ActivitiesPage } from './pages/estimate/activities/activities.page';
import { ActivityPage } from './pages/estimate/activities/activity.page';
import { QuestionPage } from './pages/estimate/activities/question.page';
import { PresentationPage } from './pages/estimate/presentation/presentation.page';
import { ScopeQuestionPage } from './pages/estimate/presentation/scope-question.page';
import { EstimateParamPage } from './pages/params/estimate-param.page';
import { CompanyParamPage } from './pages/params/company-param.page';
import { DocumentParamPage } from './pages/params/document-param.page';
import { DocumentsPage } from './pages/document/documents.page';
import { DocumentNewPage } from './pages/document/document-new.page';
import { DocumentDraftPage } from './pages/document/document-draft.page';
import { Page404 } from './pages/404-page';
import { DocumentQuotePage } from './pages/document/document-quote.page';
import { DocumentInvoicePage } from './pages/document/document-invoice.page';
import {
  routeActivities,
  routeCategory,
  routeCompanyParams,
  routeDocumentParams,
  routeDocuments,
  routeDraft,
  routeEstimateParams,
  routeHome,
  routeInvoice,
  routeNewInvoice,
  routeNewQuote,
  routePresentation,
  routeQuestion,
  routeQuote,
  routeScope
} from './routes';

export const Router: FC<{ formUrl: string }> = ({ formUrl }) => <Switch>
  <Route exact path={routeHome}>
    <HomePage />
  </Route>
  <Route exact path={routeActivities}>
    <ActivitiesPage />
  </Route>
  <Route exact path={routeCategory}>
    <ActivityPage />
  </Route>
  <Route exact path={routeQuestion}>
    <QuestionPage />
  </Route>
  <Route exact path={routePresentation}>
    <PresentationPage formUrl={formUrl} />
  </Route>
  <Route exact path={routeScope}>
    <ScopeQuestionPage />
  </Route>
  <Route exact path={routeEstimateParams}>
    <EstimateParamPage />
  </Route>
  <Route exact path={routeCompanyParams}>
    <CompanyParamPage />
  </Route>
  <Route exact path={routeDocumentParams}>
    <DocumentParamPage />
  </Route>
  <Route exact path={routeDocuments}>
    <DocumentsPage />
  </Route>
  <Route exact path={routeNewQuote}>
    <DocumentNewPage documentType={'quote'} />
  </Route>
  <Route exact path={routeNewInvoice}>
    <DocumentNewPage documentType={'invoice'} />
  </Route>
  <Route exact path={routeDraft}>
    <DocumentDraftPage />
  </Route>
  <Route exact path={routeQuote}>
    <DocumentQuotePage />
  </Route>
  <Route exact path={routeInvoice}>
    <DocumentInvoicePage />
  </Route>
  <Route path={'*'} exact>
    <Page404 />
  </Route>
</Switch>;
