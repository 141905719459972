import * as React from 'react';
import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Col } from '../col';
import { DragStartInfo, getTypo, Shapes, SpanH4, SpanSubtitle1, ThemeType } from '../../common';
import { Row } from '../row';
import { SquareButton } from '../square-button';
import { Svg } from '../svg';
import { Switch } from '../switch';

const DRAGGABLE_PADDING_X = 24;
const DRAGGABLE_PADDING_Y = 20;

interface DraggableListCardProps {
  title: string;
  subtitle?: string;
  htmlSubtitle?: string;
  onDragStart: (event: DragStartInfo) => void;
  isDragged: boolean;
  onMove: (event: number) => void;
  onDelete?: () => void;
  index: number;
  onEdit?: () => void;
  toggleState?: boolean;
  onToggle?: () => void;
  isCollapsed?: boolean;
  custom?: ReactNode;
}

const ParentDragCard = styled.div<{ isDragged: boolean; isCollapsed?: boolean }>`
  margin: ${({ isCollapsed }) => (isCollapsed ? '0' : '16px')} 0;
  box-sizing: content-box;
  border-radius: 4px;
  border: 1px dashed ${({ isDragged, theme }) => (isDragged ? theme.colors.grey80 : 'transparent')};
  width: 100%;
`;
const DCard = styled.div<{ isDragged: boolean; isCollapsed?: boolean }>`
  display: block;
  ${({ isDragged }) => (isDragged ? Shapes.shadow1 : '')};
  transform: ${({ isDragged }) => (isDragged ? 'scale(1.01)' : 'scale(1.0)')};
  transition: box-shadow ease-in-out 200ms, z-index ease-in-out 200ms,
    transform ease-in-out 100ms
      ${({ isDragged }) => (isDragged ? '' : ', top ease-in-out 100ms, left ease-in-out 100ms')};
  position: absolute;
  padding: ${DRAGGABLE_PADDING_Y}px ${DRAGGABLE_PADDING_X}px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.grey95};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? '0' : '4px')};
  ${({ isCollapsed, theme }) => (isCollapsed ? '0' : `border: 1px solid ${theme.colors.grey95}`)};
  ${({ isCollapsed, isDragged, theme }) =>
    isDragged ? '' : isCollapsed ? `border-bottom: 1px solid ${theme.colors.grey80}` : ''};
  cursor: move;
  user-select: none;
  z-index: ${({ isDragged }) => (isDragged ? '2' : '1')};
`;
const Wrapper = styled.div`
  ${getTypo('subtitle1')};
  > p {
    margin: 0;
  }
`;

export const DraggableCard: FC<DraggableListCardProps> = props => {
  const theme = useContext<ThemeType>(ThemeContext);
  const {
    subtitle,
    htmlSubtitle,
    title,
    onDragStart,
    isDragged,
    onMove,
    onDelete,
    index,
    onEdit,
    onToggle,
    toggleState,
    isCollapsed,
    custom,
  } = props;
  const [getHeight, setHeight] = useState<number>(0);
  const emitDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    event.dataTransfer.setDragImage(img, 0, 0);
    event.dataTransfer.setData('text', '');
    onDragStart({
      offSet: {
        x: event.pageX - target.offsetLeft - window.scrollX,
        y: event.pageY - target.offsetTop - window.scrollY,
      },
    });
  };
  const emitMove = (event: React.KeyboardEvent) => {
    switch (event.keyCode) {
      case 40: // down
        event.preventDefault();
        onMove(1);
        break;
      case 38: // up
        event.preventDefault();
        onMove(-1);
        break;
    }
  };
  const cardRef = useRef<HTMLDivElement>();
  const parentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const cardCurrent = cardRef.current;
    if (cardCurrent) {
      setHeight(cardCurrent.clientHeight);
    }
  }, []);
  useEffect(() => {
    if (!getHeight) {
      return;
    }
    const parentCurrent = parentRef.current;
    if (!parentCurrent) {
      return;
    }
    const child = parentCurrent.firstChild as HTMLDivElement;
    if (!child) {
      return;
    }
    child.style.left = parentCurrent.offsetLeft + 1 + 'px'; // + 1px for border
    child.style.top = parentCurrent.offsetTop + 1 + 'px';
  }, [getHeight, index, isDragged]);

  return (
    <ParentDragCard
      ref={parentRef as any}
      isCollapsed={isCollapsed}
      isDragged={isDragged}
      style={{ height: getHeight }}
    >
      <DCard
        ref={cardRef as any}
        isDragged={isDragged}
        onDragStart={emitDragStart}
        isCollapsed={isCollapsed}
        draggable
        tabIndex={0}
        onKeyDown={emitMove}
      >
        <Row
          alignItems={['center']}
          style={{
            transition: 'opacity 200ms ease-in-out',
            opacity: onToggle ? (toggleState ? 1 : 0.5) : 1,
          }}
        >
          <Col>
            <Svg
              style={{ marginTop: '6px' }}
              width={24}
              height={24}
              icon={'handle'}
              fill={theme.colors.grey40}
            />
          </Col>
          {!!onToggle && (
            <Col>
              <Switch value={toggleState} onChange={onToggle} />
            </Col>
          )}
          <Col columns={[7]}>
            <div>
              <SpanH4>{title}</SpanH4>
            </div>
            {htmlSubtitle && <Wrapper dangerouslySetInnerHTML={{ __html: htmlSubtitle }}></Wrapper>}
            {subtitle && (
              <div>
                <SpanSubtitle1>{subtitle}</SpanSubtitle1>
              </div>
            )}
          </Col>

          <Col style={{ marginLeft: 'auto' }}>
            <Row alignItems={['center']}>
              {onEdit && (
                <Col>
                  <SquareButton icon={'edit'} onClick={onEdit} />
                </Col>
              )}
              {onDelete && (
                <Col>
                  <SquareButton icon={'delete'} onClick={onDelete} />
                </Col>
              )}
              {!!custom && <Col>{custom}</Col>}
            </Row>
          </Col>
        </Row>
      </DCard>
    </ParentDragCard>
  );
};
