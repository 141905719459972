import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Shapes } from '../../common';

interface DashBoardCardProps {
  /**
   * combien de colonne de dashboard , par défaut 1
   */
  col?: number;
  noPadding?: boolean;
  /**
   * combien de ligne , par défaut 1
   */
  row?: number;
  children?: React.ReactNode;
}

const DashboardCardStyled = styled.div<DashBoardCardProps>`
  ${Shapes.card};
  background: ${({ theme }) => theme.colors.light};
  padding: ${({ noPadding }): number => (noPadding ? 0 : 30)}px;
  width: ${({ col }): number => 310 * (col || 1) + 30 * ((col || 1) - 1)}px;
  height: ${({ row }): number => 310 * (row || 1) + 30 * ((row || 1) - 1)}px;
  margin: 0 16px 32px;
  position: relative;
`;
export const DashboardCard: FC<DashBoardCardProps> = props => {
  const { ...rest } = props;
  return <DashboardCardStyled {...rest} />;
};
