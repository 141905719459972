import { AddDay, DatePicker, Input, RangeSign, Row, SpanBody2, SpanCaption1 } from '@linkeo.com/ui-lib-react';
import { InteractiveCol } from '../ui/interactive-col';
import { DateToIsoShort } from '../../utils/date.utils';
import { FC, FormEvent, useEffect, useState } from 'react';
import { QuoteDocument } from '../../interface/document.type';
import { FormFooter } from '../ui/form-footer';
import { useDocParam } from '../../providers/document-param.provider';
import { getInvalidQuoteDocument, QuoteDocumentErrors } from '../../utils/quote-document-validator';
import { FormattedMessage, useIntl } from 'react-intl';

interface DocumentInfosFormProps {
  globalErrors: QuoteDocumentErrors;
  onChange: (v: QuoteDocument) => void;
  quoteDocument: QuoteDocument;
  readOnly: boolean;
}

export const DocumentHeadForm: FC<DocumentInfosFormProps> = props => {
  const { readOnly, quoteDocument, onChange, globalErrors } = props;
  const [getErrors, setErrors] = useState<QuoteDocumentErrors>(globalErrors);
  const [getEditMode, setEditMode] = useState<boolean>(false);
  const [getFormControl, setFormControl] = useState<QuoteDocument>(quoteDocument);
  useEffect(() => {
    setErrors(getInvalidQuoteDocument(getFormControl, quoteDocument.parameters.locale));
  }, [getFormControl, quoteDocument.parameters.locale]);
  const intl = useIntl();
  const { quoteLifetime, invoicePaymentDeadline } = useDocParam();
  const hasGlobalErrors = !!(globalErrors['expirationDate'] || globalErrors['issueDate'] || globalErrors['title']);
  const emitChange = (event: FormEvent) => {
    event.preventDefault();
    onChange(getFormControl);
    setEditMode(false);
  };
  const issueDateChange = (date: Date|null) => {
    if (!date) {
      return
    }
    if (RangeSign(new Date(getFormControl.expirationDate || ''), date, null, true) < 0) {
      setFormControl({
        ...getFormControl,
        issueDate: DateToIsoShort(date),
        expirationDate: DateToIsoShort(AddDay(date, quoteDocument.type === 'quote' ? quoteLifetime : invoicePaymentDeadline)),
      });
    } else {
      setFormControl({
        ...getFormControl,
        issueDate: DateToIsoShort(date),
      });
    }
  };
  useEffect(() => {
    setFormControl(quoteDocument);
  }, [getEditMode, quoteDocument]);
  return getEditMode ? <form onSubmit={emitChange}>
    <Input
      hasError={!!getErrors['title']}
      label={intl.formatMessage({ id: 'documentTitleLabel', defaultMessage: 'Titre du document' })}
      small
      value={getFormControl.title}
      onChange={v => {
        setFormControl({ ...getFormControl, title: v });
      }} />
    {quoteDocument.id &&
      <SpanCaption1><FormattedMessage id={'documentHeadFormRefLabel'} defaultMessage={'Référence : {number}'}
                                      values={{ number: quoteDocument.id }} /></SpanCaption1>}<br />
    <DatePicker required label={quoteDocument.type === 'quote' ? intl.formatMessage({
      id: 'issueDate',
      defaultMessage: 'Date d’émission',
    }) : intl.formatMessage({ id: 'billingDate', defaultMessage: 'Date de facturation' })} small
                value={new Date(getFormControl.issueDate)}
                hasError={!!getErrors['issueDate']}
                disabledDays={{ min: AddDay(new Date(), -1) }}
                onChange={issueDateChange} />
    <DatePicker required disabledDays={{ min: new Date(getFormControl.issueDate) }}
                label={quoteDocument.type === 'quote' ? intl.formatMessage({
                  id: 'documentHeadFormValidationDateInput',
                  defaultMessage: 'Valide jusqu’au',
                }) : intl.formatMessage({
                  id: 'documentHeadFormExpirationDateInput',
                  defaultMessage: 'Date d’échéance',
                })} small
                hasError={!!getErrors['expirationDate']}
                value={new Date(getFormControl.expirationDate || '')}
                onChange={v => v && setFormControl({ ...getFormControl, expirationDate: DateToIsoShort(v)})} />
    <FormFooter onCancel={() => setEditMode(false)} small />
  </form> : <Row>
    <InteractiveCol hasError={hasGlobalErrors} tabIndex={readOnly ? -1 : 0} columns={[12]} locked={readOnly}
                    onClick={() => !readOnly && setEditMode(true)}>
      <p>
        <SpanBody2>{quoteDocument.type === 'quote' ? intl.formatMessage({
          id: 'quote',
          defaultMessage: 'Devis',
        }) : intl.formatMessage({ id: 'invoice', defaultMessage: 'Facture' })} {quoteDocument.title}</SpanBody2>
      </p>
      {quoteDocument.id &&
        <SpanCaption1><FormattedMessage id={'documentHeadFormRefLabel'} defaultMessage={'Référence : {number}'}
                                        values={{ number: quoteDocument.id }} /></SpanCaption1>}<br />
      <SpanCaption1>{quoteDocument.type === 'quote' ? intl.formatMessage({
        id: 'issueDate',
        defaultMessage: 'Date d’émission',
      }) : intl.formatMessage({ id: 'billingDate', defaultMessage: 'Date de facturation' })}
        : {intl.formatDate(quoteDocument.issueDate || new Date(), { dateStyle: 'short' })}</SpanCaption1><br />
      <SpanCaption1>{quoteDocument.type === 'quote' ? intl.formatMessage({
        id: 'documentHeadFormValidationDateInput',
        defaultMessage: 'Valide jusqu’au',
      }) : intl.formatMessage({
        id: 'documentHeadFormExpirationDateInput',
        defaultMessage: 'Date d’échéance',
      })}
        : {intl.formatDate(quoteDocument.expirationDate || new Date(), { dateStyle: 'short' })}</SpanCaption1><br />
    </InteractiveCol>
  </Row>;
};