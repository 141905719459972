import styled, { css } from 'styled-components';
import { ThemeFontsAvailable, ThemeType } from './themes';
import { lighten } from 'polished';

const antialias = css`
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-variant-ligatures: none;
`;
export const getTypo = (font: ThemeFontsAvailable) => {
  return css<{ theme: ThemeType }>`
    ${antialias};
    font-family: ${({ theme }): string => theme.fonts[font].family};
    font-weight: ${({ theme }): string => theme.fonts[font].weight};
    font-size: ${({ theme }): string => theme.fonts[font].size};
    line-height: ${({ theme }): string => theme.fonts[font].lineHeight};
    color: ${({ theme }): string => theme.fonts[font].color};
    letter-spacing: ${({ theme }): string => theme.fonts[font].letterSpacing};
    font-style: ${({ theme }): string => theme.fonts[font].style};
    text-transform: ${({ theme }): string => theme.fonts[font].textTransform};
    text-decoration: ${({ theme }): string => theme.fonts[font].textDecoration};
    margin: ${({ theme }): string => theme.fonts[font].margin};
  `;
};
export const SpanH1 = styled.span`
  ${getTypo('h1')};
`;
export const SpanH2 = styled.span`
  ${getTypo('h2')};
`;
export const SpanH3 = styled.span`
  ${getTypo('h3')};
`;
export const SpanH4 = styled.span`
  ${getTypo('h4')};
`;
export const SpanH5 = styled.span`
  ${getTypo('h5')};
`;
export const SpanH6 = styled.span`
  ${getTypo('h6')};
`;
export const SpanBody1 = styled.span`
  ${getTypo('body1')};
`;
export const SpanBody2 = styled.span`
  ${getTypo('body2')};
`;
export const SpanCaption1 = styled.span`
  ${getTypo('caption1')};
`;
export const SpanCaption2 = styled.span`
  ${getTypo('caption2')};
`;
export const SpanButton = styled.span`
  ${getTypo('fontButton')};
`;
export const SpanSmallButton = styled.span`
  ${getTypo('smallFontButton')};
`;
export const SpanSubtitle1 = styled.span`
  ${getTypo('subtitle1')};
`;
export const SpanSubtitle2 = styled.span`
  ${getTypo('subtitle2')};
`;
export const SpanLink = styled.span`
  ${getTypo('link')};
  cursor: pointer;
  transition: color ease-in-out 200ms;
  &:hover {
    color: ${({ theme }) => lighten(0.2, theme.fonts.link.color)};
  }
`;
export const H1 = styled.h1`
  ${getTypo('h1')};
`;
export const H2 = styled.h2`
  ${getTypo('h2')};
`;
export const H3 = styled.h3`
  ${getTypo('h3')};
`;
export const H4 = styled.h4`
  ${getTypo('h4')};
`;
export const H5 = styled.h5`
  ${getTypo('h5')};
`;
export const H6 = styled.h6`
  ${getTypo('h6')};
`;

export const P = styled.p`
  ${getTypo('body1')};
  margin: 0 0 8px 0;
`;
export const PageTitle = styled.h3`
  ${getTypo('h3')};
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 0 15px 0;
`;

export const PageSubtitle = styled.h6`
  ${getTypo('h6')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 0 0 15px 0;
`;
export const CardTitle = styled.h4`
  ${getTypo('body2')};
  transition: color ease-in-out 200ms;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 0 15px 0;
`;
export const CardSubtitle = styled.p`
  ${getTypo('subtitle1')};
  transition: color ease-in-out 200ms;
  color: ${({ theme }) => theme.colors.grey40};
  margin: 0 0 25px 0;
`;
export const DashboardCardSubtitle = styled.p`
  ${getTypo('subtitle1')};
  color: ${({ theme }) => theme.colors.grey60};
  margin: 0 0 16px 0;
`;
export const DashboardCardTitle = styled.h3`
  ${getTypo('h3')};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 0 16px 0;
`;
export const DashboardCardBody = styled.p`
  ${getTypo('body1')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 0;
`;
