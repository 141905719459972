import * as React from 'react';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { CardSubtitle, CardTitle, Keyframes, SpanLink, ThemeType } from '../../common';
import { BaseButton } from '../base-button';
import { InOut } from '../in-out';
import { Card } from '../card';
import { Svg } from '../svg';

export interface BoxProps {
  title?: string;
  subtitle?: string;
  onClose: () => void;
  hide?: boolean;
  closeText?: string;
  children?: React.ReactNode;
}

const BoxHeader = styled.header`
  position: relative;
  padding: 40px 40px 15px;
`;
const BoxClose = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`;
const BoxTextClose = styled(BaseButton)`
  background: transparent;
  position: absolute;
  top: 40px;
  right: 40px;
  text-align: right;
`;
export const Box: React.FC<BoxProps> = props => {
  const { title, subtitle, children, closeText, hide, onClose } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <InOut exit={{ keyframes: Keyframes.slideOutToTop }} show={!hide}>
      <Card style={{ padding: '0px' }}>
        <BoxHeader>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
          {closeText && (
            <BoxTextClose onClick={() => onClose && onClose()}>
              <SpanLink>{closeText}</SpanLink>
            </BoxTextClose>
          )}
          {!closeText && (
            <BoxClose>
              <BaseButton onClick={() => onClose && onClose()}>
                <Svg icon="cross" fill={theme.colors.grey60} width={24} height={24} />
              </BaseButton>
            </BoxClose>
          )}
        </BoxHeader>
        {children}
      </Card>
    </InOut>
  );
};
