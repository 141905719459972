import * as React from 'react';
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Keyframes } from '../../common';

type State = 'in' | 'start' | 'shown' | 'end' | 'out';
type AnimationConfig = {
  keyframes?: Keyframe[];
  options?: KeyframeAnimationOptions;
};
const execAnimation = (
  element: HTMLDivElement,
  keyframes: Keyframe[],
  options: KeyframeAnimationOptions
): Animation => {
  const option = {
    ...options,
    easing: options.easing || 'cubic-bezier(0.8, 0, 0.2, 1)',
    duration: options.duration || 250,
    delay: options.delay, // managed by component because animation event listener has not on begin event
  };
  return element.animate(keyframes, option);
};

interface InOutProps {
  /**
   * permet de scroll une fois que l'élement apparait
   */
  autoScroll?: boolean | ScrollIntoViewOptions;
  /**
   * configuration de l'animation en entré
   */
  enter?: AnimationConfig;
  /**
   * configuration de l'animation en sorti
   */
  exit?: AnimationConfig;
  /**
   * permet de garder le contenu dans dom en le cachant avec juste avec opacity:0 et visibility:hidden
   */
  keepContent?: boolean;
  /**
   * permet de montrer ou afficher en appliquand les animations configurer
   */
  show: boolean;
  /**
   * style imposé au démarage de l'animation default: opacity:0
   */
  startStyle?: CSSProperties;
  /**
   * démarre l'animation si le composant apparait d'un coup
   */
  startTriggering?: boolean;
  /**
   * style appliquer quand l'element est montré
   */
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const InOut: FC<InOutProps> = props => {
  const {
    children,
    show,
    startTriggering,
    enter,
    exit,
    style,
    autoScroll,
    startStyle,
    keepContent,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [getState, setState] = useState<State>(show ? (startTriggering ? 'in' : 'shown') : 'out');
  const previous = useRef<boolean>(startTriggering ? false : show);
  useEffect(() => {
    const container = containerRef.current as HTMLDivElement;
    if (!container) {
      return;
    }
    if (!show) {
      return;
    }
    if (previous.current) {
      return;
    }

    previous.current = show;
    const anim = execAnimation(
      container,
      enter?.keyframes || Keyframes.fadeIn,
      enter?.options || {}
    );
    setState('start');
    const onFinish = () => {
      setState('shown');
      if (autoScroll) {
        if (typeof autoScroll === 'boolean') {
          container.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        } else {
          container.scrollIntoView(autoScroll);
        }
      }
    };
    anim.addEventListener('finish', onFinish, { once: true });
    return () => {
      anim.finish();
    };
  }, [show, autoScroll, enter, exit]);
  useEffect(() => {
    const container = containerRef.current as HTMLDivElement;
    if (!container) {
      return;
    }
    if (show) {
      return;
    }
    if (!previous.current) {
      return;
    }
    previous.current = show;
    const anim = execAnimation(
      container,
      exit?.keyframes || Keyframes.fadeOut,
      exit?.options || {}
    );
    setState('end');
    const onFinish = () => {
      setState('out');
    };
    anim.addEventListener('finish', onFinish, { once: true });
    return () => {
      anim.finish();
    };
  }, [show, enter, exit, autoScroll]);

  return useMemo(() => {
    const hiddenStates = getState === 'in' || getState === 'out';
    return (
      <div
        ref={containerRef}
        style={{
          visibility: hiddenStates ? 'hidden' : undefined,
          opacity: hiddenStates ? 0 : undefined,
          pointerEvents: hiddenStates ? 'none' : undefined,
          ...(getState === 'start' ? startStyle || { opacity: 0 } : {}),
          ...style,
        }}
      >
        {hiddenStates && !keepContent ? null : children}
      </div>
    );
  }, [children, getState, keepContent, startStyle, style]);
};
