import * as React from 'react';
import {
  ChangeEvent,
  DragEvent,
  CSSProperties,
  FC,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  ThemeType,
  SpanSubtitle1,
  SpanBody1,
  SpanLink,
  FileType,
  completed,
  error,
  finalized,
  Thumbnails,
} from '../../common';
import { Flex } from '../flex';
import { Svg } from '../svg';
import { Button } from '../button';
import { InOut } from '../in-out';
import { SquareButton } from '../square-button';
import { UploaderApi } from './uploader-api';

const Wrapper = styled.div<{ width?: string; height?: string; error?: boolean }>`
  width: ${({ width }) => width || '300px'};
  height: ${({ height }) => height || '200px'};
  position: relative;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.box.borderRadius};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.danger : theme.colors.grey90)};
  text-align: center;
`;
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey90};
  border-radius: inherit;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
`;
const Preview = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #ccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
`;

interface UploaderProps {
  small?: boolean;
  width?: string;
  height?: string;
  middleLabel: string;
  buttonLabel: string;
  value?: FileType;
  previewStyle?: CSSProperties;
  apiBaseUri: string;
  onError?: (e: any) => void;
  codeBouton: string;
  token?: string;
  onChange: (file: FileType | undefined) => void;
  /**
   * input accept attribute
   */
  fileType?: string;
  /**
   * default 'large'
   */
  previewSize?: keyof Thumbnails;
}

export const Uploader: FC<UploaderProps> = ({
  small,
  middleLabel,
  buttonLabel,
  height,
  width,
  value,
  previewStyle,
  apiBaseUri,
  onError,
  codeBouton,
  onChange,
  token,
  fileType,
  previewSize,
}) => {
  const theme = useContext<ThemeType>(ThemeContext);
  const [getLocal, setLocal] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useMemo<UploaderApi>(() => new UploaderApi(apiBaseUri), [apiBaseUri]);
  const controller = useRef<AbortController>();

  useEffect(() => {
    return () => controller.current?.abort();
  }, []);

  const onUpload = async (val: File) => {
    controller.current?.abort();
    controller.current = new AbortController();
    try {
      setLoading(true);
      const resp = await api.createMedias(
        codeBouton,
        {
          fileSize: val.size,
          mimeType: val.type,
        },
        token,
        controller.current?.signal
      );
      if (!resp.chunkCount || !resp.id || !resp.chunkSize) {
        setHasError(true);
        return;
      }
      let index = 0;
      do {
        await api.addChunks(
          codeBouton,
          resp.id,
          val.slice(index * resp.chunkSize, resp.chunkSize + index * resp.chunkSize),
          index,
          token,
          controller.current?.signal
        );
        index++;
      } while (index < resp.chunkCount);
      let response = await api.completeMedias(
        codeBouton,
        resp.id,
        completed,
        token,
        controller.current?.signal
      );
      do {
        response = await api.getMedias(codeBouton, resp.id, token, controller.current?.signal);
        switch (response.status) {
          default:
          case completed:
            await new Promise(resolve => setTimeout(resolve, 2000));
            break;
          case error:
            setHasError(true);
            onError && onError(response.error?.message);
            break;
          case finalized:
            onChange(response);
            setHasError(false);
            break;
        }
      } while (response.status === completed);
      setLoading(false);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        setLoading(true);
      } else {
        setHasError(true);
        onError && onError(e);
        setLoading(false);
      }
    }
  };

  const onDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const data = event.dataTransfer.files;
    await onUpload(data[0]);
  };

  const onInputChange = async (event: ChangeEvent) => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    setLocal(target.value);
    const files: FileList = target.files || new FileList();
    await onUpload(files[0]);
  };

  const onDeleteClick = async () => {
    setLocal('');
    onChange(undefined);
  };

  return (
    <Wrapper
      error={hasError}
      onDragOver={e => e.preventDefault()}
      onDrop={onDrop}
      width={width}
      height={height}
    >
      <Container>
        <Flex
          style={{
            flexDirection: 'column',
            flexWrap: 'nowrap',
            width: '100%',
            height: '100%',
            rowGap: 10,
          }}
          alignItems={['center']}
          justifyContent={['center']}
        >
          <Svg
            icon={'image'}
            fill={theme.colors.grey40}
            width={small ? 32 : 48}
            height={small ? 32 : 48}
          />
          <div style={{ width: small ? '100%' : '60%' }}>
            {small ? (
              <SpanSubtitle1>{middleLabel}</SpanSubtitle1>
            ) : (
              <SpanBody1>{middleLabel}</SpanBody1>
            )}
          </div>
          <label>
            <input
              value={getLocal}
              accept={fileType}
              type={'file'}
              onChange={onInputChange}
              style={{ opacity: 0, position: 'absolute', width: 0, height: 0 }}
            />
            {small ? (
              <SpanLink style={{ fontSize: '15px' }}>{buttonLabel}</SpanLink>
            ) : (
              <Button as={'div'} colorType={'secondary'}>
                {buttonLabel}
              </Button>
            )}
          </label>
        </Flex>
      </Container>
      <InOut show={!!value?.thumbnails} startTriggering>
        <Preview
          style={{
            ...previewStyle,
            backgroundImage: value?.thumbnails
              ? `url(${
                  previewSize && value.thumbnails[previewSize]
                    ? value.thumbnails[previewSize]
                    : value.thumbnails.large ??
                      value.thumbnails.medium ??
                      value.thumbnails.small ??
                      value.url
                })`
              : 'none',
          }}
        >
          <Flex justifyContent={['flex-end']} style={{ padding: small ? '5px' : '10px' }}>
            <SquareButton icon={'cross'} small={small} onClick={onDeleteClick} />
          </Flex>
        </Preview>
      </InOut>
      <InOut show={loading}>
        <Container>
          <Flex
            style={{ width: '100%', height: '100%' }}
            alignItems={['center']}
            justifyContent={['center']}
          >
            <Svg stroke={theme.colors.dark} animatedIcon={'spinner'} />
          </Flex>
        </Container>
      </InOut>
    </Wrapper>
  );
};
