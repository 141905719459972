import React, { FC, FormEvent, useEffect, useState } from 'react';
import { BankInformations, Company } from '../../interface/company.types';
import { InteractiveCol } from '../ui/interactive-col';
import { Col, Input, Row, SpanBody2, SpanSubtitle1, SpanSubtitle2 } from '@linkeo.com/ui-lib-react';
import {
  BankInformationsErrors,
  getInvalidBankInformations,
  hasBankInformations,
} from '../../utils/quote-document-validator';
import styled from 'styled-components';
import { FormFooter } from '../ui/form-footer';
import { useApi } from '../../providers/api-provider';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirm } from '../../providers/confirm-provider';
import { Locale } from '../../providers/intl.provider';

interface BankInformationDocumentFormProps {
  company: Company;
  onChange: (v: Company) => void;
  readOnly: boolean;
  locale: Locale;
}

const RoundedBorder = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey70};
    border-radius: 9px;
    padding: 14px;
`;
export const BankInformationDocumentForm: FC<BankInformationDocumentFormProps> = props => {
  const { company, onChange, readOnly, locale } = props;
  const { openConfirm } = useConfirm();
  const intl = useIntl();
  const [getEditMode, setEditMode] = useState<boolean>(false);
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getFormControl, setFormControl] = useState<BankInformations>(company.bankInformations);
  const [getErrors, setErrors] = useState<BankInformationsErrors>({});
  const [getMessageErrors, setMessageErrors] = useState<string>('');
  const isRequired = hasBankInformations(getFormControl);
  const API = useApi();

  useEffect(() => {
    setErrors(getInvalidBankInformations(getFormControl, locale));
  }, [getFormControl, locale]);

  useEffect(() => {
    setFormControl(company.bankInformations);
  }, [company]);

  const onConfirmBankInfoChange = () => {
    setLoading(true);
    API.patchCompany({
      bankInformations: getFormControl,
    }).then((result) => {
      onChange(result);
    }).catch(() => {
      setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
    }).finally(() => {
      setLoading(false);
      setEditMode(false);
    });
  };
  const emitChange = (event: FormEvent) => {
    event.preventDefault();
    setMessageErrors('');
    if (Object.keys(getErrors).length) {
      return;
    }
    openConfirm({ title: '', content: 'Ẽtes-vous sûr de vouloir modifier vos informations bancaires ?' })
      .then(() => onConfirmBankInfoChange())
      .catch(() => {
      });
  };

  return <Row>
      {getEditMode ? <Col columns={[12]}>
          <form onSubmit={emitChange}>
            <Input hasError={getErrors.bankName}
                   required={locale === 'fr-FR' ? isRequired : false}
                   small
                   label={intl.formatMessage({ id: 'bankInfoFormNameLabel', defaultMessage: 'Banque' })}
                   value={getFormControl.bankName}
                   onChange={v => setFormControl({ ...getFormControl, bankName: v })} />
            <Input hasError={getErrors.rib}
                   required={locale === 'fr-FR' ? isRequired : false}
                   small
                   label={intl.formatMessage({ id: 'bankInfoFormRIBLabel', defaultMessage: 'RIB' })}
                   value={getFormControl.rib}
                   onChange={v => setFormControl({ ...getFormControl, rib: v })} />
            <Input hasError={getErrors.iban}
                   required={locale === 'fr-FR' ? isRequired : false}
                   small
                   label={intl.formatMessage({ id: 'bankInfoFormIBANLabel', defaultMessage: 'IBAN' })}
                   value={getFormControl.iban}
                   onChange={v => setFormControl({ ...getFormControl, iban: v })} />
            <Input hasError={getErrors.bic}
                   required={locale === 'fr-FR' ? isRequired : false}
                   small
                   label={intl.formatMessage({ id: 'bankInfoFormBICLabel', defaultMessage: 'BIC' })}
                   value={getFormControl.bic}
                   onChange={v => setFormControl({ ...getFormControl, bic: v })} />
            <FormFooter errorMessage={getMessageErrors} small onCancel={() => setEditMode(false)} loading={getLoading} />
          </form>
        </Col> :
        <InteractiveCol hasError={!!Object.keys(getErrors).length}
                        onClick={() => !readOnly && setEditMode(true)}
                        columns={[12]} tabIndex={readOnly ? -1 : 0}
                        locked={readOnly}>
          <RoundedBorder>
            <SpanBody2><FormattedMessage id={'bankInfoFormTitle'}
                                         defaultMessage={'Informations Bancaires'} /></SpanBody2>
            <Row style={{ marginTop: '10px' }}>
              <Col columns={[3.5]}><SpanSubtitle2>{intl.formatMessage({
                id: 'bankInfoFormNameLabel',
                defaultMessage: 'Banque',
              })}</SpanSubtitle2></Col>
              <Col columns={[8.5]}><SpanSubtitle1>{getFormControl.bankName}</SpanSubtitle1></Col>
              <Col columns={[3.5]}><SpanSubtitle2>{intl.formatMessage({
                id: 'bankInfoFormRIBLabel',
                defaultMessage: 'RIB',
              })}</SpanSubtitle2></Col>
              <Col columns={[8.5]}><SpanSubtitle1>{getFormControl.rib}</SpanSubtitle1></Col>
              <Col columns={[3.5]}><SpanSubtitle2>{intl.formatMessage({
                id: 'bankInfoFormIBANLabel',
                defaultMessage: 'IBAN',
              })}</SpanSubtitle2></Col>
              <Col columns={[8.5]}><SpanSubtitle1>{getFormControl.iban}</SpanSubtitle1></Col>
              <Col columns={[3.5]}><SpanSubtitle2>{intl.formatMessage({
                id: 'bankInfoFormBICLabel',
                defaultMessage: 'BIC',
              })}</SpanSubtitle2></Col>
              <Col columns={[8.5]}><SpanSubtitle1>{getFormControl.bic}</SpanSubtitle1></Col>
            </Row>
          </RoundedBorder>
        </InteractiveCol>
      }
    </Row>
};