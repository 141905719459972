import React, { FC, FormEvent, useState } from 'react';
import {
  BaseButton,
  Card,
  Col,
  Flex,
  InOut,
  Input,
  Row, SpanSubtitle1,
  SpanSubtitle2,
  Svg, Textarea,
} from '@linkeo.com/ui-lib-react';
import { InteractiveCol } from '../ui/interactive-col';
import { SmallCol, SmallRow } from '../ui/small-columns';
import { ItemState } from '../../interface/product.types';
import styled, { useTheme } from 'styled-components';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { useIntl } from 'react-intl';
import { TextItem, TitleItem } from '../../interface/document.type';
import { FormFooter } from '../ui/form-footer';

const Title = styled(SpanSubtitle2)`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

interface TitleLineProps {
  itemState: ItemState;
  onPositionMove: (direction: 'up' | 'down') => void;
  onDelete: () => void;
  onCancel: () => void;
  onSubmit: (item: ItemState) => void;
  readOnly: boolean;
  onStateChange: (v: ItemState) => void;
}

export const TextLine: FC<TitleLineProps> = ({
                                                itemState,
                                                onPositionMove,
                                                onDelete,
                                                onCancel,
                                                onSubmit,
                                                readOnly,
                                                onStateChange,
                                              }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [getFormControl, setFormControl] = useState<TitleItem | TextItem>(itemState.item.itemType === 'title' ?
    itemState.item : itemState.item.itemType === 'text'
      ? itemState.item
      : {
        itemType: 'title',
        text: '',
      });

  const _onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit({ ...itemState, item: getFormControl, editMode: false });
  };

  return <Row>
    {itemState.editMode ? <Col columns={[12]}>
        <InOut show={true} autoScroll={{ behavior: 'smooth', block: 'center' }} startTriggering>
          <Card style={{ margin: '0 -70px', padding: '16px 8px' }}>
            <Flex justifyContent={['space-between']}>
              <Flex style={{ width: '62px' }} direction={['column']} justifyContent={['space-between']}>
                <div>
                  <BaseButton onClick={() => onPositionMove('up')}>
                    <Svg icon={'up'} fill={theme.colors.primary} width={24} height={24} />
                  </BaseButton>
                </div>
                <div>
                  <BaseButton onClick={() => onPositionMove('down')}>
                    <Svg icon={'down'} fill={theme.colors.primary} width={24} height={24} />
                  </BaseButton>
                </div>
              </Flex>
              <form style={{ flex: '1' }} onSubmit={_onSubmit}>
                <SmallRow>
                  {getFormControl.itemType === 'title' ?
                    <Input
                      small
                      label={intl.formatMessage({ id: 'TitleFormLabel', defaultMessage: 'Votre titre libre' })}
                      value={getFormControl.text}
                      onChange={v => setFormControl(UpdateItemInObject(getFormControl, 'text', v))} />
                    : <Textarea small
                                style={{ padding: '8px' }}
                                label={intl.formatMessage({ id: 'TextFormLabel', defaultMessage: 'Votre texte libre' })}
                                value={getFormControl.text}
                                onChange={v => setFormControl(UpdateItemInObject(getFormControl, 'text', v))} />}
                </SmallRow>
                <FormFooter small onCancel={onCancel} />
              </form>
              <div style={{ width: '62px', alignSelf: 'center', textAlign: 'right' }}>
                <BaseButton onClick={() => onDelete()}>
                  <Svg icon={'delete'} fill={theme.colors.primary} width={24} height={24} />
                </BaseButton>
              </div>
            </Flex>
          </Card>
        </InOut>
      </Col>
      : <InteractiveCol
        locked={itemState.readOnly || readOnly}
        columns={[12]}
        onClick={() => !itemState.readOnly && !readOnly && onStateChange({ ...itemState, editMode: true })}>
        <SmallRow justifyContent={['flex-start']}>
          <SmallCol>
            {getFormControl.itemType === 'title' ? <Title style={{ fontWeight: '800' }}>
              {getFormControl.text}
            </Title> : <SpanSubtitle1>{getFormControl.text}</SpanSubtitle1>}
          </SmallCol>
        </SmallRow>
      </InteractiveCol>}
  </Row>;
};