import React, { useEffect, useState } from 'react';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { getMessage, IntlProvider, Locale, Locales } from './providers/intl.provider';
import { RoutingProvider } from './providers/routing.provider';
import { Theme } from './config/theme';
import { ThemeProvider, ToasterProvider } from '@linkeo.com/ui-lib-react';
import { ApiProvider } from './providers/api-provider';
import { AuthProvider } from './providers/auth-provider';
import { Router } from './router';
import { ConfirmProvider } from './providers/confirm-provider';
import { api, formUrl, ssoCheck, ssoClientId, ssoClientSecret, ssoLogin, ssoScope } from './config/config';

function App() {
  const [token, setToken] = useState<string>();
  const match = matchPath<{ lang?: string }>(window.location.pathname, '/:lang');
  let lang: Locale = 'fr-FR';
  if (match) {
    const mathLang = match.params.lang as Locale;
    if (mathLang) {
      if (Locales.indexOf(mathLang) !== -1) {
        lang = match.params.lang as Locale;
      } else {
        window.location.href = '/fr-FR';
      }
    }
  }

  useEffect(() => {
    const encodedToken = sessionStorage.getItem('token');
    if (!encodedToken) {
      return;
    }
    setToken(encodedToken);
  }, [token]);

  const onTokenChange = (token: string) => {
    setToken(token);
  };

  return (
    <BrowserRouter basename={'/' + lang}>
      <ThemeProvider theme={Theme}>
        <IntlProvider locale={lang} messages={getMessage(lang)}>
          <ConfirmProvider>
            <ToasterProvider>
              <RoutingProvider>
                <AuthProvider onTokenChange={onTokenChange} ssoLogin={ssoLogin} ssoCheck={ssoCheck} ssoScope={ssoScope}
                              ssoClientSecret={ssoClientSecret} ssoClientId={ssoClientId}>
                  <ApiProvider api={api} ssoScope={ssoScope} ssoCheck={ssoCheck} ssoLogin={ssoLogin} formUrl={formUrl}>
                    <Router formUrl={formUrl} />
                  </ApiProvider>
                </AuthProvider>
              </RoutingProvider>
            </ToasterProvider>
          </ConfirmProvider>
        </IntlProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
