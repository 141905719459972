import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Shapes } from '../../common';

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  onClick?: () => void;
  /**
   * espacement interne , par défaut "40px"
   */
}

const Wrapper = styled.article<CardProps>`
  ${Shapes.card};
  background: ${({ theme }) => theme.colors.light};
  padding: ${({ theme }) => theme.card.padding};
  position: relative;
  transition: 200ms ease-in-out all;
  outline: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  ${({ onClick, theme }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover,&:focus {
      ${Shapes.shadow2};
    }
    &:focus {
      border: 1px solid ${theme.colors.grey80};
    }
  `};
`;
export const Card: FC<CardProps> = props => {
  const { children, onClick, ...rest } = props;
  return (
    <Wrapper {...(rest as any)} tabIndex={onClick ? 0 : undefined} onClick={onClick}>
      {children}
    </Wrapper>
  );
};
