import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  Col,
  ImageValue,
  InOut,
  Input,
  InputImage,
  InputNumber, Radio,
  Row,
  SpanCaption1,
  Switch,
} from '@linkeo.com/ui-lib-react';
import { Choice, EditChoice } from '../../interface/choice.types';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { EditImage } from '../../interface/api.types';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

interface ChoiceFormProps {
  choice: Choice;
  idForm: string;
  onSubmit: (value: EditChoice) => void;
}

export const ChoiceForm: FC<ChoiceFormProps> = ({ idForm, choice, onSubmit }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [getChoice, setChoice] = useState<Choice>(choice);
  const [getImageError, setImageError] = useState<boolean>(false);
  const [getEditImage, setEditImage] = useState<EditImage>({
    isNew: false,
    image: { stringFormat: choice?.picture?.absoluteUrl },
  });

  useEffect(() => {
    setChoice(choice);
    setEditImage({
      isNew: false,
      image: { stringFormat: choice?.picture?.absoluteUrl },
    });
  }, [choice]);

  const emitChange = (evt: FormEvent) => {
    evt.preventDefault();
    onSubmit({
      choice: getChoice,
      editImage: getEditImage,
    });
  };
  const changeImage = (image: ImageValue) => {
    setImageError(false);
    setEditImage({
      isNew: true,
      image,
    });
  };

  return <form id={idForm} onSubmit={emitChange}>
    <Input
      required
      label={intl.formatMessage({ id: 'choiceFormTitleLabel', defaultMessage: 'Intitulé du choix' })}
      value={getChoice.label}
      onChange={v => setChoice(UpdateItemInObject(getChoice, 'label', v))}
    />
    <Input
      label={intl.formatMessage({ id: 'questionFormDescLabel', defaultMessage: 'Description' })}
      caption={intl.formatMessage({
        id: 'choiceFormDescCaption',
        defaultMessage: 'Ajoutez une description pour préciser l’intitulé si nécessaire',
      })}
      value={getChoice.description||""}
      onChange={v => setChoice(UpdateItemInObject(getChoice, 'description', v))}
    />
    <InputImage
      label={intl.formatMessage({ id: 'actFormImageLabel', defaultMessage: 'Image d’illustration' })}
      addImageLabel={intl.formatMessage({ id: 'addImage', defaultMessage: 'Ajouter une image' })}
      replaceImageLabel={intl.formatMessage({ id: 'replaceImage', defaultMessage: 'Remplacer l’image' })}
      removeImageLabel={intl.formatMessage({ id: 'removeImage', defaultMessage: 'Supprimer l’image' })}
      value={getEditImage.image}
      onError={() => setImageError(true)}
      caption={getImageError ? intl.formatMessage({
        id: 'fieldLogoSizeError',
        defaultMessage: 'Accepte les images jpg,png de 2mb max',
      }) : ''}
      hasError={getImageError}
      onChange={changeImage} />
    <Switch value={getChoice.setPrice}
            onLabel={intl.formatMessage({
              id: 'choiceFormPriceRangeLabel',
              defaultMessage: 'Définir une valeur pour ce choix',
            })}
            onChange={v => setChoice(UpdateItemInObject(getChoice, 'setPrice', v))} />
    <InOut show={getChoice.setPrice} autoScroll>
      <Row>
        <Col columns={[12, 6]}>
          <InputNumber
            disabled={!getChoice.setPrice}
            required
            label={intl.formatMessage({ id: 'choiceFormPriceRangeMinLabel', defaultMessage: 'Valeur minimum' })}
            min={0}
            step='any'
            value={getChoice.minPrice}
            onChange={v => setChoice(UpdateItemInObject(getChoice, 'minPrice', v))}
          />
        </Col>
        <Col columns={[12, 6]}>
          <InputNumber
            disabled={!getChoice.setPrice}
            required
            label={intl.formatMessage({ id: 'choiceFormPriceRangeMaxLabel', defaultMessage: 'Valeur maximum' })}
            min={0}
            step='any'
            value={getChoice.maxPrice}
            onChange={v => setChoice(UpdateItemInObject(getChoice, 'maxPrice', v))}
          />
        </Col>
      </Row>
      <SpanCaption1>
        {intl.formatMessage({
          id: 'choiceFormAddPriceCaption',
          defaultMessage: 'La valeur de ce choix s’additionnera à la valeur de base de l’activité',
        })}
      </SpanCaption1>
    </InOut>
    <Switch
      onLabel={intl.formatMessage({
        id: 'questionFormQuantityLabel',
        defaultMessage: 'Demander au prospect de préciser une quantité pour ce choix',
      })}
      onChange={v => setChoice(UpdateItemInObject(getChoice, 'addChoiceQuantity', v))}
      value={getChoice.addChoiceQuantity}
    />
    <InOut show={getChoice.addChoiceQuantity} autoScroll>
      <Row>
        <Col columns={[12, 6]}>
          <Input
            disabled={!getChoice.addChoiceQuantity}
            required
            label={intl.formatMessage({
              id: 'choiceFormQuantityTitleLabel',
              defaultMessage: 'Intitulé du champ quantité',
            })}
            value={getChoice.choiceQuantityLabel}
            onChange={v => setChoice(UpdateItemInObject(getChoice, 'choiceQuantityLabel', v))}
          />
        </Col>
        <Col columns={[12, 6]}>
          <Input label={intl.formatMessage({ id: 'choiceFormQuantityUnitLabel', defaultMessage: 'Unité à utiliser' })}
                 value={getChoice.choiceQuantityUnit}
                 onChange={v => setChoice(UpdateItemInObject(getChoice, 'choiceQuantityUnit', v))}
          />
        </Col>
      </Row>
      <SpanCaption1 style={{ color: theme.colors.grey60 }}>
        {intl.formatMessage({
          id: 'choiceFormQuantityCaption',
          defaultMessage: 'La quantité renseignée sera multiplié par la valeur du choix pour fournir une estimation.',
        })}
      </SpanCaption1>
    </InOut>
    <Switch
      onLabel={intl.formatMessage({
        id: 'choiceFormEligibilitySwitchLabel',
        defaultMessage: 'Définir un critère d\'éligibilité pour ce choix',
      })}
      onChange={(val) => setChoice({ ...getChoice, eligibility: val ? true : null })}
      value={getChoice.eligibility !== null && getChoice.eligibility !== undefined} />
    <InOut show={getChoice.eligibility !== null && getChoice.eligibility !== undefined}>
      <Row>
        <Col columns={[12, 6]}>
          <Radio name={'eligibility_radio_input'}
                 onChange={() => setChoice({ ...getChoice, eligibility: true })}
                 value={!!getChoice.eligibility}
                 caption={intl.formatMessage({
                   id: 'choiceFormEligibilityRadioCaption',
                   defaultMessage: 'Si le prospect va jusqu\'au bout du formulaire, la réponse {text} lui sera donnée',
                 }, {
                   text: <strong>{intl.formatMessage({
                     id: 'eligibility',
                     defaultMessage: 'éligible',
                   })}</strong>,
                 })}>
            {intl.formatMessage({ id: 'choiceFormEligibilityRadioLabel', defaultMessage: 'Ce choix est éligible.' })}
          </Radio>
        </Col>
        <Col columns={[12, 6]}>
          <Radio name={'ineligibility_radio_input'}
                 onChange={() => setChoice({ ...getChoice, eligibility: false })}
                 value={!getChoice.eligibility}
                 caption={intl.formatMessage({
                   id: 'choiceFormIneligibilityRadioCaption',
                   defaultMessage: 'Met fin au formulaire, la réponse de {text} est donnée',
                 }, { text: <strong>{intl.formatMessage({ id: 'noteligible', defaultMessage: 'non-éligibilité' })}</strong> })}>
            {intl.formatMessage({
              id: 'choiceFormIneligibilityRadioLabel',
              defaultMessage: 'Ce choix n\'est pas éligible.',
            })}
          </Radio>
        </Col>
      </Row>
    </InOut>
  </form>;
};

