import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, Textarea } from '@linkeo.com/ui-lib-react';
import { useAuth } from '../../providers/auth-provider';
import { useIntl } from 'react-intl';
import { Locale } from '../../providers/intl.provider';

interface IntegrationFormDialogProps {
  onClose: () => void;
  show: boolean,
  formId:string;
  formUrl: string;
  locale: Locale;
}

export const IntegrationFormDialog: FC<IntegrationFormDialogProps> = props => {
  const { onClose, show ,formId, formUrl, locale} = props;
  const [getCopied, setCopied] = useState<boolean>(false);
  const intl = useIntl();
  useEffect(
    () => {
      setCopied(false);
    }
    , [show]);
  const AUTH = useAuth();
  const copy = (event: React.MouseEvent) => {
    const target = event.target as HTMLTextAreaElement;
    target.select();
    document.execCommand('copy');
    setCopied(true);
  };
  const footer = <Button colorType={'secondary'} onClick={onClose}>{intl.formatMessage({id: 'close', defaultMessage: 'Fermer'})}</Button>;
  return <Dialog title={intl.formatMessage({id: 'formIntegration', defaultMessage: 'Intégrer le formulaire'})} onClose={onClose} show={show} size={'md'} footer={footer}>
    <Textarea
      label={intl.formatMessage({id: 'formIntegrationInputLabel', defaultMessage: 'Script à intégrer dans la page web à l’endroit ou vous voulez afficher l’estimation'})}
      caption={getCopied ? intl.formatMessage({id: 'formIntegrationInputCaption', defaultMessage: 'Texte copié !'}) : ''}
      onClick={copy}
      onChange={() => {
      }}
      readOnly
      value={`<script type='application/javascript' src='${formUrl}/static/js/quote.js?codebouton=${AUTH.codeBouton}&locale=${locale}&formid=${formId}'></script>`} />
  </Dialog>;
};