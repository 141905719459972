export const isValidSiret = (siret: string) => {
  let isValid;
  if ((siret.length != 14))
    isValid = false;
  else {
    let somme = 0;
    let tmp = 0;
    for (let i = 0; i < siret.length; i++) {
      if ((i % 2) == 0) {
        tmp = parseInt(siret.charAt(i), 10) * 2;
        if (tmp > 9) {
          tmp -= 9;
        }
      } else {
        tmp = parseInt(siret.charAt(i));
      }
      somme += tmp;
    }
    isValid = (somme % 10) == 0;
  }
  return isValid;
};
/**
 *
 * @param handler
 * @param time
 * @param ref du timeout
 * @constructor
 * @return return the new ref
 */
export const Debounce = (handler: () => void, time: number, ref: number): number => {
  window.clearTimeout(ref);
  return window.setTimeout(handler, time);
};
const convertCharToIbanNumber = (char: string): number => {
  const offset = 55;
  return char.toUpperCase().charCodeAt(0) - offset;
};
/**
 *
 * @param dividend a big number in string
 * @param divisor
 */
const moduloBigNumber = (dividend: string, divisor: number): number => {
  const partLength = 10;
  let tempDividend = dividend;
  while (tempDividend.length > partLength) {
    const part = Number.parseInt(tempDividend.substring(0, partLength), 10);
    tempDividend = (part % divisor) + tempDividend.substring(partLength);
  }
  return Number.parseInt(tempDividend.substring(0, partLength), 10) % divisor;
};
export const ibanLengthByCountryCode = (code: string): number=>{
  const compare = code.toLowerCase();
  const lengthsByCode = {
    'al' : 28, 'ad' : 24, 'at' : 20, 'az' : 28, 'bh' : 22, 'be' : 16, 'ba' : 20, 'br' : 29, 'bg' : 22, 'cr' : 21, 'hr' : 21, 'cy' : 28, 'cz' : 24,
    'dk' : 18, 'do' : 28, 'ee' : 20, 'fo' : 18, 'fi' : 18, 'fr' : 27, 'ge' : 22, 'de' : 22, 'gi' : 23, 'gr' : 27, 'gl' : 18, 'gt' : 28, 'hu' : 28,
    'is' : 26, 'ie' : 22, 'il' : 23, 'it' : 27, 'jo' : 30, 'kz' : 20, 'kw' : 30, 'lv' : 21, 'lb' : 28, 'li' : 21, 'lt' : 20, 'lu' : 20, 'mk' : 19,
    'mt' : 31, 'mr' : 27, 'mu' : 30, 'mc' : 27, 'md' : 24, 'me' : 22, 'nl' : 18, 'no' : 15, 'pk' : 24, 'ps' : 29, 'pl' : 28, 'pt' : 25, 'qa' : 29,
    'ro' : 24, 'sm' : 27, 'sa' : 24, 'rs' : 22, 'sk' : 24, 'si' : 19, 'es' : 24, 'se' : 24, 'ch' : 21, 'tn' : 24, 'tr' : 26, 'ae' : 23, 'gb' : 22, 'vg' : 24,
}
    // @ts-ignore
  return lengthsByCode[compare] || -1;
}
export const isValidRib = ( $rib: string):  boolean=> {
  const $key = $rib.substr( -2);
  const $bank = $rib.substr(0, 5);
  const $branch = $rib.substr( 5, 5);
  const $account = $rib.substr( 10, 11);
  let $tabcompte = "";
  if (!$key || !$bank || !$branch || !$account) {
    return false;
  }
  const $len = $account.length;
  if ($len != 11) {
    return false;
  }
  for (let $i = 0; $i < $len; $i++) {
    const $car = $account.substr($i,  1);
    if (Number.isNaN(parseInt($car, 10))) {
      const $c = $car.charCodeAt(0) - ('A'.charCodeAt(0) - 1);
      const $b = (($c + Math.pow(2, ($c - 10) / 9)) % 10) + (($c > 18 && $c < 25) ? 1 : 0);
      $tabcompte += $b;
    } else {
      $tabcompte += $car;
    }
  }
  const $int = $bank + $branch + $tabcompte + $key;
  return ($int.length >= 21 && moduloBigNumber($int, 97) == 0);
}
export const isValidIban = (input: String): boolean => {
  const ibanLength = ibanLengthByCountryCode(input.substring(0,2));
  if(ibanLength !== input.length){
    return false;
  }
  const trimLess = input.trim().replace('-', '');
  const reordered = trimLess.substring(4) + trimLess.substring(0, 4);
  const asArray = reordered.split('');
  const replacedCharByNumber = asArray.map(item => {
    if (Number.isNaN(parseInt(item, 10))) {
      return convertCharToIbanNumber(item).toString(10);
    }
    return item;
  });
  const finalNumber = replacedCharByNumber.join('');
  const ibanModulo = 97;
  return moduloBigNumber(finalNumber, ibanModulo) === 1;
};
