import * as React from 'react';
import { FC } from 'react';
import { BaseInputNumber, BaseInputNumberProps } from '../base-input-number';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';

interface InputNumberProps extends LabelWrapperProps, BaseInputNumberProps {}

export const InputNumber: FC<InputNumberProps> = props => {
  const { label, disabled, caption, right, hasError, required, small, ...rest } = props;
  return (
    <LabelWrapper {...{ label, disabled, caption, right, hasError, required, small }}>
      <BaseInputNumber {...{ disabled, hasError, required, small, ...rest }} />
    </LabelWrapper>
  );
};
