import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QuoteDocument } from '../../interface/document.type';
import { PageWrapper } from '../../components';
import { AddDay, Button, Col, Container, Flex, PageSubtitle, Row, Svg, useToaster } from '@linkeo.com/ui-lib-react';
import { useApi } from '../../providers/api-provider';
import { EstimationCardRecap } from '../../components/estimation/estimation-card-recap';
import { DateToIsoShort } from '../../utils/date.utils';
import { DocumentView } from '../../components/document/document-view';
import { AcceptationQuoteDialog } from '../../components/document/acceptation-quote-dialog';
import { DuplicateDocumentButton } from '../../components/document/duplicate-document-button';
import { DownloadDocumentButton } from '../../components/document/download-document-button';
import { useIntl } from 'react-intl';
import { ServerError } from '../../components/commons/server-error';
import { Error404 } from '../../components/commons/404-error';

export const DocumentQuotePage: FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const [getDocument, setDocument] = useState<QuoteDocument>();
  const [getShowAcceptedDialog, setShowAcceptedDialog] = useState<boolean>(false);
  const [getLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<boolean>(false);
  const history = useHistory();
  const API = useApi();
  const intl = useIntl();
  const toast = useToaster();

  const acceptDocument = (date: Date) => {
    if (!getDocument) {
      return;
    }
    setIsLoading(true);
    API.updateDocument({
      ...getDocument,
      acceptance: 'accepted',
      acceptanceDate: DateToIsoShort(date),
    }).then(result => {
      setDocument(result);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })
    )).finally(() => {
      setShowAcceptedDialog(false);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    API.retrieveDocument(documentId)
      .then(result => setDocument(result))
      .catch(() => setPageError(true))
      .finally(() => setLoading(false));
  }, [API, documentId]);

  const convertToInvoice = () => {
    if (!getDocument) {
      return;
    }
    const { id, ...rest } = getDocument;
    API.createDocument({
      ...rest,
      status: 'draft',
      type: 'invoice',
      expirationDate: undefined,
      quoteId: id,
    }).then(result => {
      history.push(`/draft/document/${result.id}`);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  return <PageWrapper isLoading={loading}>
    <Container size={'lg'}>
      {getDocument && !pageError ? <>
        {getDocument.estimationId ? <EstimationCardRecap estimationId={getDocument.estimationId} /> : null}
        {getDocument.acceptance === 'accepted' && <Flex alignItems={['center']}>
          <Svg style={{ marginBottom: '14px', marginRight: '10px' }} icon={'check-circle'} fill={'#35D759'}
               width={24} height={24} />
          <PageSubtitle>
            {
              intl.formatMessage({ id: 'acceptedQuoteAt', defaultMessage: 'Devis accepté le {date}' },
                { date: intl.formatDate(getDocument.acceptanceDate || new Date(), { dateStyle: 'short' }) })
            }
          </PageSubtitle>
        </Flex>}
        <DocumentView
          errors={{}}
          onDocumentChange={() => {
          }} quoteDocument={getDocument} readOnly={true} />
        <Row style={{ marginTop: '20px' }}>
          <Col>
            <DuplicateDocumentButton quoteDocument={getDocument} />
          </Col>
          <Col>
            <DownloadDocumentButton documentId={getDocument.id} fileTitle={getDocument.title} />
          </Col>
          {getDocument.acceptance === 'pending' &&
            <Col style={{ marginLeft: 'auto' }}>
              <Button colorType={'primary'}
                      onClick={() => setShowAcceptedDialog(true)}>
                {intl.formatMessage({ id: 'quotePageConfirmQuote', defaultMessage: 'Accepter le devis' })}
              </Button>
            </Col>}
          {getDocument.acceptance === 'accepted' &&
            <Col style={{ marginLeft: 'auto' }}>
              <Button colorType={'primary'}
                      onClick={convertToInvoice}>
                {intl.formatMessage({ id: 'quotePageShowInvoice', defaultMessage: 'Convertir en facture' })}
              </Button>
            </Col>}
        </Row>
        <AcceptationQuoteDialog
          documentId={documentId}
          minDate={AddDay(new Date(getDocument?.issueDate || ''), -1)}
          maxDate={new Date(getDocument?.expirationDate || '')}
          onClose={() => setShowAcceptedDialog(false)}
          onSubmit={acceptDocument} show={getShowAcceptedDialog}
          isLoading={getLoading}
        />
      </> : getDocument && pageError ? <ServerError /> : <Error404 />}
    </Container>
  </PageWrapper>;
};