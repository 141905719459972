import styled from 'styled-components';

export const ContainerSizeSM = 'sm';
export const ContainerSizeMD = 'md';
export const ContainerSizeLG = 'lg';
export const ContainerSizeFluid = 'fluid';

export const ContainerSizes = [
  ContainerSizeSM,
  ContainerSizeMD,
  ContainerSizeLG,
  ContainerSizeFluid,
] as const;

export type ContainerSize = typeof ContainerSizes[number];

export interface ContainerProps {
  size: ContainerSize | number;
}

const MaxWidth = (size: ContainerSize | number): string => {
  switch (size) {
    case 'lg':
      return '992px';
    case 'md':
      return '768px';
    case 'sm':
      return '576px';
    case 'fluid':
      return 'initial';
    default:
      return size + 'px';
  }
};
export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${(props): string => MaxWidth(props.size)};
  margin: 0 auto;
  padding: 32px max(10px, min(2vw, 20px));
  box-sizing: border-box;
  position: relative;
`;
