import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface TopStickyProps {
  children?: React.ReactNode;
}

const Wrapper = styled.div<{ sticky: boolean }>`
  z-index: 5;
  position: absolute;
  width: 100%;
  ${({ sticky }): string =>
    sticky
      ? `
    position: fixed;
    top:0;
    `
      : ''};
`;
export const TopSticky: FC<TopStickyProps> = props => {
  const { children } = props;
  const [getSticky, setSticky] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>();
  const [getHeight, setHeight] = useState<number>(0);
  const [getOffset, setOffset] = useState<number | undefined>();

  useEffect(() => {
    const onScroll = () => {
      const query = navRef.current;
      if (!query) {
        return;
      }
      const contentRect = query.getBoundingClientRect();
      if (contentRect.top <= 0) {
        setSticky(true);
      }
      if (typeof getOffset === 'undefined') {
        return;
      }
      if (window.scrollY <= getOffset) {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    if (typeof navRef !== 'undefined') {
      const current = navRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        setHeight(rect.height);
        if (typeof getOffset === 'undefined') {
          setOffset(rect.top + window.scrollY);
        }
      }
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [navRef, getOffset]);
  return (
    <>
      <Wrapper sticky={getSticky} ref={navRef as any}>
        {children}
      </Wrapper>
      <div style={{ height: getHeight }} />
    </>
  );
};
