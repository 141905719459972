import React, { FC, FormEvent, useEffect, useReducer, useRef, useState } from 'react';
import { FormFooter } from '../ui/form-footer';
import { EstimateParam, EstimateParamTax } from '../../interface/estimate-param.types';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  BaseButton,
  BaseInputImage,
  Card,
  CardSubtitle,
  CardTitle,
  Col,
  Deletable,
  ImageValue,
  InOut,
  Input,
  LabelWrapper,
  RadioGroup,
  RadioOption,
  Row,
  SpanLink,
  Svg
} from '@linkeo.com/ui-lib-react';
import { ReducerArray, ReducerArrayType, ReducerObject, ReducerObjectType } from '../../utils/react-reducer.utils';
import { useApi } from '../../providers/api-provider';
import { useIntl } from 'react-intl';
import { routeHome } from '../../routes';

interface estimationParamsFormProps {
  estimationParam: EstimateParam;
  isLoading: boolean;
  onChange: (v: EstimateParam) => void;
}

type RecipientsId = { id: number, value: string }

const Space = styled.div`
    margin: 30px 0;
`;

// ['mediaType', ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/pdf', 'application/zip']],

export const EstimationParamsForm: FC<estimationParamsFormProps> = props => {
  const { estimationParam, isLoading, onChange } = props;
  const intl = useIntl();
  const [getFormControl, setFormControl] = useReducer<ReducerObjectType<EstimateParam>>(ReducerObject, estimationParam);
  const [getTempRecipient, setTempRecipient] = useReducer<ReducerArrayType<RecipientsId>>(ReducerArray, []);
  const [getLocal, setLocal] = useState<string>('');
  const [getLoadFile, setLoadFile] = useState<boolean>(false);
  const forwardId = useRef<number>(0);
  const [getErrorFile, setErrorFile] = useState<string>('');
  const API = useApi();
  const theme = useTheme();
  const history = useHistory();
  const optionsTaxes: RadioOption<EstimateParamTax>[] = [
    {
      data: 'TVA',
      name: intl.formatMessage({ id: 'paramTarifTaxeTTCLabel', defaultMessage: 'Afficher un prix TTC' }),
      columns: [-1],
    },
    {
      data: 'HT',
      name: intl.formatMessage({ id: 'paramTarifTaxeHTLabel', defaultMessage: 'Afficher un prix HT' }),
      columns: [-1],
    },
  ];

  useEffect(() => {
    setFormControl({ replace: estimationParam });
    setTempRecipient({
      replace: estimationParam.recipients.map(recipient => ({
        value: recipient,
        id: forwardId.current++,
      })),
    });
  }, [estimationParam]);

  const emitChange = (event: FormEvent) => {
    event.preventDefault();
    onChange({
      ...getFormControl,
      recipients: Array.from(new Set(getTempRecipient.map(recipient => recipient.value)))
    });
  };

  const uploadFile = (file: ImageValue) => {
    setErrorFile('');
    if (!file.binary) {
      return;
    }
    setLoadFile(true);
    API.postFile(file.binary, '/file').then(result => {
      setFormControl({ merge: { file: result } });
    }).finally(() => {
      setLoadFile(false);
    });
  };

  const downloadFile = () => {
    if (!getFormControl.file) {
      return;
    }
    const file = getFormControl.file;
    fetch(file.absoluteUrl).then(res => res.blob()).then((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `attachment_${file.filename}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return <form onSubmit={emitChange}>
    <Space>
      <Card>
        <CardTitle>
          {intl.formatMessage({ id: 'paramTarifTaxeTitle', defaultMessage: 'Taxes' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'paramTarifTaxeSubtitle',
            defaultMessage: 'Voulez-vous communiquer sur un prix HT ou TTC ?',
          })}
        </CardSubtitle>
        <RadioGroup<EstimateParamTax>
          required
          options={optionsTaxes}
          groupName={'tarif'}
          onChange={(v) => v.data && setFormControl({ merge: { tax: v.data } })}
          value={optionsTaxes.find(f => f.data === getFormControl.tax)} />
      </Card>
    </Space>
    <Space>
      <Card>
        <CardTitle>
          {intl.formatMessage({ id: 'paramTarifReceptionTitle', defaultMessage: 'Réception des demandes' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'paramTarifReceptionSubtitle',
            defaultMessage: 'Ajoutez ici les adresses Email auxquelles vous souhaitez recevoir les demandes d’estimation en ligne.',
          })}
        </CardSubtitle>
        {getTempRecipient.map(
          (mail, index) => <Deletable
            offsetTop={'36px'}
            key={mail.id}
            onDelete={() => setTempRecipient({ removeOne: index })}>
            <Input
              label={intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' })}
              type={'email'}
              value={mail.value}
              pattern={'[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'}
              onChange={value => setTempRecipient({ update: { index, item: { ...mail, value } } })}
              hasError={getTempRecipient.filter(s => s.value === mail.value).length > 1}
              required
            />
          </Deletable>)}
        <BaseButton style={{ color: theme.colors.primary }}
                    onClick={() => setTempRecipient({ push: { id: forwardId.current++, value: '' } })}>
          +&nbsp;
          <SpanLink
            style={{ color: theme.colors.primary }}>{intl.formatMessage({
            id: 'addAReception',
            defaultMessage: 'Ajouter une adresse supplémentaire',
          })}</SpanLink>
        </BaseButton>
      </Card>
    </Space>
    <Space>
      <Card>
        <CardTitle>
          {intl.formatMessage({
            id: 'estimationParamFileTitle',
            defaultMessage: 'Attacher une pièce jointe à l’email',
          })}
        </CardTitle>
        <Row style={{ marginTop: '30px' }}>
          <Col columns={[4]}>
            <LabelWrapper hasError={!!getErrorFile} caption={getErrorFile}>
              <BaseInputImage
                accept='.png,.jpg,.jpeg,.gif,.pdf,.zip'
                onChange={uploadFile}
                onError={() => setErrorFile('Maximum 2mb')}
                local={getLocal}
                onLocalChange={setLocal} />
            </LabelWrapper>
          </Col>
          <Col columns={[2]}>
            <InOut show={getLoadFile}>
              <Svg animatedIcon={'spinner'} stroke={'black'} />
            </InOut>
          </Col>
          <Col columns={[6]}>
            {getFormControl.file?.absoluteUrl ?
              <Row>
                <Col>
                  <BaseButton onClick={downloadFile}>
                    <SpanLink>{intl.formatMessage({
                      id: 'estimationParamFileView',
                      defaultMessage: 'Voir la pièce jointe',
                    })}
                    </SpanLink>
                  </BaseButton>
                </Col>
                <Col>
                  <BaseButton onClick={() => setFormControl({
                    merge: {
                      file: {
                        absoluteUrl: '',
                        filename: '',
                      },
                    },
                  })}><SpanLink>{intl.formatMessage({
                    id: 'estimationParamFileRemove',
                    defaultMessage: 'Supprimer la pièce jointe',
                  })}</SpanLink></BaseButton></Col></Row> : null}
          </Col>
        </Row>
      </Card>
    </Space>
    <FormFooter onCancel={() => history.push(routeHome)} loading={isLoading} disabled={false} />
  </form>;
};