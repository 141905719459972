import {
  BaseButton,
  DashboardCard,
  DashboardCardTitle,
  DashboardSlider,
  Flex,
  getTypo,
  H2,
  H3,
  Row,
  SpanBody1,
  Svg,
} from '@linkeo.com/ui-lib-react';
import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeActivities, routeEstimateParams, routePresentation } from '../../routes';

const DashboardCardSubtitle = styled.p`
  ${getTypo('subtitle1')};
  color: ${({ theme }) => theme.colors.grey60};
  margin: 0 0 16px 0;
`;

interface DashboardProps {
  quoteCount: number;
}

const Caption = styled.div`
  ${getTypo('subtitle1')};
  color: #666666;
`;
export const Dashboard: FC<DashboardProps> = () => {
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  return <Row wraps={['wrap']} justifyContent={['center']}>
    <DashboardSlider slides={[
      {
        title: intl.formatMessage({id: 'dashboardSlider1subtitle', defaultMessage: 'Bienvenue sur Quote, votre assistant devis'}),
        alt: 'logo',
        body: intl.formatMessage({id: 'dashboardSlider1Body', defaultMessage: 'Paramétrez vos activités. Proposez des estimations en ligne et recevez des devis pré-établis.'}),
        imageUri: '/img/dashboard-slide-1.svg',
      },
    ]} subtitle={
      intl.formatMessage({id: 'dashboardSliderTitle', defaultMessage: 'À propos de Quote'})
    } />
    <DashboardCard>
      <BaseButton style={{ textAlign: 'center' }} onClick={() => history.push(routeActivities)}>
        <Svg icon={'button'} fill={theme.colors.primary} style={{ marginBottom: 10 }} />
        <DashboardCardTitle>
          {intl.formatMessage({id: 'dashboardCard3Sub5Title', defaultMessage: 'Activités'})}
        </DashboardCardTitle>
        <SpanBody1>
          {intl.formatMessage({id: 'dashboardCard3Sub5Body', defaultMessage: 'Ajoutez ici les activités que vous proposez.'})}
        </SpanBody1>
      </BaseButton>
    </DashboardCard>
    <DashboardCard>
      <BaseButton style={{ textAlign: 'center' }} onClick={() => history.push(routePresentation)}>
        <Svg icon={'form'} fill={theme.colors.primary} style={{ marginBottom: 10 }} />
        <DashboardCardTitle>
          {intl.formatMessage({id: 'dashboardCard3Sub6Title', defaultMessage: 'Présentation'})}
        </DashboardCardTitle>
        <SpanBody1>
          {intl.formatMessage({id: 'dashboardCard3Sub6Body', defaultMessage: 'Réglez ici les informations que vous souhaitez afficher dans vos formulaires.'})}
        </SpanBody1>
      </BaseButton>
    </DashboardCard>
    <DashboardCard>
      <BaseButton style={{ textAlign: 'center' }} onClick={() => history.push(routeEstimateParams)}>
        <Svg icon={'gear'} fill={theme.colors.primary} style={{ marginBottom: 10 }} />
        <DashboardCardTitle>
          {intl.formatMessage({id: 'dashboardCard3Sub4Title', defaultMessage: 'Paramètres'})}
        </DashboardCardTitle>
        <SpanBody1>
          {intl.formatMessage({id: 'dashboardCard3Sub4Body', defaultMessage: 'Réglez ici la manière dont vous souhaitez communiquer vos estimations.'})}
        </SpanBody1>
      </BaseButton>
    </DashboardCard>
    <DashboardCard>
      <DashboardCardSubtitle>{intl.formatMessage({id: 'dashboardCard4Subtitle', defaultMessage: 'Assistance'})}</DashboardCardSubtitle>
      <Flex alignItems={['center']} style={{ marginBottom: '10px' }}>
        <Svg
          style={{ margin: '0 5px 10px 0' }}
          width={30}
          height={30}
          icon={'life-buoy'}
          fill={'#F2423F'}
        />
        <H2>{intl.formatMessage({id: 'supportPhoneNumber', defaultMessage: '0972670167'})}</H2>
      </Flex>
      <H3>
        {intl.formatMessage({id: 'dashboardCard4Title', defaultMessage: 'Besoin d’aide ?'})}
      </H3>
      <Caption>
        {intl.formatMessage({id: 'dashboardCard4Body1', defaultMessage: 'N’hésitez pas à contacter'})}
        <br />
        {intl.formatMessage({id: 'dashboardCard4Body2', defaultMessage: 'le service clients.'})}
        <br />
        {intl.formatMessage({id: 'dashboardCard4Body3', defaultMessage: 'Joignable du lundi au'})}
        <br />
        {intl.formatMessage({id: 'dashboardCard4Body4', defaultMessage: 'vendredi de 8:30 à 18:00'})}
      </Caption>
    </DashboardCard>
  </Row>;
};