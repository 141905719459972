import { FC, useCallback, useEffect, useState } from 'react';
import { PageWrapper, PageWrapperSubHeader } from '../../components';
import { ScrollWindowPagination } from '../../components/ui/scroll-window-pagination';
import { DocumentsFilter } from '../../components/documents-list/documents-filter';
import {
  Button,
  Col,
  Container,
  PageTitle,
  Row,
  SelectOption,
  SpanBody1, useToaster,
} from '@linkeo.com/ui-lib-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi } from '../../providers/api-provider';
import { DocumentType, ListQuoteDocuments, PartialQuoteDocument } from '../../interface/document.type';
import { DocumentCard } from '../../components/documents-list/document-card';
import { DocumentParamProvider } from '../../providers/document-param.provider';
import { FormattedMessage, useIntl } from 'react-intl';
import * as React from 'react';
import { DocumentStatusToPageRoute } from '../../utils/document.utils';
import { routeNewInvoice, routeNewQuote } from '../../routes';
import { ServerError } from '../../components/commons/server-error';

const quote: DocumentType = 'quote';
const invoice: DocumentType = 'invoice';

type Pagination = {
  offset: number;
  limit: number;
}
const itemPerPage = 10;

export const DocumentsPage: FC = () => {
  const history = useHistory();
  const API = useApi();
  const intl = useIntl();
  const toast = useToaster();
  const { search } = useLocation();
  const [getListDocuments, setListDocuments] = useState<ListQuoteDocuments>();
  const [getLockScroll, setLockScroll] = useState<boolean>(false);
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getPagination, setPagination] = useState<Pagination>({ offset: 0, limit: itemPerPage });
  const [getHighlight, setHighlight] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<SelectOption<PartialQuoteDocument>[]>([]);
  const documentTypeOptions = [
    {
      value: undefined,
      label: intl.formatMessage({ id: 'documentsLabelAll', defaultMessage: 'Tous les documents' }),
    },
    {
      value: quote,
      label: intl.formatMessage({ id: 'documentsLabelQuotes', defaultMessage: 'Tous les devis' }),
    },
    {
      value: invoice,
      label: intl.formatMessage({ id: 'documentsLabelInvoices', defaultMessage: 'Toutes les factures' }),
    },
  ];
  const [filter, setFilter] = useState<SelectOption<undefined | DocumentType>>(documentTypeOptions[0]);

  useEffect(() => {
    const highlight = new URLSearchParams(search).get('highlight');
    if (!highlight) {
      return;
    }
    setHighlight(highlight);
  }, [search]);

  const getSearchList = useCallback(async () => {
    let result = await API.getDocuments({ search: searchValue });
    let list = result.documents;
    while (result.total > list.length) {
      result = await API.getDocuments({ search: searchValue, offset: list.length });
      list = [...list, ...result.documents];
    }
    return list;
  }, [API, searchValue]);

  useEffect(() => {
    if (!searchValue) {
      return;
    }
    setLockScroll(false);
    setPagination({ offset: 0, limit: itemPerPage });
    getSearchList()
      .then(result => {
        setSearchOptions(result.map(doc => {
          return {
            value: doc,
            label: doc.id + ' ' + (doc.recipient.companyName || doc.recipient.lastName),
          };
        }));
      });
  }, [searchValue, API, getSearchList]);

  useEffect(() => {
    setIsLoading(true);
    setLockScroll(false);
    setPagination({ offset: 0, limit: itemPerPage });
    API.getDocuments({ type: filter.value }).then(result => {
      setListDocuments(result);
    }).catch(() => setError(true)).finally(() => setIsLoading(false));
  }, [API, filter.value]);

  const onScrollDown = () => {
    if (!getListDocuments) {
      return;
    }
    setLockScroll(true);
    if (getPagination.offset + itemPerPage > getListDocuments.total) {
      return;
    }
    const pagi = {
      offset: getPagination.offset + itemPerPage,
      limit: itemPerPage,
    };
    setPagination(pagi);
    setLoading(true);
    API.getDocuments({ ...pagi, type: filter.value }).then((result) => {
      setListDocuments({
        ...result,
        documents: [...getListDocuments.documents, ...result.documents],
      });
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' },
    ))).finally(() => {
      setLoading(false);
      setLockScroll(false);
    });
  };

  return <PageWrapper isLoading={isLoading}>
    <PageWrapperSubHeader>
      <DocumentsFilter options={documentTypeOptions}
                       onSelect={(filter) => setFilter(filter)}
                       value={filter}
                       onSearch={(search) => setSearchValue(search)}
                       searchOptions={searchOptions}
                       onSearchClose={() => setSearchOptions([])}
                       onSearchSelect={(v) => history.push(`/${DocumentStatusToPageRoute(v.value.status, v.value.type)}/document/${v.value.id}`)}
      />
    </PageWrapperSubHeader>
    <DocumentParamProvider>
      {!error ? <ScrollWindowPagination
        onNext={onScrollDown}
        lock={getLockScroll}
        isLoading={getLoading}
        moreResultLabel={intl.formatMessage({ id: 'moreResultLabel', defaultMessage: '+ de résultat' })}>
        <Container size={'lg'}>
          <Row justifyContent={['space-between']} style={{ paddingTop: '15px', paddingBottom: '15px' }}
               alignItems={['center']}>
            <Col>
              <PageTitle><FormattedMessage id={'documentsLabelAll'} defaultMessage={'Tous les documents'} /></PageTitle>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Row>
                <Col>
                  <Button colorType={'primary'} icon={'page-plus'} onClick={() => history.push(routeNewQuote)}>
                    <FormattedMessage id={'documentsPageAddQuoteButtonLabel'} defaultMessage={'Ajouter un devis'} />
                  </Button>
                </Col>
                <Col>
                  <Button colorType={'primary'} icon={'page-plus'} onClick={() => history.push(routeNewInvoice)}>
                    <FormattedMessage id={'documentsPageAddInvoiceButtonLabel'}
                                      defaultMessage={'Ajouter une facture'} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {getListDocuments?.documents && getListDocuments.documents.map(doc => <DocumentCard
            highlighted={getHighlight === doc.id}
            key={doc.id} doc={doc} />)}
          {getListDocuments?.documents && !getListDocuments.documents.length && <p><SpanBody1>
            <FormattedMessage id={'documentsPageEmpty'} defaultMessage={'Aucun document disponible'} />
          </SpanBody1></p>}
        </Container>
      </ScrollWindowPagination> : <ServerError />}
    </DocumentParamProvider>
  </PageWrapper>;
};
