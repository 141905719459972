import { Product, ReferentialProduct } from '../interface/product.types';
import { Locale } from '../providers/intl.provider';

export const ProductToProductReferential = (product: Product,locale:Locale): ReferentialProduct => {
  return {
    name: product.name,
    taxes: product.taxes,
    type: product.productType,
    description: product.description,
    code: product.productCode,
    price: product.price,
    locale
  };
};
export const UpdateProductFromReferential = (product: Product, referential: ReferentialProduct): Product => {
  return {
    ...product,
    name: referential.name,
    taxes: referential.taxes,
    productType: referential.type,
    description: referential.description,
    productCode: referential.code,
    price: referential.price,
  };
};
export const IsSameProductAsReferential = (product: Product, referential: ReferentialProduct): boolean => {
  let same = true;
  if (referential.price !== product.price) {
    same = false;
  }
  if (referential.name !== product.name) {
    same = false;
  }
  if (referential.type !== product.productType) {
    same = false;
  }
  if (referential.code !== product.productCode) {
    same = false;
  }
  if (referential.description !== product.description) {
    same = false;
  }
  if (referential.taxes.some((t, i) => t.rate !== product.taxes[i].rate)) {
    same = false;
  }
  return same;
};