import { FC, useEffect, useState } from 'react';
import { PageWrapper } from '../../components';
import { Container, ImageValue, PageTitle, useToaster } from '@linkeo.com/ui-lib-react';
import { Company } from '../../interface/company.types';
import { CompanyForm } from '../../components/company/company.form';
import { useApi } from '../../providers/api-provider';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeHome } from '../../routes';
import { getMessage, IntlProvider, Locale } from '../../providers/intl.provider';
import { ServerError } from '../../components/commons/server-error';

export const CompanyParamPage: FC = () => {
  const [getCompany, setCompany] = useState<Company>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const API = useApi();
  const toast = useToaster();
  const history = useHistory();
  const intl = useIntl();
  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([API.getCompany(), API.getDocumentParam()]).then(([c, p]) => {
      setLocale(p.locale);
      if (c.address.addressLines.length > 0) {
        setCompany(c);
      } else {
        setCompany({
            ...c,
            address: {
              ...c.address,
              addressLines: [''],
            },
          },
        );
      }
    }).catch(e => {
      console.error(e);
      setError(true);
    }).finally(() => setIsLoading(false));
  }, [API]);

  const submit = (company: Company, logo: ImageValue) => {
    if (logo.binary) {
      API.postFile(logo.binary, '/company/logo').then((picture) => {
        API.putCompany({
          ...company,
          logo: picture.absoluteUrl,
        }).then(result => {
          setCompany(result);
          history.push(routeHome);
        }).catch(() => toast(intl.formatMessage({
          id: 'errorServerMessage',
          defaultMessage: 'Une erreur est survenue',
        })));
      });
    } else {
      API.putCompany(company).then(result => {
        setCompany(result);
        history.push(routeHome);
      }).catch(() => toast(intl.formatMessage({
        id: 'errorServerMessage',
        defaultMessage: 'Une erreur est survenue',
      })));
    }
  };

  return <PageWrapper isLoading={isLoading}>
    {!error && locale ? <IntlProvider locale={locale} messages={getMessage(locale)}>
      <Container size={'lg'}>
      <PageTitle style={{ marginBottom: '20px' }}>
        <FormattedMessage id={'companyParamTitle'} defaultMessage={'Paramètres de l’entreprise'} />
      </PageTitle>
      {getCompany ?
        <CompanyForm company={getCompany} onSubmit={submit} locale={locale} />
        : null}
    </Container></IntlProvider> : <ServerError />}
  </PageWrapper>;
};