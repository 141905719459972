import axios from 'axios';
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

export interface SSOData {
  refresh_token?: string | null;
  token: string;
  expires: number;
  codeBouton: string
}

const defaultSSOData: SSOData = {
  token: '',
  refresh_token: '',
  expires: 0,
  codeBouton: ''
}

interface AuthProviderProps {
  onTokenChange: (token: string) => void;
  ssoLogin: string;
  ssoScope: string;
  ssoCheck: string;
  ssoClientSecret: string;
  ssoClientId: string
}

const STORAGE_KEY = 'sso_token';

interface SSORessourceResponse {
  access_token: string;
  client_id: string;
  expires: number;
  scope: string;
  user_id: string;
}

interface SSORefreshResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: 'Bearer';
}

class ssoApi {
   constructor(private ssoUri: string) {
   }

   getResource(token: string, scope: string): Promise<SSORessourceResponse> {
     const params = new URLSearchParams();
     params.append('access_token', token);
     params.append('scope', scope);
     return axios.post<SSORessourceResponse>(this.ssoUri + '/resource', params, {
       headers: {'Content-Type': 'application/x-www-form-urlencoded'}
     }).then(resp => resp.data)}

  refresh(clientId: string, clientSecret: string, refreshToken: string): Promise<SSORefreshResponse> {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);
    params.append('refresh_token', refreshToken);
    return axios.post<SSORefreshResponse>(this.ssoUri + '/token', params, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    }).then(resp => resp.data)
  }
}

const getStoredData = (): SSOData | null => {
  const storedToken = sessionStorage.getItem(STORAGE_KEY);
  if (!storedToken){
    return null
  }
  return JSON.parse(storedToken)
}

const isExpired = (data: SSOData) : boolean => {
  return (data.expires * 1000 - 10000) < Date.now()
}

const getQueryParams = (): {token: string | null, refresh_token: string | null} => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const refresh_token = urlParams.get('refresh_token');
  return { token, refresh_token }
}

const AuthContext = createContext<SSOData>(defaultSSOData);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<AuthProviderProps> = ({ children, ssoClientSecret, ssoClientId, ssoCheck, ssoScope, onTokenChange }) => {
  const [getData, setData] = useState<SSOData | null>(null);
  const interval = useRef<number | null>(null)
  const ssoAPI = useMemo(() => new ssoApi(ssoCheck), [ssoCheck])

  const queryParams = getQueryParams();

  const refresh = useCallback((refreshToken: string, codeBouton: string) : Promise<SSOData> => {
    return ssoAPI.refresh(ssoClientId, ssoClientSecret, refreshToken)
      .then((data) => {
        return {
          token: data.access_token,
          expires: data.expires_in + (Date.now()/1000),
          refresh_token: data.refresh_token,
          codeBouton
        }
      })
  }, [ssoAPI, ssoClientId, ssoClientSecret])

  const updateToken = useCallback((SSOData: SSOData) => {
    onTokenChange(SSOData.token);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(SSOData));
    if (interval.current) {
      clearTimeout(interval.current)
    }
    interval.current = window.setTimeout(() => {
      refresh(SSOData.refresh_token || '', SSOData.codeBouton).then((tokenData) => {
        updateToken(tokenData)
      })
    }, (SSOData.expires * 1000 - 10000) - Date.now());
    setData(SSOData)
  }, [onTokenChange, refresh])

  useEffect(() => {
    const storedData = getStoredData();
    if (!storedData && (!queryParams.refresh_token || !queryParams.token)) {
      return window.location.replace(ssoCheck)
    } else if (queryParams.refresh_token && queryParams.token) {
      ssoAPI.getResource(queryParams.token, ssoScope)
        .then(resp => {
          updateToken({
            token: resp.access_token,
            expires: resp.expires,
            codeBouton: resp.user_id,
            refresh_token: queryParams.refresh_token
          })
        }).catch(() => {
          return window.location.replace(ssoCheck);
      })
    } else if (storedData) {
      if (isExpired(storedData) && storedData.refresh_token) {
        refresh(storedData.refresh_token, storedData.codeBouton)
          .then(token => updateToken(token))
          .catch(() => {
            return window.location.replace(ssoCheck)
          })
      } else {
        updateToken(storedData)
      }
    }
    return
  }, [queryParams.refresh_token, queryParams.token, refresh, ssoAPI, ssoCheck, ssoScope, updateToken]);

  return <AuthContext.Provider
    value={getData || defaultSSOData}>
    {getData ? children : null}
  </AuthContext.Provider>;
};
