import { FC, PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  attributeName: string;
  rootRef?: HTMLDivElement | null;
}

export const Portal: FC<PropsWithChildren<PortalProps>> = ({
  children,
  attributeName,
  rootRef,
}) => {
  const [getContainer, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = document.createElement('div');
    container.setAttribute(attributeName, 'true');
    if (rootRef) {
      rootRef.after(container);
    } else {
      document.body.appendChild(container);
    }
    setContainer(container);
    return () => {
      container.remove();
      setContainer(null);
    };
  }, [attributeName, rootRef]);
  return getContainer ? ReactDOM.createPortal(children, getContainer) : null;
};
