import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey80};
  border-radius: 4px;
  height: 8px;
  width: 100%;
  position: relative;
`;
const Bar = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.primary};
  width: 0;
  transition: width 200ms ease-in-out;
  height: 100%;
  border-radius: 4px;
`;

interface ProgressBarProps {
  color?: string;
  /**
   * Pourcentage de la progression
   */
  value: number;
}

export const ProgressBar: FC<ProgressBarProps> = props => {
  const { value, color } = props;
  return (
    <Wrapper>
      <Bar style={{ width: value + '%' }} bgColor={color} />
    </Wrapper>
  );
};
