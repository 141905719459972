import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActivityForm } from '../../../components/activity/activity-form';
import { PageWrapper } from '../../../components';
import { Container } from '@linkeo.com/ui-lib-react';
import { useApi } from '../../../providers/api-provider';
import { Activity } from '../../../interface/activity.types';
import { Error404 } from '../../../components/commons/404-error';

export const ActivityPage: FC = () => {
  const { categoryId, activityId } = useParams<{ categoryId: string, activityId: string }>();
  const [getActivity, setActivity] = useState<Activity>({
    id: 'NEW',
    name: '',
    description: '',
    questions: [],
    minPrice: null,
    maxPrice: null,
    active: true,
    type: 'CUSTOMER',
  });
  const [getGlobalLoading, setGlobalLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const API = useApi();

  useEffect(() => {
    if (activityId.startsWith('NEW')) {
      setGlobalLoading(false);
      return;
    }
    API.getActivity(categoryId, activityId).then(result => {
      setActivity(result);
    }).catch(e => {
      console.error(e);
      setError(true)
    }).finally(() => {
      setGlobalLoading(false);
    });
  }, [API, categoryId, activityId]);

  return <PageWrapper isLoading={getGlobalLoading}>
    {!error ? <Container size={'lg'}>
      <ActivityForm categoryId={categoryId} activity={getActivity} />
    </Container> : <Error404 />}
  </PageWrapper>;
};