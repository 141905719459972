import * as React from 'react';
import { CSSProperties, DragEvent, FC, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  ImageValue,
  Shapes,
  SpanBody1,
  SpanH2,
  SpanLink,
  SpanSubtitle1,
  ThemeType,
} from '../../common';
import { SquareButton } from '../square-button';
import { ReadFileToDataUrl } from '../../helpers';
import { BaseInputImage } from '../base-input-image';
import { Flex } from '../flex';
import { InOut } from '../in-out';
import { Svg } from '../svg';
import { Button } from '../button';

interface ImageBlockUploaderProps {
  accept?: string;
  buttonLabel: string;
  hasError?: boolean;
  /**
   * default 300px
   */
  height?: string;
  isLoading?: boolean;
  /**
   * in megabyte default 2mb
   */
  maxSize?: number;
  middleLabel?: string;
  onChange: (val: ImageValue) => void;
  onError: (reason: any) => void;
  small?: boolean;
  titleLabel?: string;
  value: ImageValue;
  /**
   * default 650px
   */
  width?: string;
  previewStyle?: CSSProperties;
}

const Wrapper = styled.div<{ width?: string; height?: string; redBorder?: boolean }>`
  width: 100%;
  max-width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '300px'};
  position: relative;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.box.borderRadius};
  border: 1px solid
    ${({ redBorder, theme }) => (redBorder ? theme.colors.danger : theme.colors.grey90)};
  text-align: center;

  &:focus-within {
    ${Shapes.shadow2}
  }
`;
const Preview = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: #ccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
`;
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey90};
  box-sizing: border-box;
  position: absolute;
  border-radius: inherit;
  width: 100%;
  height: 100%;
`;
export const ImageBlockUploader: FC<ImageBlockUploaderProps> = props => {
  const {
    value,
    onChange,
    buttonLabel,
    titleLabel,
    middleLabel,
    height,
    width,
    hasError,
    maxSize,
    onError,
    isLoading,
    small,
    accept,
    previewStyle,
  } = props;
  const [getLocal, setLocal] = useState<string>('');

  const emitDelete = () => {
    onChange({ stringFormat: '' });
    setLocal('');
  };
  const onDropImage = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const data = event.dataTransfer.files;
    if (!(data && data.length)) {
      return;
    }
    const file = data[0];
    ReadFileToDataUrl(file, maxSize)
      .then(result => {
        onChange({
          stringFormat: result as string,
          binary: file,
        });
      })
      .catch(error => onError(error));
  };
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Wrapper
      redBorder={hasError}
      onDragOver={e => e.preventDefault()}
      onDrop={onDropImage}
      width={width}
      height={height}
    >
      <Container>
        <Flex
          style={{ flexDirection: 'column', width: '100%', height: '100%' }}
          alignItems={['center']}
          justifyContent={['center']}
        >
          <Flex alignItems={['center']} justifyContent={['center']}>
            <div style={{ marginRight: '5px' }}>
              <Svg
                icon={'image'}
                fill={theme.colors.grey40}
                width={small ? 32 : 48}
                height={small ? 32 : 48}
              />
            </div>
            <SpanH2
              style={{
                color: theme.colors.grey40,
                fontSize: small ? 24 : 36,
              }}
            >
              {titleLabel}
            </SpanH2>
          </Flex>
          <div style={{ marginBottom: 10 }}>
            {small ? (
              <SpanSubtitle1>{middleLabel}</SpanSubtitle1>
            ) : (
              <SpanBody1 style={{ color: theme.colors.grey40 }}>{middleLabel}</SpanBody1>
            )}
          </div>
          <label>
            <BaseInputImage
              maxSize={maxSize}
              onChange={onChange}
              onError={onError}
              onLocalChange={setLocal}
              accept={accept}
              local={getLocal}
              style={{ opacity: 0, position: 'absolute', width: 0, height: 0 }}
            />
            {small ? (
              <SpanLink>{buttonLabel}</SpanLink>
            ) : (
              <Button as={'div'} colorType={'secondary'}>
                {buttonLabel}
              </Button>
            )}
          </label>
        </Flex>
      </Container>
      <InOut show={!!value.stringFormat} startTriggering>
        <Preview style={{ ...previewStyle, backgroundImage: `url(${value.stringFormat})` }}>
          <Flex justifyContent={['flex-end']} style={{ padding: small ? '5px' : '10px' }}>
            <SquareButton icon={'cross'} small={small} onClick={emitDelete} />
          </Flex>
        </Preview>
      </InOut>
      <InOut show={isLoading || false}>
        <Container>
          <Flex
            style={{ width: '100%', height: '100%' }}
            alignItems={['center']}
            justifyContent={['center']}
          >
            <Svg stroke={theme.colors.dark} animatedIcon={'spinner'} />
          </Flex>
        </Container>
      </InOut>
    </Wrapper>
  );
};
