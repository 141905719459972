import * as React from 'react';
import { ChangeEvent, useContext } from 'react';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import styled, { ThemeContext } from 'styled-components';
import { Svg } from '../svg';
import { Shapes, SpanBody1, ThemeType } from '../../common';

export interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'label'>,
    LabelWrapperProps {
  onChange: (val: boolean, originalEvent: ChangeEvent) => void;
  value: boolean;
  children?: React.ReactNode;
}

const InvisibleInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;
const Label = styled.label`
  display: inline-block;
  white-space: nowrap;
`;
const Square = styled.span<{
  checked: boolean;
  right?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}>`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 23px;
  height: 23px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${({ checked, theme }): string =>
    checked ? theme.colors.primary : theme.colors.grey95};
  border: 1px solid
    ${({ checked, theme, hasError }) =>
      hasError ? theme.colors.danger : checked ? theme.colors.primary : theme.colors.grey80};
  overflow: hidden;
  transition: background-color 200ms ease-in-out;

  svg {
    position: relative;
    top: -1px;
    left: -2px;
    transform: scale(1.4, 1.4);
    transition: opacity 200ms ease-in-out;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
  }

  ${Label}:focus-within & {
    ${({ disabled }) => (disabled ? '' : Shapes.shadow1)};
    border-color: ${({ theme }) => theme.colors.grey40};
  }
`;

const Title = styled(SpanBody1)<{ checked: boolean; disabled?: boolean }>`
  display: inline-block;
  white-space: normal;
  transition: color ease-in-out 200ms;
  color: ${({ checked, theme }) => (checked ? theme.colors.grey10 : theme.colors.grey60)};

  ${Label}:hover & {
    ${({ disabled, theme }) => (disabled ? '' : 'color: ' + theme.colors.grey40)};
  }
`;
export const Checkbox: React.FC<CheckboxProps> = props => {
  const theme = useContext<ThemeType>(ThemeContext);
  const {
    onChange,
    value,
    children,
    label,
    right,
    hasError,
    caption,
    disabled,
    required,
    small,
    ...rest
  } = props;
  const changeCheckbox = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked, event);
  };
  const renderTitle = (
    <Title
      checked={value}
      disabled={disabled}
      style={
        right
          ? {
              textAlign: 'right',
              marginRight: '5px',
            }
          : {
              textAlign: 'left',
              marginLeft: '5px',
            }
      }
    >
      {children}
    </Title>
  );
  return (
    <LabelWrapper {...{ label, caption, required, hasError, right, disabled, small }}>
      <Label>
        <InvisibleInput
          {...(rest as any)}
          {...{ disabled, required }}
          onChange={changeCheckbox}
          checked={value}
          type={'checkbox'}
        />
        {right && renderTitle}
        <Square checked={value} hasError={hasError} right={right} disabled={disabled}>
          <Svg
            fill={theme.colors.light}
            width={24}
            height={24}
            icon={theme.icons === 'back' ? 'check' : 'checkmark-outline'}
          />
        </Square>
        {!right && renderTitle}
      </Label>
    </LabelWrapper>
  );
};
