import * as React from 'react';
import { DropdownMask } from './dropdown.styled';
import { DropdownProps } from './dropdown.types';
import { InOut } from '../in-out';
import { Keyframes } from '../../common';
import { FC, useEffect, useState } from 'react';

export const Dropdown: FC<DropdownProps> = props => {
  const { fulfilled, children, onClose, wrapperRef, right, show, offset } = props;
  const [getWrapperHeight, setWrapperHeight] = useState<number>(0);
  const [getDirection, setDirection] = useState<'top' | 'bottom'>('top');
  useEffect(() => {
    if (!wrapperRef) {
      return;
    }
    const elem = wrapperRef.current as HTMLDivElement;
    const onScroll = () => {
      const rect = elem.getBoundingClientRect();
      const ratio = rect.y / window.innerHeight;
      const limit = 0.4;
      if (ratio < -0.5 || ratio > 1.5) {
        if (show) {
          onClose();
        }
      } else if (ratio > limit && getDirection === 'top') {
        setDirection('bottom');
      } else if (ratio <= limit && getDirection === 'bottom') {
        setDirection('top');
      }
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [wrapperRef, getDirection, onClose, show]);
  useEffect(() => {
    const cleanUp = () => {
      window.removeEventListener('click', autoClose);
      if (!wrapperRef.current) {
        return;
      }
      wrapperRef.current.removeEventListener('keydown', keyPress, true);
    };
    const autoClose = (event: MouseEvent) => {
      if (!wrapperRef.current) {
        return;
      }
      const path = event.composedPath();
      if (!path.includes(wrapperRef.current)) {
        onClose();
      }
    };
    const keyPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab' || event.code === 'Tab' || event.which === 9) {
        setTimeout(() => {
          if (!wrapperRef.current) {
            return;
          }
          const stillBlurInto = wrapperRef.current.contains(document.activeElement);
          if (!stillBlurInto) {
            onClose();
          }
        }, 1);
        return;
      }
      if (event.key === 'Escape' || event.code === 'Escape' || event.which === 27) {
        onClose();
      }
    };
    if (wrapperRef.current) {
      wrapperRef.current.style.position = 'relative';
      setWrapperHeight(wrapperRef.current.clientHeight);
      if (show) {
        window.addEventListener('click', autoClose);
        wrapperRef.current.addEventListener('keydown', keyPress, true);
      } else {
        cleanUp();
      }
    }
    return () => {
      cleanUp();
    };
  }, [onClose, wrapperRef, show]);

  return (
    <DropdownMask
      style={{ [getDirection]: `${getWrapperHeight - (offset || 0)}px` }}
      fulfilled={fulfilled}
      right={right}
    >
      <div style={{ pointerEvents: 'initial' }}>
        <InOut
          show={show}
          enter={{ keyframes: Keyframes.slideInFromTop }}
          exit={{ keyframes: [], options: { duration: 1 } }}
        >
          {children}
        </InOut>
      </div>
    </DropdownMask>
  );
};
