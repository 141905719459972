import { FC, FormEvent, useEffect, useState } from 'react';
import {
  Card,
  CardTitle,
  Checkbox,
  Col,
  ImageValue,
  InOut,
  Input,
  InputImage,
  InputNumber,
  Row,
  Textarea,
} from '@linkeo.com/ui-lib-react';
import { useHistory } from 'react-router-dom';
import { Address, Company, LegalInformations } from '../../interface/company.types';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { FormFooter } from '../ui/form-footer';
import { AddressLinesFields } from './address-lines-fields';
import {
  AddressErrors,
  BankInformationsErrors,
  getInvalidSender,
  LegalInformationErrors,
  SenderErrors,
} from '../../utils/quote-document-validator';
import { BankInfoFields } from './bank-info.fields';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeHome } from '../../routes';
import { Locale } from '../../providers/intl.provider';

interface CompanyFormProps {
  company: Company;
  onSubmit: (company: Company, logo: ImageValue) => void;
  locale: Locale;
}

export const CompanyForm: FC<CompanyFormProps> = props => {
  const { company, onSubmit, locale } = props;
  const intl = useIntl();
  const history = useHistory();
  const [getFormControl, setFormControl] = useState<Company>(company);
  const [getLogo, setLogo] = useState<ImageValue>({
    stringFormat: company.logo,
  });
  const [getErrors, setErrors] = useState<SenderErrors>({});
  const [getImageError, setImageError] = useState<boolean>(false);

  useEffect(() => {
    setErrors(getInvalidSender(getFormControl, locale));
  }, [getFormControl, locale]);

  useEffect(() => {
    setFormControl(company);
    setLogo({
      stringFormat: company.logo,
    });
  }, [company]);

  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (Object.keys(getErrors).length) {
      return;
    }
    onSubmit(getFormControl, getLogo);
  };

  const changeLegalInformationValue = function <T extends keyof LegalInformations>(key: T, value: LegalInformations[T]) {
    setFormControl(
      UpdateItemInObject(getFormControl, 'legalInformations',
        UpdateItemInObject(getFormControl.legalInformations, key, value),
      ),
    );
  };

  const changeAddressValue = function <T extends keyof Address>(key: T, value: Address[T]) {
    setFormControl(
      UpdateItemInObject(getFormControl, 'address',
        UpdateItemInObject(getFormControl.address, key, value),
      ),
    );
  };

  useEffect(() => {
    setImageError(false);
  }, [getLogo]);

  return <Card>
    <form onSubmit={emitSubmit}>
      <CardTitle>
        {intl.formatMessage({ id: 'youCompany', defaultMessage: 'Votre entreprise' })}
      </CardTitle>
      <Row>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.name}
            hasError={!!getErrors.name}
            required
            label={intl.formatMessage({ id: 'companyName', defaultMessage: 'Nom de l’entreprise' })}
            onChange={v => setFormControl(UpdateItemInObject(getFormControl, 'name', v))} />
        </Col>
        <Col columns={[12, 6]}>
          <InputImage
            label={intl.formatMessage({ id: 'fieldLogoLabel', defaultMessage: 'Logo de votre entreprise' })}
            addImageLabel={intl.formatMessage({ id: 'addImage', defaultMessage: 'Ajouter une image' })}
            replaceImageLabel={intl.formatMessage({ id: 'replaceImage', defaultMessage: 'Remplacer l’image' })}
            removeImageLabel={intl.formatMessage({ id: 'removeImage', defaultMessage: 'Supprimer l’image' })}
            caption={getImageError ?
              intl.formatMessage({
                id: 'fieldLogoSizeError',
                defaultMessage: 'Accepte les images jpg,png de 2mb max',
              }) : intl.formatMessage({
                id: 'fieldLogoCaption',
                defaultMessage: 'Ce logo sera affiché en en-tête de vos devis',
              })}
            onError={() => setImageError(true)}
            hasError={getImageError}
            value={getLogo} onChange={setLogo} />
        </Col>
      </Row>
      <CardTitle>
        {intl.formatMessage({ id: 'address', defaultMessage: 'Adresse' })}
      </CardTitle>
      <Row>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.address.country}
            label={intl.formatMessage({ id: 'country', defaultMessage: 'Pays' })}
            hasError={getErrors.address && !!(getErrors.address as AddressErrors).country}
            required
            onChange={v => changeAddressValue('country', v)} />
        </Col>
        <Col columns={[12, 6]}>
          <AddressLinesFields
            addressLineErrors={getErrors.address && (getErrors.address as AddressErrors).addressLines}
            addressLines={getFormControl.address.addressLines}
            onChange={v => changeAddressValue('addressLines', v)} />
        </Col>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.address.postalCode}
            hasError={getErrors.address && !!(getErrors.address as AddressErrors).postalCode}
            required
            label={intl.formatMessage({ id: 'postalCode', defaultMessage: 'Code postal' })}
            onChange={v => changeAddressValue('postalCode', v)} />
        </Col>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.address.city}
            hasError={getErrors.address && !!(getErrors.address as AddressErrors).city}
            required
            label={intl.formatMessage({ id: 'city', defaultMessage: 'Ville' })}
            onChange={v => changeAddressValue('city', v)} />
        </Col>
      </Row>
      <CardTitle>
        {intl.formatMessage({ id: 'coordinates', defaultMessage: 'Coordonnées' })}
      </CardTitle>
      <Row>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.telephone}
            hasError={!!getErrors.telephone}
            required
            label={intl.formatMessage({ id: 'telephone', defaultMessage: 'Téléphone' })}
            onChange={v => setFormControl(UpdateItemInObject(getFormControl, 'telephone', v))} />
        </Col>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.email}
            hasError={!!getErrors.email}
            required
            label={intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' })}
            onChange={v => setFormControl(UpdateItemInObject(getFormControl, 'email', v))} />
        </Col>
      </Row>
      <CardTitle>
        {intl.formatMessage({ id: 'legalInformation', defaultMessage: 'Informations légales' })}
      </CardTitle>
      <Row>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.legalInformations.businessName}
            hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).businessName}
            onChange={v => changeLegalInformationValue('businessName', v)}
            required={locale === 'fr-FR'}
            label={intl.formatMessage({
              id: 'companyFormBusinessNameLabel',
              defaultMessage: 'Raison sociale de votre entreprise',
            })} />
        </Col>
        <Col columns={[12, 6]}>
          <Input
            value={getFormControl.legalInformations.legalStatus}
            hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).legalStatus}
            required={locale === 'fr-FR'}
            label={intl.formatMessage({ id: 'legalStatus', defaultMessage: 'Statut juridique (SARL, EURL...)' })}
            onChange={v => changeLegalInformationValue('legalStatus', v)} />
        </Col>

        {locale === 'fr-FR' && <><Col columns={[12, 6]}>
          <InputNumber
            step={1}
            min={0}
            value={getFormControl.legalInformations.capitalShare}
            hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).capitalShare}
            required
            label={intl.formatMessage({ id: 'capitalShare', defaultMessage: 'Capital' })}
            onChange={v => changeLegalInformationValue('capitalShare', Math.round(v || 0))} />
        </Col>
          <Col columns={[12, 6]}>
            <Input
              value={getFormControl.legalInformations.apeCode}
              hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).apeCode}
              maxLength={5}
              required
              label={intl.formatMessage({ id: 'ApeCode', defaultMessage: 'APE/NAF' })}
              onChange={v => changeLegalInformationValue('apeCode', v)} />
          </Col></>}

      </Row>
      <CardTitle>
        <FormattedMessage id={'SIRET'} defaultMessage={'SIRET'} />
      </CardTitle>
      <Row>
        <Col columns={[12, 6]} style={{ paddingTop: '38px', paddingBottom: '13px' }}>
          <Checkbox onChange={v => changeLegalInformationValue('awaitingRegistration', v)}
                    value={getFormControl.legalInformations.awaitingRegistration}>
            <FormattedMessage id={'legalInfoFormRegistrationCheckbox'} defaultMessage={'Immatriculation en cours ?'} />
          </Checkbox>
        </Col>
        <Col columns={[12, 6]}>
          <InOut show={!getFormControl.legalInformations.awaitingRegistration}>
            <Input
              value={getFormControl.legalInformations.siret}
              inputMode={'numeric'}
              required={locale === 'fr-FR'}
              hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).siret}
              minLength={locale === 'fr-FR' ? 14 : undefined}
              maxLength={locale === 'fr-FR' ? 14 : undefined}
              label={intl.formatMessage({ id: 'SIRET', defaultMessage: 'SIRET' })}
              onChange={v => changeLegalInformationValue('siret', v)} />
          </InOut>
        </Col>
      </Row>
      {locale !== 'en-AU' && <><CardTitle>
        <FormattedMessage id={'TVA'} defaultMessage={'TVA'} />
      </CardTitle>
        <Row>
          <Col columns={[12, 6]} style={{ paddingTop: '38px', paddingBottom: '13px' }}>
            <Checkbox
              value={getFormControl.legalInformations.isSubjectToVat}
              onChange={v => changeLegalInformationValue('isSubjectToVat', v)}
            >
              {intl.formatMessage({ id: 'companyFormIsSubjectToVatLabel', defaultMessage: 'Je facture la TVA' })}
            </Checkbox>
          </Col>
          <Col columns={[12, 6]}>
            <InOut show={getFormControl.legalInformations.isSubjectToVat}>
              <Input
                required={locale === 'fr-FR'}
                hasError={getErrors.legalInformations && !!(getErrors.legalInformations as LegalInformationErrors).vatNumber}
                value={getFormControl.legalInformations.vatNumber}
                label={intl.formatMessage({ id: 'numTVAIntra', defaultMessage: 'N° de TVA intracommunautaire' })}
                placeholder={locale.endsWith('US') ? intl.formatMessage({
                  id: 'numTVAIntraPlaceholderUS',
                  defaultMessage: 'SST: xxx / CST: xxx / CTY: xxx / DST: xxx',
                }) : ''}
                onChange={v => changeLegalInformationValue('vatNumber', v)} />
            </InOut>
          </Col>
        </Row></>
      }
      <BankInfoFields
        bankInformations={getFormControl.bankInformations}
        locale={locale}
        onChange={v => setFormControl({ ...getFormControl, bankInformations: v })}
        errors={(getErrors.bankInformations as BankInformationsErrors) || {}} />
      <CardTitle>
        {intl.formatMessage({ id: 'legalNotice', defaultMessage: 'Mentions légales' })}
      </CardTitle>
      <Textarea
        value={getFormControl.legalInformations.legalNotice || ''}
        onChange={v => changeLegalInformationValue('legalNotice', v)}
        label={intl.formatMessage({
          id: 'companyFormLegalMentionLabel',
          defaultMessage: 'Ajoutez vos mentions légales ici si nécessaire',
        })} />
      <FormFooter onCancel={() => history.push(routeHome)} />
    </form>
  </Card>;
};