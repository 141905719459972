import { FC, useEffect, useState } from 'react';
import { Card, Col, ImageBlockUploader, ImageValue, Row, SpanCaption1, useToaster } from '@linkeo.com/ui-lib-react';
import { QuoteDocument } from '../../interface/document.type';
import styled from 'styled-components';
import { TotalDisplay } from '../total/total-display';
import { DocumentItems } from '../documents-list/document-items';
import { RecipientForm } from '../recipient/recipient.form';
import { DocumentHeadForm } from './document-head-form';
import {
  hasBankInformations,
  QuoteDocumentErrors,
  RecipientErrors,
  SenderErrors,
} from '../../utils/quote-document-validator';
import { CompanyDocumentForm } from '../company/company-document.form';
import { useApi } from '../../providers/api-provider';
import { Company } from '../../interface/company.types';
import { LegalInformationDocumentForm } from '../company/legal-information-document.form';
import { BankInformationDocumentForm } from '../bank-information/bank-information-document-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMessage, IntlProvider } from '../../providers/intl.provider';

const SignatureBox = styled.div`
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    height: 100px;
    margin: 20px 0;

    span {
        color: #666;
    }
`;

interface DocumentViewProps {
  errors: QuoteDocumentErrors;
  onDocumentChange: (v: QuoteDocument) => void;
  quoteDocument: QuoteDocument;
  readOnly: boolean;
}

export const DocumentView: FC<DocumentViewProps> = props => {
  const { quoteDocument, onDocumentChange, readOnly, errors } = props;
  const intl = useIntl();
  const toast = useToaster();
  const [getImageLoading, setImageLoading] = useState<boolean>(false);
  const [getLogo, setLogo] = useState<ImageValue>({
    stringFormat: quoteDocument.sender.logo,
  });

  useEffect(() => {
    setLogo({
      stringFormat: quoteDocument.sender.logo,
    });
  }, [quoteDocument]);

  const API = useApi();
  const mergedRequestLogo = async (binary: any): Promise<Company> => {
    const picture = await API.postFile(binary, '/company/logo');
    return API.patchCompany({ logo: picture.absoluteUrl });
  };

  const updateCompanyLogo = (image: ImageValue) => {
    if (!image.stringFormat) {
      setLogo(image);
    }
    if (!image.binary) {
      return;
    }
    setImageLoading(true);
    mergedRequestLogo(image.binary).then(result => {
      setLogo({
        stringFormat: result.logo,
      });
      onDocumentChange({ ...quoteDocument, sender: result });
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }),
    )).finally(() => setImageLoading(false));
  };

  return <IntlProvider locale={quoteDocument.parameters.locale} messages={getMessage(quoteDocument.parameters.locale)}>
    <Card style={{ borderColor: quoteDocument.acceptance === 'accepted' ? '#35D759' : undefined }}>
      <Row style={{ marginBottom: '36px' }}>
        <Col columns={[7]}>
          {quoteDocument.status === 'draft' ? <ImageBlockUploader
            buttonLabel={intl.formatMessage({
              id: 'documentViewLogoButtonLabel',
              defaultMessage: 'Ajouter votre logo',
            })}
            small
            value={getLogo}
            onChange={updateCompanyLogo}
            isLoading={getImageLoading}
            onError={() => {
            }}
            previewStyle={{ backgroundSize: 'contain' }}
            height={'140px'}
            width={'140px'} /> : quoteDocument.sender.logo ?
            <img alt={'logo'} width={140} height={140} style={{ objectFit: 'contain' }}
                 src={quoteDocument.sender.logo} /> : null
          }
        </Col>
        <Col columns={[5]}>
          <DocumentHeadForm
            globalErrors={errors}
            onChange={v => onDocumentChange(v)}
            quoteDocument={quoteDocument}
            readOnly={readOnly} />
        </Col>
      </Row>
      <Row>
        <CompanyDocumentForm
          locale={quoteDocument.parameters.locale}
          senderErrors={errors.sender as SenderErrors || {}}
          onChange={v => onDocumentChange({ ...quoteDocument, sender: v })} readOnly={readOnly}
          value={quoteDocument.sender} />
        <RecipientForm
          recipientErrors={errors.recipient as RecipientErrors || {}}
          onChange={v => onDocumentChange({ ...quoteDocument, recipient: v })}
          value={quoteDocument.recipient}
          readOnly={readOnly} />
      </Row>
      <DocumentItems
        readOnly={readOnly}
        locale={quoteDocument.parameters.locale}
        items={quoteDocument.items}
        currency={quoteDocument.parameters.currency}
        total={quoteDocument.total.totalWithTax}
        onChange={v => onDocumentChange({ ...quoteDocument, items: v })} />
      <Row>
        <Col columns={[6]}>
          {quoteDocument && quoteDocument.type === 'invoice' && hasBankInformations(quoteDocument.sender.bankInformations) ?
            <IntlProvider locale={quoteDocument.parameters.locale}
                          messages={getMessage(quoteDocument.parameters.locale)}>
              <BankInformationDocumentForm
                company={quoteDocument.sender}
                locale={quoteDocument.parameters.locale}
                readOnly={readOnly}
                onChange={v => onDocumentChange({
                  ...quoteDocument,
                  sender: v,
                })}
              /></IntlProvider> : null}
        </Col>
        <Col columns={[6]}>
          <TotalDisplay
            onDiscountChange={v => onDocumentChange({ ...quoteDocument, discount: v })}
            onDepositChange={v => onDocumentChange({ ...quoteDocument, deposit: v })}
            deposit={quoteDocument?.deposit || { value: 0, type: 'amount' }}
            discount={quoteDocument?.discount || { value: 0, type: 'amount' }}
            readOnly={readOnly}
            locale={quoteDocument.parameters.locale}
            currency={quoteDocument.parameters.currency}
            total={quoteDocument.total}
            isInvoice={quoteDocument.type === 'invoice'} />
        </Col>
      </Row>
      {quoteDocument.type === 'quote' && <Row>
        <Col columns={[6]} />
        <Col columns={[6]}>
          <SignatureBox>
            <SpanCaption1>
              <FormattedMessage id={'documentViewConfirmAndSignLabel'}
                                defaultMessage={'Mention “bon pour accord” et signature'} />
            </SpanCaption1>
          </SignatureBox>
        </Col>
      </Row>}
      <Row style={{ padding: '20px 0' }}>
        <IntlProvider locale={quoteDocument.parameters.locale} messages={getMessage(quoteDocument.parameters.locale)}>
          <LegalInformationDocumentForm onChange={c => onDocumentChange({ ...quoteDocument, sender: c })}
                                        locale={quoteDocument.parameters.locale}
                                        company={quoteDocument.sender} readOnly={readOnly} />
        </IntlProvider>
      </Row>
    </Card>
  </IntlProvider>;
};