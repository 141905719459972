import { FC, FormEvent, useEffect, useState } from 'react';
import { InteractiveCol } from '../ui/interactive-col';
import { Checkbox, Col, InOut, Input, InputNumber, Row, SpanCaption1, Textarea } from '@linkeo.com/ui-lib-react';
import { Company, LegalInformations } from '../../interface/company.types';
import { FormFooter } from '../ui/form-footer';
import { getInvalidLegalInformation, LegalInformationErrors } from '../../utils/quote-document-validator';
import { useApi } from '../../providers/api-provider';
import { FormattedMessage, useIntl } from 'react-intl';
import { Locale } from '../../providers/intl.provider';

interface LegalInformationDocumentFormProps {
  company: Company;
  onChange: (v: Company) => void;
  readOnly: boolean;
  locale: Locale;
}

export const LegalInformationDocumentForm: FC<LegalInformationDocumentFormProps> = props => {
  const { readOnly, company, onChange, locale } = props;
  const [getEditMode, setEditMode] = useState<boolean>(false);
  const { legalInformations } = company;
  const [getFormControl, setFormControl] = useState<LegalInformations>(legalInformations);
  const [getErrors, setErrors] = useState<LegalInformationErrors>({});
  const [getLoading, setLoading] = useState<boolean>(false);
  const API = useApi();
  const intl = useIntl();
  const [getMessageErrors, setMessageErrors] = useState<string>('');

  useEffect(() => {
    setErrors(getInvalidLegalInformation(getFormControl, locale));
  }, [getFormControl, locale]);

  useEffect(() => {
    setFormControl(company.legalInformations);
  }, [company]);

  const onSubmitForm = (ev: FormEvent) => {
    ev.preventDefault();
    setMessageErrors('');
    if (Object.keys(getErrors).length) {
      return;
    }
    setLoading(true);
    API.patchCompany({ legalInformations: getFormControl }).then(result => {
      onChange(result);
      setEditMode(false);
    }).catch(() => {
      setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
    }).finally(() => setLoading(false));
  };

  return getEditMode ? <Col columns={[12]}>
      <form onSubmit={onSubmitForm}>
        <Row>
          <Col columns={[12, 12, 4]}>
            <Input
              label={intl.formatMessage({ id: 'legalStatus', defaultMessage: 'Statut Juridique' })}
              hasError={getErrors.legalStatus}
              required={locale === 'fr-FR'}
              value={getFormControl.legalStatus}
              small
              onChange={v => setFormControl({ ...getFormControl, legalStatus: v })} />
            <Input
              label={intl.formatMessage({
                id: 'legalInfoFormBusinessName',
                defaultMessage: 'Raison sociale de votre entreprise',
              })}
              required={locale === 'fr-FR'}
              hasError={getErrors.businessName}
              value={getFormControl.businessName}
              small
              onChange={v => setFormControl({ ...getFormControl, businessName: v })} />
            {locale === 'fr-FR' && <InputNumber
              label={intl.formatMessage({ id: 'legalInfoFormShareCapitalLabel', defaultMessage: 'Capital social' })}
              hasError={getErrors.capitalShare}
              required
              value={getFormControl.capitalShare}
              small
              step={1}
              min={0}
              onChange={v => setFormControl({ ...getFormControl, capitalShare: Math.round(v || 0) })} />}
          </Col>

          <Col columns={[12, 12, 4]} style={{ paddingTop: '12px' }}>
            <Checkbox
              value={getFormControl.awaitingRegistration}
              onChange={v => setFormControl({ ...getFormControl, awaitingRegistration: v })}>
              <FormattedMessage id={'legalInfoFormRegistrationCheckbox'} defaultMessage={'Immatriculation en cours ?'} />
            </Checkbox>
            <InOut show={!getFormControl.awaitingRegistration}><Input
              label={intl.formatMessage({ id: 'SIRET', defaultMessage: 'SIRET' })}
              inputMode={'numeric'}
              minLength={locale === 'fr-FR' ? 14 : undefined}
              maxLength={locale === 'fr-FR' ? 14 : undefined}
              hasError={getErrors.siret}
              required={locale === 'fr-FR'}
              value={getFormControl.siret}
              small
              onChange={v => setFormControl({ ...getFormControl, siret: v })} /></InOut>
            {locale === 'fr-FR' && <Input
              label={intl.formatMessage({ id: 'legalInfoFormAPELabel', defaultMessage: 'Code APE' })}
              hasError={getErrors.apeCode}
              required
              maxLength={5}
              value={getFormControl.apeCode}
              small
              onChange={v => setFormControl({ ...getFormControl, apeCode: v })} />}
          </Col>

          {locale !== 'en-AU' && <Col columns={[12, 12, 4]} style={{ paddingTop: '12px' }}>
            <Checkbox
              value={getFormControl.isSubjectToVat}
              onChange={v => setFormControl({ ...getFormControl, isSubjectToVat: v })}>
              <FormattedMessage id={'legalInfoFormTVACheckbox'} defaultMessage={'Assujetti à la TVA'} />
            </Checkbox>
            <InOut show={getFormControl.isSubjectToVat}>
              <Input
                label={intl.formatMessage({ id: 'numTVAIntra', defaultMessage: 'N° TVA intracommunautaire' })}
                hasError={getErrors.vatNumber}
                required={locale === 'fr-FR'}
                placeholder={locale.endsWith('US') ? intl.formatMessage({
                  id: 'numTVAIntraPlaceholderUS',
                  defaultMessage: 'SST: xxx / CST: xxx / CTY: xxx / DST: xxx',
                }) : ''}
                value={getFormControl.vatNumber}
                small
                onChange={v => setFormControl({ ...getFormControl, vatNumber: v })} />
            </InOut>
          </Col>}
          <Col columns={[12]}>
            <Textarea
              small
              value={getFormControl.legalNotice || ''}
              onChange={v => setFormControl({ ...getFormControl, legalNotice: v })}
              label={intl.formatMessage({ id: 'legalInfoFormLegalNoticesLabel', defaultMessage: 'Mentions légales' })} />
          </Col>
        </Row>
        <FormFooter small errorMessage={getMessageErrors} loading={getLoading} onCancel={() => setEditMode(false)}
                    disabled={!!Object.keys(getErrors).length} />
      </form>
    </Col> :
    <InteractiveCol style={{ textAlign: 'center' }} onClick={() => !readOnly && setEditMode(true)}
                    tabIndex={readOnly ? -1 : 0}
                    columns={[12]} locked={readOnly}>
      <SpanCaption1>
        {legalInformations.legalStatus || intl.formatMessage({
          id: 'legalInfoFormLegalStatusLabel',
          defaultMessage: 'Statut Juridique',
        })}&nbsp;
        {legalInformations.businessName}&nbsp;-&nbsp;
        {legalInformations.awaitingRegistration ? intl.formatMessage({
          id: 'SIRET',
          defaultMessage: 'SIRET',
        }) : intl.formatMessage({ id: 'SIRET', defaultMessage: 'SIRET' }) + legalInformations.siret}&nbsp;
        {locale !== 'fr-FR' ? null : legalInformations.apeCode ? '- ' + intl.formatMessage({
          id: 'legalInfoFormRCSLabel',
          defaultMessage: 'RCS',
        }) + ' ' + legalInformations.apeCode : '- ' + intl.formatMessage({
          id: 'legalInfoFormRCSLabel',
          defaultMessage: 'RCS',
        })}&nbsp;
        {locale === 'en-AU' ? null : legalInformations.isSubjectToVat ? '- ' + intl.formatMessage({
          id: 'numTVAIntra',
          defaultMessage: 'N° TVA intracommunautaire',
        }) + ' ' + legalInformations.vatNumber : '- ' + intl.formatMessage({
          id: 'numTVAIntra',
          defaultMessage: 'N° TVA intracommunautaire',
        })}
        {legalInformations.legalNotice ? <><br />{legalInformations.legalNotice}</> : null}
      </SpanCaption1>
    </InteractiveCol>;
};