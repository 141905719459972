import { Bubble, Copy, Header, LabelDate, Subtitle } from './bubble.styled';
import { BubbleProps } from './bubble.types';
import * as React from 'react';

export class BubbleComponent extends React.PureComponent<BubbleProps> {
  render(): React.ReactNode {
    return (
      <Bubble className={'lnk-bubble'}>
        {this.renderHeader()}
        <Copy className={'lnk-bubble__copy'}>{this.props.children}</Copy>
      </Bubble>
    );
  }

  renderDate(): React.ReactNode {
    if (this.props.date) {
      return <LabelDate className={'lnk-bubble__label-date'}>{this.props.date}</LabelDate>;
    }
  }

  renderHeader(): React.ReactNode {
    if (this.props.blockTitle || this.props.date) {
      return (
        <Header className={'lnk-bubble__header'}>
          {this.renderTitle()}
          {this.renderDate()}
        </Header>
      );
    }
  }

  renderTitle(): React.ReactNode {
    if (this.props.blockTitle) {
      return <Subtitle className={'lnk-bubble__subtitle'}>{this.props.blockTitle}</Subtitle>;
    }
  }
}
