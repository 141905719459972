import React, { FC, FormEvent, useEffect, useState } from 'react';
import { InteractiveCol } from '../ui/interactive-col';
import { DocumentAddressBox } from '../document/document-address-box';
import { Company } from '../../interface/company.types';
import { SmallCol } from '../ui/small-columns';
import { FormFooter } from '../ui/form-footer';
import { AddressErrors, getInvalidSender, SenderErrors } from '../../utils/quote-document-validator';
import { Input } from '@linkeo.com/ui-lib-react';
import { AddressSmallFields } from '../address/address-small-fields';
import { useApi } from '../../providers/api-provider';
import { useIntl } from 'react-intl';
import { Locale } from '../../providers/intl.provider';

interface CompanyDocumentFormProps {
  onChange: (v: Company) => void;
  readOnly: boolean;
  senderErrors: SenderErrors;
  value: Company;
  locale: Locale;
}

export const CompanyDocumentForm: FC<CompanyDocumentFormProps> = props => {
  const { value, onChange, readOnly, senderErrors, locale } = props;
  const [getEditMode, setEditMode] = useState<boolean>(false);
  const [getError, setError] = useState<SenderErrors>(senderErrors);
  const [getFormControl, setFormControl] = useState<Company>(value);
  const [getLoading, setLoading] = useState<boolean>(false);
  const API = useApi();
  const intl = useIntl();
  const [getMessageErrors, setMessageErrors] = useState<string>('');

  useEffect(() => {
    setError(getInvalidSender(getFormControl, locale, true));
  }, [getFormControl, locale]);

  const onSubmitForm = (ev: FormEvent) => {
    ev.preventDefault();
    setMessageErrors('');
    if (Object.keys(getError).length) {
      return
    }
    setLoading(true);
    API.patchCompany({
      name: getFormControl.name,
      telephone: getFormControl.telephone,
      email: getFormControl.email,
      address: getFormControl.address,
    }).then(updatedCompany => {
      onChange(updatedCompany);
      setEditMode(false);
    }).catch(() => {
      setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
    }).finally(() => {
      setLoading(false);
    });
  };

  return <>{getEditMode ? <SmallCol columns={[5]}>
      <form onSubmit={onSubmitForm}>
        <Input hasError={!!getError.name} small required label={intl.formatMessage({
          id: 'companyDocFormCompanyNameLabel',
          defaultMessage: 'Nom de votre entreprise',
        })}
               value={getFormControl.name}
               onChange={v => setFormControl({ ...getFormControl, name: v })} />
        <AddressSmallFields errors={(getError.address as AddressErrors) || {}} value={getFormControl.address}
                            onChange={v => setFormControl({ ...getFormControl, address: v })} />
        <Input hasError={!!getError.telephone} required small
               label={intl.formatMessage({ id: 'companyDocFormPhoneLabel', defaultMessage: 'Numéro de téléphone' })}
               type={'tel'}
               value={getFormControl.telephone}
               onChange={v => setFormControl({ ...getFormControl, telephone: v })} />
        <Input hasError={!!getError.email} required small
               label={intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' })}
               type={'email'}
               value={getFormControl.email}
               onChange={v => setFormControl({ ...getFormControl, email: v })} />
        <FormFooter errorMessage={getMessageErrors} small onCancel={() => setEditMode(false)} loading={getLoading} />
      </form>
    </SmallCol> :
    <InteractiveCol hasError={!!Object.keys(getError).length}
                    onClick={() => !readOnly && setEditMode(true)}
                    columns={[5]} tabIndex={readOnly ? -1 : 0} locked={readOnly}>
      <DocumentAddressBox
        value={{
          companyName: value.name || intl.formatMessage({
            id: 'companyDocFormCompanyNameLabel',
            defaultMessage: 'Nom de votre entreprise',
          }),
          address: value.address,
          telephone: value.telephone || intl.formatMessage({
            id: 'companyDocFormPhoneLabel',
            defaultMessage: 'Numéro de téléphone',
          }),
          email: value.email || intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' }),
        }} />
    </InteractiveCol>}<SmallCol columns={[2]} /></>;
};