import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SpanSubtitle2, ThemeType } from '../../common';
import { BaseButton } from '../base-button';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { Svg } from '../svg';
import { IconName } from '../../icons';

const SwitchContainer = styled.div<LabelWrapperProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 2px 0;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
`;
const SwitchLabelChoice = styled(SpanSubtitle2)<{
  checked?: boolean;
  small?: boolean;
  disabled?: boolean;
}>`
  transition: ease-in-out color 200ms;
  padding-top: 6px;
  color: ${({ checked, theme }) => (checked ? theme.colors.grey10 : theme.colors.grey60)};
  cursor: ${({ checked, disabled }) => (checked || disabled ? 'normal' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-weight: ${({ small }) => small && 400};
`;
const SwitchButton = styled(BaseButton)<{ active?: boolean; color?: string }>`
  border-radius: 10px;
  border: none;
  width: 40px;
  height: 20px;
  position: relative;
  transition: background-color 200ms ease-in-out;
  background-color: ${({ active, theme, color }) =>
    active ? (color ? color : theme.colors.primary) : theme.colors.grey80};

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    transition: left ease-in-out 200ms;
    left: ${({ active }): number => (active ? 22 : 2)}px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.light};
  }
`;
const IconButton = styled(Svg)`
  position: absolute;
  z-index: 2;
  margin-top: 6px;
  pointer-events: none;
`;

export interface SwitchProps extends LabelWrapperProps {
  /**
   * Texte affiché pour la position "on"
   */
  onLabel?: string;
  /**
   * Texte affiché pour la position "off"
   */
  offLabel?: string;
  value?: boolean;
  onChange?: (event: boolean) => void;
  color?: string;
  onIcon?: IconName;
  offIcon?: IconName;
}

export const Switch: React.FC<SwitchProps> = props => {
  const {
    onLabel,
    offLabel,
    caption,
    disabled,
    label,
    value,
    hasError,
    right,
    onChange,
    small,
    color,
    onIcon,
    offIcon,
  } = props;
  const theme = React.useContext<ThemeType>(ThemeContext);
  const emitChange = (newVal: boolean) => {
    if (onChange) {
      onChange(newVal);
    }
  };
  const clickOn = (event: React.MouseEvent) => {
    event.preventDefault();
    emitChange(true);
  };
  const clickOff = (event: React.MouseEvent) => {
    event.preventDefault();
    emitChange(false);
  };

  const toggleSwitch = () => {
    emitChange(!value);
  };
  const keyDown = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 37) {
      emitChange(false);
    }
    if (event.keyCode === 39) {
      emitChange(true);
    }
  };
  return (
    <LabelWrapper {...{ caption, disabled, label, hasError, right, small }}>
      <SwitchContainer
        {...{ right, disabled }}
        role={'switch'}
        aria-checked={'true'}
        id={'switch-select'}
      >
        {!!offLabel && (
          <SwitchLabelChoice
            style={{ marginRight: small ? '6px' : '12px' }}
            onClick={clickOff}
            disabled={disabled}
            checked={!value}
            small={small}
          >
            {offLabel}
          </SwitchLabelChoice>
        )}
        <div>
          {offIcon && !value && (
            <IconButton
              icon={offIcon}
              fill={color ? color : theme.colors.primary}
              width={16}
              height={16}
              style={{ opacity: 0.5, left: 2 }}
            />
          )}
          <SwitchButton
            disabled={disabled}
            active={value}
            onKeyDown={keyDown}
            onClick={toggleSwitch}
            color={color}
          />
          {onIcon && value && (
            <IconButton
              icon={onIcon}
              fill={color ? color : theme.colors.primary}
              width={16}
              height={16}
              style={{ left: 22 }}
            />
          )}
        </div>
        {!!onLabel && (
          <SwitchLabelChoice
            style={{ marginLeft: small ? '6px' : '12px' }}
            disabled={disabled}
            onClick={clickOn}
            checked={value}
            small={small}
          >
            {onLabel}
          </SwitchLabelChoice>
        )}
      </SwitchContainer>
    </LabelWrapper>
  );
};
