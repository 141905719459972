import * as React from 'react';
import { FC, ReactNode, useContext, useEffect, useRef } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { BaseButton } from '../base-button';
import { getTypo, ThemeType } from '../../common';
import { Container, ContainerProps } from '../container';
import { Flex } from '../flex';
import { focusFirstFocusableElement, focusLastFocusableElement } from '../../helpers';
import { InOut } from '../in-out';
import { Svg } from '../svg';
import { Portal } from '../portal';

const DialogContent = styled.div`
  padding: 48px;
  background-color: ${({ theme }) => theme.colors.light};
`;

const DialogFooterContainer = styled.footer`
  text-align: right;
  background-color: ${({ theme }) => theme.colors.grey10};
  color: ${({ theme }) => theme.colors.light};
  border-radius: 0 0 4px 4px;

  button {
    border-radius: 0;
  }
`;
const DialogContainer = styled.article`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  z-index: 100;
`;

const DialogHeader = styled.header<{ backgroundColor?: string }>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.primary};
  border-radius: 4px 4px 0 0;
`;

const DialogButtonClose = styled(BaseButton)<{ backgroundColor?: string }>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.primary};
  width: 40px;
  height: 40px;
  padding: 7px 0;
  border-radius: 0 4px 0 0;
`;
const DialogTitle = styled.h5`
  ${getTypo('subtitle2')};
  padding: 12px 16px;
  margin: 0;
  color: ${({ theme }) => theme.colors.light};
`;

export interface DialogProps extends ContainerProps {
  footer?: string | ReactNode;
  noBackdrop?: boolean;
  noEscape?: boolean;
  onClose: () => void;
  show: boolean;
  title: string;
  rootRef?: HTMLDivElement | null;
  children?: React.ReactNode;
  backgroundColor?: string;
}

export const Dialog: FC<DialogProps> = props => {
  const {
    show,
    children,
    onClose,
    size,
    noBackdrop,
    noEscape,
    title,
    rootRef,
    footer,
    backgroundColor,
  } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  const refDialog = useRef<HTMLDivElement>();

  const backDropClick = (event: React.MouseEvent) => {
    const target: HTMLElement = event.target as HTMLElement;
    if (noBackdrop || !target) {
      return;
    }
    if (!target.hasAttribute('data-backdrop')) {
      return;
    }
    onClose();
  };
  const keyDownEscape = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27 || event.key === 'Escape') {
      if (!noEscape) {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (rootRef) {
      rootRef.classList.add('root-ref');
    }

    const queryNotInModal = document.querySelectorAll<HTMLElement>(
      rootRef ? `.${rootRef.className}` : 'body > div:not([data-modal])'
    );

    const reset = () => {
      for (let i = 0; i < queryNotInModal.length; i++) {
        queryNotInModal[i].style.filter = '';
        queryNotInModal[i].style.transition = 'blur ease-in-out 250ms';
      }
      setTimeout(() => {
        for (let i = 0; i < queryNotInModal.length; i++) {
          queryNotInModal[i].style.transition = '';
        }
      }, 250); // after animation finish
    };
    if (show) {
      for (let i = 0; i < queryNotInModal.length; i++) {
        queryNotInModal[i].style.filter = 'blur(2px)';
        queryNotInModal[i].style.transition = 'blur ease-in-out 200ms';
      }
      setTimeout(() => {
        const el = refDialog.current;
        if (!el) {
          return;
        }
        focusFirstFocusableElement(el);
      }, 20); // timeout for let time to dom appear
    } else {
      // reset style
      reset();
    }
    return () => {
      reset();
    };
  }, [show, refDialog, rootRef]);
  const focusTrap = (last?: boolean) => {
    const el = refDialog.current as HTMLElement;
    if (!show || !el) {
      return;
    }
    if (last) {
      focusLastFocusableElement(el);
      return;
    }
    focusFirstFocusableElement(el);
  };
  return (
    <Portal attributeName={'data-modal'} rootRef={rootRef}>
      <InOut show={show}>
        <DialogContainer
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
          data-backdrop
          onClick={backDropClick}
          onKeyDown={keyDownEscape}
        >
          <div tabIndex={0} onFocus={() => focusTrap(true)} />
          <div style={{ width: '100%' }} ref={refDialog as any}>
            <Container size={size || 'lg'}>
              <DialogHeader backgroundColor={backgroundColor}>
                <Flex justifyContent={['space-between']}>
                  <DialogTitle>{title}</DialogTitle>
                  <DialogButtonClose onClick={onClose} backgroundColor={backgroundColor}>
                    <Svg
                      icon={theme.icons === 'back' ? 'cross' : 'close-fill'}
                      height={24}
                      width={24}
                      fill={theme.colors.light}
                    />
                  </DialogButtonClose>
                </Flex>
              </DialogHeader>
              <DialogContent>{children}</DialogContent>
              <DialogFooterContainer>{footer}</DialogFooterContainer>
            </Container>
          </div>
          <div tabIndex={0} onFocus={() => focusTrap()} />
        </DialogContainer>
      </InOut>
    </Portal>
  );
};
