import { FC } from "react";
import { Col, Row, InOut, BaseButton, Card, Flex, Svg } from "@linkeo.com/ui-lib-react";
import { InteractiveCol } from "../ui/interactive-col";
import { ItemState } from '../../interface/product.types';
import { useTheme } from "styled-components";
import { SmallRow, SmallCol } from "../ui/small-columns";

interface SeparatorLineProps {
    readOnly: boolean;
    itemState: ItemState;
    onStateChange: (v: ItemState) => void;
    onPositionMove: (direction: 'up' | 'down') => void;
    onDelete: () => void;
}

export const SeparatorLine: FC<SeparatorLineProps> = ({ onDelete, onPositionMove, readOnly, itemState, onStateChange }) => {
    const theme = useTheme();

    return <Row style={{ borderTop: itemState.editMode ? 'none' : '1px solid #ccc', marginBottom: '6px' }}>
        {itemState.editMode ? <Col columns={[12]}>
            <InOut
                show={true}
                autoScroll={{ behavior: 'smooth', block: 'center' }}
                startTriggering>
                <Card style={{ margin: '0 -70px', padding: '16px 8px' }}>
                    <SmallRow justifyContent={['space-between']} alignItems={['center']}>
                        <SmallCol>
                            <SmallRow justifyContent={['space-between']} alignItems={['center']}>
                                <Flex style={{ width: '62px' }} direction={['column']} justifyContent={['space-between']}>
                                    <BaseButton onClick={() => onPositionMove('up')}>
                                        <Svg icon={'up'} fill={theme.colors.primary} width={24} height={24} />
                                    </BaseButton>
                                    <BaseButton onClick={() => onPositionMove('down')}>
                                        <Svg icon={'down'} fill={theme.colors.primary} width={24} height={24} />
                                    </BaseButton>
                                </Flex>
                            </SmallRow>
                        </SmallCol>
                        <SmallCol style={{flexGrow: 1, borderTop: '1px solid #ccc'}}>
                           
                        </SmallCol>
                        <SmallCol>
                            <SmallRow justifyContent={['space-between']} alignItems={['center']}>
                                <div style={{ padding: '0 15px' }}>
                                    <BaseButton onClick={onDelete}>
                                        <Svg icon={'delete'} fill={theme.colors.primary} width={24} height={24} />
                                    </BaseButton>
                                </div>
                            </SmallRow>
                        </SmallCol>
                    </SmallRow>
                </Card>
            </InOut>
        </Col>
            : <InteractiveCol
                locked={itemState.readOnly || readOnly}
                columns={[12]}
                onClick={() => !itemState.readOnly && !readOnly && onStateChange({ ...itemState, editMode: true })}>
            </InteractiveCol>}
    </Row>
}