import * as React from 'react';
import { Increment, Pad } from '../../helpers';
import { BaseButton } from '../../components/base-button';
import { BaseInput } from '../../components/base-input';
import styled from 'styled-components';
import { Svg } from '../../components/svg';

interface NumberSelectProps {
  min: number;
  max: number;
  value: number;
  onChange: (event: number) => void;
}

const IconButton = styled(BaseButton)`
  background: transparent;
  padding: 0 8px;
`;
export const NumberSelect: React.FC<NumberSelectProps> = props => {
  const { value, onChange, max, min } = props;
  const change = (event: React.FormEvent) => {
    const element = event.target as HTMLInputElement;
    let val = Number.parseInt(element.value, 10);
    if (val >= max) {
      val = max;
    }
    if (val <= min) {
      val = min;
    }
    onChange(val);
  };
  const showVal = Pad(value, 2);
  const upValue = () => {
    onChange(Increment(value, 1, min, max, true));
  };
  const downValue = () => {
    onChange(Increment(value, -1, min, max, true));
  };
  return (
    <div style={{ width: '45px', textAlign: 'center' }}>
      <IconButton onClick={upValue}>
        <Svg icon={'up'} width={24} height={24} />
      </IconButton>
      <BaseInput
        value={showVal}
        onChange={change}
        type="text"
        style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }}
      />
      <IconButton onClick={downValue}>
        <Svg icon={'down'} width={24} height={24} />
      </IconButton>
    </div>
  );
};
