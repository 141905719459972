export type ScreenDevices = 'xs' | 'small' | 'tablet' | 'desktop' | 'xl';
export const DEVICES = ['xs', 'small', 'tablet', 'desktop', 'xl'] as ScreenDevices[];

export const PickChoiceByDevice = <T>(device: ScreenDevices, choices: T[]): T => {
  switch (device) {
    case 'xl':
      return choices[4] || choices[3] || choices[2] || choices[1] || choices[0];
    case 'desktop':
      return choices[3] || choices[2] || choices[1] || choices[0];
    case 'tablet':
      return choices[2] || choices[1] || choices[0];
    case 'small':
      return choices[1] || choices[0];
    case 'xs':
    default:
      return choices[0];
  }
};
export const MakeCssMediaQuery = (css: string, devices: ScreenDevices): string => {
  let media = 0;
  switch (devices) {
    case 'xs':
      return css;
    case 'small':
      media = 576;
      break;
    case 'tablet':
      media = 768;
      break;
    case 'desktop':
      media = 992;
      break;
    case 'xl':
      media = 1200;
      break;
  }
  return `
     @media (min-width: ${media}px) {
        ${css}
     }
    `;
};
export const MakeCssMediaQueryStrict = (css: string, devices: ScreenDevices): string => {
  let min = 0;
  let max = 0;
  switch (devices) {
    case 'xs':
      max = 575;
      break;
    case 'small':
      min = 576;
      max = 767;
      break;
    case 'tablet':
      min = 768;
      max = 991;
      break;
    case 'desktop':
      min = 992;
      max = 1200;
      break;
    case 'xl':
      min = 1200;
      break;
  }
  return `
     @media (min-width: ${min}px) ${max ? `and (max-width: ${max}px)` : ''}  {
        ${css}
     }
    `;
};
