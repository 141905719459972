import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { PageWrapper } from '../../../components';
import {
  Button,
  Card,
  Col,
  Container,
  InOut,
  Keyframes,
  PageSubtitle,
  PageTitle,
  Row,
  SelectMenu, SpanLink, useToaster,
} from '@linkeo.com/ui-lib-react';
import { useApi } from '../../../providers/api-provider';
import {
  FormParams,
  FormScope, PRICE,
  ScopeQuestion,
  Scopes,
  ScopeType,
} from '../../../interface/scope.types';
import { ScopeList } from '../../../components/scope/scope-list';
import { QuestionType } from '../../../interface/question.types';
import { useHistory } from 'react-router-dom';
import { QuestionDialog } from '../../../components/question/question.dialog';
import { FindAndUpdateItemInArray, UpdateItemInArray } from '../../../utils/deep-object.utils';
import { PrevFormDialog } from '../../../components/presentation/prev-form.dialog';
import { IntegrationFormDialog } from '../../../components/presentation/integration-form.dialog';
import { useAuth } from '../../../providers/auth-provider';
import { FormattedMessage, useIntl } from 'react-intl';
import { EstimationValueParamsForm } from '../../../components/estimation-params/estimation-value-params-form';
import { ServerError } from '../../../components/commons/server-error';
import styled from 'styled-components';
import { CreateFormDialog } from '../../../components/presentation/create-form-dialog';

const MenuWrapper = styled.div`
  max-width: 220px;
  min-width: 110px;
  > ol {
    max-height: 50vh;
  }
`;

export const PresentationPage: FC<{ formUrl: string }> = ({ formUrl }) => {
  const API = useApi();
  const { codeBouton } = useAuth();
  const history = useHistory();
  const toast = useToaster();
  const scopeRef = useRef<ScopeType>('TARGETING');
  const [getScopes, setScopes] = useState<Scopes>();
  const [getShowDialog, setShowDialog] = useState<boolean>(false);
  const [getShowDialogPrev, setShowDialogPrev] = useState<boolean>(false);
  const [getShowDialogIntegration, setShowDialogIntegration] = useState<boolean>(false);
  const { locale, ...intl } = useIntl();
  const [getAllForms, setAllForms] = useState<FormScope[]>();
  const [getCurrentForm, setCurrentForm] = useState<FormScope>();
  const [formId, setFormId] = useState<string>();
  const [getFormLoad, setFormLoad] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultFormParams: FormParams = {
    unit: {
      type: PRICE,
    },
    display: 'BETWEEN',
    details: '',
    formType: null,
  };

  useEffect(() => {
    API.getAllForms().then(result => {
      setAllForms(result);
      const current = result.find(f => f.locale === locale) || result[0];
      setCurrentForm(current);
      setFormId(current.id);
    }).catch(e => {
      console.error(e);
      setError(true);
    }).finally(() => setFormLoad(false));
  }, [API, locale]);

  useEffect(() => {
    setFormLoad(true);
    if (!formId) {
      return;
    }
    API.getFormScopes(formId).then((result) => {
      setScopes(result);
    }).catch(e => {
      console.error(e);
      setError(true);
    }).finally(() => {
      setFormLoad(false);
    });
  }, [API, formId]);

  const goToScopeQuestion = (scope: ScopeType, questionType: QuestionType | string) => {
    history.push(`/estimate/presentation/${scope}/question/${questionType}`);
  };

  const changeScope = (scope: ScopeType, questions: ScopeQuestion[]) => {
    if (!getCurrentForm) {
      return;
    }
    API.putScope(getCurrentForm.id, scope, questions).then((result) => {
      setScopes({
        ...getScopes,
        [scope]: result,
      });
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const deleteQuestion = (scope: ScopeType, question: ScopeQuestion) => {
    API.deleteScopeQuestion(scope, question).then((result) => {
      setScopes({
        ...getScopes,
        [scope]: result,
      });
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const changeQuestion = (scope: ScopeType, question: ScopeQuestion) => {
    if (!getCurrentForm) {
      return;
    }
    if (question.type !== 'QUESTION_CUSTOM') {
      API.putScopeSetting(getCurrentForm.id, scope, question).then((result) => {
        setScopes({
          ...getScopes,
          // @ts-ignore
          [scope]: FindAndUpdateItemInArray(getScopes[scope], 'id', result),
        });
      }).catch(() => toast(intl.formatMessage({
        id: 'errorServerMessage',
        defaultMessage: 'Une erreur est survenue',
      })));
      return;
    }
    API.putScopeQuestion(scope, question).then((result) => {
      setScopes({
        ...getScopes,
        // @ts-ignore
        [scope]: FindAndUpdateItemInArray(getScopes[scope], 'id', result),
      });
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const addScopeQuestion = (scope: ScopeType) => {
    scopeRef.current = scope;
    setShowDialog(true);
  };

  const onUpdateParams = (params: FormParams) => {
    if (!getCurrentForm) {
      return;
    }
    API.putFormParams(getCurrentForm.id, params)
      .then((params) => {
        setCurrentForm({ ...getCurrentForm, params });
        if (!getAllForms) {
          return;
        }
        const index = getAllForms.findIndex(el => el.id === getCurrentForm.id);
        setAllForms(UpdateItemInArray(getAllForms, index, { ...getCurrentForm, params }));
      }).catch(() => toast(intl.formatMessage({
      id: 'errorServerMessage',
      defaultMessage: 'Une erreur est survenue',
    })));
  };

  const createForm = useCallback(async (form: Omit<FormScope, 'id'>) => {
    setLoading(true)
    try {
      const newForm = await API.createForm(form);
      setFormLoad(true);
      const list = await API.getAllForms();
      setAllForms(list);
      setCurrentForm(newForm);
      setFormId(newForm.id)
    } catch (e) {
      console.error(e)
      toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'}))
    } finally {
      setOpenFormDialog(false);
      setLoading(false);
      setFormLoad(false);
    }
  }, [API, intl, toast]);

  return <PageWrapper isLoading={getFormLoad}>
    {!error ? <Row justifyContent={'center'} wraps={'nowrap'}>
      <Col columns={[2.5]}>
        <Container size={'md'}>
          {getAllForms && getCurrentForm &&
            <MenuWrapper>
              <SelectMenu
                items={getAllForms.map(form => ({
                  value: form,
                  label: form.name,
                }))}
                title={intl.formatMessage({ id: 'formListTitle', defaultMessage: 'Liste des formulaires' })}
                icon={'right'}
                selected={{ label: getCurrentForm.name, value: getCurrentForm }}
                onSelect={(s) => {
                  setCurrentForm(s.value);
                  setFormId(s.value.id);
                }} />
            </MenuWrapper>}
        </Container>
      </Col>
      <Col>
        <Container size={'lg'}>
          <PageTitle
            style={{ marginBottom: '10px' }}>{intl.formatMessage({
            id: 'formPresentationTitle',
            defaultMessage: 'Formulaires',
          })}</PageTitle>
          <PageSubtitle>
            <FormattedMessage id={'formPresentationSubtitle'}
                              defaultMessage={'Le formulaire d’estimation présent sur votre site internet apparaîtra en séquences.{br}Vous pouvez paramétrer ici les informations que vous souhaitez demander à vos clients pour obtenir leur contact et/ou établir vos devis plus rapidement.'}
                              values={{ br: < br /> }} />
          </PageSubtitle>
          <Button colorType='primary' onClick={() => setOpenFormDialog(true)}>
            <FormattedMessage id='formPresentationCreateFormButtonLabel' defaultMessage={'Créer un formulaire'} />
          </Button>

          <Row style={{ marginTop: '20px', marginBottom: '20px' }} alignItems={['center']}>
            <Col>
              <Button colorType={'outline'}
                      onClick={() => setShowDialogPrev(true)}>{intl.formatMessage({
                id: 'formPreview',
                defaultMessage: 'Prévisualiser le formulaire',
              })}</Button>
            </Col>
            <Col>
              <Button colorType={'outline'} onClick={() => setShowDialogIntegration(true)}>
                {intl.formatMessage({ id: 'formIntegration', defaultMessage: 'Intégrer le formulaire' })}
              </Button>
            </Col>
            {getCurrentForm ? <Col style={{ marginLeft: 'auto' }}>
              <a
                target={'_blank'}
                rel={'noreferrer'}
                href={`${formUrl}/?codebouton=${codeBouton}&locale=${getCurrentForm.locale}&formid=${getCurrentForm.id}`}>
                <SpanLink>
                  {intl.formatMessage({ id: 'formOpenInNewTab', defaultMessage: 'Ouvrir dans un nouvel onglet' })}
                </SpanLink>
              </a>
            </Col> : null}
          </Row>
          <InOut show={!getFormLoad} keepContent={true}
                 enter={{ keyframes: Keyframes.slideInFrom('100px') }}>
            <EstimationValueParamsForm params={getCurrentForm?.params ?? defaultFormParams}
                                       name={getCurrentForm?.name}
                                       onParamsChange={onUpdateParams}
            />
            <Card>
              {(['TARGETING', 'ACTIVITY', 'ACTIVITY_QUESTIONS', 'CONTACT'] as ScopeType[])
                .map(k => ({ key: k, val: getScopes ? getScopes[k] : null }))
                .map(({ key, val }) => (
                  !!val && <ScopeList
                    key={key}
                    onChange={q => changeScope(key, q)}
                    scope={key}
                    formType={getCurrentForm?.params.formType}
                    questions={val}
                    onAddQuestion={() => addScopeQuestion(key)}
                    onChangeQuestion={q => changeQuestion(key, q)}
                    onDeleteQuestion={q => deleteQuestion(key, q)}
                    onEditQuestion={q => {
                      goToScopeQuestion(key, q.id);
                    }}
                  />
                ))}
            </Card>
          </InOut>

          {getCurrentForm ? <PrevFormDialog show={getShowDialogPrev} onClose={() => setShowDialogPrev(false)}
                                            formUrl={formUrl}
                                            locale={getCurrentForm.locale}
                                            formId={getCurrentForm.id} /> : null}
          {getCurrentForm ?
            <IntegrationFormDialog show={getShowDialogIntegration} onClose={() => setShowDialogIntegration(false)}
                                   formUrl={formUrl}
                                   locale={getCurrentForm.locale}
                                   formId={getCurrentForm.id} /> : null}
          <CreateFormDialog onClose={() => setOpenFormDialog(false)} show={openFormDialog}
                            loading={loading}
                            onSubmit={createForm}
                            formType={{
                              name: '',
                              locale: 'fr-FR',
                              params: defaultFormParams,
                            }} />
          <QuestionDialog
            show={getShowDialog} onClose={() => setShowDialog(false)}
            onValid={t => goToScopeQuestion(scopeRef.current, t)} />
        </Container></Col></Row> : <ServerError />}
  </PageWrapper>;
};


