import { FC, useEffect, useState } from 'react';
import { useApi } from '../../providers/api-provider';
import { ActivitiesQuestion, Estimation, QuestionAnswer } from '../../interface/estimation.type';
import {
  Button,
  Card,
  Col,
  H3,
  InOut,
  Row,
  SpanCaption1,
  SpanSubtitle1,
  SpanSubtitle2,
} from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { EstimateParamDisplayType } from '../../interface/scope.types';

interface EstimationCardRecapProps {
  estimationId: string;
}

const getAllActivities = (activities: ActivitiesQuestion): string => {
  return Object.keys(activities).join(', ');
};

interface QuestionAnswerDisplayProps {
  qna: QuestionAnswer;
}

const QuestionAnswerDisplay: FC<QuestionAnswerDisplayProps> = props => {
  const { qna } = props;
  const theme = useTheme();
  return <div key={qna.question}>
    <SpanSubtitle1
      style={{ color: theme.colors.grey50 }}>
      {qna.question}
    </SpanSubtitle1>
    {qna.answer.label.map(label => <div key={label}>
      <SpanCaption1>{label}</SpanCaption1>
    </div>)}
    {qna.answer.overallQuantity && <div>
      <SpanSubtitle1
        style={{ color: theme.colors.grey50 }}>
        {qna.answer.overallQuantity.question}
      </SpanSubtitle1><br />
      <SpanCaption1>{qna.answer.overallQuantity.quantity} {qna.answer.overallQuantity.unit}</SpanCaption1>
    </div>}
  </div>;
};

export const EstimationCardRecap: FC<EstimationCardRecapProps> = props => {
  const { estimationId } = props;
  const [getEstimation, setEstimation] = useState<Estimation>();
  const [getShowDetails, setShowDetails] = useState<boolean>(false);
  const API = useApi();
  const theme = useTheme();
  const intl = useIntl();

  useEffect(() => {
    API.getEstimation(estimationId).then(result => setEstimation(result));
  }, [estimationId, API]);

  const getAmount = (price: { min: string; max: string }, display: EstimateParamDisplayType): string => {
    if (display === 'FROM' || price.min === price.max) {
      return intl.formatMessage({id: 'getAmountMin', defaultMessage: 'À partir de {min}'}, {min: price.min});
    } else {
      return intl.formatMessage({id: 'getAmountMinMax', defaultMessage: 'Entre {min} et {max}'}, {min: price.min, max: price.max});
    }
  };

  const getFullNameWithContact = (contact: QuestionAnswer[]): string => {
    const findFirstName = contact.find(f => f.question === intl.formatMessage({id: 'firstname', defaultMessage: 'Prénom'}));
    const findLastName = contact.find(f => f.question === intl.formatMessage({id: 'lastname', defaultMessage: 'Nom'}));
    if (!findFirstName || !findLastName) {
      return '';
    }
    return `${findFirstName.answer.label[0] || ''} ${findLastName.answer.label[0]}`;
  };

  return getEstimation ? <Card style={{ marginBottom: '40px' }}>
    <Row justifyContent={['space-between']} alignItems={['center']}>
      <Col>
        {getEstimation.CONTACT && <H3>{getFullNameWithContact(getEstimation.CONTACT)}</H3>}
        <SpanSubtitle1>{getAllActivities(getEstimation.ACTIVITIES)}</SpanSubtitle1><br />
        <SpanSubtitle2>{getAmount(
          {
            min: intl.formatNumber(getEstimation.price.min, { style: 'currency', currency: getEstimation.currency }),
            max: intl.formatNumber(getEstimation.price.max, { style: 'currency', currency: getEstimation.currency }),
          },
          getEstimation.display)}</SpanSubtitle2>
      </Col>
      <Col>
        {getShowDetails ? <Button colorType={'outline'} icon={'up'} iconRight onClick={() => setShowDetails(false)}>
          <FormattedMessage id={'EstimateCardHideButtonLabel'} defaultMessage={'Masquer les détails'} />
        </Button> : <Button colorType={'outline'} icon={'down'} iconRight onClick={() => setShowDetails(true)}>
          <FormattedMessage id={'EstimateCardDetailsButtonLabel'} defaultMessage={'Détails de la demande'}/>
        </Button>}
      </Col>
    </Row>
    <InOut show={getShowDetails}>
      <Row>
        <Col columns={[6]}>
          <div>
            <p>
              <SpanCaption1 style={{ color: theme.colors.grey60 }}>
                <FormattedMessage id={'EstimateCardRequestTitle'} defaultMessage={"Demande"} />
              </SpanCaption1>
            </p>
            {Object.keys(getEstimation.ACTIVITIES).map(activity => <div key={activity} style={{ marginBottom: '10px' }}>
              <SpanSubtitle2>{activity}</SpanSubtitle2>
              {getEstimation.ACTIVITIES[activity].map(qna => <QuestionAnswerDisplay key={qna.question} qna={qna} />)}
            </div>)}
            <p>
              <SpanCaption1 style={{ color: theme.colors.grey60 }}>
                <FormattedMessage id={'EstimateCardAdditionalInfoTitle'} defaultMessage={'Infos complémentaires'} />
              </SpanCaption1>
            </p>
            {getEstimation.ACTIVITY_QUESTIONS && getEstimation.ACTIVITY_QUESTIONS.map(qna => <QuestionAnswerDisplay
              key={qna.question} qna={qna} />)}
          </div>
        </Col>
        <Col columns={[6]}>
          <div style={{ background: theme.colors.grey95, padding: '20px' }}>
            <p>
              <SpanCaption1 style={{ color: theme.colors.grey60 }}>
                <FormattedMessage id={'presentationContactTitle'} defaultMessage={'Contact'}/>
              </SpanCaption1>
            </p>
            {getEstimation.CONTACT && getEstimation.CONTACT.map(qna => <QuestionAnswerDisplay key={qna.question}
                                                                                              qna={qna} />)}
            <p>
              <SpanCaption1 style={{ color: theme.colors.grey60 }}>
                <FormattedMessage id={'presentationTargetTitle'} defaultMessage={'Ciblage'}/>
                </SpanCaption1>
            </p>
            {getEstimation.TARGETING && getEstimation.TARGETING.map(qna => <QuestionAnswerDisplay key={qna.question}
                                                                                                  qna={qna} />)}
          </div>
        </Col>
      </Row>
    </InOut>
  </Card> : null;
};