import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { getTypo, SelectOption, Shapes, SpanCaption1, ThemeType } from '../../common';
import { MatchLike, NormalizeText } from '../../helpers';
import { IconName } from '../../icons';
import { Svg } from '../svg';
import { Row } from '../row';
import { Col } from '../col';

export interface MenuItemProps {
  selected: boolean;
}

export interface SelectMenuProps<T> {
  highlight?: string;
  items: SelectOption<T>[];
  onSelect: (item: SelectOption<T>) => void;
  selected?: SelectOption<T>;
  title?: string;
  icon?: IconName;
}

const MenuContainer = styled.ol`
  ${Shapes.shadow1};
  position: relative;
  background-color: ${({ theme }) => theme.colors.light};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  border-radius: 4px;
  max-height: 25vh;
`;
const Item = styled.li`
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
const MenuItem = styled.div<MenuItemProps>`
  ${getTypo('body1')};
  color: ${({ theme }) => theme.colors.grey50};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid transparent;
  background: ${({ selected, theme }): string =>
    selected ? theme.colors.grey95 : theme.colors.light};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.grey20};
    background: ${({ theme }) => theme.colors.grey95};
  }
`;
const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.dark};
`;
const Title = styled.div`
  ${getTypo('body2')};
  padding: 8px;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.light};
  z-index: 1;
  border-radius: 4px;
`;

const HighLighter: FC<{ label: string; search?: string; children?: React.ReactNode }> = props => {
  const { label, search } = props;
  const normalizeItem = NormalizeText(label);
  const normalizeHighlight = NormalizeText(search);
  const match: RegExpMatchArray | undefined = MatchLike(normalizeItem, normalizeHighlight);
  if (!match || typeof match.index === 'undefined') {
    return <>{label}</>;
  }
  const start: number = match.index || 0;
  const end: number = start + match[0].length;
  return (
    <>
      {label.slice(0, start)}
      <Highlight>{label.slice(start, end)}</Highlight>
      {label.slice(end)}
    </>
  );
};
export const SelectMenu = function<T>(props: React.PropsWithChildren<SelectMenuProps<T>>) {
  const { onSelect, selected, items, highlight, title, icon } = props;
  const theme = React.useContext<ThemeType>(ThemeContext);
  const refList = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const parent = refList.current as HTMLElement;
    if (!parent) {
      return;
    }
    if (!selected) {
      return;
    }
    const selectedIndex = items.findIndex(f =>
      f.uid && selected.uid ? f.uid === selected?.uid : f.label === selected.label
    );
    if (selectedIndex < 0) {
      return;
    }
    const childTarget = parent.childNodes[selectedIndex] as HTMLDivElement;
    if (!childTarget) return;
    parent.scrollTo({
      behavior: 'smooth',
      top: childTarget.offsetTop - parent.clientHeight / 3,
    });
  }, [selected, refList, items]);

  return (
    <MenuContainer ref={refList as any}>
      {title && <Title>{title}</Title>}
      {items.map(item => (
        <Item
          key={item.uid || item.label}
          title={item.label}
          data-selected={
            selected?.uid && item.uid
              ? selected.uid === item.uid
                ? true
                : null
              : selected?.label === item.label
              ? true
              : null
          }
        >
          <MenuItem onClick={() => onSelect(item)} selected={selected?.label === item.label}>
            <Row alignItems={'center'} justifyContent={'space-between'} wraps={'nowrap'}>
              <Col style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <HighLighter label={item.label} search={highlight}>
                  {item.label}
                </HighLighter>
              </Col>
              {icon && (
                <Col>
                  <Svg icon={icon} height={26} width={26} color={theme.colors.grey20} />
                </Col>
              )}
            </Row>
            {item.caption ? (
              <>
                <br />
                <SpanCaption1>{item.caption}</SpanCaption1>
              </>
            ) : null}
          </MenuItem>
        </Item>
      ))}
    </MenuContainer>
  );
};
