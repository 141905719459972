import styled, { ThemeContext } from 'styled-components';
import * as React from 'react';
import { SpanBody1, SpanH1, ThemeType } from '../../common';
import { Row } from '../row';
import { Col } from '../col';

export interface TopHeaderProps {
  subtitle: string;
  title: string;
}

const Wrapper = styled.div<{ bgColor: string }>`
  padding: 25px;
  background-color: ${({ bgColor }) => bgColor};
`;
const Title = styled.h1`
  margin: 0;
`;
const Subtitle = styled.h2`
  margin: 0;
`;
export const TopHeader: React.FC<TopHeaderProps> = props => {
  const { title, subtitle } = props;
  const theme = React.useContext<ThemeType>(ThemeContext);
  return (
    <Wrapper bgColor={theme.colors.primary}>
      <Row alignItems={['center']}>
        <Col>
          <Title>
            <SpanH1 style={{ color: theme.colors.secondary }}>{title}</SpanH1>
          </Title>
        </Col>
        <Col>
          <Subtitle>
            <SpanBody1 style={{ color: theme.colors.invertPrimary }}>{subtitle}</SpanBody1>
          </Subtitle>
        </Col>
      </Row>
    </Wrapper>
  );
};
