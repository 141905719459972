import * as React from 'react';
import { FC, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { Flex } from '../flex';
import { BaseInputImage } from '../base-input-image';
import { InOut } from '../in-out';
import { getTypo, ImageValue, ThemeType } from '../../common';
import { Svg } from '../svg';

const ImagePreview = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 1px solid #999999;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface InputImageProps extends LabelWrapperProps {
  addImageLabel?: string;
  isLoading?: boolean;
  /**
   * in megabyte default 2mb
   */
  maxSize?: number;
  onChange: (value: ImageValue) => void;
  onError: (reason: any) => void;
  removeImageLabel?: string;
  replaceImageLabel?: string;
  value: ImageValue;
  accept?: string;
}

const ButtonLink = styled.span`
  ${getTypo('link')};
  cursor: pointer;
`;
export const InputImage: FC<InputImageProps> = props => {
  const {
    value,
    onChange,
    onError,
    maxSize,
    addImageLabel,
    removeImageLabel,
    replaceImageLabel,
    label,
    caption,
    right,
    disabled,
    hasError,
    required,
    small,
    isLoading,
    accept,
  } = props;
  const [getLocal, setLocal] = useState<string>('');
  const emitDelete = () => {
    onChange({});
    setLocal('');
  };
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <LabelWrapper {...{ label, caption, right, disabled, hasError, required, small }}>
      <Flex alignItems={['center']}>
        <label>
          <Flex alignItems={['center']}>
            <ImagePreview>
              {value.stringFormat && !isLoading && (
                <img src={value.stringFormat as string} alt={'preview'} width={40} height={40} />
              )}
              {!value.stringFormat && !isLoading && (
                <Svg icon={'image'} width={24} height={24} fill={theme.colors.grey60} />
              )}
              {isLoading && (
                <Svg animatedIcon={'spinner'} width={24} height={24} stroke={theme.colors.dark} />
              )}
            </ImagePreview>
            <BaseInputImage
              disabled={disabled}
              required={required}
              maxSize={maxSize}
              onChange={onChange}
              onError={onError}
              onLocalChange={setLocal}
              local={getLocal}
              accept={accept}
              style={{ opacity: 0, position: 'absolute', width: 0, height: 0 }}
            />
            {value.stringFormat && (
              <ButtonLink style={{ marginRight: '10px' }}>
                {replaceImageLabel || 'Remplacer l’image'}
              </ButtonLink>
            )}
            {!value.stringFormat && <ButtonLink>{addImageLabel || 'Ajouter une image'}</ButtonLink>}
          </Flex>
        </label>
        <InOut show={!!value.stringFormat}>
          <ButtonLink onClick={emitDelete}>{removeImageLabel || 'Supprimer l’image'}</ButtonLink>
        </InOut>
      </Flex>
    </LabelWrapper>
  );
};
