import { RangeSign } from '../math';
import { DisabledDays } from '../../common';

type locales = string | string[];

export const IsSameDay = (dateA: Date, dateB: Date): boolean => {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  );
};
export const MonthName = (date: Date, locales?: locales): string => {
  return date.toLocaleString(locales, { month: 'long' });
};
export const DayName = (date: Date, locales?: locales): string => {
  return date.toLocaleString(locales, { weekday: 'long' });
};
export const FirstDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month, 1);
};

export const LastDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month + 1, 0);
};

export const getDayOfWeek = (index: number, offset: number): Date => {
  const today = new Date();
  let offsetDay = index + offset; // in js day begin by sunday
  offsetDay = offsetDay > 6 ? 0 : offsetDay < 0 ? 6 : offsetDay;
  return new Date(today.setDate(today.getDate() - today.getDay() + offsetDay));
};

export const FirstMonDayOfWeek = (date: Date, offset: number = 1): Date => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + offset;
  if (diff > 1) {
    return new Date(d.setDate(diff - 7));
  }
  return new Date(d.setDate(diff));
};
export const AddDay = (date: Date, day: number): Date => {
  const d = new Date(date);
  const add = d.getDate() + day;
  return new Date(d.setDate(add));
};
export const DaysName = (offset: number, locales?: locales): string[] => {
  const arr: string[] = [];
  for (let i = 0; i < 7; i++) {
    arr.push(DayName(getDayOfWeek(i, offset), locales));
  }
  return arr;
};
export const MonthsNames = (locales?: locales): string[] => {
  const arr: string[] = [];
  for (let i = 0; i < 12; i++) {
    arr.push(MonthName(new Date(new Date().setMonth(i)), locales));
  }
  return arr;
};
export const IsDisabledDate = (date: Date, disabled?: DisabledDays): boolean => {
  if (!disabled) {
    return false;
  }
  const { max, min, intervals, days, weekDays } = disabled;
  let val = false;
  const sign = RangeSign(date, min, max);
  if (sign !== 0) {
    val = true;
  }
  if (intervals) {
    intervals.forEach(([a, b]) => {
      const signInterval = RangeSign(date, a, b);
      if (signInterval === 0) {
        val = true;
      }
    });
  }
  if (days && days.some(dd => IsSameDay(date, dd))) {
    val = true;
  }
  if (weekDays && weekDays.some(day => date.getDay() === day)) {
    val = true;
  }
  return val;
};
export type DateLocalParams = { separator: string; order: ('d' | 'm' | 'y')[] };
export const localToDateParams = (locale: string = navigator.language): DateLocalParams => {
  switch (locale) {
    case 'fr':
    case 'fr_FR':
    case 'fr_CA':
      return {
        separator: '/',
        order: ['d', 'm', 'y'],
      };
    default:
    case 'en':
    case 'en_US':
    case 'en_CA':
    case 'en_AU':
      return {
        separator: '-',
        order: ['m', 'd', 'y'],
      };
  }
};
export const dateToString = (date?: Date | null, locale?: string) => {
  const params = localToDateParams(locale);
  let typeDate = '';
  if (date) {
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    params.order.forEach(val => {
      switch (val) {
        case 'd':
          typeDate += (dd > 9 ? '' : '0') + dd + params.separator;
          break;
        case 'm':
          typeDate += (mm > 9 ? '' : '0') + mm + params.separator;
          break;
        case 'y':
          typeDate += date.getFullYear();
          break;
      }
    });
  }
  return typeDate;
};
