import * as React from 'react';
import { ChangeEvent, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import { BaseInput, BaseInputProps } from '../base-input';

const BaseTextareaStyled = styled(BaseInput)`
  height: initial;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  min-height: 90px;
  line-height: 1.25rem;
  padding: ${({ small }) => (small ? '5px' : '12px 8px')};
`;

type eventNames = 'onChange' | 'onCut' | 'onPaste' | 'onDrop' | 'onKeyDown';

export interface TextareaFieldProps
  extends BaseInputProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'label'>,
    LabelWrapperProps {
  noResize?: boolean;
  onChange?: (val: string, originalEvent: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const Textarea: React.FC<TextareaFieldProps> = props => {
  const {
    caption,
    disabled,
    hasError,
    required,
    right,
    label,
    onChange,
    value,
    noResize,
    small,
    readOnly,
    ...rest
  } = props;
  const refTextArea = React.useRef<HTMLInputElement>();
  const resize = (): void => {
    if (noResize) {
      return;
    }
    const textarea = refTextArea.current as HTMLInputElement;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };
  const handler = (name: eventNames, event: SyntheticEvent<HTMLInputElement>): void => {
    window.setTimeout(() => {
      resize();
    }, 0);
    if (name === 'onChange') {
      const target = event.target as HTMLInputElement;
      onChange && onChange(target.value, event as ChangeEvent<HTMLInputElement>);
      return;
    }
    const eventFn = props[name];
    if (eventFn) {
      eventFn(event as any);
    }
  };
  React.useEffect(() => {
    resize();
  });
  return (
    <LabelWrapper {...{ label, caption, disabled, hasError, required, right, small }}>
      <BaseTextareaStyled
        {...(rest as any)}
        {...{ disabled, hasError, required, right, small }}
        value={value || ''}
        ref={refTextArea}
        as="textarea"
        readOnly={onChange ? readOnly : true}
        onChange={e => handler('onChange', e)}
        onCut={e => handler('onCut', e)}
        onPaste={e => handler('onPaste', e)}
        onDrop={e => handler('onDrop', e)}
        onKeyDown={e => handler('onKeyDown', e)}
      />
    </LabelWrapper>
  );
};
