export type Devise = 'EUR' | 'USD' | 'GBP' | 'CHF' | 'AUD' | 'CAD';
export const getSymbolCurrency = (devise: Devise) => {
  switch (devise) {
    default:
      return devise;
    case 'USD':
    case 'AUD':
    case 'CAD':
      return '$';
    case 'EUR':
      return '€';
    case 'CHF':
      return 'Frs';
    case 'GBP':
      return '£';
  }
};
export const RoundFloat = (n: number = 0, precision: number = 1): number => {
  return Math.round(n * precision) / precision;
};
