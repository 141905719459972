import { Button, Col, Row } from '@linkeo.com/ui-lib-react';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

interface QuestionFooterProps {
    onCancel: () => void;
}

export const QuestionFooter: FC<QuestionFooterProps> = props => {
    const intl = useIntl();
    const { onCancel } = props;
    return <Row as={'footer'} justifyContent={['flex-end']}>
        <Col>
            <Button onClick={onCancel} colorType={'outline'}>
                {intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}
            </Button>
        </Col>
        <Col>
            <Button type={'submit'} colorType={'primary'}>
                {intl.formatMessage({id: 'save', defaultMessage: 'Enregistrer'})}
            </Button>
        </Col>
    </Row>;
};