import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { SpanSubtitle2 } from '../../common';

interface NavBarProps {
  rightTitle?: string;
  children?: React.ReactNode;
}

const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.light};
  padding: 15px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
`;
const RightTitle = styled.div`
  margin-left: auto;
`;
const Items = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 30px;
  }
`;
export const NavBar: FC<NavBarProps> = props => {
  const { children, rightTitle } = props;
  return (
    <Nav>
      <Items>{children}</Items>
      <RightTitle>
        <SpanSubtitle2>{rightTitle}</SpanSubtitle2>
      </RightTitle>
    </Nav>
  );
};
