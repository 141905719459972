import { FC, useEffect, useState } from 'react';
import { Button, Dialog } from '@linkeo.com/ui-lib-react';
import { useAuth } from '../../providers/auth-provider';
import { useIntl } from 'react-intl';
import { Locale } from '../../providers/intl.provider';

interface PrevFormDialogProps {
  onClose: () => void;
  show: boolean,
  formId:string;
  formUrl: string;
  locale: Locale;
}

export const PrevFormDialog: FC<PrevFormDialogProps> = props => {
  const { onClose, show,formId, formUrl, locale } = props;
  const [getDiv, setDiv] = useState<HTMLDivElement>();
  const AUTH = useAuth();
  const intl = useIntl();

  useEffect(() => {
    if (!getDiv) {
      return;
    }
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = `${formUrl}/static/js/quote.js?codebouton=${AUTH.codeBouton}&locale=${locale}&formid=${formId}`;
    getDiv.appendChild(script);
    return () => {
      for (let i = 0; i < getDiv.children.length; i++) {
        getDiv.removeChild(getDiv.children[i]);
      }
    };
  }, [getDiv, AUTH, locale, formId, formUrl]);

  useEffect(() => {
    if (!show) {
      return;
    }
    setTimeout(() => { //for let time modal open
      setDiv(document.getElementById('lnk_container_script') as HTMLDivElement);
    }, 1);
  }, [show]);

  const footer = <Button colorType={'secondary'} onClick={onClose}>{intl.formatMessage({id: 'close', defaultMessage: 'Fermer'})}</Button>;

  return <Dialog
    title={intl.formatMessage({id: 'formPreview', defaultMessage: 'Prévisualiser le formulaire'})}
    onClose={onClose}
    show={show}
    size={'fluid'}
    footer={footer}>
    <div style={{ maxHeight: '70vh', overflow: 'auto', background: '#f5f5f5' }} id='lnk_container_script' />
  </Dialog>;
};