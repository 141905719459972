import { FC } from 'react';
import { BaseButton, Col, Deletable, Input, Row, Svg } from '@linkeo.com/ui-lib-react';
import { RemoveItemInArray, UpdateItemInArray } from '../../utils/deep-object.utils';
import { AddressLineErrors } from '../../utils/quote-document-validator';
import { useIntl } from 'react-intl';

interface AddressLinesFieldsProps {
  addressLines: string[];
  onChange: (addressLines: string[]) => void;
  small?: boolean;
  addressLineErrors?: AddressLineErrors | boolean;
}

export const AddressLinesFields: FC<AddressLinesFieldsProps> = props => {
  const { addressLines, onChange, small,addressLineErrors } = props;
  const intl = useIntl();
  return <div>
    {
      addressLines.map((a, i) => <div
        key={i}>
        {i === 0 ? <Row>
          <Col style={{ flex: '1' }}>
            <Input
              hasError={typeof addressLineErrors === 'object' ? addressLineErrors.indexOf(0) !== -1 : addressLineErrors}
              value={a}
              small={small}
              required
              label={intl.formatMessage({id: 'address', defaultMessage: 'Adresse'})}
              onChange={v => onChange(UpdateItemInArray(addressLines, i, v))} />
          </Col>
          <Col style={{marginTop:small ? '37px' : '55px'}}>
            <BaseButton onClick={() => onChange([...addressLines, ''])}>
              <Svg icon={'plus-circle'} width={small ? 20 :24} height={small ? 20 :24} fill={"#666"}/>
            </BaseButton>
          </Col>
        </Row> : <Deletable offsetTop={small ? "25px" : "32px"}  onDelete={() => onChange(RemoveItemInArray(addressLines, i))} hide={false}>
          <Input
            hasError={typeof addressLineErrors === 'object' ? addressLineErrors.indexOf(i) !== -1 : addressLineErrors}
            label={intl.formatMessage({id: 'address', defaultMessage: 'Adresse'}) + intl.formatMessage({id: 'addressRowLabel', defaultMessage: ' ligne '}) + (i + 1)}
            small={small}
            value={a}
            required
            onChange={v => onChange(UpdateItemInArray(addressLines, i, v))}
          />
        </Deletable>
        }
      </div>)
    }
  </div>;
};