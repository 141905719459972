import { FC } from 'react';
import { Card, Col, Row, SpanBody1, SpanBody2, SpanCaption1, SpanSubtitle1, Svg } from '@linkeo.com/ui-lib-react';
import { PartialQuoteDocument } from '../../interface/document.type';
import { DocumentStatusTag } from './document-status-tag';
import { useHistory } from 'react-router-dom';
import { DocumentStatusToPageRoute } from '../../utils/document.utils';
import { useTheme } from 'styled-components';
import { SmallCol, SmallRow } from '../ui/small-columns';
import { FormattedMessage, useIntl } from 'react-intl';

interface DocumentCardProps {
  doc: PartialQuoteDocument;
  highlighted?: boolean;
}

export const DocumentCard: FC<DocumentCardProps> = props => {
  const { doc, highlighted } = props;
  const { recipient, total } = doc;
  const { address } = recipient;
  const intl = useIntl();
  const history = useHistory();
  const onClick = () => {
    history.push(`/${DocumentStatusToPageRoute(doc.status, doc.type)}/document/${doc.id}`);
  };
  const theme = useTheme();
  return <Card style={{
    marginTop: '20px',
    marginBottom: '20px',
    padding: '40px 30px',
    cursor: 'pointer',
    backgroundColor: highlighted ? '#ECDFDF' : undefined,
    borderColor: highlighted ? '#FF7133' : undefined,
  }} onClick={onClick} tabIndex={0} title={highlighted ? intl.formatMessage({
    id: 'documentCardRecentlyCreatedTag',
    defaultMessage: 'Récemment créé',
  }) : undefined}>
    <Row alignItems={['center']}>
      <Col title={doc.estimationId ? intl.formatMessage({
        id: 'documentCardAddedType1',
        defaultMessage: 'Demande internet',
      }) : intl.formatMessage({ id: 'documentCardAddedType2', defaultMessage: 'Ajout manuel' })}>
        <Svg
          icon={doc.estimationId ? 'web' : 'page'}
          width={24} height={24} fill={theme.colors.grey80} />
      </Col>
      <Col columns={[3]}>
        <div>
          {recipient.companyName ? <><SpanBody2>{recipient.companyName}</SpanBody2><br /></> : null}
          <SpanBody2>{recipient.firstName} {recipient.lastName}</SpanBody2><br />
        </div>
        <div>
          <SpanCaption1>{address.postalCode} {address.city}</SpanCaption1>
        </div>
      </Col>
      <Col columns={[3]}>
        <div>
          <SpanSubtitle1>{doc.type === 'quote' ? intl.formatMessage({
            id: 'quote',
            defaultMessage: 'Devis',
          }) : intl.formatMessage({
            id: 'invoice',
            defaultMessage: 'Facture',
          })} {doc.id}</SpanSubtitle1>
        </div>
        <div>
          <SpanCaption1>{intl.formatDate(doc.issueDate, { dateStyle: 'short' })}</SpanCaption1>
        </div>
      </Col>
      <Col columns={[2.5]} style={{ padding: 0 }}>
        <SmallRow>
          <SmallCol style={{ marginBottom: '5px' }}>
            <DocumentStatusTag tag={doc.type} />
          </SmallCol>
          {doc.tags?.filter(f => f === 'finalized' ? !doc.tags?.includes('accepted') : true).map(tag => <SmallCol
            style={{ marginBottom: '5px' }} key={tag}><DocumentStatusTag tag={tag} /></SmallCol>)}
        </SmallRow>
      </Col>
      <Col style={{ marginLeft: 'auto' }}>
        <div style={{ textAlign: 'right' }}>
          <SpanBody1><FormattedMessage id={'documentCardTotalHT'} defaultMessage={'Total HT : {total}'} values={{
            total: intl.formatNumber(total.totalWithoutTax, {
              style: 'currency',
              currency: doc.parameters.currency,
            }),
          }} /></SpanBody1>
        </div>
        <div style={{ textAlign: 'right' }}>
          <SpanCaption1><FormattedMessage id={'documentCardTTC'} defaultMessage={'TTC : {amount}'} values={{
            amount: intl.formatNumber(total.totalWithTax, {
              style: 'currency',
              currency: doc.parameters.currency,
            }),
          }} /></SpanCaption1>
        </div>
      </Col>
    </Row>
  </Card>;
};