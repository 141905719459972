import * as React from 'react';
import { PropsWithChildren, useContext, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Increment } from '../../helpers';
import { BaseButton } from '../base-button';
import { Dropdown } from '../dropdown';
import { getTypo, SelectOption, ThemeType } from '../../common';
import { Svg } from '../svg';
import { FilterMenu } from '../filter-menu';

const ButtonDropdown = styled(BaseButton)<{ active: boolean }>`
  ${getTypo('subtitle1')};
  padding: 15px 15px 15px 30px;
  border: none;
  border-right: 1px solid ${({ theme }) => theme.colors.grey80};
  color: ${({ active, theme }) => (active ? theme.colors.grey20 : theme.colors.grey40)};
`;
const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;

export interface FilterSelectProps<T> {
  onChange: (v: SelectOption<T>) => void;
  options: SelectOption<T>[];
  value: SelectOption<T>;
}

export function FilterSelect<T>(props: PropsWithChildren<FilterSelectProps<T>>) {
  const { value, options, onChange } = props;
  const [getOpen, setOpen] = useState<boolean>(false);
  const refWrapper = useRef<HTMLDivElement>();
  const emitChange = (selected: SelectOption<T>) => {
    onChange(selected);
    setOpen(false);
  };
  const getSelectedIndex = (): number => {
    return options.findIndex(item => item.label === value.label);
  };
  const getSelectedLabel = (): string => {
    const index = getSelectedIndex();
    if (index !== -1) {
      return options[index].label;
    }
    return '';
  };
  const keyDown = (ev: React.KeyboardEvent) => {
    const selectedIndex = getSelectedIndex();
    switch (ev.key) {
      case 'ArrowDown':
        onChange(options[Increment(selectedIndex, 1, 0, options.length - 1, true)]);
        break;
      case 'ArrowUp':
        onChange(options[Increment(selectedIndex, -1, 0, options.length - 1, true)]);
        break;
    }
  };
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Wrapper ref={refWrapper as any}>
      <ButtonDropdown
        onClick={() => setOpen(true)}
        onFocus={() => setOpen(true)}
        onKeyDown={keyDown}
        active={getOpen}
      >
        <span style={{ marginRight: '40px' }}>{getSelectedLabel()}</span>
        <Svg
          width={12}
          height={12}
          icon={'down'}
          fill={getOpen ? theme.colors.grey20 : theme.colors.grey60}
        />
      </ButtonDropdown>
      <Dropdown
        wrapperRef={refWrapper as any}
        show={getOpen}
        onClose={() => setOpen(false)}
        fulfilled
      >
        <FilterMenu onClick={emitChange} selected={value} options={options} />
      </Dropdown>
    </Wrapper>
  );
}
