export const routeHome = '/';
export const routeActivities = '/estimate/activities';
export const routeCategory = '/estimate/category/:categoryId/activity/:activityId/';
export const routeQuestion = '/estimate/category/:categoryId/activity/:activityId/question/:questionType';
export const routePresentation = '/estimate/presentation';
export const routeScope = '/estimate/presentation/:scope/question/:questionType';
export const routeEstimateParams = '/estimate/param';
export const routeCompanyParams = '/quote/company/param';
export const routeDocumentParams = '/quote/document/param';
export const routeDocuments = '/documents';
export const routeNewQuote = '/new/quote';
export const routeNewInvoice = '/new/invoice';
export const routeDraft = '/draft/document/:documentId';
export const routeQuote = '/quote/document/:documentId';
export const routeInvoice = '/invoice/document/:documentId';
