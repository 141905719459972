import styled from 'styled-components';

export const BaseButton = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  display: inline-block;
  border: 1px transparent solid;
  background: transparent;
  user-select: none;
  box-shadow: none;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  padding: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  outline-color: silver;
`;
