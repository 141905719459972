import * as React from 'react';
import { FC, useContext } from 'react';
import { Svg } from '../svg';
import { Ripple } from '../ripple';
import styled, { css, ThemeContext } from 'styled-components';
import { getTypo, Shapes, ThemeType } from '../../common';
import { BaseButton } from '../base-button';
import { lighten } from 'polished';
import { IconName } from '../../icons';
import { mergeStyled } from '../../helpers';

export type ColorType = 'primary' | 'secondary' | 'outline' | 'outlineSecondary';

export interface IconContainerProps {
  marginSide: string;
}

export interface ButtonStyledProps {
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  small?: boolean;
}

export interface LoaderContainerButtonProps {
  hide?: boolean;
  small?: boolean;
}

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  /** overwrite background color */
  backgroundColor?: string;
  /** overwrite border color */
  borderColor?: string;
  /** color type button */
  colorType?: ColorType;
  /** disabled button */
  disabled?: boolean;
  /** overwrite font color */
  fontColor?: string;
  /** place icon into button */
  icon?: IconName;
  /** place icon to the right of content button */
  iconRight?: boolean;
  /** active loading spinner */
  loading?: boolean;
  /** deactivate ripple effect */
  noRipple?: boolean;
  /** small size button */
  small?: boolean;
  /** used for button select */
  select?: boolean;
}

const normalBtnSize = css`
  ${getTypo('fontButton')};
  ${Shapes.button};
`;
const smallBtnSize = css`
  ${getTypo('smallFontButton')};
  ${Shapes.smallButton};
`;
const ContentButton = styled.span<{ show: boolean }>`
  position: relative;
  transition: ease opacity 200ms;
  opacity: ${({ show }): number => (show ? 1 : 0)};
`;
const buttonShape = css<{ small?: boolean }>`
  ${({ small }) => (small ? smallBtnSize : normalBtnSize)};
`;
const buttonBackground = css<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover:not(:disabled) {
    background-color: ${({ backgroundColor }) => lighten(0.03, backgroundColor)};
  }

  &:focus:not(:disabled) {
    background-color: ${({ backgroundColor }) => lighten(0.03, backgroundColor)};
  }
`;
const buttonBorder = css<{ borderColor: string }>`
  border-color: ${({ borderColor }) => borderColor};

  &:hover:not(:disabled) {
    border-color: ${({ borderColor }) => lighten(0.03, borderColor)};
  }

  &:focus:not(:disabled) {
    border-color: ${({ borderColor }) => lighten(0.03, borderColor)};
  }

  &:active:not(:disabled) {
    border-color: ${({ borderColor }) => lighten(0.03, borderColor)};
  }
`;
const buttonFontColor = css<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
`;
const ButtonStyle = css`
  position: relative;
  transition: background-color ease-in-out 200ms, ease-in-out border-color 200ms,
    ease-in-out color 200ms, ease-in-out box-shadow 200ms;
  transform-origin: center;

  &:focus:not(:disabled) {
    ${Shapes.shadow1};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey80};
    border-color: ${({ theme }) => theme.colors.grey80};
    color: ${({ theme }) => theme.colors.light};
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    ${Shapes.shadow2};
  }
`;
const ButtonContainer = mergeStyled(styled(BaseButton)<ButtonStyledProps>``, [
  buttonShape,
  ButtonStyle,
  buttonBackground,
  buttonFontColor,
  buttonBorder,
]);
const IconContainer = styled.div<IconContainerProps>`
  display: inline-block;
  line-height: 0.83;
  vertical-align: middle;
  ${({ marginSide }) => 'margin-' + marginSide + ': 4px'};
  margin-top: -2px;
  margin-bottom: -1px;
`;

const LoaderContainerButton = styled.div<LoaderContainerButtonProps>`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  display: flex;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  align-items: center;
  justify-content: center;
`;

export const Button: FC<ButtonProps> = props => {
  const {
    loading,
    small,
    noRipple,
    children,
    disabled,
    colorType,
    icon,
    iconRight,
    backgroundColor,
    borderColor,
    fontColor,
    onClick,
    select,
    ...rest
  } = props;
  const theme = useContext<ThemeType>(ThemeContext);
  const iconSize = small ? '16px' : '24px';

  const getPartColor = (): { FC: string; BGC: string; BC: string } => {
    switch (colorType) {
      case 'outlineSecondary':
        return {
          FC: theme.colors.secondary,
          BGC: 'transparent',
          BC: theme.colors.secondary,
        };
      case 'outline':
        return {
          FC: theme.colors.primary,
          BGC: 'transparent',
          BC: theme.colors.primary,
        };
      case 'primary':
        return {
          FC: theme.colors.invertPrimary,
          BGC: theme.colors.primary,
          BC: theme.colors.primary,
        };
      case 'secondary':
        return {
          FC: theme.colors.invertSecondary,
          BGC: theme.colors.secondary,
          BC: theme.colors.secondary,
        };
      default:
        return {
          FC: theme.colors.grey10,
          BGC: theme.colors.grey90,
          BC: theme.colors.grey90,
        };
    }
  };
  const { FC, BGC, BC } = getPartColor();
  const renderIcon = (marginSide: string, select?: boolean): React.ReactNode | undefined => {
    if (!icon) {
      return;
    }
    return select ? (
      iconRight ? (
        <Svg
          icon={icon}
          width={iconSize}
          height={iconSize}
          style={{ position: 'absolute', top: '50%', right: '5', transform: 'translateY(-50%)' }}
          fill={fontColor || FC}
        />
      ) : (
        <Svg
          icon={icon}
          width={iconSize}
          height={iconSize}
          style={{ position: 'absolute', top: '50%', left: '5', transform: 'translateY(-50%)' }}
          fill={fontColor || FC}
        />
      )
    ) : (
      <IconContainer marginSide={marginSide}>
        <Svg icon={icon} width={iconSize} height={iconSize} fill={fontColor || FC} />
      </IconContainer>
    );
  };
  return (
    <ButtonContainer
      {...(rest as any)}
      small={small}
      disabled={disabled}
      backgroundColor={backgroundColor || BGC}
      borderColor={borderColor || BC}
      fontColor={fontColor || FC}
      onClick={e => (onClick && !loading ? onClick(e) : null)}
    >
      {!loading && !iconRight ? renderIcon('right', select) : undefined}
      <ContentButton className={'lnk-button__content'} show={!loading}>
        {children}
      </ContentButton>
      {!loading && iconRight ? renderIcon('left', select) : undefined}
      <LoaderContainerButton hide={!loading}>
        <Svg animatedIcon={'spinner'} stroke={fontColor || FC} width={iconSize} height={iconSize} />
      </LoaderContainerButton>
      {!noRipple && !disabled ? <Ripple color={fontColor || FC} /> : ''}
    </ButtonContainer>
  );
};
