import { Recipient, ReferentialCustomer } from '../interface/document.type';
import { Address } from '../interface/company.types';


export const UpdateRecipientFromReferential = (recipient: Recipient, customer: ReferentialCustomer): Recipient => {
  return {
    ...recipient,
    customerId: customer.id,
    lastName: customer.lastName,
    firstName: customer.firstName,
    companyName: customer.companyName,
    address: customer.address,
    telephone: customer.telephone,
    email: customer.email,
  };
};

export const isSameAddress = (a: Address,b: Address): boolean => {
  let same = true;
    if(a.city !== b.city){
      same = false;
    }
    if(a.postalCode !== b.postalCode){
      same = false;
    }
    if(a.state !== b.state){
      same = false;
    }
    if(a.province !== b.province){
      same = false;
    }
    if(a.country !== b.country){
      same = false;
    }
    if (
      JSON.stringify(a.addressLines) !== JSON.stringify(b.addressLines)){
      same = false;
    }
  return same
}
export const IsSameRecipientAsCustomer = (recipient: Recipient, customer: ReferentialCustomer): boolean => {
  let same = true;
  if (customer.firstName !== recipient.firstName) {
    same = false;
  }
  if (customer.lastName !== recipient.lastName) {
    same = false;
  }
  if (customer.email !== recipient.email) {
    same = false;
  }
  if (customer.telephone !== recipient.telephone) {
    same = false;
  }
  if (customer.telephone !== recipient.telephone) {
    same = false;
  }
  if (!isSameAddress(customer.address ,recipient.address)) {
    same = false;
  }

  return same;
};