import * as React from 'react';
import { ChangeEvent, useContext } from 'react';
import { LabelWrapper, LabelWrapperProps } from '../label-wrapper';
import styled, { ThemeContext } from 'styled-components';
import { Svg } from '../svg';
import { SpanBody1, ThemeType } from '../../common';

export interface RadioProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'label'>,
    LabelWrapperProps {
  /**
   * the name of the group
   */
  name: string;
  onChange: (val: boolean, originalEvent: ChangeEvent) => void;
  value: boolean;
}

const InvisibleInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;
const Label = styled.label`
  display: inline-block;
  white-space: nowrap;
`;
const Circle = styled.span<{ checked: boolean; hasError?: boolean; disabled?: boolean }>`
  display: inline-block;
  vertical-align: top;

  > svg {
    border-radius: 50%;

    > :first-child {
      stroke: ${({ hasError, theme, checked }) =>
        checked
          ? theme.colors.primary
          : hasError
          ? theme.colors.danger + '!important'
          : theme.colors.grey60};
    }

    > :last-child {
      fill: ${({ checked, theme }) => (checked ? theme.colors.primary : 'transparent')};
      transition: transform ease-in-out 150ms;
      transform: scale(${({ checked }) => (checked ? 1 : 0)});
      transform-origin: center;
    }
  }
`;

const Title = styled(SpanBody1)<{ checked: boolean; disabled?: boolean }>`
  display: inline-block;
  white-space: normal;
  transition: color ease-in-out 200ms;
  color: ${({ checked, theme }) => (checked ? theme.colors.grey10 : theme.colors.grey60)};

  ${Label}:hover & {
    ${({ disabled, theme }) => (disabled ? '' : 'color: ' + theme.colors.grey40)};
  }

  ${Label}:focus-within & {
    text-decoration: underline;
  }
`;
export const Radio: React.FC<RadioProps> = props => {
  const theme = useContext<ThemeType>(ThemeContext);
  const {
    onChange,
    value,
    children,
    label,
    right,
    hasError,
    caption,
    disabled,
    required,
    name,
    small,
    ...rest
  } = props;
  const changeCheckbox = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked, event);
  };

  const renderTitle = (
    <Title
      checked={value}
      disabled={disabled}
      style={
        right
          ? {
              textAlign: 'right',
              marginRight: '5px',
            }
          : {
              textAlign: 'left',
              marginLeft: '5px',
            }
      }
    >
      {children}
    </Title>
  );
  return (
    <LabelWrapper {...{ label, caption, required, hasError, right, disabled, small }}>
      <Label>
        <InvisibleInput
          {...(rest as any)}
          {...{ disabled, required }}
          onChange={changeCheckbox}
          checked={value}
          name={name}
          type={'radio'}
        />
        {right && renderTitle}
        <Circle checked={value} hasError={hasError} disabled={disabled}>
          <Svg fill={theme.colors.light} width={24} height={24}>
            <circle cx="24" cy="24" r="18" strokeWidth="4" />
            <circle cx="24" cy="24" r="12" />
          </Svg>
        </Circle>
        {!right && renderTitle}
      </Label>
    </LabelWrapper>
  );
};
