import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { LabelWrapperProps } from '../label-wrapper';
import { CalendarProps } from '../calendar';
import { Input } from '../input';
import { dateToString, IsDisabledDate, localToDateParams } from '../../helpers';

type OmitBaseInput = Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'label'>;

export interface InputDateProps extends LabelWrapperProps, CalendarProps, OmitBaseInput {
  onChange: (event: Date | null) => void;
  value?: Date | null;
  children?: React.ReactNode;
}

export const InputDate: FC<InputDateProps> = ({
  value,
  onChange,
  disabledDays,
  readOnly,
  right,
  locale,
  ...props
}) => {
  const [getType, setType] = useState<string>(dateToString(value, locale));
  useEffect(() => {
    setType(dateToString(value, locale));
  }, [value, locale]);
  const onTypeDate = (value: string) => {
    const intlParams = localToDateParams(locale);
    if (!value) {
      setType('');
      if (value !== null) {
        onChange(null);
      }
      return;
    }
    const regDate = /^(\d{2}).?(\d{2}).?(\d{4})$/gm;
    const lastChar = value[value.length - 1];
    if (lastChar && lastChar !== intlParams.separator && isNaN(parseInt(lastChar, 10))) {
      return;
    }
    if (lastChar !== intlParams.separator && value.length === 3) {
      const withFirstSeparation = value.slice(0, 2) + intlParams.separator + value.slice(2);
      setType(withFirstSeparation);
      return;
    }
    if (lastChar !== intlParams.separator && value.length === 6) {
      const withSecondSeparation = value.slice(0, 5) + intlParams.separator + value.slice(5);
      setType(withSecondSeparation);
      return;
    }
    const result = regDate.exec(value);
    if (value.length > 10) {
      return;
    }
    if (result) {
      const day = Number.parseInt(result[intlParams.order.indexOf('d') + 1]);
      const month = Number.parseInt(result[intlParams.order.indexOf('m') + 1]);
      const year = Number.parseInt(result[intlParams.order.indexOf('y') + 1]);
      const date = new Date(year, month - 1, day);
      if (IsDisabledDate(date, disabledDays)) {
        return;
      }
      onChange(date);
    }
    setType(value);
  };
  return (
    <Input
      {...(props as any)}
      {...{ readOnly, right }}
      autoComplete="off"
      value={getType}
      onChange={onTypeDate}
    />
  );
};
