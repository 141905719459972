import React, { FC, FormEvent, useState } from 'react';
import { QuestionNumber } from '../../interface/question.types';
import { Box, Input } from '@linkeo.com/ui-lib-react';
import { QuestionCommon } from './question-common';
import { QuestionFooter } from './question-footer';
import { useIntl } from 'react-intl';

interface NumberFormProps {
  onClose: () => void;
  onSubmit: (value: QuestionNumber) => void;
  question: QuestionNumber;
}

export const NumberForm: FC<NumberFormProps> = props => {
  const { onClose, question, onSubmit } = props;
  const intl = useIntl();
  const [getQuestion, setQuestion] = useState<QuestionNumber>(question);
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(getQuestion);
  };
  return <Box
    title={intl.formatMessage({id: 'questionFormNumberTitle', defaultMessage: 'Question quantité'})}
    subtitle={intl.formatMessage({id: 'questionFormNumberSubtitle', defaultMessage: 'Les prospects à qui vous poserez cette question devront répondre par une quantité.{br}Le tarif en cours de calcul sera multiplié par cette quantité pour fournir une estimation.'}, {br: <br />}) as string}
    onClose={onClose}>
    <form onSubmit={emitSubmit} style={{ padding: '0 40px 40px 40px' }}>
      <QuestionCommon
        onChange={v => setQuestion({ ...getQuestion, ...(v as QuestionNumber) })}
        value={getQuestion}>
        <Input
          required
          label={intl.formatMessage({id: 'questionFormNumberUnitLabel', defaultMessage: 'Unité à utiliser pour cette question de quantité'})}
          value={getQuestion.unit}
          onChange={v => setQuestion({ ...getQuestion, unit: v })} />
      </QuestionCommon>
      <QuestionFooter onCancel={onClose} />
    </form>
  </Box>;
};
