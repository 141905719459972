import * as React from 'react';
import { FC, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import { mergeStyled } from '../../helpers';

interface RippleProps {
  color?: string;
}

interface ActiveRipple {
  x: number;
  y: number;
  rand: number;
}

const rippleEffect = keyframes`
    0% {
      opacity: 1;
      transform: scale(1, 1);
    }
    100%{
      opacity: 0;
      transform: scale(20, 20);
    }
`;
const RIPPLE_DURATION = 1000;

const RippleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;
const RippleSubContainer = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;
`;
const RippleColor = css<{ color?: string }>`
  &:not(svg) {
    background: ${({ color, theme }) => (color ? rgba(color, 0.2) : theme.colors.light)};
  }

  &.svg {
    fill: ${({ color, theme }): string => (color ? rgba(color, 0.2) : theme.colors.light)};
  }
`;
const RippleObject = mergeStyled(
  styled.span<{ color?: string }>`
    position: absolute;
    opacity: 0;
    transform: scale(10, 10);
    transition: opacity 0.3s ease-in-out;
    transform-origin: center;
    animation: ${rippleEffect} ${RIPPLE_DURATION}ms ease-out;
    animation-delay: 10ms;
    border-radius: inherit;

    &:not(svg) {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  `,
  [RippleColor]
);
export const Ripple: FC<RippleProps> = props => {
  const { color } = props;
  const refContainer = useRef<HTMLDivElement>(null);
  const [getActives, setActives] = useState<ActiveRipple[]>([]);
  const addRipple = (event: React.MouseEvent) => {
    const current = refContainer.current;
    if (!current) {
      return;
    }
    const rect = current.getBoundingClientRect();
    setActives([
      {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        rand: Math.random(),
      },
    ]);
  };
  return (
    <RippleContainer ref={refContainer} onMouseDown={addRipple}>
      {getActives.map(ripple => (
        <RippleSubContainer key={ripple.rand}>
          <RippleObject
            style={{
              top: `${ripple.y - 10}px`,
              left: `${ripple.x - 10}px`,
            }}
            color={color}
          />
        </RippleSubContainer>
      ))}
    </RippleContainer>
  );
};
