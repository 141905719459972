import { FC, useEffect, useRef, useState } from 'react';
import { PageWrapper } from '../../components';
import { Button, Col, Container, Row, useToaster } from '@linkeo.com/ui-lib-react';
import { DocumentView } from '../../components/document/document-view';
import { DocumentStatus, DocumentType, QuoteDocument } from '../../interface/document.type';
import { useApi } from '../../providers/api-provider';
import { NumberMod } from '../../interface/product.types';
import { useHistory } from 'react-router-dom';
import { DocumentParamProvider } from '../../providers/document-param.provider';
import { FinalisationDocumentDialog } from '../../components/document/finalisation-document-dialog';
import { getInvalidQuoteDocument, QuoteDocumentErrors } from '../../utils/quote-document-validator';
import { useRouting } from '../../providers/routing.provider';
import { FormattedMessage, useIntl } from 'react-intl';

const defaultMod: NumberMod = {
  value: 0,
  type: 'percentage',
};

interface DocumentNewPageProps {
  documentType: DocumentType;
}

export const DocumentNewPage: FC<DocumentNewPageProps> = props => {
  const { documentType } = props;
  const [getDocument, setDocument] = useState<QuoteDocument>();
  const [getErrors, setErrors] = useState<QuoteDocumentErrors>({});
  const [getLoading, setLoading] = useState<boolean>(false);
  const [getShowConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const intl = useIntl();
  const history = useHistory();
  const toast = useToaster();
  const API = useApi();
  const { beforeRouteChange, removeChangeEvent } = useRouting();
  const changeRef = useRef<number>(0);
  useEffect(() => {
    API.simulateDocument({
      items: [],
      status: 'draft',
      title: '',
      type: documentType,
      deposit: defaultMod,
      discount: defaultMod,
    }).then((doc) => {
      setDocument({
        ...doc,
      });
    });
  }, [API, documentType]);
  const updateDraft = (doc: QuoteDocument) => {
    const { recipient, issueDate, expirationDate, ...rest } = doc;
    setLoading(true);
    API.simulateDocument({ ...rest }).then(
      result => setDocument({
        ...doc, ...result,
        recipient,
        issueDate,
        expirationDate,
        title: doc.title,
      })).catch(() => {
        toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'}))
    }).finally(() => setLoading(false));
  };
  const emitSave = (status: DocumentStatus) => {
    if (!getDocument) {
      return;
    }
    setLoading(true);
    const { ...rest } = getDocument;
    changeRef.current = 1;
    API.createDocument({ ...rest, status }).then(result => {
      history.push(`/draft/document/${result.id}`);
    }).catch(() => {
      toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'}))
    }).finally(() => {
      setLoading(false);
      setShowConfirmDialog(false);
    });
  };
  useEffect(() => {
    if (!getDocument) {
      return;
    }
    beforeRouteChange((event) => {
      if (changeRef.current > 1) {
        const confirmation = confirm(
          intl.formatMessage({
            id: 'confirmNoSave',
            defaultMessage: 'vos changements n’ont pas été enregistré voulez vous continuez',
          }),
        );
        if (!confirmation) {
          event.preventDefault();
        }
      }
    });
    changeRef.current++;
    setErrors(getInvalidQuoteDocument(getDocument, getDocument.parameters.locale));
    return () => {
      removeChangeEvent();
    };
  }, [getDocument, changeRef, beforeRouteChange, removeChangeEvent, intl]);
  return <PageWrapper isLoading={!getDocument}>
    <DocumentParamProvider>
      {getDocument &&
        <Container size={'lg'}>
          <DocumentView
            errors={getErrors}
            readOnly={getLoading}
            quoteDocument={getDocument}
            onDocumentChange={updateDraft}>
          </DocumentView>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <Button loading={getLoading} colorType={'secondary'} onClick={() => emitSave('draft')}>
                <FormattedMessage id={'saveDraft'} defaultMessage={'Enregistrer le brouillon'} />
              </Button>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Button disabled={!!Object.keys(getErrors).length} loading={getLoading} colorType={'primary'}
                      onClick={() => setShowConfirmDialog(true)}>
                {getDocument.type === 'quote' ? intl.formatMessage({
                  id: 'finalizeQuote',
                  defaultMessage: 'Finaliser le devis',
                }) : intl.formatMessage({ id: 'finalizeInvoice', defaultMessage: 'Finaliser la facture' })}
              </Button>
            </Col>
          </Row>
          <FinalisationDocumentDialog
            isLoading={getLoading}
            onClose={() => setShowConfirmDialog(false)} onConfirm={() => emitSave('finalized')}
            show={getShowConfirmDialog} documentType={getDocument.type} />
        </Container>
      }
    </DocumentParamProvider>
  </PageWrapper>;
};
