import * as React from 'react';
import styled from 'styled-components';
import { Shapes } from '../../common';
import { Flex } from '../flex';
import { Pad } from '../../helpers';
import { NumberSelect } from '../../others/number-select/number-select';

export interface TimeSelectProps {
  value: string;
  /**
   * Default time is 12:00
   */
  defaultTime?: string;
  onChange: (event: string) => void;
}

const TimeSelectContainer = styled.div`
  ${Shapes.card};
  width: 140px;
  background: ${({ theme }) => theme.colors.light};
`;

export const TimeSelect: React.FC<TimeSelectProps> = props => {
  const { value, defaultTime, onChange } = props;
  const regex = /^(\w{2}):(\w{2})$/;
  const exec = regex.exec(value);
  let hours = 12;
  let minutes = 0;
  if (exec) {
    hours = Number.parseInt(exec[1]);
    minutes = Number.parseInt(exec[2]);
  } else {
    if (defaultTime) {
      const execDefault = regex.exec(defaultTime);
      if (execDefault) {
        hours = Number.parseInt(execDefault[1]);
        minutes = Number.parseInt(execDefault[2]);
      }
    }
  }
  const changeHour = (event: number) => {
    const val = Pad(event, 2) + ':' + Pad(minutes, 2);
    onChange(val);
  };
  const changeMinute = (event: number) => {
    const val = Pad(hours, 2) + ':' + Pad(event, 2);
    onChange(val);
  };
  return (
    <TimeSelectContainer>
      <Flex alignItems={['center']} style={{ padding: '0 16px' }}>
        <NumberSelect max={23} min={0} onChange={changeHour} value={hours} />
        <span style={{ margin: '0 5px' }}>:</span>
        <NumberSelect max={59} min={0} onChange={changeMinute} value={minutes} />
      </Flex>
    </TimeSelectContainer>
  );
};
