import { FC } from "react";
import styled, { useTheme } from "styled-components";
import { Button, Flex, getTypo, Svg } from "@linkeo.com/ui-lib-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { routeHome } from "../../routes";

const H1 = styled.h1`
  ${getTypo('h1')};
  color: black;
  margin: 10px 0;
`;
const Text = styled.p`
  ${getTypo('h6')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 30px 0;
`;

export const Error404: FC = () => {
    const theme = useTheme();
    const intl = useIntl();

    return <Flex justifyContent={['center']} style={{ paddingTop: '100px' }}>
        <div style={{ textAlign: 'center' }}>
            <Svg icon={'unhappy'} width={36} height={36} fill={theme.colors.grey60} />
            <H1>404</H1>
            <Text>
                <FormattedMessage id={'notFoundBody'} defaultMessage={'La page que vous recherchez{br}semble introuvable.'} values={{ br: <br /> }} />
            </Text>
            <Link to={routeHome}>
                <Button colorType={'outlineSecondary'} backgroundColor={'#F5F5F5'}>
                    {intl.formatMessage({ id: 'notFoundReturn', defaultMessage: 'Retour à l’accueil' })}
                </Button>
            </Link>
        </div>
    </Flex>
}