import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { LabelWrapperProps } from '../label-wrapper';
import { Dropdown } from '../dropdown';
import { Debounce, MatchLike, NormalizeText } from '../../helpers';
import { SelectMenu } from '../select-menu';
import { SelectOption } from '../../common';
import { Input } from '../input';

export interface SelectProps<T> extends LabelWrapperProps {
  debounceTimeout?: number;
  onDebounceTyping?: (val: string) => void;
  onSelect?: (val: SelectOption<T>) => void;
  onTyping?: (val: string) => void;
  options?: SelectOption<T>[];
  readOnly?: boolean;
  selected?: SelectOption<T>;
  /**
   * can be uncontrolled value
   */
  typing?: string;
}

export function Select<T>(props: React.PropsWithChildren<SelectProps<T>>) {
  const {
    typing,
    onTyping,
    onSelect,
    selected,
    caption,
    label,
    hasError,
    disabled,
    required,
    right,
    options,
    readOnly,
    small,
    debounceTimeout,
    onDebounceTyping,
  } = props;
  const [getTyping, setTyping] = useState<string>(typing || '');
  const [getSelected, setSelected] = useState<SelectOption<T> | undefined>();
  useEffect(() => {
    if (typeof typing === 'undefined') {
      return;
    }
    setTyping(typing);
  }, [typing]);
  useEffect(() => {
    setSelected(selected);
    if (selected) {
      setTyping(selected.label);
    }
  }, [selected]);
  const [getFiltered, setFiltered] = useState<SelectOption<T>[]>(options || []);
  const [getSelIndex, setSelIndex] = useState<number | undefined>();
  const [getNoFilter, setNoFilter] = useState<boolean>(true);
  const [getOpen, setOpen] = useState<boolean>(false);
  const refWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!options) {
      return;
    }
    if (getNoFilter) {
      setFiltered(options);
      return;
    }
    const normalizeInput = NormalizeText(getTyping);
    setFiltered(
      options.filter(item => {
        const normalizeItem = NormalizeText(item.label);
        return MatchLike(normalizeItem, normalizeInput);
      })
    );
  }, [getTyping, options, getNoFilter]);
  useEffect(() => {
    if (!getFiltered.length) {
      setSelIndex(undefined);
      return;
    }
    if (!getSelected) {
      setSelIndex(0);
      return;
    }
    const searchIndex = getFiltered.findIndex(f => f.label === getSelected.label);
    if (searchIndex < 0) {
      setSelIndex(0);
      return;
    }
    setSelIndex(searchIndex);
  }, [getFiltered, getSelected]);
  const debounceRef = useRef<number | undefined>();
  const emitTyping = (val: string) => {
    setNoFilter(false);
    setOpen(true);
    if (onTyping) {
      onTyping(val);
    } else {
      setTyping(val);
    }
    if (!onDebounceTyping) {
      return;
    }
    debounceRef.current = Debounce(
      () => {
        onDebounceTyping(val);
      },
      debounceTimeout || 500,
      debounceRef.current || 0
    );
  };

  const getItemByIndex = (index?: number): SelectOption<T> | undefined => {
    if (typeof index === 'undefined') {
      return;
    }
    return getFiltered[index];
  };
  const emitSelection = (item: SelectOption<T> | undefined): void => {
    if (!item) {
      return;
    }
    if (onSelect) {
      onSelect(item);
    } else {
      setSelected(item);
    }
    setOpen(false);
    setTimeout(() => {
      //for let time dropdown close
      setNoFilter(true);
    }, 300);
  };
  const selectDown = (): void => {
    if (typeof getSelIndex === 'undefined') {
      return;
    }
    let newIndex = getSelIndex + 1;
    if (newIndex > getFiltered.length - 1) {
      newIndex = 0;
    }
    setSelIndex(newIndex);
  };
  const selectUp = (): void => {
    if (typeof getSelIndex === 'undefined') {
      return;
    }
    let newIndex = getSelIndex - 1;
    if (newIndex < 0) {
      newIndex = setFiltered.length - 1;
    }
    setSelIndex(newIndex);
  };
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (readOnly) {
      return;
    }
    switch (event.keyCode) {
      case 40: // down
        selectDown();
        setOpen(true);
        break;
      case 38: // up
        selectUp();
        setOpen(true);
        break;
      case 27: // escape
        setTyping('');
        break;
      case 13: // enter
        emitSelection(getItemByIndex(getSelIndex));
        break;
      case 9: // tab
      default:
    }
  };
  return (
    <div style={{ position: 'relative', width: '100%' }} ref={refWrapper}>
      <Input
        {...{ caption, label, hasError, disabled, required, right, small }}
        onChange={emitTyping}
        value={getTyping}
        autoComplete="off"
        onFocus={readOnly ? undefined : () => setOpen(true)}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        icon={readOnly ? undefined : 'down'}
      />
      {refWrapper && (
        <Dropdown
          wrapperRef={refWrapper}
          onClose={() => setOpen(false)}
          fulfilled
          show={getOpen}
          offset={-12}
        >
          <SelectMenu<T>
            onSelect={emitSelection}
            highlight={getTyping}
            selected={typeof getSelIndex !== 'undefined' ? getFiltered[getSelIndex] : undefined}
            items={getFiltered}
          />
        </Dropdown>
      )}
    </div>
  );
}
