import {
  Button,
  CardSubtitle,
  CardTitle,
  Checkbox,
  DraggableList,
  DraggableListItem,
  Shapes,
  SpanSubtitle1,
} from '@linkeo.com/ui-lib-react';
import React, { FC } from 'react';
import { AIDES_ET_PRIMES, FormType, ScopeQuestion, ScopeType } from '../../interface/scope.types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useConfirm } from '../../providers/confirm-provider';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeActivities } from '../../routes';

const GreyBox = styled.div`
  ${Shapes.bubble};
  text-align: center;
  padding: 30px;
  color: ${({ theme }) => theme.colors.grey40};
`;

interface ScopeListProps {
  onAddQuestion: () => void;
  onChange: (questions: ScopeQuestion[]) => void;
  onChangeQuestion: (question: ScopeQuestion) => void;
  onDeleteQuestion: (question: ScopeQuestion) => void;
  onEditQuestion: (question: ScopeQuestion) => void;
  questions: ScopeQuestion[];
  scope: ScopeType;
  formType?: FormType | null;
}

export const ScopeList: FC<ScopeListProps> = ({
                                                onChange,
                                                questions,
                                                scope,
                                                onChangeQuestion,
                                                onDeleteQuestion,
                                                onEditQuestion,
                                                formType,
                                              }) => {
  const history = useHistory();
  const intl = useIntl();
  const { openConfirm } = useConfirm();
  let title = '';
  let subtitle = '';
  switch (scope) {
    case 'ACTIVITY':
      title = intl.formatMessage({ id: 'presentationActivitiesCategoryTitle', defaultMessage: 'Catégorie d’activité' });
      break;
    case 'CONTACT':
      title = intl.formatMessage({ id: 'presentationContactTitle', defaultMessage: 'Contact' });
      subtitle = intl.formatMessage({
        id: 'presentationContactSubtitle',
        defaultMessage: 'Quelles informations de contact voulez-vous obtenir ?',
      });
      break;
    case 'TARGETING':
      title = intl.formatMessage({ id: 'presentationTargetTitle', defaultMessage: 'Ciblage' });
      subtitle = intl.formatMessage({
        id: 'presentationTargetSubtitle',
        defaultMessage: 'Quelles données souhaitez-vous demander à vos prospect pour mieux les cibler ?',
      });
      break;
    case 'ACTIVITY_QUESTIONS':
      title = intl.formatMessage({ id: 'presentationActivityTitle', defaultMessage: 'Activités' });
      subtitle = intl.formatMessage({
        id: 'presentationActivitySubtitle',
        defaultMessage: 'Demandez des informations supplémentaires à la suite des activités',
      });
      break;
  }

  const reorder = (item: DraggableListItem<ScopeQuestion>[]) => {
    onChange(item.map(i => i.data));
  };

  const toggleItem = (item: DraggableListItem<ScopeQuestion>) => {
    onChangeQuestion({
      ...item.data,
      active: !item.data.active,
    });
  };
  const deleteItem = (item: DraggableListItem<ScopeQuestion>) => {
    openConfirm({
      title: '',
      content: intl.formatMessage({
        id: 'confirmDelete',
        defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}',
      }, { name: item.title }),
    }).then(() => {
      onDeleteQuestion(item.data);
    }).catch(() => {
    });
  };
  const toggleMandatory = (item: ScopeQuestion, value: boolean) => {
    onChangeQuestion({
      ...item,
      mandatory: value,
    });
  };

  return <>
    <div style={{ margin: '40px 0' }}>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
      {
        scope === 'ACTIVITY' && !questions.some((q) => q.type === 'CATEGORY') && <GreyBox>
          <SpanSubtitle1>
            {formType === AIDES_ET_PRIMES
              ? <FormattedMessage
                id={'scopeNoAssistanceActivityNotification'}
                defaultMessage={'Vous n’avez pas encore ajouter vos activités d\'aide{br}Commencez par cette étape avant de paramétrer la présentation de votre formulaire.'}
                values={{
                  br: <br />,
                }} />
              : <FormattedMessage
              id={'scopeNoActivityNotification'}
              defaultMessage={'Vous n’avez pas encore ajouter vos activités{br}Commencez par cette étape avant de paramétrer la présentation de votre formulaire.'}
              values={{
                br: <br />,
              }} />}
          </SpanSubtitle1>
          <div style={{ marginTop: '20px' }}>
            <Button onClick={() => history.push(routeActivities)}
                    colorType={'primary'}>{intl.formatMessage({
              id: 'addMyActivity',
              defaultMessage: 'Ajouter mes activités',
            })}</Button>
          </div>
        </GreyBox>
      }
      <DraggableList<ScopeQuestion>
        items={questions.map(q => ({
          data: q,
          id: q.id,
          title: q.name,
          subtitle: q.description,
          canRemove: q.type === 'QUESTION_CUSTOM',
          displayModify: q.type === 'QUESTION_CUSTOM',
          canToggle: q.type !== 'QUESTION_CUSTOM',
          toggleState: q.active,
          custom: q.type === 'QUESTION_REFERENTIAL' ? <Checkbox
            value={q.mandatory} onChange={e => toggleMandatory(q, e)}
            right>{intl.formatMessage({
            id: 'mandatoryField',
            defaultMessage: 'Champ obligatoire',
          })}</Checkbox> : undefined,
        }))}
        onChange={reorder}
        onToggle={toggleItem}
        onDelete={deleteItem}
        onEdit={d => onEditQuestion(d.data)}
      />
      {/*            <div style={{margin: '30px 0'}}>
                <BaseButton style={{color: Theme.primary.color}} onClick={onAddQuestion}>
                    +
                    <SpanLink
                        style={{color: Theme.primary.color}}>
                        {intl.formatMessage({id: 'askQuestion', defaultMessage: 'Poser une question supplémentaire'})}
                    </SpanLink>
                </BaseButton>
            </div>*/}
    </div>

  </>;
};
