import React, { FC } from 'react';
import { Choice, EditChoice } from '../../interface/choice.types';
import { Button, Dialog } from '@linkeo.com/ui-lib-react';
import { ChoiceForm } from './choice.form';
import { useIntl } from 'react-intl';

interface ChoiceDialogProps {
  choice?: Choice;
  onClose: () => void;
  onSubmit: (value: EditChoice) => void;
  loading: boolean
}

export const ChoiceDialog: FC<ChoiceDialogProps> = props => {
  const { choice, onSubmit, onClose, loading } = props;
  const intl = useIntl();
  const footer = <>
    <Button colorType={'secondary'} onClick={onClose}>
      {intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}
    </Button>
    <Button form={'choiceForm'} colorType={'secondary'} type={'submit'} loading={loading}>
      {intl.formatMessage({id: 'save', defaultMessage: 'Enregistrer'})}
    </Button>
  </>;
  return <Dialog
    show={!!choice}
    onClose={onClose}
    footer={footer}
    title={choice?.id.startsWith('NEW')
      ? intl.formatMessage({id: 'addAChoice', defaultMessage: 'Ajouter un choix'})
      : intl.formatMessage({id: 'modifyChoice', defaultMessage: 'Modifier le choix'})}
    size={'md'}>
    <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '70vh' }}>
      {
        !!choice && <ChoiceForm
          idForm={'choiceForm'}
          choice={choice}
          onSubmit={onSubmit} />
      }
    </div>
  </Dialog>;
};
