import { FC } from 'react';
import { Col, Row, SpanSubtitle2 } from "@linkeo.com/ui-lib-react"
import { InteractiveCol } from "../ui/interactive-col"
import { SmallCol, SmallRow } from "../ui/small-columns"
import { FormattedMessage, useIntl } from "react-intl"
import { COL_SIZES_CONFIG } from "../document/col-sizes-config"
import { Devise } from '../../utils/number.utils';
import { ItemState } from '../../interface/product.types';
import { SubtotalEdit } from './subtotal-edit';

interface SubtotalLineProps {
    readOnly: boolean;
    currency: Devise;
    onStateChange: (v: ItemState) => void;
    itemState: ItemState;
    onPositionMove: (direction: 'up' | 'down') => void;
    onDelete: () => void;
}

export const SubtotalLine: FC<SubtotalLineProps> = ({ readOnly, currency, itemState, onStateChange, onPositionMove, onDelete }) => {
    const intl = useIntl();

    if (itemState.item.itemType !== 'subtotal') {
        return null;
    }

    return <Row style={{ borderTop: '1px solid #ccc', marginBottom: '16px' }}>
        {itemState.editMode ? <Col columns={[12]}>
            <SubtotalEdit
                onPositionMove={onPositionMove}
                total={intl.formatNumber(itemState.item.total?.totalWithoutTax || 0, {
                    style: 'currency',
                    currency,
                })}
                onDelete={onDelete}
            />
        </Col> :
            <InteractiveCol
                locked={itemState.readOnly || readOnly}
                columns={[12]}
                onClick={() => !itemState.readOnly && !readOnly && onStateChange({ ...itemState, editMode: true })}>
                <SmallRow justifyContent={['space-between']}>
                    <SmallCol columns={COL_SIZES_CONFIG[0]}>
                        <SpanSubtitle2 style={{ fontWeight: '800' }}>
                            <FormattedMessage id={'quoteNewLayoutSubtotal'} defaultMessage={'Sous-total'} />
                        </SpanSubtitle2>
                    </SmallCol>
                    <SmallCol columns={COL_SIZES_CONFIG[4]} style={{ textAlign: 'right' }}>
                        <SpanSubtitle2 style={{ fontWeight: '800' }}>{intl.formatNumber(itemState.item.total?.totalWithoutTax || 0, {
                            style: 'currency',
                            currency,
                        })}</SpanSubtitle2>
                    </SmallCol>
                </SmallRow>
            </InteractiveCol>}
    </Row>
}