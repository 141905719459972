import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { FlexColToCss, FlexCss } from './flex.utils';
import { FlexProperties } from './flex.type';
import { MakeCssMediaQuery, DEVICES } from '../../common';
import { mergeStyled } from '../../helpers';

export interface FlexProps extends HTMLProps<HTMLDivElement>, FlexProperties {
  columns?: number | number[];
}

export const ResponsiveWrap = css<FlexProperties>`
  ${({ wraps }) =>
    typeof wraps === 'string'
      ? `flex-wrap: ${wraps};`
      : wraps
      ? DEVICES.slice(0, wraps.length).map(device =>
          MakeCssMediaQuery(FlexCss(device, 'flex-wrap: ', wraps), device)
        )
      : 'flex-wrap: wrap'};
`;
export const ResponsiveAlignItems = css<FlexProperties>`
  ${({ alignItems }) =>
    typeof alignItems === 'string'
      ? `align-items: ${alignItems};`
      : alignItems
      ? DEVICES.slice(0, alignItems.length).map(device =>
          MakeCssMediaQuery(FlexCss(device, 'align-items: ', alignItems), device)
        )
      : ''};
`;
export const ResponsiveJustifyContent = css<FlexProperties>`
  ${({ justifyContent }) =>
    typeof justifyContent === 'string'
      ? `justify-content: ${justifyContent};`
      : justifyContent
      ? DEVICES.slice(0, justifyContent.length).map(device =>
          MakeCssMediaQuery(FlexCss(device, 'justify-content: ', justifyContent), device)
        )
      : ''};
`;
export const ResponsiveDirection = css<FlexProperties>`
  ${({ direction }) =>
    typeof direction === 'string'
      ? `flex-direction: ${direction};`
      : direction
      ? DEVICES.slice(0, direction.length).map(device =>
          MakeCssMediaQuery(FlexCss(device, 'flex-direction: ', direction), device)
        )
      : ''};
`;
export const ResponsiveCol = css<{ columns?: number[] }>`
  ${({ columns }) =>
    typeof columns === 'number'
      ? columns < 0
        ? ''
        : `width: ${(100 * columns) / 12}%;`
      : columns
      ? DEVICES.slice(0, columns.length).map(device =>
          MakeCssMediaQuery(FlexColToCss(device, columns), device)
        )
      : ''};
`;
export const Flex = mergeStyled(
  styled.div<FlexProps>`
    display: flex;
    position: relative;
  `,
  [
    ResponsiveWrap,
    ResponsiveAlignItems,
    ResponsiveJustifyContent,
    ResponsiveDirection,
    ResponsiveCol,
  ]
);
