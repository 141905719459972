export const getFocusable = (element: HTMLElement): NodeListOf<HTMLElement> => {
  return element.querySelectorAll<HTMLElement>(`
    input:not([tabindex^="-"]):not(:disabled),
    textarea:not([tabindex^="-"]):not(:disabled),
    select:not([tabindex^="-"]):not(:disabled),
    button:not([tabindex^="-"]):not(:disabled),
    a[href]:not([tabindex^="-"]):not(:disabled),
    *[tabindex]:not([tabindex^="-"]):not(:disabled)
  `);
};
export const focusFirstFocusableElement = (element: HTMLElement): void => {
  const focusable = getFocusable(element);
  if (!focusable) {
    return;
  }
  if (focusable.length) {
    focusable[0].focus();
  }
};
export const focusLastFocusableElement = (element: HTMLElement): void => {
  const focusable = getFocusable(element);
  if (!focusable) {
    return;
  }
  if (focusable.length) {
    focusable[focusable.length - 1].focus();
  }
};
/**
 *
 * @param handler
 * @param time
 * @param ref du timeout
 * @constructor
 * @return return the new ref
 */
export const Debounce = (handler: () => void, time: number, ref: number): number => {
  window.clearTimeout(ref);
  return window.setTimeout(handler, time);
};
