import { FileStatus, FileType } from '../../common';

export class UploaderApi {
  public constructor(private apiBaseUri: string) {}

  async createMedias(
    codeBouton: string,
    body: FileType,
    token?: string,
    signal?: AbortSignal
  ): Promise<FileType> {
    const headers: HeadersInit = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const options: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
      signal,
    };
    const response = await fetch(`${this.apiBaseUri}/${codeBouton}/medias`, options);
    if (!response.ok) {
      throw new Error(`Failed to create media: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  }

  async addChunks(
    codeBouton: string,
    mediaId: string,
    file: Blob,
    index: number,
    token?: string,
    signal?: AbortSignal
  ): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    const headers: HeadersInit = {
      'Media-Chunk-Index': index.toString(),
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const options: RequestInit = {
      method: 'POST',
      headers,
      body: formData,
      signal,
    };
    const response = await fetch(
      `${this.apiBaseUri}/${codeBouton}/medias/${mediaId}/chunks`,
      options
    );
    if (!response.ok) {
      throw new Error(`Failed to add chunks: ${response.status} ${response.statusText}`);
    }
  }

  async completeMedias(
    codeBouton: string,
    mediaId: string,
    status: FileStatus,
    token?: string,
    signal?: AbortSignal
  ): Promise<FileType> {
    const body = JSON.stringify({ status });
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const options: RequestInit = {
      method: 'PATCH',
      headers,
      body,
      signal,
    };
    const response = await fetch(`${this.apiBaseUri}/${codeBouton}/medias/${mediaId}`, options);
    if (!response.ok) {
      throw new Error(`Failed to complete media: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  }

  async getMedias(
    codeBouton: string,
    mediaId: string,
    token?: string,
    signal?: AbortSignal
  ): Promise<FileType> {
    const headers: HeadersInit = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const options: RequestInit = {
      method: 'GET',
      headers,
      signal,
    };
    const response = await fetch(`${this.apiBaseUri}/${codeBouton}/medias/${mediaId}`, options);
    if (!response.ok) {
      throw new Error(`Failed to get media: ${response.status} ${response.statusText}`);
    }
    return await response.json();
  }
}
