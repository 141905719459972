import React, { FC, useEffect } from 'react';
import { BaseButton, InOut, SpanLink, Svg } from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';

export interface ScrollWindowPaginationProps {
  containerElement?: HTMLElement;
  isLoading: boolean;
  lock: boolean;
  moreResultLabel?: string;
  onNext: () => void;
}

export const ScrollWindowPagination: FC<ScrollWindowPaginationProps> = props => {
  const { onNext, lock, children, containerElement, isLoading, moreResultLabel } = props;
  const theme = useTheme();
  useEffect(() => {
    let oneTime = false;
    const onScroll = () => {
      if (lock || oneTime) {
        return;
      }
      const pos = window.pageYOffset + window.innerHeight + 20;
      const max = document.body.scrollHeight;
      if (pos >= max) {
        onNext();
        oneTime = true;
      }
    };
    (containerElement || window).addEventListener('scroll', onScroll, { passive: true });
    return () => {
      (containerElement || window).removeEventListener('scroll', onScroll);
    };
  }, [onNext, lock, containerElement]);
  return <>
    {children}
    <div style={{ textAlign: 'center' }}>
      {!lock && !isLoading && <BaseButton onClick={onNext}><SpanLink>{moreResultLabel}</SpanLink></BaseButton>}
      <InOut show={isLoading} keepContent={true}>
        <Svg animatedIcon={'spinner'} stroke={theme.colors.grey20} />
      </InOut>
    </div>
  </>;
};
