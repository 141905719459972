import * as React from 'react';
import { useContext } from 'react';
import { Flex } from '../flex';
import { Star } from '../star';
import styled, { ThemeContext } from 'styled-components';
import { getTypo, ThemeType } from '../../common';
import { Increment, SetInRange } from '../../helpers';

export interface EvaluationProps {
  color?: string;
  maxStars?: number;
  onChange?: (e: number) => void;
  showScore: boolean;
  scoreColor?: string;
  value: number;
}

const Score = styled.span<{ scoreColor?: string }>`
  ${getTypo('subtitle2')};
  padding-left: 10px;
  width: 50px;
  text-align: right;
  color: ${({ scoreColor, theme }) => (scoreColor ? scoreColor : theme.colors.grey60)};
`;
export const Evaluation: React.FC<EvaluationProps> = props => {
  const { value, onChange, maxStars, color, showScore, scoreColor } = props;
  const max = maxStars || 5;
  const theme = useContext<ThemeType>(ThemeContext);
  const readOnly = !onChange;
  const keyDown = (event: React.KeyboardEvent) => {
    if (!onChange) {
      return;
    }
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowUp':
        onChange(Increment(value, 0.5, 0, max));
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        onChange(Increment(value, -0.5, 0, max));
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        onChange(SetInRange(parseInt(event.key, 10), 0, max));
    }
  };
  const emitChange = (val: number) => {
    if (!onChange) {
      return;
    }
    if (val === value) {
      onChange(val - 0.5);
    } else {
      onChange(val);
    }
  };
  const stars = () => {
    const items: React.ReactNode[] = [];
    for (let i = 1; i <= max; i++) {
      const isHalf = Math.ceil(value) === i && value < i;
      const isActive = value >= i;
      items.push(
        <Star
          key={i}
          color={isActive || isHalf ? color || theme.colors.primary : ''}
          onClick={readOnly ? undefined : () => emitChange(i)}
          half={isHalf}
        />
      );
    }
    return items;
  };
  return (
    <Flex
      onKeyDown={readOnly ? undefined : keyDown}
      alignItems={['center']}
      tabIndex={readOnly ? undefined : 1}
    >
      {stars()}
      {showScore && (
        <Score scoreColor={scoreColor}>
          {value}/{max}
        </Score>
      )}
    </Flex>
  );
};
