export const UpdateItemInObject = <T, U extends keyof T>(current: T, key: U, value: T[U]): T => {
  return { ...current, [key]: value };
};
export const RemoveItemInObject = <T, U extends keyof T>(current: T, key: U): Omit<T, U> => {
  // eslint-disable-next-line
  const { [key]: omit, ...extract } = current;
  return extract;
};
export const FindAndDeleteItemInArray = <T, U extends keyof T>(
  current: T[],
  key: U,
  value: Pick<T, U>
): T[] => {
  const index = current.findIndex(c => c[key] === value[key]);
  if (index < 0) {
    return current;
  }
  return RemoveItemInArray(current, index);
};
export const FindAndReplaceItemInArray = <T, U extends keyof T>(
  current: T[],
  key: U,
  value: T
): T[] => {
  const index = current.findIndex(c => c[key] === value[key]);
  if (index < 0) {
    return current;
  }
  return ReplaceItemInArray(current, index, value);
};
export const ReplaceItemInArray = <T>(current: T[], index: number, value: T): T[] => {
  return current.map((item, i) => (i === index ? value : item));
};
export const MergeItemInArray = <T>(current: T[], index: number, value: Partial<T>): T[] => {
  return current.map((item, i) => (i === index ? { ...item, ...value } : item));
};
export const RemoveItemInArray = <T>(current: T[], index: number): T[] => {
  return current.filter((_, i) => i !== index);
};
export const TranslateItemInArray = <T>(arr: T[], from: number, to: number): T[] => {
  if (to < 0) {
    return arr;
  }
  const copy = arr.slice();
  copy.splice(to, 0, copy.splice(from, 1)[0]);
  return copy;
};
export type Reducer<S, A> = (prevState: S, action: A) => S;

export type ActionNumber = RequireOnlyOne<{
  divide?: number;
  increment?: number;
  multiply?: number;
  set?: number;
}>;

export type ReducerNumberType = Reducer<number, ActionNumber>;
export const ReducerNumber = (current: number, action: ActionNumber): number => {
  if (typeof action.set === 'number') {
    return action.set;
  }
  if (typeof action.increment === 'number') {
    return current + action.increment;
  }
  if (typeof action.multiply === 'number') {
    return current * action.multiply;
  }
  if (typeof action.divide === 'number') {
    return current / action.divide;
  }
  return current;
};
type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];
export type ActionArray<T, U extends keyof T = never> = RequireOnlyOne<{
  /**
   * remplace array by an empty array []
   */
  clean?: boolean;
  /**
   * find an item and make action
   */
  findAnd?: {
    /**
     * type of selection
     */
    selection: RequireOnlyOne<{
      /**
       * the selection is unique argument is index position in array
       */
      unique?: number;
      /**
       * the selection is unique/multiple
       */
      multiple?: {
        /**
         * precise the matching key
         */
        key: U;
        /**
         * define value for matching
         */
        equal: Pick<T, U>;
      };
    }>;
    /**
     * after selection find matches do the action on item
     */
    action: RequireOnlyOne<{
      /**
       *  remove item(s) for array
       */
      remove?: boolean;
      /**
       * merge values {...oldValue,...newValue} (work only if items is an object
       */
      merge?: Partial<T>;
      /**
       * remplace item(s) with new item
       */
      replace?: T;
    }>;
  };
  /**
   * concat array at the end of current array
   */
  mergeAfter?: T[];
  /**
   * concat array at the beginning of current array
   */
  mergeBefore?: T[];
  /**
   * add item at the end of current array
   */
  push?: T;
  /**
   * replace all array by new one
   */
  replace?: T[];
  /**
   * add item at the beginning of current array
   */
  unshift?: T;
}>;

export type ReducerArrayType<T, U extends keyof T = never> = Reducer<T[], ActionArray<T, U>>;
export const ReducerArray = <T, U extends keyof T = never>(
  current: T[],
  action: ActionArray<T, U>
): T[] => {
  if (action.clean === true) {
    return [];
  }
  if (typeof action.findAnd !== 'undefined') {
    const findAction = action.findAnd.action;
    const findSelection = action.findAnd.selection;
    if (findAction.remove === true) {
      return current.filter((item, i) => {
        if (typeof findSelection.unique === 'number') {
          return findSelection.unique !== i;
        }
        if (typeof findSelection.multiple !== 'undefined') {
          const { key, equal } = findSelection.multiple;
          return item[key] !== equal[key];
        }
        return true;
      });
    }
    if (typeof findAction.replace !== 'undefined' || typeof findAction.merge !== 'undefined') {
      return current.map((item, i) => {
        const newItem =
          typeof findAction.replace !== 'undefined'
            ? findAction.replace
            : typeof findAction.merge !== 'undefined'
            ? { ...item, ...findAction.merge }
            : item;
        if (typeof findSelection.unique === 'number') {
          return findSelection.unique === i ? newItem : item;
        }
        if (typeof findSelection.multiple !== 'undefined') {
          const { key, equal } = findSelection.multiple;
          return item[key] === equal[key] ? newItem : item;
        }
        return item;
      });
    }
  }
  if (typeof action.mergeAfter !== 'undefined') {
    return [...current, ...action.mergeAfter];
  }
  if (typeof action.mergeBefore !== 'undefined') {
    return [...action.mergeBefore, ...current];
  }
  if (typeof action.push !== 'undefined') {
    return [...current, action.push];
  }
  if (typeof action.unshift !== 'undefined') {
    return [action.unshift, ...current];
  }
  if (typeof action.replace !== 'undefined') {
    return action.replace;
  }
  return current;
};

export type ActionObject<T> = RequireOnlyOne<{
  merge?: Partial<T>;
  replace?: T;
}>;

export type ReducerObjectType<T> = Reducer<T, ActionObject<T>>;
export const ReducerObject = <T>(current: T, action: ActionObject<T>): T => {
  if (action.replace) {
    return action.replace;
  }
  if (action.merge) {
    return { ...current, ...action.merge };
  }
  return current;
};
