import { PickChoiceByDevice, ScreenDevices } from '../../common';

export const FlexWidth = (device: ScreenDevices, columns?: number[]): number | undefined => {
  if (!columns) {
    return;
  }
  const result = PickChoiceByDevice(device, columns);
  return result < 0 ? result : (100 * result) / 12;
};
export const FlexColToCss = (device: ScreenDevices, col: number[]): string => {
  const res = FlexWidth(device, col);
  if (typeof res === 'undefined') {
    return '';
  }
  return res < 0 ? 'width: initial;' : `width: ${res}%;`;
};
export const FlexCss = (device: ScreenDevices, prefix: string, choices?: string[]): string => {
  if (!choices) {
    return '';
  }
  const selection = PickChoiceByDevice(device, choices);
  if (!selection) {
    return '';
  }
  return prefix + selection + ';';
};
