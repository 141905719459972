import React, { FC, useEffect, useState } from 'react';
import { Category } from '../../interface/category.types';
import { Button, Col, H5, InOut, Row, Select, SelectOption, SquareButton, useToaster } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { FindAndUpdateItemInArray, UpdateItemInObject } from '../../utils/deep-object.utils';
import { ActivityList } from '../activity/activity-list';
import { Activity } from '../../interface/activity.types';
import { useApi } from '../../providers/api-provider';
import { CategoryModifyDialog } from './category-modify-dialog';
import { ApiReferential } from '../../interface/api.types';
import { useHistory } from 'react-router-dom';
import { useConfirm } from '../../providers/confirm-provider';
import { useIntl } from 'react-intl';

interface CategoryListProps {
  categories: Category[];
  onChange: (items: Category[]) => void;
}

const CategoryHeader = styled.header`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey80};
`;
const CategoryWrapper = styled.section`
  background: ${({ theme }) => theme.colors.grey95};
  border-radius: 8px;
  margin: 40px 0;
`;
export const CategoryList: FC<CategoryListProps> = props => {
  const { categories, onChange } = props;
  const intl = useIntl();
  const { openConfirm } = useConfirm();
  const [getSelectVal, setSelectVal] = useState<SelectOption<ApiReferential>>();
  const [getSelectTyping, setSelectTyping] = useState<string>('');
  const toast = useToaster();

  useEffect(() => {
    if (!getSelectVal) {
      return
    }
    setSelectTyping(getSelectVal.label);
  }, [getSelectVal])

  const [getLoading, setLoading] = useState<boolean>(false);
  const [getEditCat, setEditCat] = useState<Category | undefined>();
  const [getReferential, setReferential] = useState<ApiReferential[]>([]);
  const API = useApi();
  const history = useHistory();

  const addActivity = (categoryId: string) => {
    history.push(`/estimate/category/${categoryId}/activity/NEW`);
  };

  const editActivity = (categoryId: string, activity: Activity) => {
    history.push(`/estimate/category/${categoryId}/activity/${activity.id}`);
  };

  const hasNotSameCategory = () => {
    const find = categories.findIndex((cat) => cat.name === getSelectTyping);
    return find < 0;
  };

  const modifyCategory = (category: Category) => {
    setEditCat(undefined);
    API.putCategory(category).then((result) => {
      onChange(FindAndUpdateItemInArray(categories, 'id', result));
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const deleteCategory = (catId: string, name: string) => {
    openConfirm({
      title: '',
      content: intl.formatMessage({ id: 'confirmDelete', defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}' }, { name }),
    }).then(() => {
      API.deleteCategory(catId).then((result) => {
        onChange(result);
      }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
    });
  };

  const toggleActivity = (activity: Activity, cat: Category) => {
    const activeActivity = {
      ...activity,
      active: !activity.active,
    };
    onChange(FindAndUpdateItemInArray(categories, 'id',
      UpdateItemInObject(cat, 'activities',
        FindAndUpdateItemInArray(cat.activities, 'id', activeActivity))));
    API.putActivity(cat.id, activeActivity).then((result) => {
      onChange(FindAndUpdateItemInArray(categories, 'id',
        UpdateItemInObject(cat, 'activities',
          FindAndUpdateItemInArray(cat.activities, 'id', result))));
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })));
  };

  const deleteActivity = (activity: Activity, cat: Category) => {
    openConfirm({
      title: '',
      content: intl.formatMessage({ id: 'confirmDelete', defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}' }, { name: activity.name }),
    }).then(() => {
      API.deleteActivity(cat.id, activity.id).then(result => {
        onChange(FindAndUpdateItemInArray(categories, 'id',
          UpdateItemInObject(cat, 'activities', result)));
      }).catch(() => {
        toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }))
      });
    });
  };

  const duplicateCategory = (categoryId: string, categoryName: string) => {
    setLoading(true);
    API.postDuplicateCategory({
      name: categoryName + intl.formatMessage({ id: 'duplicated', defaultMessage: ' copie' }),
      categoryId: categoryId
    }).then(response => onChange([...categories, response])).finally(() => {
      setLoading(false)
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })))
  }

  const duplicateActivity = (cat: Category, activityId: string) => {
    setLoading(true);
    API.postDuplicateActivity(cat.id, activityId).then(response =>
      onChange(FindAndUpdateItemInArray(categories, 'id',
        UpdateItemInObject(cat, 'activities',
          [...cat.activities, response]
        )))
    ).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }))
    ).finally(() => setLoading(false))
  }

  const addCategory = () => {
    if (!getSelectTyping.length || !hasNotSameCategory()) {
      return;
    }
    setLoading(true);
    if (getSelectVal) {
      API.postCategoryReferential(getSelectVal.value).then((response) => {
        onChange([...categories, response]);
        setSelectVal(undefined);
      }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' })
      )).finally(() => {
        setLoading(false);
      });
      return;
    }
    API.postCategory({
      name: getSelectTyping,
      activities: [],
      type: 'CUSTOMER',
    }).then((response) => {
      onChange([...categories, response]);
      setSelectVal(undefined);
    }).catch(() => toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }))
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (intl.locale === 'fr-FR') {
       API.getReferential().then((result) => {
        setReferential(result);
      });
    }
  }, [API, intl]);

  return <>
    {categories.map((cat, catIndex) => <InOut
      show startTriggering key={catIndex}>
      <CategoryWrapper>
        <CategoryHeader>
          <Row justifyContent={['space-between']} alignItems={['center']}>
            <Col>
              <Row alignItems={['center']}>
                {cat.type !== 'REFERENTIAL' || (!cat.readOnly && cat.type === 'REFERENTIAL') ?
                  <Col><SquareButton icon={'edit'} onClick={() => setEditCat(cat)} /></Col>:null}
                <Col><H5 style={{ margin: '0' }}>{cat.name}</H5></Col>
              </Row>
            </Col>
            <Col>
              {!cat.readOnly && <Row>
                <Col>
                  <SquareButton icon={'copy'} onClick={() => duplicateCategory(cat.id, cat.name)} />
                </Col>
                <Col>
                  <SquareButton icon={'delete'} onClick={() => deleteCategory(cat.id, cat.name)} />
                </Col>
              </Row>}
            </Col>
          </Row>
        </CategoryHeader>
        <ActivityList
          onCopy={(a) => duplicateActivity(cat, a.id)}
          activities={cat.activities}
          onChange={activities => modifyCategory({ ...cat, activities })}
          onEdit={e => editActivity(cat.id, e)}
          onNew={() => addActivity(cat.id)}
          onDelete={(act) => deleteActivity(act, cat)}
          onToggle={(act) => toggleActivity(act, cat)}
          categoryName={cat.name}
          readOnly={cat.readOnly}
        />
      </CategoryWrapper>
    </InOut>)
    }
    <Row alignItems={['center']} justifyContent={['space-between']}>
      <Col columns={[8]}>
        <Select<ApiReferential>
          onSelect={setSelectVal}
          selected={getSelectVal}
          onTyping={setSelectTyping}
          typing={getSelectTyping}
          label={intl.formatMessage({ id: 'addACategory', defaultMessage: 'Ajouter une catégorie d’activité' })}
          options={getReferential.filter(f => !categories.some(s => s.name === f.name)).map((r => ({
            label: r.name,
            value: r,
            id: r.id,
          })))}
        />
      </Col>
      <Col>
        <Button
          loading={getLoading}
          disabled={!getSelectTyping.length || !hasNotSameCategory()}
          onClick={addCategory}
          style={{ marginTop: '31px' }}
          colorType={'primary'}>{intl.formatMessage({ id: 'addThisCategory', defaultMessage: 'Ajouter cette catégorie' })}
        </Button>
      </Col>
    </Row>
    <CategoryModifyDialog
      onClose={() => setEditCat(undefined)}
      onChange={modifyCategory}
      category={getEditCat} />
  </>;
};
