import { BaseButton, IconName, InOut, NavBar, NavLink, SpanSubtitle1 } from '@linkeo.com/ui-lib-react';
import { Link, useLocation } from 'react-router-dom';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../providers/auth-provider';
import { useIntl } from 'react-intl';
import { routeActivities, routeCompanyParams, routeDocumentParams, routeDocuments, routeEstimateParams, routeHome, routePresentation } from '../../routes';

const SubMenu = styled.div`
  position: relative;
`;
const SubNav = styled.div`
  position: absolute;
  background: #E6E6E6;
  border-radius: 0 0 4px 4px;
  z-index: 1;
  top: 39px;
  left: 0;
`;
const SubNavItem = styled.div<{ active: boolean }>`

  white-space: nowrap;

  a {
    display: block;
    text-decoration: none;
    cursor: ${({ active }) => active ? 'default' : 'pointer'};
    pointer-events: ${({ active }) => active ? 'none' : 'initial'};
    padding: 15px;

    span {
      transition: color ease-in-out 200ms;
      color: ${({ active, theme }) => active ? theme.colors.primary : '#333'};

    }

    &:hover, &:focus {
      background-color: #ccc;

      span {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
const Wrapper = styled.div`
  position: relative;
  z-index: 11;
`;
const SubMenuButton = styled(BaseButton)`
  display: block;
  border: none;
`;

const NavigationSubMenu: FC<{ label: string, icon: IconName, subItems: { path: string, label: string }[] }> = props => {
  const { label, icon, subItems } = props;
  const [getOpen, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const currentUrl = location.pathname || '/';
  const elRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!elRef.current) {
      return;
    }
    if (!getOpen) {
      return;
    }
    const autoClose = (event: MouseEvent) => {
      const el = elRef.current as HTMLElement;
      if (!el) {
        return;
      }
      const target = event.target as HTMLElement;
      if (el.contains(target)) { // avoid close when click on button
        return;
      }
      setOpen(false);
    };
    window.addEventListener('click', autoClose);
    return () => {
      window.removeEventListener('click', autoClose);
    };
  }, [getOpen]);
  return <SubMenu ref={elRef}>
    <SubMenuButton onClick={() => setOpen(!getOpen)}>
      <NavLink label={label} icon={icon} active={subItems.some(s => currentUrl === s.path)} />
    </SubMenuButton>
    <InOut show={getOpen}>
      <SubNav>
        {subItems.map(si => <SubNavItem active={currentUrl === si.path} key={si.label}>
          <Link to={si.path}>
            <SpanSubtitle1>
              {si.label}
            </SpanSubtitle1>
          </Link>
        </SubNavItem>)}
      </SubNav>
    </InOut>
  </SubMenu>;
};
const NavigationLink: FC<{ path: string, label: string, icon: IconName }> = props => {
  const { path, label, icon } = props;
  const location = useLocation();
  const currentUrl = location.pathname || '/';

  return <Link to={path} style={{ textDecoration: 'none' }}>
    <NavLink
      label={label}
      icon={icon}
      active={currentUrl === path}
    />
  </Link>;
};
export const Navigation = () => {
  const intl = useIntl();
  const AUTH = useAuth();
  return <Wrapper>
    <NavBar rightTitle={AUTH.codeBouton}>
      <NavigationLink label={intl.formatMessage({id: 'navHome', defaultMessage: 'Accueil'})} icon={'home'} path={routeHome} />
      <NavigationLink label={intl.formatMessage({id: 'navDocuments', defaultMessage: 'Documents'})} icon={'page'} path={routeDocuments} />
      <NavigationLink label={intl.formatMessage({id: 'navSubActivity', defaultMessage: 'Vos activités'})} icon={'button'} path={routeActivities} />
      <NavigationLink label={intl.formatMessage({id: 'navSubForm', defaultMessage: 'Formulaires'})} icon={'form'}
                      path={routePresentation} />
      <NavigationSubMenu label={intl.formatMessage({id: 'navConfig', defaultMessage: 'Paramètres'})} icon={'gear'} subItems={[
        {
          path: routeEstimateParams,
          label: intl.formatMessage({id: 'navSubConfig', defaultMessage: 'Paramètres de l’estimation'}),
        },
        {
          path: routeCompanyParams,
          label: intl.formatMessage({id: 'companyParamTitle', defaultMessage: 'Paramètres de l’entreprise'}),
        },
        {
          path: routeDocumentParams,
          label: intl.formatMessage({id: 'documentParamTitle', defaultMessage: 'Paramètres du document'}),
        },
      ]} />
    </NavBar>
  </Wrapper>;
};
