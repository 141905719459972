import React, { createContext, FC, ReactNode, useContext, useReducer, useRef } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { Keyframes, Shapes, SpanSubtitle1, ThemeType } from '../../common';
import { Portal } from '../portal';
import { ReducerArray, ReducerArrayType } from '../../helpers';
import { InOut } from '../in-out';
import { IconName } from '../../icons';
import { Row } from '../row';
import { Col } from '../col';
import { Svg } from '../svg';

export type ToasterMessageType = 'default' | 'error' | 'success';

interface ToasterProps {
  closeButton?: boolean;
  icon?: IconName;
  outlined?: boolean;
  type?: ToasterMessageType;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  ${Shapes.card};
  padding: 24px;
  border: transparent solid 1px;
  display: inline-block;
  margin: 10px auto;
`;
export const Toast: FC<ToasterProps> = ({ children, type, outlined, icon, closeButton }) => {
  const theme = useContext<ThemeType>(ThemeContext);
  let color = theme.colors.light;
  let backgroundColor = theme.colors.grey10;
  let borderColor = theme.colors.grey10;
  switch (type) {
    case 'default':
      if (!outlined) {
        break;
      }
      color = theme.colors.grey40;
      borderColor = theme.colors.grey40;
      backgroundColor = theme.colors.grey95;
      break;
    case 'error':
      if (!outlined) {
        color = theme.colors.grey10;
        borderColor = theme.colors.danger;
        backgroundColor = theme.colors.danger;
        break;
      }
      color = '#BC3F40';
      borderColor = '#BC3F40';
      backgroundColor = '#FFE4E4';
      break;
    case 'success':
      if (!outlined) {
        color = theme.colors.grey10;
        borderColor = theme.colors.success;
        backgroundColor = theme.colors.success;
        break;
      }
      color = '#199752';
      borderColor = '#199752';
      backgroundColor = '#DCF8E7';
      break;
  }
  return (
    <Wrapper style={{ backgroundColor, borderColor }}>
      <Row justifyContent={['center']} alignItems={['start']}>
        {icon ? (
          <Col>
            <Svg icon={icon} fill={color} width={18} height={18} />
          </Col>
        ) : null}
        <Col>
          <SpanSubtitle1 style={{ color }}>{children}</SpanSubtitle1>
        </Col>
        {closeButton ? (
          <Col>
            <Svg icon={'cross-circle'} fill={color} width={18} height={18} />
          </Col>
        ) : null}
      </Row>
    </Wrapper>
  );
};

export type toasterContextProps = (
  message: string | ReactNode,
  config?: ToasterProps,
  time?: number
) => void;
type ToastInfo = {
  message: string | ReactNode;
  config: ToasterProps;
  uid: number;
  show: boolean;
};
const ToasterContext = createContext<toasterContextProps>(() => {
  console.info('toaster provider is not init add ToasterProvider in parent');
});
export const useToaster = () => useContext(ToasterContext);
const ToastPositionContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  text-align: center;
`;
export const ToasterProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [getToasts, setToast] = useReducer<ReducerArrayType<ToastInfo, 'uid'>>(ReducerArray, []);
  const count = useRef<number>(0);
  const push = (message: string | ReactNode, config: ToasterProps = {}, time?: number) => {
    const newCount = count.current++;
    setToast({ push: { message, config, uid: newCount, show: true } });
    setTimeout(() => {
      setToast({
        findAnd: {
          selection: { multiple: { key: 'uid', equal: { uid: newCount } } },
          action: { merge: { show: false } },
        },
      });
      setTimeout(() => {
        setToast({
          findAnd: {
            selection: { multiple: { key: 'uid', equal: { uid: newCount } } },
            action: { remove: true },
          },
        });
      }, 250);
    }, time || 5000);
  };

  return (
    <ToasterContext.Provider value={push}>
      {children}

      <Portal attributeName={'data-toaster'}>
        <ToastPositionContainer>
          {getToasts.map(t => (
            <InOut
              show={t.show}
              startTriggering={true}
              key={t.uid}
              enter={{ keyframes: Keyframes.slideInFrom(undefined, '50px') }}
              exit={{ keyframes: Keyframes.slideOutFrom(undefined, '-50px') }}
            >
              <Toast {...t.config}>{t.message}</Toast>
            </InOut>
          ))}
        </ToastPositionContainer>
      </Portal>
    </ToasterContext.Provider>
  );
};
