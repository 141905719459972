import * as React from 'react';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { SpanCaption1, SpanSubtitle1 } from '../../common';
import { InOut } from '../in-out';
import { mergeStyled } from '../../helpers';

export interface LabelWrapperStyledProps {
  /** active disabled style */
  disabled?: boolean;
  /** active error style */
  hasError?: boolean;
  /** active required style */
  required?: boolean;
  /** smaller */
  small?: boolean;
}

export interface LabelCaptionProps extends LabelWrapperStyledProps {
  /** text right **/
  right?: boolean;
}

export interface LabelWrapperProps extends LabelWrapperStyledProps, LabelCaptionProps {
  /** show caption at bottom */
  caption?: ReactNode | string;
  /** show label at top */
  label?: ReactNode | string;
  children?: React.ReactNode;
}

const SmallContainer = css<{ small?: boolean }>`
  margin-top: ${({ small }) => (small ? '8px' : '16px')};
  margin-bottom: ${({ small }) => (small ? '8px' : '16px')};
`;
const DisabledContainer = css<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ErrorLabel = css<{ hasError?: boolean }>`
  ${({ theme, hasError }) => hasError && `color:${theme.colors.danger}`};
`;
const PositionLabel = css<{ right?: boolean }>`
  ${({ right }) => (right ? 'text-align: right' : '')};
`;
const RequiredLabel = css<{ required?: boolean }>`
  &:after {
    ${({ required }) => (required ? 'content: " *"' : '')};
  }
`;
const LabelWrapperContainer = mergeStyled(
  styled.div<{ disabled?: boolean; small?: boolean; right?: boolean }>`
    position: relative;
    width: 100%;
    box-sizing: border-box;
  `,
  [SmallContainer, DisabledContainer, PositionLabel]
);
const LabelText = mergeStyled(styled(SpanSubtitle1)<LabelCaptionProps>``, [
  ErrorLabel,
  PositionLabel,
  RequiredLabel,
]);
const LabelSmallText = mergeStyled(styled(SpanCaption1)<LabelCaptionProps>``, [
  ErrorLabel,
  PositionLabel,
  RequiredLabel,
]);
const CaptionText = mergeStyled(styled(SpanCaption1)<LabelCaptionProps>``, [
  ErrorLabel,
  PositionLabel,
]);

const OverFlow = styled.label<{ small?: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: ${({ small }) => (small ? '4px 0' : '8px 0')};
`;
export const LabelWrapper: React.FC<LabelWrapperProps> = props => {
  const { children, label, caption, right, disabled, hasError, required, small } = props;
  return (
    <LabelWrapperContainer disabled={disabled} small={small} right={right}>
      <InOut show={!!label}>
        <OverFlow small={small} htmlFor={'switch-select'}>
          {small ? (
            <LabelSmallText {...{ right, hasError, required }}>{label}</LabelSmallText>
          ) : (
            <LabelText {...{ right, hasError, required }}>{label}</LabelText>
          )}
        </OverFlow>
      </InOut>
      {children}
      <InOut show={!!caption}>
        <CaptionText as={'div'} {...{ right, hasError }}>
          {caption}
        </CaptionText>
      </InOut>
    </LabelWrapperContainer>
  );
};
