import { UlStyled } from './list.styled';
import * as React from 'react';
export interface ListProps {
  items?: React.ReactNode[];
}
export class List extends React.PureComponent<ListProps> {
  render(): React.ReactNode {
    return <UlStyled>{this.renderItems()}</UlStyled>;
  }

  renderItems(): React.ReactNode[] {
    const items: React.ReactNode[] = [];
    if (this.props.items) {
      this.props.items.forEach((item, index) => {
        items.push(<li key={index}>{item}</li>);
      });
    }
    return items;
  }
}
