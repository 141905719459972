import styled from 'styled-components';
import { getTypo } from '../../common';

export const UlStyled = styled.ul`
  ${getTypo('caption1')};
  color: ${({ theme }) => theme.colors.grey50};
  list-style: outside;
  margin: 0;
  padding: 0 0 0 20px;

  li {
    margin: 16px 0;
  }
`;
