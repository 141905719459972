import { FC } from 'react';
import {
  BaseButton,
  Button,
  Col,
  Input,
  InputNumber,
  LabelWrapper,
  Row,
  SpanSubtitle1,
  Svg,
} from '@linkeo.com/ui-lib-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Taxes } from '../../interface/document-param.types';
import { ProductType } from '../../interface/product.types';
import { useTheme } from 'styled-components';

interface TaxesFormProps {
  label: string;
  taxes: Taxes[];
  type: ProductType;
  changeTaxe: (type: ProductType, indexTaxes: number, update: number | string) => void;
  onTaxeAdd: (type: ProductType) => void;
  onTaxeRemove: (type: ProductType, indexTaxes: number) => void;
  small?: boolean;
}

export const TaxesForm: FC<TaxesFormProps> = ({ label, taxes, changeTaxe, type, onTaxeAdd, onTaxeRemove, small }) => {
  const theme = useTheme();
  const intl = useIntl();

  return <Col columns={[12, 12, 12, 6]} style={{padding: small ? "0 8px" : "0 16px"}}>
    <LabelWrapper label={label} required small={small}>
      {taxes.map((el, i) => {
        return <Row key={i} gapColumn={4} style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
          <Col columns={[7]}>
            <Input
              type={'text'}
              required
              placeholder={intl.formatMessage({id: 'documentTaxeParamFormTaxNamePlaceholder', defaultMessage: 'Nom de la taxe'})}
              onChange={v => changeTaxe(type, i, v)}
              value={el.name}
            />
          </Col>
          <Col columns={[3]}>
            <InputNumber
              step='any'
              required
              min={0}
              placeholder={'0'}
              value={el.rate}
              onChange={v => changeTaxe(type, i, v || 0)}
            />
          </Col>
          <Col>
            <SpanSubtitle1>%</SpanSubtitle1>
          </Col>
          <Col>
            <BaseButton onClick={() => onTaxeRemove(type, i)}>
              <Svg icon={'delete'} height={24} width={24} fill={theme.colors.primary} />
            </BaseButton>
          </Col>
        </Row>;
      })}
    </LabelWrapper>
    <Button colorType={'outline'} onClick={() => onTaxeAdd(type)}>
      <FormattedMessage id={'documentParamFormAddTaxesButtonLabel'} defaultMessage={'+ Ajouter une taxe'} />
    </Button>
  </Col>;
};