import * as React from 'react';
import { SelectContrast } from '../../helpers';
import styled from 'styled-components';
import { Button } from '../button';

export interface TagProps {
  color: string;
  fontColor?: string;
  onClick?: () => void;
  showCloseIcon?: boolean;
  children?: React.ReactNode;
}

const TagButton = styled(Button)`
  padding-left: 6px;
  padding-right: 6px;
`;
export const Tag: React.FC<TagProps> = props => {
  const { color, onClick, children, showCloseIcon, fontColor } = props;
  const fc = fontColor || SelectContrast(color, ['white', 'black']);
  return (
    <TagButton
      small
      fontColor={fc}
      backgroundColor={color}
      onClick={onClick}
      icon={showCloseIcon ? 'cross' : undefined}
      iconRight={true}
    >
      {children}
    </TagButton>
  );
};
