import React, { FC, useCallback, useEffect, useState } from 'react';
import { InteractiveCol } from '../ui/interactive-col';
import { DocumentAddressBox } from '../document/document-address-box';
import { Recipient, ReferentialCustomer } from '../../interface/document.type';
import { Col, Input, Select, SelectOption } from '@linkeo.com/ui-lib-react';
import { AddressSmallFields } from '../address/address-small-fields';
import { FormFooter } from '../ui/form-footer';
import { AddressErrors, getInvalidRecipient, RecipientErrors } from '../../utils/quote-document-validator';
import { IsSameRecipientAsCustomer, UpdateRecipientFromReferential } from '../../utils/recipient.utils';
import { useApi } from '../../providers/api-provider';
import { EditReferentialDialog } from '../referential/edit-referential.dialog';
import { EditReferentialType } from '../../interface/api.types';
import { useIntl } from 'react-intl';

interface RecipientFormProps {
  onChange: (v: Recipient) => void;
  readOnly: boolean;
  recipientErrors: RecipientErrors;
  value: Recipient;
}

export const RecipientForm: FC<RecipientFormProps> = props => {
  const { readOnly, onChange, value, recipientErrors } = props;
  const [getEditMode, setEditMode] = useState<boolean>(false);
  const [getError, setError] = useState<RecipientErrors>(recipientErrors);
  const [getFormControl, setFormControl] = useState<Recipient>(value);
  const [getShowDialog, setShowDialog] = useState<boolean>(false);
  const API = useApi();
  const intl = useIntl();
  const [getMessageErrors, setMessageErrors] = useState<string>('');
  const [getCustomersChoice, setCustomersChoice] = useState<SelectOption<ReferentialCustomer>[]>([]);
  const [getLoading, setLoading] = useState<boolean>(false);

  const searchCostumer = useCallback((params: { company?: string, name?: string }) => {
    API.getCustomers(params).then(result => setCustomersChoice(result.customers.map(p => {
      return {
        value: p,
        label: params.company ? p.companyName : `${p.firstName} ${p.lastName}`,
        caption: params.company ? `${p.address.postalCode} ${p.firstName} ${p.lastName}` : `${p.address.postalCode} ${p.companyName}`,
      };
    })));
  }, [API]);

  useEffect(() => {
    searchCostumer({});
  }, [searchCostumer]);

  useEffect(() => {
    setFormControl(value);
  }, [value]);

  useEffect(() => {
    setError(getInvalidRecipient(getFormControl));
  }, [getFormControl]);

  const submitForm = (ev: React.FormEvent) => {
    ev.preventDefault();
    setMessageErrors('');
    if (!getFormControl.customerId) {
      setShowDialog(true);
      return;
    }
    setLoading(true);
    API.getCustomer(getFormControl.customerId).then(customer => {
      if (IsSameRecipientAsCustomer(getFormControl, customer)) {
        emitChange('keep');
      } else {
        setShowDialog(true);
      }
    }).catch(() => {
      setShowDialog(true);
    }).finally(() => setLoading(false));
  };

  const emitChange = (type: EditReferentialType) => {
    setMessageErrors('');
    if (type === 'keep') {
      onChange(getFormControl);
      setShowDialog(false);
      setEditMode(false);
      return;
    }
    setLoading(true);
    const { customerId, ...recipient } = getFormControl;
    if (type === 'create') {
      API.createCustomer(recipient).then((result) => {
        onChange(UpdateRecipientFromReferential(getFormControl, result));
      }).catch(() => {
        setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
      }).finally(() => {
        setLoading(false);
        setShowDialog(false);
        setEditMode(false);
      });
    }
    if (type === 'update') {
      if (!customerId) {
        setEditMode(false);
        setShowDialog(false);
        setLoading(false);
        return;
      }
      API.updateCustomer({ ...recipient, id: customerId }).then((result) => {
        onChange(UpdateRecipientFromReferential(getFormControl, result));
      }).catch(() => {
        setMessageErrors(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
      }).finally(() => {
        setEditMode(false);
        setShowDialog(false);
        setLoading(false);
      });
    }
  };

  return getEditMode ? <Col columns={[5]}>
      <form onSubmit={submitForm}>
        <Select<ReferentialCustomer>
          small
          label={intl.formatMessage({ id: 'RecipientFormFirstNameLabel', defaultMessage: 'Prénom de votre client' })}
          required={!getFormControl.companyName}
          hasError={!!getError.firstName}
          onSelect={(selected) => setFormControl(UpdateRecipientFromReferential(getFormControl, selected.value))}
          typing={getFormControl.firstName}
          onTyping={(typing) => setFormControl({ ...getFormControl, firstName: typing })}
          onDebounceTyping={(typing) => searchCostumer({ name: typing })}
          options={getCustomersChoice} />
        <Select<ReferentialCustomer>
          small
          label={intl.formatMessage({ id: 'RecipientFormLastNameLabel', defaultMessage: 'Nom de votre client' })}
          required={!getFormControl.companyName}
          hasError={!!getError.lastName}
          onSelect={(selected) => setFormControl(UpdateRecipientFromReferential(getFormControl, selected.value))}
          typing={getFormControl.lastName}
          onTyping={(typing) => setFormControl({ ...getFormControl, lastName: typing })}
          onDebounceTyping={(typing) => searchCostumer({ name: typing })}
          options={getCustomersChoice} />
        <Select<ReferentialCustomer>
          small
          label={intl.formatMessage({ id: 'RecipientFormCompanyLabel', defaultMessage: 'Entreprise' })}
          required={!getFormControl.lastName && !getFormControl.firstName}
          hasError={!!getError.companyName}
          onSelect={(selected) => setFormControl(UpdateRecipientFromReferential(getFormControl, selected.value))}
          typing={getFormControl.companyName}
          onTyping={(typing) => setFormControl({ ...getFormControl, companyName: typing })}
          onDebounceTyping={(typing) => searchCostumer({ company: typing })}
          options={getCustomersChoice} />
        <AddressSmallFields errors={(getError.address as AddressErrors) || {}} value={getFormControl.address}
                            onChange={v => setFormControl({ ...getFormControl, address: v })} />
        <Input hasError={!!getError.telephone} small required
               label={intl.formatMessage({ id: 'companyDocFormPhoneLabel', defaultMessage: 'Numéro de téléphone' })}
               type={'tel'}
               value={getFormControl.telephone}
               onChange={v => setFormControl({ ...getFormControl, telephone: v })} />
        <Input hasError={!!getError.email} small required
               label={intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' })} type={'email'}
               value={getFormControl.email}
               onChange={v => setFormControl({ ...getFormControl, email: v })} />
        <FormFooter errorMessage={getMessageErrors} small onCancel={() => {
          setEditMode(false);
          setFormControl(value);
        }} />
      </form>
      <EditReferentialDialog
        concern={'customer'}
        isLoading={getLoading}
        onClose={() => setShowDialog(false)}
        onSubmit={emitChange} show={getShowDialog} />
    </Col> :
    <InteractiveCol hasError={!!Object.keys(recipientErrors).length} onClick={() => !readOnly && setEditMode(true)}
                    columns={[5]} tabIndex={readOnly ? -1 : 0}
                    locked={readOnly}>
      <DocumentAddressBox value={{
        address: value.address,
        email: value.email || intl.formatMessage({ id: 'email', defaultMessage: 'Adresse Email' }),
        telephone: value.telephone || intl.formatMessage({
          id: 'companyDocFormPhoneLabel',
          defaultMessage: 'Numéro de téléphone',
        }),
        companyName: value.companyName || intl.formatMessage({
          id: 'RecipientFormCompanyLabel',
          defaultMessage: 'Entreprise',
        }),
        firstName: value.firstName || intl.formatMessage({ id: 'firstname', defaultMessage: 'Prénom' }),
        lastName: value.lastName || intl.formatMessage({ id: 'lastname', defaultMessage: ' et Nom du client' }),
      }} />
    </InteractiveCol>;
};