import { Locale } from '../providers/intl.provider';

export const LocaleToName = (locale:Locale)=>{
  switch (locale){
    case 'en-US':
      return 'English (us)'
    case 'en-AU':
      return 'English (au)'
    case 'en-CA':
      return 'English (ca)'
    case 'es-US':
      return 'Español (us)'
    case 'fr-CA':
      return 'Français (ca)'
    case 'fr-FR':
      return 'Français (fr)'
    default:
      return locale;
  }
}