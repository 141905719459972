import { FC } from 'react';
import { QuoteDocument } from '../../interface/document.type';
import { Button, useToaster } from '@linkeo.com/ui-lib-react';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../providers/api-provider';
import { useIntl } from 'react-intl';

interface DuplicateDocumentButtonProps {
  quoteDocument: QuoteDocument;
}

export const DuplicateDocumentButton: FC<DuplicateDocumentButtonProps> = props => {
  const { quoteDocument } = props;
  const history = useHistory();
  const API = useApi();
  const intl = useIntl();
  const toast = useToaster();

  const duplicateDocument = () => {
    const confirmation = confirm(
      intl.formatMessage({id: 'confirmCreateDocument', defaultMessage: 'Cette action va créer un nouveau document, êtes vous sûr ?'}));
    if (!confirmation) {
      return;
    }
    // eslint-disable-next-line
    const { id, ...rest } = quoteDocument;
    API.createDocument({
      ...rest,
      status: 'draft',
    }).then(result => {
      history.push('/documents/?highlight=' + result.id);
    }).catch(() => toast(intl.formatMessage({id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue'})));
  };

  return <Button colorType={'secondary'} onClick={duplicateDocument}>
    {intl.formatMessage({id: 'duplicate', defaultMessage: 'Dupliquer'})}
  </Button>;
};