import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '../button';

const SquareBtn = styled(Button)`
  padding: 0 0 0 ${({ small }) => (small ? '3px' : '5px')};
  width: ${({ small }) => (small ? '24px' : '40px')};
  height: ${({ small }) => (small ? '24px' : '40px')};
`;

interface SquareButtonProps extends ButtonProps {}

export const SquareButton: FC<SquareButtonProps> = props => {
  return <SquareBtn {...(props as any)} />;
};
