import { FC, FormEvent, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Dialog, Input, RadioGroup, RadioOption, Select, SelectOption, SpanCaption1 } from "@linkeo.com/ui-lib-react";
import { AIDES_ET_PRIMES, FormScope, FormType } from "../../interface/scope.types";
import { LocaleToName } from "../../utils/locale.utils";
import { Locale } from "../../providers/intl.provider";

interface CreateFormDialogProps {
    show: boolean;
    onClose: () => void;
    formType: Omit<FormScope, 'id'>;
    onSubmit: (form: Omit<FormScope, 'id'>) => void;
    loading: boolean
}

export const CreateFormDialog: FC<CreateFormDialogProps> = ({ show, onClose, formType, loading, onSubmit }) => {
    const intl = useIntl();
    const [form, setForm] = useState<Omit<FormScope, 'id'>>(formType);
    const optionsFormType: RadioOption<FormType | null>[] = useMemo(() => {
        return [
            {
                data: null,
                name: intl.formatMessage({ id: 'createFormDialogTypeEstimateRadioLabel', defaultMessage: 'Estimation' }),
                column: -1,
                caption: intl.formatMessage({ id: 'createFormDialogTypeEstimateRadioCaption', defaultMessage: 'Prix ou Valeur' })
            }, {
                data: AIDES_ET_PRIMES,
                name: intl.formatMessage({ id: 'createFormDialogTypeAssistanceRadioLabel', defaultMessage: 'Aide' }),
                column: -1,
                caption: intl.formatMessage({ id: 'createFormDialogTypeAssistanceRadioCaption', defaultMessage: 'Estimation d’éligibilité ou non' })
            }
        ]
    }, [intl]);
    const localeOptions: SelectOption<Locale>[] = useMemo(() => {
        return ['fr-FR', 'fr-CA', 'es-US', 'en-US', 'en-AU', 'en-CA'].map(locale => ({
            value: locale as Locale,
            label: LocaleToName(locale as Locale),
        }))
    }, []);

    const _onSubmit = (e: FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit(form)
    }

    const footer = <Button colorType={'secondary'} loading={loading} type='submit' form='form_dialog'>
        {intl.formatMessage({ id: 'createButton', defaultMessage: 'Créer' })}
    </Button>;

    return <Dialog onClose={onClose} show={show} footer={footer} size={"md"}
        title={intl.formatMessage({ id: 'formPresentationCreateFormButtonLabel', defaultMessage: 'Créer un formulaire' })}>
        <form id='form_dialog' onSubmit={_onSubmit}>
            <Input value={form.name} style={{ width: '50%' }} onChange={(name) => setForm({ ...form, name })} required
                label={intl.formatMessage({ id: 'createFormDialogNameLabel', defaultMessage: 'Nom du formulaire' })} />
            <RadioGroup groupName={"formType"} options={optionsFormType}
                required
                label={intl.formatMessage({ id: 'createFormDialogRadioGroupLabel', defaultMessage: 'Type de formulaire' })}
                value={optionsFormType.find(el => el.data === form.params.formType)}
                onChange={(val) => setForm({ ...form, params: { ...form.params, formType: val.data } })} />
            <Col columns={[6]} style={{padding: 0}}>
            <Select label={intl.formatMessage({ id: 'createFormDialogLocaleSelectLabel', defaultMessage: 'Sélectionnez la localité qui correspond à l’audience de ce formulaire' })}
                selected={{ label: LocaleToName(form.locale), value: form.locale }}
                onSelect={(val) => setForm({ ...form, locale: val.value })}
                options={localeOptions} />
            </Col>
            <SpanCaption1>
                <FormattedMessage id={'createFormDialogCaption'} defaultMessage={'Les champs marqués d\'un * sont obligatoires.'} />
            </SpanCaption1>
        </form>
    </Dialog>
}