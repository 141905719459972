import { DocumentStatus, DocumentType } from '../interface/document.type';

export const DocumentStatusToPageRoute = (documentStatus: DocumentStatus, type: DocumentType): string => {

  switch (documentStatus) {
    default:
    case 'draft':
      return 'draft';
    case 'expired':
    case 'finalized':
      if (type === 'invoice') {
        return 'invoice';
      }
      return 'quote';
  }
};