import styled from 'styled-components';
import { DropdownMaskProps } from './dropdown.types';

export const DropdownMask = styled.div<DropdownMaskProps>`
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  pointer-events: none;
  ${({ right }): string => (right ? 'right' : 'left')}: 0;
  ${({ fulfilled }): string => (fulfilled ? 'width: 100%' : '')};
`;
