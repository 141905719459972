import { FC } from 'react';
import { CardTitle, Col, Input, Row } from '@linkeo.com/ui-lib-react';
import { BankInformations } from '../../interface/company.types';
import { BankInformationsErrors, hasBankInformations } from '../../utils/quote-document-validator';
import { ibanLengthByCountryCode } from '../../utils/algo.utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Locale } from '../../providers/intl.provider';

interface BankInfoFieldsProps {
  bankInformations: BankInformations;
  errors: BankInformationsErrors;
  onChange: (v: BankInformations) => void;
  locale: Locale
}

export const BankInfoFields: FC<BankInfoFieldsProps> = props => {
  const { bankInformations, onChange, errors, locale } = props;
  const intl = useIntl();
  const isRequired: boolean = hasBankInformations(bankInformations);
  return <>
    <CardTitle>
      <FormattedMessage id={'bankInfoFormTitle'} defaultMessage={'Informations Bancaires'} />
    </CardTitle>
    <Row>
      <Col columns={[6]}>
        <Input
          required={locale === 'fr-FR' ? isRequired : false}
          hasError={errors.bankName}
          value={bankInformations.bankName}
          label={intl.formatMessage({id: 'bankInfoFormNameLabel', defaultMessage: 'Banque'})}
          onChange={v => onChange({ ...bankInformations, bankName: v })} />
      </Col>
      <Col columns={[6]}>
        <Input
          required={locale === 'fr-FR' ? isRequired : false}
          hasError={errors.rib}
          value={bankInformations.rib}
          label={intl.formatMessage({id: 'bankInfoFormRIBLabel', defaultMessage: 'RIB'})}
          onChange={v => onChange({ ...bankInformations, rib: v })} />
      </Col>
      <Col columns={[6]}>
        <Input
          required={locale === 'fr-FR' ? isRequired : false}
          hasError={errors.iban}
          maxLength={ibanLengthByCountryCode(bankInformations.iban.substring(0,2))}
          value={bankInformations.iban}
          label={intl.formatMessage({id: 'bankInfoFormIBANLabel', defaultMessage: 'IBAN'})}
          onChange={v => onChange({ ...bankInformations, iban: v })} />
      </Col>
      <Col columns={[6]}>
        <Input
          required={locale === 'fr-FR' ? isRequired : false}
          maxLength={11}
          hasError={errors.bic}
          value={bankInformations.bic}
          label={intl.formatMessage({id: 'bankInfoFormBICLabel', defaultMessage: 'BIC'})}
          onChange={v => onChange({ ...bankInformations, bic: v })} />
      </Col>
    </Row>
  </>;
};