import * as React from 'react';
import { FC, useState } from 'react';
import { BaseInput, BaseInputProps } from '../base-input';

export interface BaseInputNumberProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'label'>,
    BaseInputProps {
  value?: number | null;
  onChange: (value: number | null, originalEvent: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BaseInputNumber: FC<BaseInputNumberProps> = props => {
  const { value, onChange, ...rest } = props;
  const [getTemp, setTemp] = useState<string | undefined>();
  const emitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (!onChange) {
      console.warn('onChange is not defined');
      return;
    }
    if (typeof target.value === 'undefined' || target.value === '') {
      if (!target.validity.badInput) {
        onChange(null, event);
        return;
      }
    }
    const parsed = parseFloat(target.value);
    if (isNaN(parsed)) {
      setTemp(target.value);
      return;
    }
    setTemp(undefined);
    onChange(parsed, event);
  };
  return (
    <BaseInput
      {...(rest as any)}
      type={'number'}
      value={
        typeof getTemp === 'undefined'
          ? typeof value === 'undefined' || value === null
            ? ''
            : value
          : getTemp
      }
      onChange={emitChange}
    />
  );
};
