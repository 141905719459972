import { FC } from 'react';
import { BaseButton, Card, Flex, InOut, SpanSubtitle2, Svg } from '@linkeo.com/ui-lib-react';
import { useTheme } from 'styled-components';
import { SmallCol, SmallRow } from '../ui/small-columns';
import { FormattedMessage } from 'react-intl';

interface SubtotalEditProps {
  onPositionMove: (direction: 'up' | 'down') => void;
  total: string;
  onDelete: () => void;
}

export const SubtotalEdit: FC<SubtotalEditProps> = ({ onPositionMove, total, onDelete }) => {
  const theme = useTheme();

  return <InOut
    show={true}
    autoScroll={{ behavior: 'smooth', block: 'center' }}
    startTriggering>
    <Card style={{ margin: '0 -70px', padding: '16px 8px' }}>
      <SmallRow justifyContent={['space-between']} alignItems={['center']}>
        <SmallCol>
          <SmallRow justifyContent={['space-between']} alignItems={['center']}>
            <Flex style={{ width: '62px' }} direction={['column']} justifyContent={['space-between']}>
              <BaseButton onClick={() => onPositionMove('up')}>
                <Svg icon={'up'} fill={theme.colors.primary} width={24} height={24} />
              </BaseButton>
              <BaseButton onClick={() => onPositionMove('down')}>
                <Svg icon={'down'} fill={theme.colors.primary} width={24} height={24} />
              </BaseButton>
            </Flex>
            <SpanSubtitle2 style={{ fontWeight: '800' }}>
              <FormattedMessage id={'quoteNewLayoutSubtotal'} defaultMessage={'Sous-total'} />
            </SpanSubtitle2>
          </SmallRow>
        </SmallCol>
        <SmallCol>
          <SmallRow justifyContent={['space-between']} alignItems={['center']}>
            <SpanSubtitle2 style={{ fontWeight: '800' }}>{total}</SpanSubtitle2>
            <div style={{padding: '0 15px'}}>
              <BaseButton onClick={onDelete}>
                <Svg icon={'delete'} fill={theme.colors.primary} width={24} height={24} />
              </BaseButton>
            </div>
          </SmallRow>
        </SmallCol>
      </SmallRow>
    </Card>
  </InOut>;
};