import React, { FC, useState } from 'react';
import { QuestionType } from '../../interface/question.types';
import { Button, CardSubtitle, CardTitle, Col, Dialog, LabelWrapper, Radio, Row } from '@linkeo.com/ui-lib-react';
import { useIntl } from 'react-intl';

interface QuestionDialogProps {
  onClose: () => void;
  onValid: (type: QuestionType) => void;
  show: boolean,
}

export const QuestionDialog: FC<QuestionDialogProps> = props => {
  const { show, onClose, onValid } = props;
  const [getValue, setValue] = useState<QuestionType>('SHORT_TEXT');
  const intl = useIntl();
  const footer = <>
    <Button colorType={'secondary'} onClick={onClose}>{intl.formatMessage({id: 'cancel', defaultMessage: 'Annuler'})}</Button>
    <Button colorType={'secondary'} onClick={() => onValid(getValue)}>{intl.formatMessage({id: 'add', defaultMessage: 'Ajouter'})}</Button>
  </>;
  return <Dialog title={intl.formatMessage({id: 'addAQuestion', defaultMessage: 'Ajouter une question'})} onClose={onClose} show={show} size={'md'}
                 footer={footer}>
    <CardTitle>
      {intl.formatMessage({id: 'addAQuestion', defaultMessage: 'Ajouter une question'})}
    </CardTitle>
    <CardSubtitle>
      {intl.formatMessage({id: 'addQuestionDialogSubtitle', defaultMessage: 'Quel type de réponse attendez-vous de votre prospect ?'})}
    </CardSubtitle>
    <Row wraps={['wrap']}>
      <Col columns={[6]}>
        <LabelWrapper caption={intl.formatMessage({id: 'addQuestionDialogTextCaption', defaultMessage: 'Pour des réponses courtes'})}>
          <Radio name={'questionChoice'}
                 value={getValue === 'SHORT_TEXT'}
                 onChange={() => setValue('SHORT_TEXT')}>
            {intl.formatMessage({id: 'addQuestionDialogTextLabel', defaultMessage: 'Texte court'})}
          </Radio>
        </LabelWrapper>
      </Col>
      <Col columns={[6]}>
        <LabelWrapper caption={intl.formatMessage({id: 'addQuestionDialogTextareaCaption', defaultMessage: 'Pour des réponses plus développées'})}>
          <Radio
            name={'questionChoice'}
            value={getValue === 'LONG_TEXT'}
            onChange={() => setValue('LONG_TEXT')}>
            {intl.formatMessage({id: 'addQuestionDialogTextareaLabel', defaultMessage: 'Texte long'})}
          </Radio>
        </LabelWrapper>
      </Col>
      <Col columns={[6]}>
        <LabelWrapper caption={intl.formatMessage({id: 'addQuestionDialogCheckboxCaption', defaultMessage: 'Plusieurs réponses parmi plusieurs choix'})}>
          <Radio
            name={'questionChoice'}
            value={getValue === 'MULTIPLE_CHOICE'}
            onChange={() => setValue('MULTIPLE_CHOICE')}>
            {intl.formatMessage({id: 'addQuestionDialogCheckboxLabel', defaultMessage: 'Choix multiple'})}

          </Radio>
        </LabelWrapper>
      </Col>
      <Col columns={[6]}>
        <LabelWrapper caption={
          intl.formatMessage({id: 'addQuestionDialogRadioCaption', defaultMessage: 'Une seule réponse parmi plusieurs choix'})}>
          <Radio
            name={'questionChoice'}
            value={getValue === 'SINGLE_CHOICE'}
            onChange={() => setValue('SINGLE_CHOICE')}>
            {intl.formatMessage({id: 'addQuestionDialogRadioLabel', defaultMessage: 'Choix unique'})}
          </Radio>
        </LabelWrapper>
      </Col>
      <Col columns={[6]}>
        <LabelWrapper caption={
          intl.formatMessage({id: 'addQuestionDialogNumberCaption', defaultMessage: 'Demander une précision sur la quantité'})}>
          <Radio
            name={'questionChoice'}
            value={getValue === 'QUANTITY'}
            onChange={() => setValue('QUANTITY')}>
            {intl.formatMessage({id: 'addQuestionDialogNumberLabel', defaultMessage: 'Quantité'})}
          </Radio>
        </LabelWrapper>
      </Col>
    </Row>

  </Dialog>;
};
