import { FC, FormEvent, useEffect, useState } from 'react';
import { Button, Dialog, InOut, Input, RadioGroup, RadioOption } from '@linkeo.com/ui-lib-react';
import { EditReferentialType } from '../../interface/api.types';
import { useIntl } from 'react-intl';

interface EditReferentialDialogDialogProps {
  askNewId?: boolean;
  isLoading: boolean;
  onClose: () => void;
  concern: 'customer' | 'product';
  isCollision?: boolean;
  currentId?: string;
  onSubmit: (state: EditReferentialType, updatedId: string | undefined) => void;
  show: boolean;
}

export const EditReferentialDialog: FC<EditReferentialDialogDialogProps> = props => {
  const { onClose, show, onSubmit, currentId, isLoading, concern, askNewId, isCollision } = props;
  const intl = useIntl();
  const [getCodeProduct, setCodeProduct] = useState<string | undefined>();
  useEffect(() => {
    setCodeProduct(currentId);
  }, [currentId]);
  const commonChoices: RadioOption<EditReferentialType>[] = [{
    uid: 'keep',
    data: 'keep', columns: [12],
    name: intl.formatMessage({
      id: 'editReferencialDialogKeepLabel',
      defaultMessage: 'Mettre à jour uniquement ce document',
    }),
  }, {
    uid: 'update',
    data: 'update', columns: [12],
    name: intl.formatMessage({
      id: 'editReferencialDialogUpdateLabel',
      defaultMessage: 'Modifier le {value} déjà existant',
    }, {
      value: concern === 'product' ? intl.formatMessage({
        id: 'product',
        defaultMessage: 'service/article',
      }) : intl.formatMessage({ id: 'client', defaultMessage: 'client' }),
    }),
  },
    {
      uid: 'create',
      data: 'create', columns: [12],
      name: intl.formatMessage({
        id: 'editReferencialDialogCreateLabel',
        defaultMessage: 'Créer un nouveau référentiel {value}',
      }, {
        value: concern === 'product' ? intl.formatMessage({
          id: 'product',
          defaultMessage: 'service/article',
        }) : intl.formatMessage({ id: 'client', defaultMessage: 'client' }),
      }),
    }];
  const options: RadioOption<EditReferentialType>[] = commonChoices.filter(cc => cc.data === 'update' ? !!currentId : true);
  const [getEditType, setEditType] = useState<RadioOption<EditReferentialType>>(options[0]);
  const emitClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };
  const emitSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    if (isLoading) {
      return;
    }
    if (!getEditType.data) {
      return;
    }
    if (getEditType.data === 'create' && currentId && (!getCodeProduct || getCodeProduct === currentId)) {
      return;
    }
    onSubmit(getEditType.data, getCodeProduct);
  };
  const footer = <>
    <Button colorType={'secondary'} onClick={emitClose} loading={isLoading}>{intl.formatMessage({
      id: 'cancel',
      defaultMessage: 'Annuler',
    })}</Button>
    <Button colorType={'secondary'} type={'submit'} form={'editReferential'}
            loading={isLoading}>{intl.formatMessage({ id: 'submit', defaultMessage: 'Valider' })}</Button>
  </>;
  return <Dialog onClose={emitClose}
                 show={show}
                 title={`${currentId ? intl.formatMessage({
                   id: 'update',
                   defaultMessage: 'Modification du',
                 }) : intl.formatMessage({
                   id: 'create',
                   defaultMessage: 'Création du',
                 })} ${concern === 'product' ? intl.formatMessage({
                   id: 'product',
                   defaultMessage: 'service/article',
                 }) : intl.formatMessage({ id: 'client', defaultMessage: 'client' })}`}
                 size={'md'}
                 footer={footer}>
    <form id={'editReferential'} onSubmit={emitSubmit}>
      <RadioGroup label={intl.formatMessage({
        id: 'editReferencielDialogRadioGroupLabel',
        defaultMessage: 'Que voulez vous faire ?',
      })}
                  options={options} value={getEditType} groupName={'EditProductType'} onChange={setEditType} />
      {askNewId && getEditType.data === 'create' && !!currentId ?
        <InOut show={true} startTriggering>
          <Input label={intl.formatMessage({
            id: 'editReferentialDialogInputLabel',
            defaultMessage: 'Veuillez indiquez une nouvelle référence',
          })}
                 caption={isCollision ? intl.formatMessage({
                   id: 'editReferentialDialogErrorMessage',
                   defaultMessage: 'Cette référence existe déjà',
                 }) : undefined}
                 required value={getCodeProduct}
                 onChange={setCodeProduct}
                 hasError={!getCodeProduct || getCodeProduct === currentId || isCollision}
          /></InOut> : null}
    </form>
  </Dialog>;
};