import { getContrast } from 'polished';
import { ColorHSV } from '../../common';

export const HslToCss = (hue: number, saturate: number, light: number): string => {
  return `hsl(${Math.round(hue)},${Math.round(saturate)}%,${Math.round(light)}%)`;
};
export const ColorSelectGradientToCss = (hue: number): string => {
  return `linear-gradient(90deg,#ffffff 0%, hsl(${hue}, 100%, 50%) 100%)`;
};
export const HueToRgb = (p: number, q: number, t: number): number => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
};

export const HslToRgb = (hue: number, saturate: number, light: number): Uint8Array => {
  const h = hue;
  const s = saturate;
  const l = light;
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = HueToRgb(p, q, h + 1 / 3);
    g = HueToRgb(p, q, h);
    b = HueToRgb(p, q, h - 1 / 3);
  }

  return new Uint8Array([Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]);
};
const calcHue = (
  max: number,
  rRatio: number,
  gRatio: number,
  bRatio: number,
  d: number
): number => {
  switch (max) {
    case rRatio:
      return (gRatio - bRatio) / d + (gRatio < bRatio ? 6 : 0);
    case gRatio:
      return (bRatio - rRatio) / d + 2;
    case bRatio:
      return (rRatio - gRatio) / d + 4;
    default:
      return 0;
  }
};

export const RgbToHsl = (red: number, green: number, blue: number): Float32Array => {
  const rRatio = red / 255;
  const gRatio = green / 255;
  const bRatio = blue / 255;
  const max = Math.max(rRatio, gRatio, bRatio);
  const min = Math.min(rRatio, gRatio, bRatio);
  const l = (max + min) / 2;
  let h = 0;
  let s = 0;
  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    h = calcHue(max, rRatio, gRatio, bRatio, d) / 6;
  }

  return new Float32Array([h, s, l]);
};

export const HsvToHsl = (hue: number, sat: number, val: number): number[] => {
  const newHue = (2 - sat) * val;
  return [
    hue,
    newHue > 0 ? (newHue < 2 ? (sat * val) / (newHue < 1 ? newHue : 2 - newHue) : 1) : 0,
    newHue / 2,
  ];
};

export const HslToHsv = (hue: number, sat: number, light: number): ColorHSV => {
  sat *= light < 0.5 ? light : 1 - light;
  const sumLS = light + sat;
  return [hue, sumLS > 0 ? (2 * sat) / sumLS : 0, sumLS];
};
/**
 * return color of the list which better for contrast
 * @param whichColor color to test contrast
 * @param contrastColors list of colors to choose for contrast
 * @constructor
 */
export const SelectContrast = (whichColor: string, contrastColors: string[]): string => {
  const contrastValues = contrastColors.map(color => getContrast(whichColor || '#000', color));
  const max = Math.max(...contrastValues);
  const index = contrastValues.indexOf(max);
  return contrastColors[index];
};

export const DecToHexaString = (val: number): string => {
  const result = val.toString(16);
  return result.length < 2 ? '0' + result : result;
};
export const HsvToCssHexaColor = (hue: number, x: number, y: number): string => {
  const hsl = HsvToHsl(hue, x, y);
  const rgb = HslToRgb(hsl[0], hsl[1], hsl[2]);
  return `#${DecToHexaString(rgb[0])}${DecToHexaString(rgb[1])}${DecToHexaString(
    rgb[2]
  )}`.toUpperCase();
};

export const CssHexaColorToHsv = (cssHexa: string): ColorHSV => {
  const extract = cssHexa.replace('#', '');
  const colorParse = [];
  for (let i = 0; i < extract.length; i += 2) {
    colorParse.push(Number.parseInt(extract.slice(i, i + 2), 16));
  }
  const hsl = RgbToHsl(colorParse[0], colorParse[1], colorParse[2]);
  return HslToHsv(hsl[0], hsl[1], hsl[2]);
};

export const CoordinateToHsv = (
  hue360: number,
  cursorX: number,
  cursorY: number,
  width: number,
  height: number
): ColorHSV => {
  return [hue360 / 360, cursorX / width, (height - cursorY) / height];
};
export const HsvToCoordinate = (
  hsv: ColorHSV,
  width: number,
  height: number
): { hue360: number; cursorX: number; cursorY: number } => {
  return {
    hue360: hsv[0] * 360,
    cursorX: width * hsv[1],
    cursorY: height - hsv[2] * height,
  };
};
