/**
 *
 * @param str: string
 * @returns {string}
 */
export const NormalizeText = (str: string | undefined): string => {
  if (!str) {
    return '';
  }
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
/**
 *
 * @param word
 * @param phrase
 * @returns {RegExpMatchArray}
 */
export const MatchLike = (phrase: string, word: string): RegExpMatchArray | undefined => {
  const sanitize = word.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
  const reg = phrase.toUpperCase().match(new RegExp(sanitize.toUpperCase()));
  if (reg) {
    return reg;
  }
};
