import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuoteDocument } from '../../interface/document.type';
import { DocumentView } from '../../components/document/document-view';
import { Col, Container, Row } from '@linkeo.com/ui-lib-react';
import { DocumentParamProvider } from '../../providers/document-param.provider';
import { PageWrapper } from '../../components';
import { useApi } from '../../providers/api-provider';
import { OpenAttachedQuoteButton } from '../../components/document/open-attached-quote-button';
import { DuplicateDocumentButton } from '../../components/document/duplicate-document-button';
import { DownloadDocumentButton } from '../../components/document/download-document-button';
import { ServerError } from '../../components/commons/server-error';
import { Error404 } from '../../components/commons/404-error';

interface DocumentInvoicePageProps {
}

export const DocumentInvoicePage: FC<DocumentInvoicePageProps> = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const API = useApi();
  const [getDocument, setDocument] = useState<QuoteDocument>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true)
    API.retrieveDocument(documentId)
      .then(result => setDocument(result))
      .catch(() => setPageError(true))
      .finally(() => setLoading(false))
  }, [API, documentId]);

  return <PageWrapper isLoading={loading}>
    <DocumentParamProvider>
      {getDocument && !pageError ?<Container size={'lg'}>
        <DocumentView
          errors={{}}
          readOnly={true}
          quoteDocument={getDocument}
          onDocumentChange={setDocument}>
        </DocumentView>
        <Row style={{ marginTop: '20px' }}>
          {
            getDocument.quoteId ? <Col>
              <OpenAttachedQuoteButton quoteId={getDocument.quoteId} />
            </Col> : null
          }
          <Col>
            <DuplicateDocumentButton quoteDocument={getDocument} />
          </Col>
          <Col>
            <DownloadDocumentButton documentId={getDocument.id} fileTitle={getDocument.title} />
          </Col>
        </Row>
      </Container>: getDocument && pageError ? <ServerError /> : <Error404 /> }
    </DocumentParamProvider>
  </PageWrapper>;
};
