import * as React from 'react';
import styled from 'styled-components';
import { Keyframes } from '../../common';
import { InOut } from '../in-out';
import { BaseButton } from '../base-button';
import { Col } from '../col';
import { Row } from '../row';
import { Svg } from '../svg';

export interface DeletableProps {
  onDelete: () => void;
  hide?: boolean;
  offsetTop?: string;
  children?: React.ReactNode;
}

const DeletableButton = styled(BaseButton)`
  background: transparent;

  svg {
    fill: ${({ theme }) => theme.colors.grey40};
    transition: ease-in-out fill 200ms;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.grey20};
    }
  }
`;
export const Deletable: React.FC<DeletableProps> = props => {
  const { offsetTop, onDelete, hide, children } = props;
  return (
    <InOut
      enter={{ keyframes: Keyframes.slideInFrom('-50px') }}
      exit={{ keyframes: Keyframes.slideOutFrom('50px') }}
      show={!hide}
    >
      <Row alignItems={['center']}>
        <Col style={{ flex: '1' }}>{children}</Col>
        <Col style={{ marginTop: offsetTop || '10px' }}>
          <DeletableButton onClick={() => onDelete && onDelete()}>
            <Svg icon={'delete'} height={24} width={24} />
          </DeletableButton>
        </Col>
      </Row>
    </InOut>
  );
};
