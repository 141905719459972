import styled from 'styled-components';
import { getTypo, Shapes } from '../../common';

const Bubble = styled.section`
  ${Shapes.bubble};
  padding: 24px;
  margin: 16px;
`;
const Subtitle = styled.span`
  ${getTypo('subtitle2')};
  margin-right: 16px;
`;
const Copy = styled.p`
  ${getTypo('body1')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 0;
  padding: 0;
`;
const Header = styled.header`
  margin-bottom: 16px;
`;
const LabelDate = styled.span`
  ${getTypo('caption1')};
  color: ${({ theme }) => theme.colors.grey50};
  white-space: nowrap;
`;
export { Bubble, Subtitle, Copy, Header, LabelDate };
