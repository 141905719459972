import { FC } from 'react';
import { Flex, SpanSubtitle1, SpanSubtitle2 } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { DocumentTotal } from '../../interface/document.type';
import { Devise, RoundFloat } from '../../utils/number.utils';
import { NumberMod } from '../../interface/product.types';
import { TotalSubLineForm } from './total-sub-line-form';
import { Locale } from '../../providers/intl.provider';
import { FormattedMessage, useIntl } from 'react-intl';

const Wrapper = styled.div`
    ${Flex} {
        margin-bottom: 5px;
    }
`;

interface DocumentTotalDisplayProps {
  currency: Devise;
  locale: Locale;
  deposit: NumberMod;
  discount: NumberMod;
  onDepositChange: (v: NumberMod) => void;
  onDiscountChange: (v: NumberMod) => void;
  readOnly: boolean;
  total?: DocumentTotal;
  isInvoice: boolean;
}

export const TotalDisplay: FC<DocumentTotalDisplayProps> = props => {
  const { total, readOnly, onDiscountChange, discount, onDepositChange, deposit, currency, isInvoice, locale } = props;
  const intl = useIntl();
  return <Wrapper>
    <Flex justifyContent={['space-between']}>
      <SpanSubtitle1><FormattedMessage id={'TotalHT'} defaultMessage={'Total HT'} /></SpanSubtitle1>
      <SpanSubtitle1>{intl.formatNumber(total?.totalWithoutTax || 0, { style: 'currency', currency })}</SpanSubtitle1>
    </Flex>
    <TotalSubLineForm
      locale={locale}
      label={intl.formatMessage({ id: 'discountTypeLabel', defaultMessage: 'Remise' })}
      switchLabel={intl.formatMessage({ id: 'discountTypeSwitchLabel', defaultMessage: 'Type de remise' })}
      readOnlyLabel={intl.formatMessage({
        id: 'discountAmountLabel',
        defaultMessage: 'Remise globale HT ({amount}) %',
      }, { amount: RoundFloat(total?.discountPercentage, 100) })}
      mod={discount}
      currency={currency}
      onModChange={onDiscountChange}
      readOnly={readOnly}
      totalAmount={total?.discountAmount || 0} />
    <Flex justifyContent={['space-between']}>
      <SpanSubtitle1><FormattedMessage id={'TotalDisplayNetHT'} defaultMessage={'et HT'} /></SpanSubtitle1>
      <SpanSubtitle1>{intl.formatNumber(total?.netTotalWithoutTax || 0, {
        style: 'currency',
        currency,
      })}</SpanSubtitle1>
    </Flex>
    {total?.taxAmount.map((tax, i) => <Flex key={i} justifyContent={['space-between']}>
      <SpanSubtitle1>{tax.name} {RoundFloat(tax.rate, 100)} % {intl.formatMessage({
        id: 'taxBaseAmountLabelTotalDisplay',
        defaultMessage: 'sur {amount} HT',
      }, { amount: intl.formatNumber(tax.baseAmount || 0, { style: 'currency', currency }) })}</SpanSubtitle1>
      <SpanSubtitle1>{intl.formatNumber(tax.amount || 0, { style: 'currency', currency })}</SpanSubtitle1>
    </Flex>)}

    <Flex justifyContent={['space-between']}>
      <SpanSubtitle2><FormattedMessage id={'TotalTTC'} defaultMessage={'Total TTC'} /></SpanSubtitle2>
      <SpanSubtitle2>{intl.formatNumber(total?.totalWithTax || 0, { style: 'currency', currency })}</SpanSubtitle2>
    </Flex>
    <TotalSubLineForm
      label={intl.formatMessage({ id: 'depositTypeLabel', defaultMessage: 'Acompte' })}
      switchLabel={intl.formatMessage({ id: 'depositTypeSwitchLabel', defaultMessage: 'Type d’acompte' })}
      readOnlyLabel={intl.formatMessage({
        id: 'depositAmountLabel',
        defaultMessage: 'Acompte TTC de {amount} %',
      }, { amount: RoundFloat(total?.depositPercentage, 100) })}
      mod={deposit}
      locale={locale}
      currency={currency}
      onModChange={onDepositChange}
      readOnly={readOnly}
      totalAmount={total?.depositAmount || 0} />
    {isInvoice && <Flex justifyContent={['space-between']}>
      <SpanSubtitle2><FormattedMessage id={'TotalDisplayNetTTC'} defaultMessage={'Net à payer'} /></SpanSubtitle2>
      <SpanSubtitle2>{intl.formatNumber(total?.netPayable || 0, { style: 'currency', currency })}</SpanSubtitle2>
    </Flex>}
  </Wrapper>;
};