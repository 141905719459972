import React, {FC} from 'react';
import styled from 'styled-components';
import {
    ExcludeChildrenByType,
    LoadingContent,
    QueryChildrenByType,
    TopHeader,
    TopSticky,
} from '@linkeo.com/ui-lib-react';
import {Navigation} from '../navigation';
import {useIntl} from 'react-intl';

interface PageWrapperProps {
    isLoading: boolean;
}

const Header = styled.header`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Main = styled.main`
  background: #f5f5f5;
  min-height: calc(100vh - 176px);
  padding: 0 48px;
  position: relative;
`;
export const PageWrapperSubHeader: FC = props => {
    const {children} = props;
    return <>{children}</>
}

export const PageWrapper: FC<PageWrapperProps> = props => {
    const {children, isLoading} = props;
    const intl = useIntl();
    const appendHeader = QueryChildrenByType(children, PageWrapperSubHeader);
    const filteredChildren = ExcludeChildrenByType(children, [PageWrapperSubHeader]);

    return (
        <div>
            <Header>
                <TopHeader title={intl.formatMessage({id: 'appTitle', defaultMessage: 'Quote'})}
                           subtitle={intl.formatMessage({
                               id: 'appSubtitle',
                               defaultMessage: 'Personnalisez vos formulaires{br}de demande de devis'
                           }, {br: <br/>}) as string}/>
                <TopSticky>
                    <Navigation/>
                    {appendHeader}
                </TopSticky>
            </Header>
            <LoadingContent isLoading={isLoading}>
                <Main id={'main'}>
                    {filteredChildren}
                </Main>
            </LoadingContent>
        </div>
    );
};
