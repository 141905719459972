import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DocumentTableHead } from '../document/document-table-head';
import { ItemState, Product } from '../../interface/product.types';
import { Button, Col, Dropdown, getTypo, Row } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { RemoveItemInArray, TranslateItemInArray, UpdateItemInArray } from '../../utils/deep-object.utils';
import { ProductLine } from '../product/product-line';
import { Devise } from '../../utils/number.utils';
import { Locale } from '../../providers/intl.provider';
import { FormattedMessage } from 'react-intl';
import { SeparatorItem, SubtotalItem, TextItem, TitleItem } from '../../interface/document.type';
import { SubtotalLine } from '../subtotal/subtotal-line';
import { SeparatorLine } from '../separator/separator-line';
import { TextLine } from '../text/text-line';

const GreyBox = styled.div`
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.grey95};
`;

const DropDown = styled.div`
  margin: 0 16px;
  padding: 8px 16px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.light};
`;

const DropDownItem = styled.button`
  border: none;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.light};
  ${getTypo('fontButton')}
  color: ${({ theme }) => theme.colors.grey40};
  line-height: 24px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

interface DocumentItemsProps {
  items: Array<Product | SubtotalItem | SeparatorItem | TitleItem | TextItem>;
  onChange: (items: (Product | SubtotalItem | SeparatorItem | TitleItem | TextItem)[]) => void;
  readOnly: boolean;
  currency: Devise;
  locale: Locale;
  total: number;
}

const defaultProduct: Product = {
  productCode: '',
  description: '',
  name: '',
  productType: 'product',
  price: 0,
  itemType: 'product',
  unit: '',
  quantity: 1,
  taxes: [],
};

const defaultSubtotal: SubtotalItem = {
  itemType: 'subtotal',
  total: {
    totalWithTax: 0,
    taxAmount: [],
    totalWithoutTax: 0,
  },
};

export const DocumentItems: FC<DocumentItemsProps> = props => {
  const { items, onChange, readOnly, currency, locale } = props;
  const [getItems, setItems] = useState<ItemState[]>([]);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(false);

  const restoreItems = useCallback(() => {
    setItems(items.map(item => {
        if ('productCode' in item) {
          return ({
            item,
            uid: item.productCode,
            editMode: false,
            readOnly: false,
          });
        } else {
          return ({
            item,
            uid: Math.floor(Math.random() * Date.now()).toString(),
            editMode: false,
            readOnly: false,
          });
        }
      },
    ));
  }, [items]);

  useEffect(() => {
    restoreItems();
  }, [restoreItems, items]);

  const addItems = () => {
    updateItemsStates([...getItems, {
      item: defaultProduct,
      uid: '' + getItems?.length,
      readOnly: true,
      editMode: true,
    }]);
  };

  const emitItemChange = (index: number, itemState: ItemState) => {
    const updatedList = UpdateItemInArray(getItems, index, itemState);
    onChange(updatedList.map(m => m.item));
  };

  const emitDeleteItem = (index: number) => {
    const updatedList = RemoveItemInArray(getItems, index);
    onChange(updatedList.map(m => m.item));
  };

  const emitMoveItem = (index: number, direction: 'down' | 'up') => {
    const destination = index + (direction === 'down' ? 1 : -1);
    if (destination < 0) {
      return;
    }
    if (destination >= getItems.length) {
      return;
    }
    const updatedList = TranslateItemInArray(getItems, index, destination);
    if (updatedList.some(el => el.item.itemType === 'subtotal')) {
      onChange(updatedList.map(m => m.item));
    } else {
      setItems(updatedList);
    }
  };

  const updateItemsStates = (itemsStates: ItemState[]) => {
    if (itemsStates.some(s => s.editMode)) {
      setItems(itemsStates.map(m => ({ ...m, readOnly: true })));
    } else {
      setItems(itemsStates.map(m => ({ ...m, readOnly: false })));
    }
  };

  const onDropDownClick = (value: string) => {
    setShow(false);
    const list = [...getItems].map(m => m.item);
    switch (value) {
      case 'subtotal' :
        onChange([...list, defaultSubtotal]);
        break;
      case 'separator' :
        onChange([...list, { itemType: 'separator' }]);
        break;
      case 'title' :
        updateItemsStates([...getItems, {
          item: { itemType: 'title', text: '' },
          uid: '' + getItems?.length,
          readOnly: true,
          editMode: true,
        }]);
        break;
      case 'text' :
        updateItemsStates([...getItems, {
          item: { itemType: 'text', text: '' },
          uid: '' + getItems?.length,
          readOnly: true,
          editMode: true,
        }]);
        break;
    }
  };

  return <div style={{ margin: '20px 0' }}>
    <DocumentTableHead />
    <div>
      {getItems && getItems.map((itemState, i) => {
        switch (itemState.item.itemType) {
          case 'product':
            return <ProductLine
              locale={locale}
              currency={currency}
              readOnly={readOnly}
              key={itemState.uid}
              itemState={itemState}
              onStateChange={v => updateItemsStates(UpdateItemInArray(getItems, i, v))}
              onItemChange={v => emitItemChange(i, v)}
              onCancel={restoreItems}
              onDelete={() => emitDeleteItem(i)}
              onPositionMove={direction => emitMoveItem(i, direction)}
            />;
          case 'subtotal' :
            return <SubtotalLine
              key={itemState.uid}
              currency={currency}
              itemState={itemState}
              onStateChange={v => updateItemsStates(UpdateItemInArray(getItems, i, v))}
              readOnly={readOnly}
              onPositionMove={direction => emitMoveItem(i, direction)}
              onDelete={() => emitDeleteItem(i)}
            />;
          case 'separator' :
            return <SeparatorLine key={itemState.uid}
                                  readOnly={readOnly}
                                  itemState={itemState}
                                  onStateChange={v => updateItemsStates(UpdateItemInArray(getItems, i, v))}
                                  onPositionMove={direction => emitMoveItem(i, direction)}
                                  onDelete={() => emitDeleteItem(i)} />;
          case 'title' :
          case 'text':
            return <TextLine key={itemState.uid}
                             itemState={itemState}
                             readOnly={readOnly}
                             onPositionMove={direction => emitMoveItem(i, direction)}
                             onDelete={() => emitDeleteItem(i)}
                             onCancel={restoreItems}
                             onSubmit={(item) => emitItemChange(i, item)}
                             onStateChange={v => updateItemsStates(UpdateItemInArray(getItems, i, v))}
            />;
        }
      })}
    </div>

    {!readOnly && <GreyBox>
      <Row justifyContent={['center']}>
        <Col>
          <Button
            disabled={getItems?.some(s => s.editMode)}
            colorType={'primary'} icon={'plus-circle'}
            onClick={addItems}>
            <FormattedMessage id={'documentItemsAddButtonLabel'} defaultMessage={'Ajouter un service/article'} />
          </Button>
        </Col>
        <Col>
          <div ref={wrapperRef}>
            <Button colorType={'outline'} icon={'down'} iconRight onClick={() => setShow(!show)}
                    disabled={getItems?.some(s => s.editMode)}>
              <FormattedMessage id={'documentItemsFormattingButtonLabel'} defaultMessage={'Mise en forme'} />
            </Button>
          </div>
          <Dropdown show={show} onClose={() => setShow(false)} wrapperRef={wrapperRef} fulfilled>
            <DropDown>
              <DropDownItem onClick={() => onDropDownClick('title')}>
                <FormattedMessage id={'quoteNewLayoutTitle'} defaultMessage={'Titre libre'} />
              </DropDownItem>
              <DropDownItem onClick={() => onDropDownClick('text')}>
                <FormattedMessage id={'quoteNewLayoutText'} defaultMessage={'Texte libre'} />
              </DropDownItem>
              <DropDownItem onClick={() => onDropDownClick('separator')}>
                <FormattedMessage id={'quoteNewLayoutSeparator'} defaultMessage={'Séparateur'} />
              </DropDownItem>
              <DropDownItem onClick={() => onDropDownClick('subtotal')}>
                <FormattedMessage id={'quoteNewLayoutSubtotal'} defaultMessage={'Sous-total'} />
              </DropDownItem>
            </DropDown>
          </Dropdown>
        </Col>
      </Row>
    </GreyBox>}
  </div>;
};