import React, { FC } from 'react';
import { Question } from '../../interface/question.types';
import { Checkbox, Input, LabelWrapper } from '@linkeo.com/ui-lib-react';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { useIntl } from 'react-intl';
import { TextEditor } from '../commons/text-editor';

interface QuestionCommonProps {
    value: Question;
    onChange: (value: Question) => void
}

export const QuestionCommon: FC<QuestionCommonProps> = props => {
    const { children, onChange, value } = props;
    const intl = useIntl();
    return <div style={{ paddingBottom: '10px' }}>
        <Input
            required
            label={intl.formatMessage({ id: 'questionFormTitleLabel', defaultMessage: 'Intitulé de la question' })}
            value={value.label}
            onChange={e => onChange(UpdateItemInObject(value, 'label', e))}
        />
        <LabelWrapper
            label={intl.formatMessage({ id: 'questionFormDescLabel', defaultMessage: 'Description' })}
            caption={intl.formatMessage({ id: 'questionFormDescCaption', defaultMessage: 'Ajoutez une description pour préciser l’objectif de votre question' })}>
            <TextEditor
                locale={intl.locale}
                value={value.description || ""}
                onChange={e => onChange(UpdateItemInObject(value, 'description', e))} />
        </LabelWrapper>
        {children}
        <Checkbox
            value={value.mandatory}
            onChange={e => onChange(UpdateItemInObject(value, 'mandatory', e))}>
            {intl.formatMessage({ id: 'questionFormReqLabel', defaultMessage: 'La réponse à cette question est obligatoire' })}
        </Checkbox>
    </div>;
};
