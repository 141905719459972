import * as React from 'react';
import { FC, useState } from 'react';
import { DashboardCard } from '../dashboard-card';
import styled from 'styled-components';
import { Flex } from '../flex';
import { InOut } from '../in-out';
import { Row } from '../row';
import { Col } from '../col';
import {
  DashboardCardBody,
  DashboardCardSubtitle,
  DashboardCardTitle,
  Keyframes,
} from '../../common';
import { BaseButton } from '../base-button';
import { Svg } from '../svg';

export interface DashboardSlide {
  alt: string;
  body: string;
  imageUri: string;
  title: string;
}

interface DashboardSliderProps {
  slides: DashboardSlide[];
  subtitle: string;
}

const ImgContainer = styled.div`
  padding: 10px 0 0 16px;

  img {
    margin-bottom: 32px;
  }
`;
const CircleContainer = styled(BaseButton)`
  padding: 5px;
  display: flex;
  align-items: center;
  background: transparent;
`;

export const DashboardSlider: FC<DashboardSliderProps> = props => {
  const { slides, subtitle } = props;
  const [getPosition, setPosition] = useState<number>(0);
  return (
    <DashboardCard col={2}>
      <Row>
        <Col columns={[6]}>
          <ImgContainer>
            <div style={{ width: 200, height: 186, overflow: 'hidden' }}>
              {slides.map((slide, index) => (
                <InOut
                  key={index}
                  enter={{ keyframes: Keyframes.slideInFromLeft }}
                  exit={{ keyframes: Keyframes.slideOutToRight }}
                  style={{ position: 'absolute' }}
                  show={getPosition === index}
                >
                  <img width={200} height={150} src={slide.imageUri} alt={slide.alt} />
                </InOut>
              ))}
            </div>
            <Flex justifyContent={['center']}>
              {slides.length > 1 &&
                slides.map((slide, index) => (
                  <CircleContainer key={index} onClick={() => setPosition(index)}>
                    <Svg width={24} height={24}>
                      <circle
                        cx="24"
                        cy="24"
                        r="21"
                        fill={getPosition === index ? '#7a9899' : 'white'}
                        stroke="#7A9899"
                        strokeWidth="3"
                      />
                    </Svg>
                  </CircleContainer>
                ))}
            </Flex>
          </ImgContainer>
        </Col>
        <Col columns={[6]}>
          <DashboardCardSubtitle>{subtitle}</DashboardCardSubtitle>
          {slides.map((slide, index) => (
            <InOut key={index} style={{ position: 'absolute' }} show={getPosition === index}>
              <DashboardCardTitle>{slide.title}</DashboardCardTitle>
              <DashboardCardBody>{slide.body}</DashboardCardBody>
            </InOut>
          ))}
        </Col>
      </Row>
    </DashboardCard>
  );
};
